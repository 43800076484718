/**
*
* RailTransit
*
*/

import React from 'react';

function RailTransit() {
  return (
    <svg version="1.1" viewBox="0 0 51.625 72">
      <circle cy="3.9688" cx="22.264" r="2.5592" fill="#6A6CB0" />
      <circle cy="3.9688" cx="29.268" r="2.5592" fill="#6A6CB0" />
      <path d="m39.26 54.281c2.6223-1.9364 4.3302-5.0395 4.3302-8.5493v-27.763c0-5.8722-4.7605-10.633-10.633-10.633h-14.472c-5.8723 0-10.633 4.7604-10.633 10.633v27.763c0 3.5465 1.7431 6.6788 4.4122 8.6105l-10.159 15.671 6.3863-0.053566 3.2489-4.975h27.884l3.1269 4.9423 6.7662 0.086189-10.257-15.731zm-16.726-45.059h7.004c1.4132 0 2.5592 1.1458 2.5592 2.5592s-1.1459 2.5592-2.5592 2.5592h-7.004c-1.4132 0-2.5592-1.1458-2.5592-2.5592s1.145-2.5593 2.559-2.5593zm-10.102 10.883c0-2.1424 1.7368-3.8792 3.8793-3.8792h19.45c2.1425 0 3.8793 1.7367 3.8793 3.8792v7.0579c0 2.1424-1.7368 3.8792-3.8793 3.8792h-19.45c-2.1425 0-3.8793-1.7368-3.8793-3.8792v-7.058zm-0.809 25.753c0-2.0829 1.6884-3.7714 3.7714-3.7714s3.7714 1.6885 3.7714 3.7714c0 2.0829-1.6884 3.7714-3.7714 3.7714s-3.771-1.688-3.771-3.771zm2.58 15.355l2.1109-3.2326h18.879l2.0451 3.2326h-23.035zm22.204-11.583c-2.083 0-3.7714-1.6885-3.7714-3.7714s1.6884-3.7714 3.7714-3.7714 3.7714 1.6885 3.7714 3.7714c0 2.082-1.688 3.771-3.771 3.771z" fill="#6A6CB0" />
    </svg>
  );
}

export default RailTransit;
