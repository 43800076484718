/*
 * DataSetImage Messages
 *
 * This contains all the text for the DataSetImage component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  elementary: {
    id: 'app.components.DataSetImage.elementary',
    defaultMessage: 'Elementary Schools',
  },
  transit: {
    id: 'app.components.DataSetImage.transit',
    defaultMessage: 'Transit',
  },
  safety: {
    id: 'app.components.DataSetImage.safety',
    defaultMessage: 'Safety',
  },
  high: {
    id: 'app.components.DataSetImage.high',
    defaultMessage: 'High Schools',
  },
  parks: {
    id: 'app.components.DataSetImage.parks',
    defaultMessage: 'Parks',
  },
  convenience: {
    id: 'app.components.DataSetImage.convenience',
    defaultMessage: 'Convenience',
  },
});
