/* eslint key-spacing: ["error", {
  "align": {
    "beforeColon": true,
    "afterColon": true,
    "on": "colon"
  }
}] */

/**
*
* IconName
*
*/

import React from 'react';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

function IconName(props) {
  const iconKeyMessagePairs = {
    cleaners                : messages.cleaners,
    'coffee-shops'          : messages.coffeeShops,
    'fire-stations'         : messages.fireStations,
    'gas-stations'          : messages.gasStations,
    grocery                 : messages.grocery,
    gyms                    : messages.gyms,
    // 'health-care'           : messages.healthCare, // none for now
    'rail-transit'          : messages.railTransit,
    pharmacies              : messages.pharmacies,
    police                  : messages.police,
    'shopping-malls'        : messages.shoppingMalls,
    'street-level-transit'  : messages.streetLevelTransit,
    'water-transit'         : messages.waterTransit,
    'recreation-centres'    : messages.recreationCenters, // US Spelling
    'recreation-centres-ca' : messages.recreationCentres, // CA Spelling
    swimming                : messages.swimming,
    'skating-rinks'         : messages.skatingRinks,
    'ski-resorts'           : messages.skiResorts,
    arenas                  : messages.arenas,
    yoga                    : messages.yoga,
    'martial-arts'          : messages.martialArts,
    bowling                 : messages.bowling,
    // NOTE : here in case they are needed
    // 'ballDiamond'           : BallDiamond,
    // 'basketballCourt'       : BasketballCourt,
    // 'communityCenter'       : CommunityCenter,
    // 'dogPark'               : DogPark,
    // 'generic'               : Generic, // repeat
    // 'golfGeneric'           : GolfGeneric
    // 'handballCourt'         : HandballCourt,
    // 'playground'            : Playground,
    // 'pool'                  : Pool,
    // 'rink'                  : Rink,
    // 'skateboardPark'        : SkateboardPark,
    // 'skiingSnowboarding'    : SkiingSnowboarding,
    // 'splashPad'             : SplashPad,
    // 'sportsField'           : SportsField,
    // 'swimmingPool'          : SwimmingPool,
    // 'tennisCourt'           : TennisCourt,
    // 'track'                 : Track,
    // 'trail'                 : Trail,
    // 'volleyballCourt'       : VolleyballCourt,
    // 'wadingPool'            : WadingPool,
  };

  const americanToCanadianSpelling = [
    'recreation-centres',
  ];

  let iconType = props.iconType;

  // NOTE : Assumes default is US spelling
  if (props.locale === 'en-CA' && americanToCanadianSpelling.includes(props.iconType)) {
    iconType = `${props.iconType}-ca`;
  }

  return (
    <FormattedMessage {...iconKeyMessagePairs[iconType]} />
  );
}



export default IconName;
