import React from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

import HoodQAPI from '../../data/HoodQAPI';

interface IState {
  options:   string[];
  isLoading: boolean;
}

interface IProps {
  onSelected(address: string): void;
  placeholder?:                string;
  api:                         HoodQAPI;
  inputProps?:                 object;
}

export default class AddressSearch extends React.Component<IProps, IState> {
  state: IState = {
    options: [],
    isLoading: false
  }

  handleSearch = (query) => {
    this.setState({ isLoading: true });
    this.props.api.fetchAddressTypeaheadResults(query).then(options => {
      this.setState({ options, isLoading: false });
    });
  }

  handleChange = (selected: string) => {
    this.props.onSelected && this.props.onSelected(selected);
  }

  render() {
    const { options, isLoading } = this.state;
    const { placeholder, inputProps } = this.props;

    return <AsyncTypeahead
      isLoading   = {isLoading}
      inputProps  = {{ ...inputProps, autocomplete: 'off' }}
      onSearch    = {this.handleSearch}
      options     = {options}
      onChange    = {this.handleChange}
      filterBy    = {(option, props) => option}
      placeholder = {placeholder}
    />
  }


}