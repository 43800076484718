import React from 'react';
import classNames from 'classnames';

import request from 'utils/request';
import ReportHelmet from '../ReportHelmet';
import AltSchools from './components/AltSchools';
import Navigation from 'components/navigation/Navigation';
import ReportNav from 'components/navigation/ReportNav';

import { getPackageContents } from '../utils';
import getBranding from '../../branding';
import ReportBanner from '../banners/ReportBanner';
import SchoolReportBanner from '../banners/SchoolReportBanner';
import Footer from '../Footer';

const styles = require('./address_report_styles.css');

// 4 datasets = altFour
// 3 datasets = threeRows
function fetchReport(packageID, authToken) {
  return request(`${process.env.PLATFORM_URL}/reports/school/${packageID}.json?auth_token=${authToken}`)
    .then(payload => payload.data.school_report);
}

class SchoolReport extends React.Component {
  constructor(props) {
    super(props);

    this.reportTitle = 'HoodQ School Report™';

    this.state = {
      loading: false,
      report:  {}
    };
  }

  componentWillMount() {
    this.loadReport(JSON.parse(this.props.document));
  }

  loadReport = (report) => {
    if (report.branding === 'schoolq') {
      this.reportTitle = 'SchoolQ Report';
    } else {
      this.reportTitle = `${getBranding(report.branding).brandName} School Report™`;
    }

    this.setState({
      report: {
        ...report,
        package_contents: report.package
          ? report.package.options.package_contents
          : getPackageContents(report),
      },
      loading: false
    });
  }

  renderPublicAndAlternativeSchools = () => {
    const { public_schools, user, alternative_schools } = this.state.report;
    const allPublic = [...public_schools.assigned, ...public_schools.unassigned];

    return (
      <section className={classNames('row', styles.row)}>
        <div className={classNames(alternative_schools.length > 0 ? 'col-12 col-sm-9' : 'col-12', styles.quadrant)}>
          <AltSchools
            paragraph={''}
            schools={allPublic}
            assignedSchoolsCount={public_schools.assigned.length}
            userReportCreatorEmail={user.email}
            expanded={false}
            layout={alternative_schools.length > 0 ? 'ThreeQuartersRow' : 'FullHorizontalRow'}
            type={'publicSchools'}
          />
        </div>
        {alternative_schools.length > 0
          ? <div className={classNames('col-12 col-sm-3', styles.quadrant)}>
              <AltSchools
                paragraph={''}
                schools={alternative_schools}
                assignedSchoolsCount={false}
                userReportCreatorEmail={user.email}
                expanded={false}
                layout={'OneQuarterRow'}
                type={'alternativeSchools'}
              />
            </div>
          : null
        }
      </section>
    );
  }

  renderCatholicSchools = () => {
    const { user, catholic_schools } = this.state.report;
    const allSchools = [...catholic_schools.assigned, ...catholic_schools.unassigned];

    return allSchools.length > 0
      ? <section className={classNames('row', styles.row)}>
          <div className={classNames('col-12', styles.quadrant)}>
            <AltSchools
              paragraph={''}
              schools={allSchools}
              assignedSchoolCount={catholic_schools.assigned.length}
              userReportCreatorEmail={user.email}
              expanded={false}
              layout={'FullHorizontalRow'}
              type={'catholicSchools'}
            />
          </div>
        </section>
      : null;
  }

  renderPrivateSchools = () => {
    const { user, private_schools } = this.state.report;

    return private_schools.length > 0
      ? <section className={classNames('row', styles.row)}>
          <div className={classNames('col-12', styles.quadrant)}>
            <AltSchools
              paragraph={''}
              schools={private_schools}
              assignedSchoolCount={false}
              userReportCreatorEmail={user.email}
              expanded={false}
              layout={'FullHorizontalRow'}
              type={'privateSchools'}
            />
          </div>
        </section>
      : null;
  }

  render() {
    const { user, address, branding, campaign, package: { options } } = this.state.report;

    // const package_options = !this.state.loading ? this.state.report.package.options : null;

    return this.state.loading ? null : (
      <div className={classNames('container', styles.highlights)}>
        {this.renderPublicAndAlternativeSchools()}
        {this.renderCatholicSchools()}
        {this.renderPrivateSchools()}
      </div>
    );
  }
}

export default SchoolReport;