import Poller from 'enginuity-library/js/lib/Poller';

export default class ProductCreationInterstitial {
  constructor(element) {
    this.poller = new Poller(element.getAttribute('data-product-url'));
    this.poller
      .tick(this.handleUpdate.bind(this))
      .then(this.handleCompletion.bind(this))
      .catch(this.handleError.bind(this))
      .start();
  }

  handleUpdate(payload) {
    if (payload.state === "error") {
      window.location.reload();
    }
  }

  handleCompletion(payload) {
    window.location.reload();
  }

  handleError(payload) {
    console.error("ERROR IN POLLER");
    console.dir(payload);
  }
}