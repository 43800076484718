/**
*
* FeatureItem
*
*/

import React from 'react';

import styles from './styles.css';

import textHelpers from 'utils/textHelpers';

function FeatureItem(props) {
  const { valueProp, label, children } = props;

  const displayValue    = valueProp && valueProp.length < 100;
  const displayChildren = typeof children !== 'undefined';

  let valueContent = null;

  if (displayValue) {
    let value = valueProp;

    if (label === 'Address' && value) {
      value = value.split(',').slice(0, 1).join(',');
    }

    if (value.constructor === Array && value) {
      value = textHelpers.toSentence(value);
    }

    valueContent = (
      <span>
        {value}
      </span>
    );
  } else if (displayChildren) {
    valueContent = (
      <span>
        {children}
      </span>
    );
  }

  return (
    <div className={styles.featureItem}>
      <span className={styles.label}>
        {label}
      </span>

      {valueContent}
    </div>
  );
}

// NOTE : Requires EITHER value OR children
// TODO : make sure of this somehow...


export default FeatureItem;
