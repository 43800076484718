/* eslint no-multi-spaces: "off" */
/* eslint react/jsx-equals-spacing: "off" */

/**
*
* SchoolsReport
*
*/

import React from 'react';
import classNames from 'classnames';

import styles from './styles.css';

import GenericSchoolsBlock from '../GenericSchoolsBlock';

function SchoolsReport(props) {
  const { assignedSchoolsContent, unassignedSchoolsContent, catholicAssignedSchoolsContent, catholicUnassignedSchoolsContent, privateSchoolsContent, locale, displayScores } = props;
  const assignedSchoolsCount = {
    assigned:         Object.keys(assignedSchoolsContent).length,
    catholicAssigned: Object.keys(catholicAssignedSchoolsContent).length,
  };

  const schoolsTypes = {
    // public:             publicSchoolsContent,
    assigned:           assignedSchoolsContent,
    unassigned:         unassignedSchoolsContent,
    catholicAssigned:   catholicAssignedSchoolsContent,
    catholicUnassigned: catholicUnassignedSchoolsContent,
    private:            privateSchoolsContent,
  };

  let schoolsBlocks = [];

  Object.keys(schoolsTypes).forEach((schoolsType) => {
    const schoolsObject = schoolsTypes[schoolsType];
    // NOTE : avoids adding section if content is empty
    if (Object.keys(schoolsObject).length) {
      schoolsBlocks.push(
        <GenericSchoolsBlock
          schoolsType          = {schoolsType}
          schools              = {schoolsObject}
          locale               = {locale}
          displayScores        = {displayScores}
          key                  = {schoolsType}
          assignedSchoolsCount = {assignedSchoolsCount[schoolsType]}
        />
      );
    }
  });

  return (
    <div className={classNames('row', styles.schoolsReport)}>
      <div className="offset-1 col-10">
        {schoolsBlocks}
      </div>
    </div>
  );
}



export default SchoolsReport;
