/**
*
* DetailedSchoolBlock
*
*/

import React from 'react';
import classNames from 'classnames';

import styles from './styles.css';

import TimeLengthDistance from '../TimeLengthDistance';
import DetailedInfoTable from '../DetailedInfoTable';
import SVGIcon from '../../../SVGIcon';

import textHelpers from 'utils/textHelpers';

const TWENTY_MIN_WALK = 1.5;

function DetailedSchoolBlock(params) {
  const { name, distance, grades, address, paragraph, url, info, locale, score } = params;

  let scoreDisplay = null;
  if (score) {
    if (locale === 'en-CA') {
      const fraserScore = (
        <div className={styles.fraserInstitute}>
          <span>
            {score}
          </span>
        </div>
      );

      scoreDisplay = (
        <div>
          <div className={classNames(styles.iconXS, 'd-xs-block d-none')}>
            <SVGIcon iconType="circle" />
            {fraserScore}
          </div>
          <div className={classNames(styles.icon, 'd-xs-none')}>
            <SVGIcon iconType="circle" />
            {fraserScore}
          </div>
        </div>
      );
    } else if (locale === 'en-US') {
      let iconStyle = styles.highScore;
      if (score > 0 && score <= 3) {
        iconStyle = styles.lowScore;
      } else if (score > 3 && score <= 7) {
        iconStyle = styles.mediumScore;
      }

      const greatSchoolsScore = (
        <div className={styles.greatSchools}>
          <span>
            {score}
          </span>
          <p>
            out of 10
          </p>
        </div>
      );

      scoreDisplay = (
        <div>
          <div className={classNames(styles.iconXS, 'd-xs-block d-none')}>
            <SVGIcon iconType="circle" />
            {greatSchoolsScore}
          </div>
          <div className={classNames(iconStyle, styles.icon, 'd-xs-none')}>
            <SVGIcon iconType="circle" />
            {greatSchoolsScore}
          </div>
        </div>
      );
    }
  }

  let gradesOffered = null;
  if (grades) {
    gradesOffered = (
      <div className={styles.gradesOffered}>
        <p>{textHelpers.toSentence(grades)}</p>
      </div>
    );
  }

  let detailedInfoTable = null;
  if (info) {
    detailedInfoTable = (
      <p>
        <DetailedInfoTable info={info} />
      </p>
    );
  }

  const urlAnchorLink = textHelpers.shortenUrl(url);
  const urlAnchor = <a href={url} target="_blank">{urlAnchorLink}</a>;

  const distanceContent = distance > TWENTY_MIN_WALK ? (
    <p>
      <TimeLengthDistance distance={distance} locale={locale} hideWalkingPerson />
    </p>
  ) : (
    <p>
      <TimeLengthDistance distance={distance} locale={locale} />
    </p>
  );

  return (
    <div className={styles.detailedSchoolBlock}>
      {scoreDisplay}
      <h3>{name}</h3>
      {distanceContent}
      {gradesOffered}
      <p>{address}</p>
      <p>{paragraph}</p>
      <p>{urlAnchor}</p>
      {detailedInfoTable}
    </div>
  );
}



export default DetailedSchoolBlock;
