/* eslint key-spacing: [2, { "align": "value" }] */

const Regex = {
  blankMessage: /^\s+$|^$/i,
  phoneDisplay: /([\d]{3})([\d]{3})([\d]{4})/,
  email:        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  url:          /^(https?:\/\/)?(www.)?([\dA-z\.-]+)\.([A-z\.]{2,6}[\/\w \.-]*)*\/?$/,
  urlShortener: /(([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)\/(.+)*\/([^\?&]+)(((\?|&)[^=\n]+=[^&\n]+)?)/i,
  website:      /[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
  phone:        /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/,
  expiryDate:   /(\d{2})\s\/\s(\d{2})/,
};

module.exports = Regex;
