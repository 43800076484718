/* eslint key-spacing: ["error", {
  "align": {
    "beforeColon": true,
    "afterColon": true,
    "on": "colon"
  }
}] */

export default {
  'Indoor Pool'            : 'Pool',
  'Outdoor Pool'           : 'Pool',
  Swimming                 : 'Pool',
  Pool                     : 'Pool',
  'Leash-Free Dog Area'    : 'Dog Park',
  'Dog Park'               : 'Dog Park',
  Playground               : 'Playground',
  'Tot Lot'                : 'Playground',
  'Wading Pool'            : 'Wading Pool',
  'Cricket Field'          : 'Sports Field',
  'Football Field'         : 'Sports Field',
  'Indoor Soccer Field'    : 'Sports Field',
  'Lacrosse Field'         : 'Sports Field',
  'Rugby Field'            : 'Sports Field',
  'Soccer Field'           : 'Sports Field',
  'Sports Field'           : 'Sports Field',
  'Ultimate Frisbee Field' : 'Sports Field',
  'Tennis Court'           : 'Tennis Court',
  'Basketball Court'       : 'Basketball Court',
  'Volleyball Court'       : 'Volleyball Court',
  'Handball Court'         : 'Handball Court',
  'Spray Park'             : 'Splash Pad',
  'Splash Pad'             : 'Splash Pad',
  'Ball Diamond'           : 'Ball Diamond',
  'Curling Rink'           : 'Rink',
  'Indoor Rink'            : 'Rink',
  'Outdoor Rink'           : 'Rink',
  'Skating Rink'           : 'Rink',
  Rink                     : 'Rink',
  Arena                    : 'Arena',
  'Community Rec. Centre'  : 'Community Centre',
  'Fitness/Leisure Centre' : 'Community Centre',
  'Community Centre'       : 'Community Centre',
  'Skateboard Park'        : 'Skateboard Park',
  'Golf Course'            : 'Golf Course',
  'Mini Golf'              : 'Mini Golf',
  'Driving Range'          : 'Driving Range',
  Track                    : 'Track',
  'Indoor Track'           : 'Track',
  'Outdoor Track'          : 'Track',
  'Cross-Country Skiing'   : 'Skiing or Snowboarding',
  'Ski Terrain Park'       : 'Skiing or Snowboarding',
  Skiing                   : 'Skiing or Snowboarding',
  Snowboarding             : 'Skiing or Snowboarding',
  'Skiing or Snowboarding' : 'Skiing or Snowboarding',
  'Fitness Trails'         : 'Trail',
  'Bicycle Trails'         : 'Trail',
  Hiking                   : 'Trail',
  Trails                   : 'Trail',
  Trail                    : 'Trail',
};
