/**
*
* MatureAdults
*
*/

import React from 'react';

function MatureAdults() {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 216 216">
      <path className="cls-2" fill="#FFF" d="M108.44549,137.26064a1.44926,1.44926,0,0,0,1.56861,1.28807h0a1.44926,1.44926,0,0,0,1.56861-1.28807V44.15151a1.44926,1.44926,0,0,0-1.56861-1.28807h0a1.44926,1.44926,0,0,0-1.56861,1.28807v93.10913Z" />
      <polyline className="cls-3" stroke="#FFF" strokeMiterlimit="10" fill="none" strokeWidth="1.34452px" points="120.226 126.896 110.014 126.896 110.91 52.051 125.42 79.757" />
      <line className="cls-3" stroke="#FFF" strokeMiterlimit="10" fill="none" strokeWidth="1.34452px" x1="132.09863" y1="126.89616" x2="129.32332" y2="126.89616" />
      <polyline className="cls-3" stroke="#FFF" strokeMiterlimit="10" fill="none" strokeWidth="1.34452px" points="137.823 103.608 149.902 126.896 141.802 126.896" />
      <path className="cls-2" fill="#FFF" d="M106.87688,46.22474s-56.47,14.34159-44.36928,91.42761c0,0,27.33865-27.33865,47.05833-24.20143C109.56593,113.45093,90.29442,85.21593,106.87688,46.22474Z" />
      <path className="cls-2" fill="#FFF" d="M153.37015,83.3522c-1.87711-.80726-3.51116,2.16048-5.72071,7.831-0.12137.31168-1.52432,3.41129-1.64988,3.711l-7.27983.5527a25.55641,25.55641,0,0,0-4.06311-.38365,0.30262,0.30262,0,0,0-.0709.036,9.13689,9.13689,0,0,0-1.94654-.04571l-7.91861.0398,0.00222-.00714c-0.079,0-.17184.00574-0.25768,0.00837l-0.0938.00049-0.00131.00263a7.30419,7.30419,0,0,0-5.02457,2.2107c-2.48775,2.58-3.8797,7.18808-4.20418,14.13744l-0.06237.02823c0.00033,0.09093.00829,0.4328,0.0192,0.87053-0.00451.13877-.01567,0.26268-0.01937,0.40326l0.02921-.01223c0.02864,1.11631.06852,2.58409,0.07222,2.79844,0.14263,8.2645.36781,8.97246,2.41069,9.014s2.48643-2.21735,2.33913-3.7443c-0.09536-.98829-0.36764-6.52288-0.42566-9.90454l0.04686-.01961a17.63686,17.63686,0,0,1,.20253-2.34684,52.35911,52.35911,0,0,1,1.64931-5.6718v47.434h0.04669a3.56354,3.56354,0,0,0,7.07172,0h0.02445v-0.24069c0.0046-.07468.02232-0.14574,0.02232-0.22165s-0.01773-.147-0.02232-0.22173V119.361h0.79027l3.67545,6.40192a3.04933,3.04933,0,0,0,.19736.33433v13.95a3.599,3.599,0,0,0,7.19809,0V125.12188a3.56482,3.56482,0,0,0-.81341-2.25313c-0.01-.02363-0.013-0.04858-0.02363-0.07213l-2.453-5.42339v-14.7466s-0.01707-.79486-0.02708-1.8337c1.30907-.11185,2.51245-0.21706,2.69553-0.241,0,0,7.28426-.30765,8.15429-0.48352S149.592,98.353,149.592,98.353c1.10137-2.7144,3.14343-7.35713,3.56368-8.42814C153.79171,88.30406,155.22666,84.15068,153.37015,83.3522Z" />
      <circle className="cls-2" fill="#FFF" cx="129.60651" cy="86.70859" r="6.3264" />
      <path className="cls-4" fill="#00AEEF" d="M127.72164,87.72756a2.00957,2.00957,0,0,0,4.01914,0" />
      <polygon className="cls-2" fill="#FFF" points="64.636 154.795 149.341 154.795 156.303 144.703 53.973 135.092 64.636 154.795" />
      <path className="cls-5" strokeMiterlimit="10" fill="none" strokeWidth="1.34452px" stroke="#00AEEF" d="M65.46775,83.47687S82.24106,64.98727,101.74594,65.27" />
      <path className="cls-5" strokeMiterlimit="10" fill="none" strokeWidth="1.34452px" stroke="#00AEEF" d="M58.128,112.836s20.37534-23.76144,43.618-21.55951" />
      <rect className="cls-4" fill="#00AEEF" x="137.18686" y="103.29034" width="1.48407" height="4.24019" />
      <path className="cls-6" stroke="#FFF" strokeMiterlimit="10" fill="none" strokeWidth="1.69608px" d="M57.49943,163.45543c4.67314,2.50287,9.865-2.55648,15.14764-2.99906,5.55627-.46551,10.955,4.29662,16.27552,2.62912,1.98492-.62209,3.61339-2.077,5.56581-2.79459,5.59752-2.05736,11.37254,2.38,17.28668,3.1468,6.04255,0.78345,12.14795-2.34246,18.14325-1.255,3.18477,0.57766,6.135,2.32282,9.36982,2.43351,3.13124,0.10714,6.07678-1.32587,9.08577-2.19885s6.62807-1.05549,8.90132,1.10053" />
    </svg>
  );
}

export default MatureAdults;
