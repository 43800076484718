/* eslint no-multi-spaces: "off" */
/* eslint react/jsx-equals-spacing: "off" */
/* eslint no-param-reassign: ["error", { "props": false }] */

/**
*
* SafetyReport
*
*/

import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import messages from './messages';
import styles from './styles.css';

import DetailedPlaceBlock from '../DetailedPlaceBlock';
import SVGIcon from '../../../SVGIcon';

function SafetyReport(props) {
  // TODO : consider dynamic descriptions for this section
  const { safety, locale, score } = props; // paragraph,

  // NOTE : comes filtered from DataSetReport component
  const filteredSafety = Object.keys(safety).map((key) => safety[key]);
  const safetyContent = [];

  filteredSafety.forEach((item) => {
    let relevantFeatures = [];
    let itemName = '';

    switch (item.place_category_key) {
      case 'fire-stations':
        relevantFeatures = ['Address'];
        itemName = (
          <FormattedMessage
            {...messages.nearestPlace}
            values={{
              place: 'fire station',
              name: item.name,
            }}
          />
        );
        break;
      case 'police':
        relevantFeatures = ['Address'];
        itemName = (
          <FormattedMessage
            {...messages.nearestPlace}
            values={{
              place: 'police facility',
              name: item.name,
            }}
          />
        );
        break;
      case 'health-care':
      default:
        relevantFeatures = ['Address', 'Phone Number', 'Type'];
        itemName = item.name;
    }

    // NOTE : filters only relevant features that
    const info = Object.keys(item.features)
      .filter(key => relevantFeatures.includes(key))
      .reduce((obj, key) => {
        obj[key] = item.features[key];
        return obj;
      }, {});

    safetyContent.push(
      <DetailedPlaceBlock
        icon              = {item.place_category_key}
        name              = {itemName}
        description       = {item.description}
        distance          = {item.distance}
        hideWalkingPerson
        address           = {item.address}
        paragraph         = {item.paragraph}
        info              = {info}
        url               = {item.url}
        locale            = {locale}
        key               = {item.name}
      />
    );
  });

  let scoreDisplay = null;
  if (score) {
    scoreDisplay = (
      <div>
        <div className={classNames(styles.iconXS, 'd-xs-block d-none')}>
          <SVGIcon iconType="circle" />
          <div className={styles.score}>
            <span>{score}</span>
          </div>
        </div>
        <div className={classNames(styles.icon, 'd-xs-none')}>
          <SVGIcon iconType="circle" />
          <div className={styles.score}>
            <span>{score}</span>
          </div>
        </div>
      </div>
    );
  }

  const paragraph = locale === 'en-CA' ? (
    <FormattedMessage {...messages.descriptionCA} />
  ) : (
    <FormattedMessage {...messages.description} />
  );

  return (
    <div className={classNames('row', styles.safetyReport)}>
      <div className="offset-1 col-10">
        {scoreDisplay}
        <div className={styles.titleParagraph}>
          <h2>
            <FormattedMessage {...messages.header} />
          </h2>
          <p>{paragraph}</p>
        </div>
        {safetyContent}
      </div>
    </div>
  );
}



export default SafetyReport;
