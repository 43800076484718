/**
*
* Playground
*
*/

import React from 'react';

function Playground() {
  return (
    <svg version="1.1" y="0px" x="0px" viewBox="0 0 53.125 72">
      <g fill="#01818E">
        <path d="m17.521 10.362l0.005136-0.016973c0.88008-2.9226 1.9585-4.9887 3.5358-6.362 0.78459-0.68129 1.6848-1.1608 2.6519-1.4516 0.96812-0.29257 1.9975-0.40568 3.0949-0.40616 1.0969 0.000479 2.1266 0.11359 3.0947 0.40616 1.4545 0.43188 2.7417 1.3079 3.7461 2.6131 1.0111 1.3048 1.7807 3.003 2.4416 5.2004l0.004833 0.015571 15.516 57.689c0.18305 0.68053-0.2203 1.3808-0.90127 1.5642-0.11092 0.029755-0.22248 0.043938-0.33216 0.044098-0.56288 0-1.0781-0.37546-1.2316-0.94537l-15.507-57.653c-0.80609-2.6896-1.737-4.2734-2.7565-5.1476-0.51475-0.4437-1.061-0.73706-1.7201-0.9385-0.48763-0.14786-1.0407-0.2393-1.669-0.277l-2.275 42.033-1.1152-0.060448 2.2731-41.984c-0.73536 0.026323-1.3725 0.12089-1.9252 0.28853-0.98477 0.30598-1.7326 0.80078-2.4692 1.7362-0.72773 0.93274-1.4045 2.34-2.0074 4.3504l-15.509 57.652c-0.18306 0.68051-0.88289 1.0839-1.5639 0.90127-0.68051-0.18336-1.0839-0.88335-0.90111-1.5642l15.518-57.688z" />
        <rect y="55.752" width="2.4368" x="22.68" transform="matrix(.05415 -.99853 .99853 .05415 -38.12 81.383)" height="10.122" />
        <circle cy="40.64" cx="18.632" r="3.5886" />
        <path d="m19.197 44.734c1.375-0.2798 2.7339 1.1776 3.0043 2.8077 0.000782 0.003269 0.001707 0.006699 0.002489 0.009972-0.47704-0.21499-1.0226-0.55634-1.639-1.1008-0.29134-0.26002-0.66836-0.40413-1.0603-0.40413-0.45429 0-0.88835 0.1946-1.1906 0.53345-0.58594 0.65605-0.52846 1.6664 0.12775 2.252 1.7734 1.595 3.4491 2.1146 4.6911 2.2338 0.33106 1.0973 0.73379 2.2137 1.2548 3.2278 0.54467-0.074169 1.1537-0.12278 1.8195-0.12386 1.5553-0.000622 3.4166 0.26938 5.4028 1.0879 1.9867 0.81683 4.0866 2.1796 6.1305 4.2911 0.76651 0.79065 0.74673 2.0535-0.044399 2.8199-0.3873 0.3753-0.88771 0.5621-1.3875 0.5621-0.52098 0-1.0413-0.20285-1.4325-0.6065-1.6985-1.7511-3.3204-2.7744-4.7843-3.3784-1.4642-0.60168-2.7822-0.78661-3.8846-0.7877-0.95674 0-1.746 0.14365-2.2882 0.28432-0.34898 0.15751-0.72086 0.29461-1.1069 0.37049-0.21623 0.0578-0.43607 0.078362-0.65168 0.063095-0.82961-0.010433-1.6985-0.387-2.5262-1.5056-1.8982-3.0212-2.5896-5.0125-2.9953-8.3849-0.281-2.452 1.183-3.97 2.558-4.25z" />
        <path d="m18.552 46.792c0.46925-0.52581 1.276-0.57177 1.8016-0.10221 0.72085 0.63689 1.3663 1.0222 1.9482 1.2481 0.54186 0.21032 1.0287 0.28276 1.4684 0.28665 0.8402 0.001404 1.5645-0.28868 2.0876-0.59512 0.2594-0.15191 0.46411-0.30521 0.59654-0.4138 0.066048-0.054531 0.1142-0.097683 0.14192-0.1237 0.011532-0.010433 0.019638-0.018219 0.024313-0.022892 0.49168-0.50322 1.2975-0.51397 1.8024-0.023693 0.50572 0.49108 0.5177 1.2992 0.026337 1.8049l0.000307-0.000462c-0.057018 0.057335-0.50711 0.51568-1.3018 0.9826-0.79207 0.46334-1.9493 0.94348-3.3776 0.94489-0.00997 0-0.02026 0.00016-0.02026 0.00016-0.24007 0-0.47579-0.015427-0.71572-0.043777-1.374-0.16218-2.8962-0.80499-4.38-2.1398-0.525-0.469-0.57-1.276-0.101-1.802z" />
      </g>
    </svg>
  );
}

export default Playground;
