const PREVIEWER_DATA_ATTRIBUTE = 'data-image-preview';

function didChange(event) {
  const { target } = event;

  if (target.tagName == "INPUT" && target.type == "file" && target.hasAttribute(PREVIEWER_DATA_ATTRIBUTE)) {
    if (target.files && target.files[0]) {
      const imageEl = document.querySelector(target.getAttribute(PREVIEWER_DATA_ATTRIBUTE));
      const reader = new FileReader();

      reader.onload = function(e) {
        imageEl.setAttribute('src', e.target.result);
      }
  
      reader.readAsDataURL(target.files[0]);
    }
  }
}

function start() {
  document.addEventListener("change", didChange);
}


export default { start };