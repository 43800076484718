/*
 * ParksReport Messages
 *
 * This contains all the text for the ParksReport component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  headerParks: {
    id: 'app.components.ParksReport.headerParks',
    defaultMessage: 'Parks',
  },
  description: {
    id: 'app.components.ParksReport.description',
    defaultMessage: 'Whether you want to sit at a park bench with a good book or use one of the special facilities offered, you\'ll be happy to know that your neighborhood is close to many wonderful recreational opportunities. See Parks and Recreation facilities near you below:',
  },
  descriptionCA: {
    id: 'app.components.ParksReport.descriptionCA',
    defaultMessage: 'Whether you want to sit at a park bench with a good book or use one of the special facilities offered, you\'ll be happy to know that your neighbourhood is close to many wonderful recreational opportunities. See Parks and Recreation facilities near you below:',
  },
  headerRec: {
    id: 'app.components.ParksReport.headerRec',
    defaultMessage: ' & Rec.',
  },
});
