/**
 *
 * Button.react.js
 *
 * A common button, if you pass it a prop "route" it'll render a link to a react-router route
 * otherwise it'll render a link with an onclick
 */

import React, { PropTypes, Children } from 'react';
import classNames from 'classnames';

import styles from './styles.css';

function Button(props) {
  const className = props.className ? props.className : styles.button;
  const buttonWrapperClass = props.buttonWrapperClassName ? props.buttonWrapperClassName : styles.buttonWrapper;

  // Render an anchor tag
  let button = (
    <a className={className} href={props.href} target={props.target} onClick={props.onClick}>
      {Children.toArray(props.children)}
    </a>
  );

  // If the Button has a handleRoute OR handleClick prop, we want to render a button
  if (props.handleRoute) {
    button = (
      <button className={className} onClick={props.handleRoute}>
        {Children.toArray(props.children)}
      </button>
    );
  } else if (props.handleClick) {
    button = (
      <button className={className} onClick={props.handleClick}>
        {Children.toArray(props.children)}
      </button>
    );
  } else if (props.download) { // If the Button has download prop set to true
    button = (
      <a className={className} href={props.href} target={props.target} download>
        {Children.toArray(props.children)}
      </a>
    );
  } else if (props.social) {
    button = (
      <a className={classNames(className, styles.social)}>
        {Children.toArray(props.children)}
      </a>
    );
  }

  return (
    <div className={buttonWrapperClass}>
      {button}
    </div>
  );
}



export default Button;
