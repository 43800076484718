import React from 'react';
import classNames from 'classnames';

import textHelpers from 'utils/textHelpers';
import SVGIcon from 'components/reports/SVGIcon';

const styles = require('../styles.css');
const mobStyles = require('../mobileStylesWNav.css');

export default function AddressReportBanner(props) {
  return (
    <div>
      <div className={classNames('d-xs-block d-none d-print-none', mobStyles.mobileAgentBranding)}>
        <div className="col-xs-12 col-sm-4">
          {props.agentSlug}
        </div>
      </div>
      <div className={classNames('col-xs-12 col-sm-8', styles.headerContent)}>
        <div className={classNames(styles.homePin, 'd-xs-none')}>
          <SVGIcon iconType="home-pin" />
        </div>
        <div className={classNames(styles.headerContentTitle, styles.headerContentWithPin)}>
          <h5 className={styles.city}>{props.address}</h5>
          <h5 className={styles.headerTitle}>{props.reportTitle}</h5>
        </div>
      </div>
      <div className={classNames('d-xs-none', styles.printableAgentBranding)}>
        <div className="col-xs-12 col-sm-4">
          {props.agentSlug}
        </div>
      </div>
    </div>
  )
}