/**
*
* SportsField
*
*/

import React from 'react';

function SportsField() {
  return (
    <svg version="1.1" y="0px" x="0px" viewBox="0 0 53.944775 72">
      <path d="m51.655 70.157h-49.782v-68.981h49.782v68.981zm-46.929-2.854h44.075v-63.273h-44.075v63.273z" fill="#01818E" />
      <rect y="34.24" x="3.3104" fill="#01818E" width="46.907" height="2.8535" />
      <path d="m26.764 48.115c-6.8638 0-12.448-5.5845-12.448-12.448s5.584-12.448 12.448-12.448 12.448 5.584 12.448 12.448-5.584 12.448-12.448 12.448zm0-22.043c-5.2905 0-9.5947 4.3042-9.5947 9.5942 0 5.2905 4.3042 9.5947 9.5947 9.5947s9.5942-4.3042 9.5942-9.5947c0-5.29-4.303-9.594-9.594-9.594z" fill="#01818E" />
      <polygon points="38.123 11.674 15.405 11.674 15.405 2.6475 18.258 2.6475 18.258 8.8203 35.269 8.8203 35.269 2.7808 38.123 2.7808" fill="#01818E" />
      <polygon points="38.123 68.819 35.269 68.819 35.269 62.735 18.258 62.735 18.258 68.774 15.405 68.774 15.405 59.882 38.123 59.882" fill="#01818E" />
    </svg>
  );
}

export default SportsField;
