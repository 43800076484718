/**
*
* Trail
*
*/

import React from 'react';

function Trail() {
  return (
    <svg version="1.1" y="0px" x="0px" viewBox="0 0 37.333332 74.25">
      <circle cy="7.1213" cx="20.305" r="5.498" fill="#01818E" />
      <path d="m19.453 50.311c0.58931-2.2958 2.9653-7.5954 1.4357-9.7267-0.61827-0.86147-0.45431-1.7016 0.072809-2.2908-0.0212-0.15941-0.042576-0.31896-0.071047-0.47754-0.36035-1.9274-0.51866-3.8399-0.49389-5.7108 0.082737-4.2925 0.64524-6.0634 1.0126-7.1174 0.1621-0.58252 0.34197-1.1564 0.54004-1.7201 1.0344-3.0175 0.27969-6.5197-2.2005-8.6089-2.439-2.0703-6.7981-1.0064-8.2946 3.5151-2.1626 6.5341-2.644 13.033-2.3159 19.913 0.12614 2.6456 1.674 4.873 3.3196 6.3019-0.38582 5.3859-1.8384 13.747-6.6 22.03-0.98774 1.7234-0.39054 3.9215 1.333 4.9091 1.7239 0.98703 3.9214 0.39067 4.9091-1.333 3.8532-6.7456 5.0044-10.38 6.3169-16.088 0.28-0.984 0.889-3.025 1.035-3.595z" fill="#01818E" />
      <path d="m34.755 32.366c0.37629-0.44842 0.58304-1.0396 0.53109-1.669-0.086189-1.0437-0.85632-1.8654-1.833-2.0647-0.20898-0.042849-0.42693-0.058353-0.65021-0.03994-0.29242 0.023806-0.5754 0.040524-0.84943 0.05158-0.030193 0.001122-0.058874 0.001066-0.088934 0.001896-0.050964 0.001608-0.10072 0.002337-0.15116 0.003378-0.53621 0.011766-1.0398-0.000717-1.5102-0.03721-1.6637-0.12819-2.9348-0.5359-3.9465-1.0781-1.1701-0.63134-2.0456-1.4857-2.7133-2.4523-0.46824-0.6783-0.8352-1.4114-1.126-2.1639-0.69589-1.8084-0.8684-3.6454-0.89634-4.4872-0.006304-0.16948-0.007193-0.29865-0.007219-0.37908 0.000198-0.04035 0.000826-0.068361 0.000853-0.083134l0.00038-0.004507c0.047211-1.2707-0.94409-2.3391-2.2146-2.3864-1.2705-0.047791-2.3385 0.94352-2.3863 2.2141-0.001663 0.049704-0.011423 0.33928 0.006094 0.81021 0.070992 1.5532 0.36985 5.1048 2.4463 8.5042 0.81037 1.3174 1.9231 2.6021 3.3834 3.6498 0.41415 0.29702 0.85175 0.57825 1.3237 0.83177 2.127 1.1497 4.808 1.7777 8.0246 1.6581 0.008858-0.000328 0.01757-0.000652 0.026398-0.00098 0.14323-0.005329 0.29177-0.018166 0.43699-0.026394 0.20612-0.011578 0.4098-0.020279 0.61993-0.037109 0.636-0.054 1.191-0.362 1.572-0.816z" fill="#01818E" />
      <path d="m8.0572 32.12c-1.806 0.11031-3.5883 0.065361-4.2394-0.4399-1.4959-1.1604-2.6467-9.1612-1.4734-12.995 0.83439-2.7265 7.3784-4.1512 11.162-5.1542-4.2223 2.933-5.1431 12.688-5.4488 18.589z" fill="#01818E" />
      <path d="m20.241 63.1c-0.58203 0-1.1719-0.14111-1.7188-0.43799-1.7529-0.95068-2.4023-3.1426-1.4521-4.895l4.8213-8.8862-8.5937-8.2026c-1.4424-1.377-1.4951-3.6626-0.11816-5.105 1.376-1.4424 3.6621-1.4961 5.1045-0.11865l10.591 10.109c1.1787 1.1255 1.458 2.9009 0.68066 4.3335l-6.1377 11.313c-0.655 1.207-1.896 1.891-3.177 1.891z" fill="#01818E" />
      <path d="m20.966 72.006c-0.045898 0-0.092773-0.004395-0.13965-0.013672-0.3916-0.07666-0.64648-0.45606-0.57031-0.84717l9.2666-47.298c0.076172-0.39111 0.45898-0.646 0.84766-0.56982 0.3916 0.07666 0.64648 0.45606 0.57031 0.84717l-9.2666 47.298c-0.067 0.345-0.37 0.584-0.708 0.584z" fill="#01818E" />
    </svg>
  );
}

export default Trail;
