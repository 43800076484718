export enum SubscriptionPlan {
  MONTHLY = 'month',
  YEARLY = 'year',
  MARKETING_CENTRE = 'marketing_centre'
};

export const PROVINCES = {
  AB: 'Alberta',
  BC: 'British Columbia',
  MB: 'Manitoba',
  NB: 'New Brunswick',
  NL: 'Newfoundland And Labrador',
  NS: 'Nova Scotia',
  NT: 'Northwest Territories',
  NU: 'Nunavut',
  ON: 'Ontario',
  PE: 'Prince Edward Island',
  QC: 'Quebec',
  SK: 'Saskatchewan',
  YT: 'Yukon'
};

export enum Province {
  ALBERTA                   = 'AB',
  BRITISH_COLUMBIA          = 'BC',
  MANITOBA                  = 'MB',
  NEW_BRUNSWICK             = 'NB',
  NEWFOUNDLAND_AND_LABRADOR = 'NL',
  NOVA_SCOTIA               = 'NS',
  NORTHWEST_TERRITORIES     = 'NT',
  NUNAVUT                   = 'NU',
  ONTARIO                   = 'ON',
  PRINCE_EDWARD_ISLAND      = 'PE',
  QUEBEC                    = 'QC',
  SASKATCHEWAN              = 'SK',
  YUKON                     = 'YT'
}

export const PLAN_PRICES = {
  [SubscriptionPlan.MONTHLY]: 2495,
  [SubscriptionPlan.YEARLY]:  23995
};

export interface User {
  id: string;
  name: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  website_address: string;
  profile_picture: string;
  brokerage_logo: string;
}

export interface Coupon {
  id:          string;
  percent_off: number;
}

export interface Affiliate {
  name:       string;
  first_name: string;
}