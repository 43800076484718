/*
 * DataSetsGallery Messages
 *
 * This contains all the text for the DataSetsGallery component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.DataSetsGallery.header',
    defaultMessage: 'Data Sets Gallery',
  },
});
