export default class ExpandingNav {
  constructor(element) {
    this.element = element;

    let state = window.localStorage.getItem('expanding-nav.state');

    this.element.setAttribute('expanded', state || false);

    this.element.addEventListener('toggled', this.handleToggled.bind(this));
  }

  handleToggled(event) {
    window.localStorage.setItem('expanding-nav.state', event.detail.expanded);
  }
}