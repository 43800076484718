/* eslint indent: "off"*/
/* eslint no-multi-spaces: "off" */

/**
*
* CustomHeaderLayout
*
*/

import React from 'react';
// import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

// import messages from './messages';
import styles from './styles.css';

function CustomHeaderLayout(props) {
  const { address, userEmail } = props; // , customHeaderImage

  let customHeaderImage      = props.customHeaderImage;
  let customHeaderImagePrint = props.customHeaderImagePrint || props.customHeaderImage;
  if (userEmail === 'eric@homesbyeric.ca') {
    customHeaderImage      = '/assets/branding/customExceptions/erichomesbyericca.jpg';
    customHeaderImagePrint = '/assets/branding/customExceptionsPrint/erichomesbyericca.jpg';
  } else if (userEmail === 'results@lilithomes.com') {
    customHeaderImage      = '/assets/branding/customExceptions/resultslilithomescom.png';
    customHeaderImagePrint = '/assets/branding/customExceptionsPrint/resultslilithomescom.png';
  } else if (userEmail === 'fatima@athomeintoronto.com' || userEmail === 'dave+fatima@hoodq.com' || userEmail === 'aldo@hoodq.com' || userEmail === 'best@test.com') {
    customHeaderImage      = '/assets/branding/customExceptions/fatimaathomeintorontocom.png';
    customHeaderImagePrint = '/assets/branding/customExceptionsPrint/fatimaathomeintorontocom.png';
  } else if (userEmail === 'selene@therichardsgroup.ca') {
    customHeaderImage      = '/assets/branding/customExceptions/selenetherichardsgroupca.png';
    customHeaderImagePrint = '/assets/branding/customExceptionsPrint/selenetherichardsgroupca.png';
  } else if (userEmail === 'csconza@kw.com') {
    customHeaderImage      = '/assets/branding/customExceptions/csconzakwcom.jpg';
    customHeaderImagePrint = '/assets/branding/customExceptionsPrint/csconzakwcom.jpg';
  } else if (userEmail === 'zan@zanmolko.com') {
    customHeaderImage      = '/assets/branding/customExceptions/zanzanmolkocom.png';
    customHeaderImagePrint = '/assets/branding/customExceptionsPrint/zanzanmolkocom.png';
  } else if (userEmail === 'dave+schoolq@hoodq.com') {
    customHeaderImage      = '/assets/branding/customExceptions/daveschoolqhoodqcom.png';
    customHeaderImagePrint = '/assets/branding/customExceptionsPrint/daveschoolqhoodqcom.png';
  }

  const addressContent = address ? (
    <div className={classNames('col-xs-12', styles.addressContent)} >
      <h2 className="d-xs-none">{address}</h2>
      <h4 className="d-xs-block d-none" style={{ margin: '0' }}>{address}</h4>
    </div>
  ) : null;

  const customHeaderImageSrc = customHeaderImage || false;
  const customHeaderImageSrcPrint = customHeaderImagePrint || false;

  // TODO : add condition here so that... if the current email of the person
  // logged in matches the email of the person who created the report
  // then go ahead and return the custom header
  if (customHeaderImageSrc) {
    return (
      <div className={classNames('text-center row', styles.customHeaderLayout)}>
        <img src={customHeaderImageSrc}      className={classNames('d-xs-block d-none', styles.ownMastheadOne, styles.noPrint)} alt="Own Masthead" />
        <img src={customHeaderImageSrc}      className={classNames('d-xs-none',  styles.ownMastheadTwo, styles.noPrint)} alt="Own Masthead" />
        <img src={customHeaderImageSrcPrint} className={classNames(styles.ownMastheadTwo, styles.printOnly)} alt="Own Masthead" />
        {addressContent}
      </div>
    );
  }

  const { customStyle } = props;
  const { firstName, lastName, sloganMessage, lineOne, lineTwo, lineThree, hideProfilePic, hideFirmLogo } = props.user; // retrieved, email,

  const profilePictureSrc = (
    props.user.profilePicture &&
    props.user.profilePicture.medium !== null &&
    props.user.profilePicture.medium
  ) || '/assets/agent-photo.jpg';

  const firmLogoSrc = (
    props.user.firmLogo &&
    props.user.firmLogo.medium !== null &&
    props.user.firmLogo.medium
  ) || '/assets/branding/remax.jpg';

  const firmLogo = hideFirmLogo ? null : (
    <div className={styles.firmLogo}>
      <img src={firmLogoSrc} alt="Agent Logo" />
    </div>
  );

  const slogan = (
    <div className={styles.slogan}>
      <h3>{sloganMessage}</h3>
    </div>
  );

  const profilePicture = hideProfilePic ? null : (
    <div className={classNames('col-xs-2', styles.profilePic)}>
      <img src={profilePictureSrc} alt="Agent Profile Pic" />
    </div>
  );

  const agentInfo = (
    <div className={hideProfilePic ? styles.agentInfoNoPic : styles.agentInfo}>
      <h3>{firstName} {lastName}</h3>
      <div>{lineOne}</div>
      <div>{lineTwo}</div>
      <div>{lineThree}</div>
    </div>
  );

  const layout = (
    <div className={classNames('col-xs-12 text-left', styles.layout)} style={customStyle}>
      <div className="row col-xs-12 col-sm-6">
        {profilePicture}
        {agentInfo}
      </div>
      <div className={classNames('col-xs-12 col-sm-6', styles.sloganLogo)}>
        {slogan}
        {firmLogo}
      </div>
    </div>
  );

  return (
    <div className={classNames('text-center row', styles.customHeaderLayout)}>
      {layout}
      {addressContent}
    </div>
  );
}

export default CustomHeaderLayout;
