$(document).on('turbolinks:load', function() {

  function scrollToBottomPrint(timedelay=0) {
    let scrollId;
    let height = 0;
    let minScrollHeight = 8;
    scrollId = setInterval(function () {
      if (height <= document.body.scrollHeight) {
        window.scrollBy(0, minScrollHeight);
      }
      else {
        clearInterval(scrollId);
        window.print();
      }
      height += minScrollHeight;
    }, timedelay);
  }


  $("li#save-print").click(function(){
    let pathName      = window.location.pathname;
    let matchDetailed = /detailed/.test(pathName);
    if (matchDetailed) {
      scrollToBottomPrint(1);
    }
    else {
      window.print();
    }
  });
});