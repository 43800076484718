/**
*
* CoffeeShops
*
*/

import React from 'react';

function CoffeeShops() {
  return (
    <svg version="1.1" viewBox="0 0 51.375 69.875">
      <path d="m40.075 35.596c-1.1312 0-2.2044 0.23295-3.1999 0.62083l0.28968-4.0186c0-0.62056-0.31275-1.1235-0.6983-1.1235h-32.82c-0.38568 0-0.6983 0.50298-0.6983 1.1235l2.4439 33.897c0 0.62055 0.31261 1.1238 0.6983 1.1238h27.931c0.38568 0 0.6983-0.50326 0.6983-1.1238l1.0068-13.963c1.29 0.734 2.7601 1.1873 4.3475 1.1873 4.8853 0 8.8608-3.9755 8.8608-8.863 0-4.885-3.975-8.86-8.86-8.86zm0 13.719c-1.6545 0-3.115-0.83482-3.9928-2.102l0.43541-6.0396c0.88839-0.96146 2.1485-1.5741 3.5574-1.5741 2.6767 0 4.8568 2.18 4.8568 4.8568 0 2.679-2.18 4.859-4.856 4.859z" fill="#ECB01F" />
      <path d="m18.074 26.686c-1.0797-1.223-2.5911-6.4259 3.6431-13.068s1.2836-10.842 1.2836-10.842c2.5204 3.2978-2.5984 8.3631-7.4208 13.816-4.8224 5.4537-1.119 8.9707-1.119 8.9707l3.613 1.123z" fill="#ECB01F" />
      <path d="m24.417 21.096c-3.387 3.8302-0.78592 6.3003-0.78592 6.3003l2.5377 0.78867c-0.75832-0.859-1.8199-4.5131 2.5586-9.1778 4.3786-4.6647 0.90158-7.6151 0.90158-7.6151 1.769 2.317-1.826 5.874-5.213 9.704z" fill="#ECB01F" />
    </svg>
  );
}

export default CoffeeShops;
