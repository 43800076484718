/**
*
* WadingPool
*
*/

import React from 'react';

function WadingPool() {
  return (
    <svg version="1.1" y="0px" x="0px" viewBox="0 0 72 46.875">
      <g fill="#01818E">
        <path d="m2.0872 6.7737c-0.001617 1.9061-0.001617 3.8127 0 5.7189 22.719 17.846 45.437-17.846 68.152 0 0.000809-1.9065 0.000809-3.8125 0-5.7189-22.716-17.846-45.428 17.845-68.152-0.0004z" />
        <path d="m1.6103 34.014c-0.002425 1.9064-0.002425 3.8128 0 5.719 22.721 17.846 45.432-17.845 68.152 0-0.002426-1.9059-0.002426-3.8129 0-5.719-22.722-17.846-45.43 17.845-68.152 0z" />
        <path d="m2.0872 20.394c-0.001617 1.9057-0.001617 3.8127 0 5.7189 22.719 17.845 45.437-17.846 68.152 0 0.000809-1.9064 0.000809-3.8126 0-5.7189-22.716-17.846-45.428 17.846-68.152 0z" />
      </g>
    </svg>
  );
}

export default WadingPool;
