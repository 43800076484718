/**
*
* Pool
*
*/

import React from 'react';

function Pool() {
  return (
    <svg version="1.1" viewBox="0 0 72 44.625">
      <circle cy="9.2081" cx="54.937" r="7.8039" fill="#00818D" />
      <path d="m10.207 10.921h9.0729l7.677 0.76138v7.2329l-18.4 11.167s3.2993 5.0758 7.1061 4.8221c3.8068-0.2539 7.2329-4.3144 7.2329-4.3144s1.9034 4.3144 6.3446 4.3144 6.9792-4.1875 6.9792-4.1875 3.5529 4.3143 6.979 4.1875c3.4261-0.12695 5.3295-2.2841 6.8522-4.1875 2.538 2.6648 5.9641 3.8068 5.9641 3.8068 3.2992-3.1724 0.25371-5.2028 0.25371-5.2028l-23.104-23.715c-0.22714-0.33328-0.49743-0.61228-0.82508-0.84704l-0.053581-0.0548-0.009285 0.01242c-0.54999-0.37605-1.1777-0.64685-1.8941-0.64685h-20.176c-1.8922 0-3.4261 1.5339-3.4261 3.4261-0.0002 1.8922 1.5337 3.4261 3.4261 3.4261z" fill="#00818D" />
      <path d="m69.992 38.731c-0.021057-0.010498-2.135-1.0472-3.771-3.1205l-0.95042-1.2028-1.198 0.958c-6.5606 5.2499-9.8984 2.3908-12.65 0.032906l-1.0584-0.90766-0.99812 0.97495c-4.692 4.5911-9.112 2.4413-11.997-0.16747l-1.0346-0.93647-1.0233 0.94979c-5.4903 5.0971-8.0998 3.0336-10.863 0.84602-0.30345-0.24046-0.60069-0.47528-0.89224-0.69461l-1.092-0.81868-0.93295 0.99531c-1.6976 1.8105-3.5417 2.7644-5.4868 2.6852-2.7217-0.041359-5.0298-1.8455-5.9649-2.9046l-1.4642-1.6598-1.0274 1.961c-0.85165 1.6275-3.6301 2.4525-4.6282 2.6334-0.82636 0.14788-1.378 0.93711-1.2315 1.7635 0.14654 0.82854 0.94416 1.3836 1.7635 1.2351 0.42266-0.074326 3.5579-0.68411 5.6158-2.6677 1.7046 1.3886 4.1648 2.6439 6.8901 2.6846 2.4119 0.054672 4.6997-0.86144 6.7527-2.672 2.8149 2.2247 6.9693 5.2696 13.622-0.2635 4.6457 3.5978 9.7681 3.6455 14.089 0.13457 2.7883 2.2605 7.3723 4.9884 14.355 0.049038 1.8617 1.9002 3.778 2.8213 3.8719 2.8654 0.2103 0.10025 0.43181 0.14724 0.6505 0.14724 0.57054 0 1.1173-0.32176 1.378-0.8713 0.357-0.759 0.033-1.667-0.726-2.026z" fill="#00818D" />
    </svg>
  );
}

export default Pool;
