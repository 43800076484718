/* eslint arrow-body-style: "off" */

/**
*
* DataSubSet
*
*/

import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import _ from 'lodash';

import messages from './messages';
import styles from './styles.css';

import textHelpers from 'utils/textHelpers';

import SVGIcon from 'components/reports/SVGIcon';

// NOTE : these are the 6 possible data subsets:
// 1A) Public Schools
// 1B) Catholic & Private Schools
// 2A) Parks
// 2B) Facilities
// 3) Transit
// 4) Convenience

// TODO : continue refactoring this component
function DataSubSet(props) {
  const { subSet, dataSetType, sortedPlaces } = props;

  const subSetPlaces = [];
  const placesArray  = sortedPlaces || subSet.places;

  let subSetTitle   = null;
  let excludeSubSet = false;

  // NOTE : only schools set (catholic/private/public schools subset) has subSet titles
  if (subSet.title) {
    const { catholicSchoolsCount, privateSchoolsCount, publicSchoolsCount } = props.dataSetCounts;
    let subSetMessage = null;

    switch (subSet.title) {
      case 'catholicPrivate':
        if (catholicSchoolsCount > 0 && privateSchoolsCount <= 0) {
          subSetMessage = "Catholic";
        } else if (catholicSchoolsCount <= 0 && privateSchoolsCount > 0) {
          subSetMessage = "Private";
        } else if (catholicSchoolsCount <= 0 && privateSchoolsCount <= 0) {
          excludeSubSet = true;
          // TODO : think about also having the styling for public schools change
          // so it doesn't look like there's another section coming, but it never shows
        } else {
          subSetMessage = "Catholic & Private";
        }
        break;
      case 'public':
      default:
        subSetMessage = "Public";
        if (publicSchoolsCount <= 0) {
          excludeSubSet = true;
          // TODO : think about also having the styling for public schools change
          // so it doesn't look like there's another section coming, but it never shows
        }
    }

    subSetTitle = <h5><FormattedMessage {...subSetMessage} /></h5>;
  }

  // NOTE : filter subsets; in this case specifically for catholicPrivate subset
  if (!excludeSubSet) {
    placesArray.forEach((place, index) => {
      let score = null;
      let iconStyle = null;
      if (dataSetType === 'schools' && place.features) {
        // Handle score icons
        if (place.features['SchoolQ Score']) {
          // NOTE : Asumes -> locale === 'en-CA'
          score = place.features['SchoolQ Score'];
          iconStyle = styles.scoreIcon;
        } else if (place.features['GreatSchools Rating']) {
          // NOTE : Asumes -> locale === 'en-US'
          score = place.features['GreatSchools Rating'];
          iconStyle = styles.scoreIcon;
        }
      } else if (dataSetType === 'parksRec') {
        // TODO : only show 2 places, the ones with the most facilities
      }

      let icon = place.iconType ? (
        <div className={styles.icon}>
          <SVGIcon iconType={place.iconType} />
        </div>
      ) : null;

      if (place.place_category_key !== 'schools' && place.place_category_key !== 'school-attendance-zones') {
        icon = place.place_category_key ? (
          <div className={styles.icon}>
            <SVGIcon iconType={place.place_category_key} />
          </div>
        ) : icon;
      }

      let name = place.name;
      // TODO : review this logic according to backend
      if (place.count && place.iconType && !place.name) {
        // TODO : check if this particular condition makes sense
        name = (
          <div className={styles.allLowercase}>
            {textHelpers.pluralize(place.count, place.iconType)}
          </div>
        );
      } else if (place.count && place.name && dataSetType === 'convenience') {
        const topBrandName      = _.max(Object.keys(place.brands), (i) => { return i.count; });
        const topBrandCount     = place.brands[topBrandName].count;
        const otherBrandsCount  = place.count - topBrandCount;
        const complimentaryText = `and ${textHelpers.pluralize(otherBrandsCount, `other ${place.name}`).toLowerCase()}`;
        name = (
          <div>
            {topBrandCount} {topBrandName} {otherBrandsCount > 0 ? complimentaryText : ''}
          </div>
        );
      } else if (place.count && place.name) {
        name = (
          <div className={styles.allLowercase}>
            {textHelpers.pluralize(place.count, place.name)}
          </div>
        );
      } else if ((place.iconType || place.place_category_key) && !place.count) {
        name = (
          <div className={styles.allCaps}>
            {place.name}
          </div>
        );
      }

      const placeContent = (
        <div className={styles.expandedFacility} key={index}>
          <div className={classNames(styles.iconContainer)}>
            <div className={iconStyle}>
              {score}
            </div>
            {icon}
          </div>
          <div className={styles.nameContainer}>
            {name}
          </div>
        </div>
      );

      subSetPlaces.push(placeContent);
    });
  }

  return (
    <div>
      {subSetTitle}
      <div>
        {subSetPlaces}
      </div>
    </div>
  );
}



export default DataSubSet;
