import React from 'react';
import { Button, Form } from 'react-bootstrap';
import PhoneInput, { isValidPhoneNumber, formatPhoneNumber } from 'react-phone-number-input/input';
import * as yup from 'yup';
import { Formik } from 'formik';
import { DirectUpload } from '@rails/activestorage';

import FeedbackFormField from './FeedbackFormField';
import HoodQLogo from './HoodQLogo';
import AddressReportBanner from "./AddressReportBanner";

import { User } from './types';

interface IProps {
  user: User;
  csrfToken: string;
}

interface IState {
  user:  User;
  files: any;
}

export default class NewUserSetup extends React.Component<IProps, IState> {
  formSchema = yup.object({
    first_name: yup.string().required("Please enter your first name."),
    last_name:  yup.string().required("Please enter your last name."),
    phone_number: yup.string()
      .test(
        'is-valid-phone-number',
        "Please enter a valid phone number.",
        (value, context) => { return value == "" || value == null ? true : isValidPhoneNumber(value) }
      ).ensure(),
    website_address: yup.string().url("Please enter a valid URL, including http://")
  });

  constructor(props) {
    super(props);
    this.state = { user: props.user, files: {} };
  }

  handleSubmit = async (values) => {
    const { files } = this.state;
    const url = window.routes.rails_direct_uploads_url;

    const signedFiles = await Promise.all(
      Object.keys(files).map(fileKey => {
        return new Promise((resolve, reject) => {
          const upload = new DirectUpload(files[fileKey], url);
          upload.create((error, blob) => {
            if (error) {
              reject(error);
            } else {
              resolve({ key: fileKey, signedId: blob.signed_id });
            }
          });
        });
      })
    );

    signedFiles.forEach((fileEntry: { key: string, signedId: string }) => {
      values[fileEntry.key] = fileEntry.signedId;
    });

    values.contact_name = values.first_name;
    values.full_name    = values.last_name;
    values.first_name = undefined;
    values.last_name  = undefined;

    const response = await fetch(window.routes.users_path, {
      method: "PATCH",
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({
        user: values,
        first_time: true,
        authenticity_token: this.props.csrfToken
      })
    });

    if (response.ok) {
      const responsePayload = await response.json();
      window.location.href = responsePayload.next;
    }
  }

  handlePhotoChange = (fieldName) => {
    return (event) => {
      const { target } = event;

      if (target.files && target.files[0]) {
        const reader = new FileReader();
        reader.onload = e => {
          this.setState({
            user: {
              ...this.state.user,
              [fieldName]: e.target.result
            },
            files: {
              [fieldName]: target.files[0]
            }
          });
        }

        reader.readAsDataURL(target.files[0]);
      }
    }
  }

  handleFieldChange = event => {
    const { target } = event;

    this.setState({
      user: {
        ...this.state.user,
        [target.name]: target.value
      }
    });

    return true;
  }

  render() {
    const { user } = this.state;

    return (
      <>
        <HoodQLogo />
        <h2 className="text-primary">Let's Get Started</h2>
        <h5>You're one step away from Canada's largest database of school and park information.</h5>
        <p>The information below will be used to personalize your reports.</p>
        <div className="row">
          <div className="form-container">
            <Formik
              validationSchema={this.formSchema}
              onSubmit={this.handleSubmit}
              initialValues={{
                first_name:      '',
                last_name:       '',
                phone_number:    '',
                website_address: ''
              }}>
              {props => (
                <Form onSubmit={props.handleSubmit}>
                  <div className="form-row">
                    <div className="col-12 col-md-6">
                      <FeedbackFormField
                        type="text"
                        name="first_name"
                        disabled={props.isSubmitting}
                        value={props.values.first_name}
                        onChange={e => this.handleFieldChange(e) && props.handleChange(e)}
                        onBlur={props.handleBlur}
                        touched={props.touched.first_name}
                        errors={props.errors.first_name}
                        placeholder="Your first name"
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <FeedbackFormField
                        type="text"
                        name="last_name"
                        disabled={props.isSubmitting}
                        value={props.values.last_name}
                        onChange={e => this.handleFieldChange(e) && props.handleChange(e)}
                        onBlur={props.handleBlur}
                        touched={props.touched.last_name}
                        errors={props.errors.last_name}
                        placeholder="Your last name"
                      />
                    </div>
                  </div>
                  <FeedbackFormField
                    type="tel"
                    name="phone_number"
                    disabled={props.isSubmitting}
                    value={props.values.phone_number}
                    onChange={e => this.handleFieldChange(e) && props.handleChange(e)}
                    onBlur={props.handleBlur}
                    touched={props.touched.phone_number}
                    errors={props.errors.phone_number}
                    placeholder="Your phone number"
                  >
                    {phoneProps => (
                      <PhoneInput
                        country="US"
                        disabled={props.isSubmitting}
                        name={phoneProps.name}
                        className={`form-control${phoneProps.touched && !!phoneProps.errors ? ' is-invalid' : ''}`}
                        value={phoneProps.value}
                        onChange={value => phoneProps.onChange({ target: { name: phoneProps.name, value }})}
                        onBlur={phoneProps.onBlur}
                        placeholder={phoneProps.placeholder}
                      />
                    )}
                  </FeedbackFormField>
                  <FeedbackFormField
                    type="url"
                    name="website_address"
                    disabled={props.isSubmitting}
                    value={props.values.website_address}
                    onChange={e => this.handleFieldChange(e) && props.handleChange(e)}
                    onBlur={props.handleBlur}
                    touched={props.touched.website_address}
                    errors={props.errors.website_address}
                    placeholder="Your website address"
                  />
                  <div className="form-group form-row">
                    <label className="col-form-label col-4">Upload Your Photo</label>
                    <div className="col-8">
                      <input
                        type="file"
                        id="user_profile_picture"
                        name="profile_picture"
                        accept="image/*"
                        onChange={this.handlePhotoChange('profile_picture')}
                      />
                    </div>
                  </div>
                  <Button type={"submit"} disabled={props.isSubmitting}>
                    {props.isSubmitting && <i className="fas fa-spinner fa-spin mr-3" />}
                    Finish my Profile
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <h5 className="banner-preview-copy">The banner on your reports will look like this:</h5>
        <div className="address-report">
          <AddressReportBanner
            streetAddress="123 Main Street"
            city="Toronto"
            state="ON"
            reportTitle="HoodQ Address Report &trade;"
            user={{ ...user,
              name: `${user.first_name || ""} ${user.last_name || ""}`,
              phone_number: formatPhoneNumber(user.phone_number)
            }}
          />
          <div className="address-report-overlay" />
        </div>
      </>
    )
  }
}