/**
*
* Transit
*
*/

import React from 'react';

function Transit(props) {
  const { x, y, height, width } = props;

  return (
    <svg x={x} y={y} height={height} width={width} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 216 216">
      <circle className="cls-1" fill="#6b6cb2" cx="107.68785" cy="108.35498" r="99.55434" />
      <path className="cls-2" fill="#fff" d="M151.718,86.76143s-1.10572-9.28806-1.9903-15.70125-3.75945-9.06692-12.60523-11.4995a76.42351,76.42351,0,0,0-18.79338-2.21447l-0.00389-.21812a79.37844,79.37844,0,0,0-18.355,2.43259C91.12449,61.99327,88.24962,64.647,87.365,71.06019s-1.9903,15.70125-1.9903,15.70125a110.38824,110.38824,0,0,0-.66343,13.26866C84.93245,105.33756,84.93245,129,84.93245,129h5.52861v6.52376a4.53346,4.53346,0,0,0,9.06692,0V129h37.81568v6.52376a4.53346,4.53346,0,1,0,9.06692,0V129h5.74975s0-23.66244.22114-28.96991A110.38824,110.38824,0,0,0,151.718,86.76143Z" />
      <path className="cls-1" fill="#6b6cb2" d="M142.29729,97.509l-47.41334-.35383a4.79076,4.79076,0,0,1-4.77672-4.77672l3.14837-17.205a4.79076,4.79076,0,0,1,4.77672-4.77672h41.169a4.79076,4.79076,0,0,1,4.77672,4.77672l3.096,17.55886A4.79076,4.79076,0,0,1,142.29729,97.509Z" />
      <rect className="cls-1" fill="#6b6cb2" x="102.40286" y="62.87785" width="32.06593" height="4.20174" rx="0.4485" ry="0.4485" />
      <circle className="cls-1" fill="#6b6cb2" cx="94.77338" cy="114.07276" r="4.7546" />
      <circle className="cls-1" fill="#6b6cb2" cx="141.88921" cy="114.07276" r="4.7546" />
      <rect className="cls-2" fill="#fff" x="58.17399" y="98.70323" width="3.53831" height="33.17165" />
      <polygon className="cls-2" fill="#fff" points="162.996 143.374 68.347 143.374 68.347 134.086 49.328 134.086 49.328 128.778 73.654 128.778 73.654 138.067 162.996 138.067 162.996 143.374" />
      <circle className="cls-2" fill="#fff" cx="60.38543" cy="90.29974" r="10.83607" />
    </svg>
  );
}



export default Transit;
