/**
*
* WaterTransit
*
*/

import React from 'react';

function WaterTransit() {
  return (
    <svg viewBox="0 0 70.666664 78.666664">
      <g fill="#01818E">
        <path d="m65.283 56.913c-3.5614-0.36948-6.5965-2.5197-8.5178-4.2571l-1.5644-1.4184-1.55 1.4383c-3.3884 3.1455-6.045 4.4689-8.2943 4.7307l7.3066-16.756 4.4355-10.561s2.2169-3.7488-1.9015-5.0163l-2.1141-0.89125v-10.147h-6.1225v-5.2793h-7.2886v-7.0766h-10.878v7.0766h-6.7605v5.2797h-6.5442v10.146l-2.1141 0.89125c-4.1183 1.2675-1.9015 5.0163-1.9015 5.0163l4.4355 10.561 6.6109 16.325c-3.2208-0.70448-5.8522-2.8013-7.0327-4.1379l-2.2133-2.507-1.5536 2.963c-1.2905 2.4602-5.4899 3.7092-7.0002 3.9831-1.2508 0.22709-2.0835 1.422-1.86 2.6729 0.22349 1.2508 1.4455 2.0961 2.6638 1.8618 0.63802-0.11174 5.3817-1.0345 8.4926-4.0354 2.5809 2.1015 6.3009 3.9994 10.425 4.0624 3.6263-0.012615 7.1084-1.3031 10.212-4.0426 2.4764 1.9573 5.6377 4.3346 9.8299 4.3346 3.0135-0.001801 6.5641-1.2328 10.774-4.7347 2.4007 1.8528 5.6845 3.6984 9.5487 4.0985 1.2724 0.13337 2.4007-0.78942 2.5305-2.0529 0.13-1.264-0.789-2.396-2.055-2.528zm-45.991-39.075h6.5461v-5.2797h17.32v5.2797h6.1243v4.7403l-14.995-6.3239-14.995 6.3239v-4.74z" />
        <path d="m60.5 69.284c-1.276-0.6993-2.5341-1.6041-3.738-2.6891l-1.5644-1.4148-1.5464 1.4346c-8.3123 7.7194-12.252 4.5869-16.426 1.2797-0.4614-0.36407-0.90838-0.71732-1.3517-1.049l-1.6473-1.2382-1.413 1.5049c-2.5701 2.7395-5.4106 4.0967-8.3015 4.0606-4.1165-0.063087-7.6058-2.7918-9.0224-4.3941l-1.4491-1.6383-1.7122 1.3608c-0.93 0.74075-2.6386 1.8906-3.5181 2.1303-1.2256 0.33343-1.9501 1.5987-1.6185 2.826 0.33523 1.2256 1.5752 1.9627 2.8297 1.6185 1.1679-0.31721 2.4908-1.0814 3.5145-1.7645 2.5881 2.2547 6.5208 4.4013 10.908 4.4662 3.6263 0.084709 7.1084-1.3013 10.212-4.0408 2.4764 1.9555 5.6377 4.3328 9.8299 4.3328 3.0135 0 6.5641-1.231 10.774-4.7347 0.98768 0.76058 2.0006 1.4256 3.0351 1.9898 1.1174 0.61279 2.516 0.20006 3.1252-0.91558 0.608-1.117 0.197-2.518-0.92-3.127z" />
      </g>
    </svg>
  );
}

export default WaterTransit;
