/**
*
* WalkTime
*
*/

import React from 'react';
import { FormattedMessage } from 'react-intl';

import messages from './messages';
import styles from './styles.css';

import SVGIcon from '../../../SVGIcon';

function WalkTime(props) {
  const { distance } = props;

  let walkTime = Math.round((distance / 4.5) * 60);

  if (walkTime === 0) {
    walkTime = 1;
  }

  return (
    <div className={styles.walkTime}>
      <SVGIcon iconType="pedestrian" />

      {walkTime} <FormattedMessage {...messages.minutes} />
    </div>
  );
}



export default WalkTime;
