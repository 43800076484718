import React from 'react';

import HoodQAPI from '../../data/HoodQAPI';

import AddressSearch from './index';

interface IProps {
  placeholder?:  string;
  value?:        string;
  formAction:    string;
  apiKey?:        string;
}

export default function(props: IProps) {
  const api = new HoodQAPI({ key: props.apiKey });

  function handleSelected(address: string) {
    const formEl = document.getElementById('search_form') as HTMLFormElement;
    formEl.submit();
  }

  return (
    <form method="POST" id="search_form" action={props.formAction}>
      <AddressSearch {...props}
        inputProps = {{ name: 'address' }}
        api        = {api}
        onSelected = {handleSelected}
      />
    </form>
  );
}