import React from 'react';
import {CardElement, Elements, ElementsConsumer} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {Button, Form} from 'react-bootstrap';

import {SubscriptionPlan} from "./types";

interface IState {
    selectedPlan: SubscriptionPlan | null,
    isSubmitting: boolean
}

interface IProps {
    stripePublicKey: string;
    csrfToken:       string;
    currentPlan:     string;
}

interface IElementsConsumerProps {
    elements: any;
    stripe:   any;
}


class EditPaymentDetails extends React.Component<IProps & IElementsConsumerProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            selectedPlan: this.props.currentPlan as SubscriptionPlan,
            isSubmitting: false
        }
    }

    handleSubmit = async (ev) => {
        const { stripe, elements } = this.props;
        let payload;

        ev.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);
        payload = await stripe.createToken(cardElement);

        const response = await fetch(window.routes.subscription_path, {
            method: "PATCH",
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                user: {
                    stripe_token: payload.token.id,
                    subscription_plan: this.state.selectedPlan as string,
                },
                authenticity_token: this.props.csrfToken
            })
        });

        if (response.ok) {
            const responsePayload = await response.json();
            window.location.href = responsePayload.next;
        }
    }

    renderPaymentField = () => {
        return (
            <>
                <CardElement />
                <div className="secure mt-2">
                    <i className="fa fa-lock" />
                    <span>Secure payments processed by <a href="https://www.stripe.com" target="_blank:">Stripe</a>.</span>
                </div>
            </>
        )
    }

    handlePlanChange = (ev) => {
        this.setState({
            selectedPlan: ev.currentTarget.value as SubscriptionPlan
        })
    }

    render() {
        const { isSubmitting, selectedPlan } = this.state;
        const { stripe, elements } = this.props;

        return (
            <Form onSubmit={this.handleSubmit}>
                <div className="row">
                    <div className="col-12">
                        <hr/>
                        <h4 className="section-header">Enter Your Credit Card Info</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 mt-4 mb-2">
                        <select className="form-control" name="subscription_plan" onChange={this.handlePlanChange}>
                            <option value="">Select current or desired plan</option>
                            <option selected={selectedPlan == SubscriptionPlan.MONTHLY} value={SubscriptionPlan.MONTHLY}>Monthly - $24.95/month</option>
                            <option selected={selectedPlan == SubscriptionPlan.YEARLY} value={SubscriptionPlan.YEARLY}>Yearly - $239.95/year</option>
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 mt-2 mb-4">
                        {this.renderPaymentField()}
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <Button type="submit" disabled={!stripe || !elements || isSubmitting}>
                            Update Your Account
                        </Button>
                    </div>
                </div>
            </Form>
        )
    }
}

export default class InjectedEditPaymentDetails extends React.Component<IProps> {
    stripePromise: any;

    constructor(props) {
        super(props);
        this.stripePromise = loadStripe(props.stripePublicKey);
    }

    render() {
        return (
            <Elements stripe={this.stripePromise}>
                <ElementsConsumer>
                    {({ elements, stripe }) => (
                        <EditPaymentDetails
                            {...this.props}
                            elements={elements}
                            stripe={stripe}
                        />
                    )}
                </ElementsConsumer>
            </Elements>
        );
    }
}