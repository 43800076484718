/*
 * Place Messages
 *
 * This contains all the text for the Place component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  url: {
    id: 'app.components.Place.url',
    defaultMessage: 'Go to the website',
  },
  lookDeeperPartOne: {
    id: 'app.components.Place.lookDeeperPartOne',
    defaultMessage: 'Look ',
  },
  lookDeeperPartTwo: {
    id: 'app.components.Place.lookDeeperPartTwo',
    defaultMessage: 'deeper',
  },
  nearbySchools: {
    id: 'app.components.Place.nearbySchools',
    defaultMessage: 'Nearby Schools',
  },
  tooltipNoCatchment: {
    id: 'app.components.Place.tooltipNoCatchment',
    defaultMessage: 'School boards for this address do not provide school attendance boundary data to third parties (like HoodQ). Therefore, schools are included based on distance from the home. Please confirm attendance eligibility with each school board.',
  },
  tooltipCatchment: {
    id: 'app.components.Place.tooltipCatchment',
    defaultMessage: 'Assigned schools are designated by the school board as the required or preferred schools for children at this address to attend. Other nearby schools are included based on distance from the home. Please confirm attendance eligibility with each school board.',
  },
  catchment: {
    id: 'app.components.Place.catchment',
    defaultMessage: 'Designated Catchment School',
  },
  nonCatchment: {
    id: 'app.components.Place.nonCatchment',
    defaultMessage: 'Non-Catchment School',
  },
});
