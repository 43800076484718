/*
 * MarketAltTwo Messages
 *
 * This contains all the text for the MarketAltTwo component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  yourInvestment: {
    id: 'app.components.MarketAltTwo.yourInvestment',
    defaultMessage: 'Neighbourhood Insights',
  },
  investmentInfo: {
    id: 'app.components.MarketAltTwo.investmentInfo',
    defaultMessage: 'This report provides real estate activity and insights in the neighbourhood. The data provided is for a 3-month period (July 1st to September 30th 2018).',
  },
  // valueHeader: {
  //   id: 'app.components.MarketAltTwo.valueHeader',
  //   defaultMessage: 'Value',
  // },
  valueContent: {
    id: 'app.components.MarketAltTwo.valueContent',
    defaultMessage: 'Average Value',
    // defaultMessage: 'Average Current Value',
  },
  // priceHeader: {
  //   id: 'app.components.MarketAltTwo.priceHeader',
  //   defaultMessage: 'Price',
  // },
  avg_sale_priceContent: {
    id: 'app.components.MarketAltTwo.priceContent',
    defaultMessage: 'Avg Sale Price',
  },
  // highHeader: {
  //   id: 'app.components.MarketAltTwo.highHeader',
  //   defaultMessage: 'High',
  // },
  high_sale_priceContent: {
    id: 'app.components.MarketAltTwo.highContent',
    defaultMessage: 'High Sale Price',
  },
  // lowHeader: {
  //   id: 'app.components.MarketAltTwo.lowHeader',
  //   defaultMessage: 'Low',
  // },
  low_sale_priceContent: {
    id: 'app.components.MarketAltTwo.lowContent',
    defaultMessage: 'Low Sale Price',
  },

  averagePrices: {
    id: 'app.components.MarketAltTwo.averagePrices',
    defaultMessage: 'Average Prices',
  },

  propertyTypes: {
    id: 'app.components.MarketAltTwo.propertyTypes',
    defaultMessage: 'Property Types',
  },
  condo: {
    id: 'app.components.MarketAltTwo.condo',
    defaultMessage: 'Condo',
  },
  bungalow: {
    id: 'app.components.MarketAltTwo.detached',
    // defaultMessage: 'Semi & Detached',
    defaultMessage: 'Bungalow',
  },
  duplex: {
    id: 'app.components.MarketAltTwo.duplex',
    defaultMessage: 'Duplex',
  },
  row: {
    id: 'app.components.MarketAltTwo.row',
    defaultMessage: 'Row',
  },
  semi: {
    id: 'app.components.MarketAltTwo.semi',
    // defaultMessage: 'Semi & Detached',
    defaultMessage: 'Bungalow',
  },
  split: {
    id: 'app.components.MarketAltTwo.split',
    defaultMessage: 'Back/Side Split',
  },
  twostorey: {
    id: 'app.components.MarketAltTwo.twostorey',
    defaultMessage: '2 Storey',
  },
});
