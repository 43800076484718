/*
 * DetailedInfoTable Messages
 *
 * This contains all the text for the DetailedInfoTable component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  address: {
    id: 'app.components.DetailedInfoTable.address',
    defaultMessage: 'Address',
  },
  language: {
    id: 'app.components.DetailedInfoTable.language',
    defaultMessage: 'Language',
  },
  dateOpened: {
    id: 'app.components.DetailedInfoTable.dateOpened',
    defaultMessage: 'Date Opened',
  },
  gradeLevel: {
    id: 'app.components.DetailedInfoTable.gradeLevel',
    defaultMessage: 'Grade Level',
  },
  orientation: {
    id: 'app.components.DetailedInfoTable.orientation',
    defaultMessage: 'Orientation',
  },
  schoolCode: {
    id: 'app.components.DetailedInfoTable.schoolCode',
    defaultMessage: 'School Code',
  },
  schoolType: {
    id: 'app.components.DetailedInfoTable.schoolType',
    defaultMessage: 'School Type',
  },
  contactName: {
    id: 'app.components.DetailedInfoTable.contactName',
    defaultMessage: 'Contact Name',
  },
  phoneNumber: {
    id: 'app.components.DetailedInfoTable.phoneNumber',
    defaultMessage: 'Phone Number',
  },
  ratingTrend: {
    id: 'app.components.DetailedInfoTable.ratingTrend',
    defaultMessage: 'Rating Trend',
  },
  schoolBoard: {
    id: 'app.components.DetailedInfoTable.schoolBoard',
    defaultMessage: 'School Board',
  },
  schoolNumber: {
    id: 'app.components.DetailedInfoTable.schoolNumber',
    defaultMessage: 'School Number',
  },
  gradesOffered: {
    id: 'app.components.DetailedInfoTable.gradesOffered',
    defaultMessage: 'Grades Offered',
  },
  mostRecentRank: {
    id: 'app.components.DetailedInfoTable.mostRecentRank',
    defaultMessage: 'Most Recent Rank',
  },
  additionalDetails: {
    id: 'app.components.DetailedInfoTable.additionalDetails',
    defaultMessage: 'Additional Details',
  },
  mostRecentRating: {
    id: 'app.components.DetailedInfoTable.mostRecentRating',
    defaultMessage: 'Most Recent Rating',
  },
  schoolBoardNumber: {
    id: 'app.components.DetailedInfoTable.schoolBoardNumber',
    defaultMessage: 'School Board Number',
  },
  districtDescription: {
    id: 'app.components.DetailedInfoTable.districtDescription',
    defaultMessage: 'District Description',
  },
  fiveYearsRank: {
    id: 'app.components.DetailedInfoTable.fiveYearsRank',
    defaultMessage: 'Rank in the Most Recent Five Years',
  },
  fraserInstituteWebpage: {
    id: 'app.components.DetailedInfoTable.fraserInstituteWebpage',
    defaultMessage: 'Fraser Institute School Details Webpage',
  },
  fiveYearsAverageRating: {
    id: 'app.components.DetailedInfoTable.fiveYearsAverageRating',
    defaultMessage: 'Rating Average in the Most Recent Five Years',
  },
  contact: {
    id: 'app.components.DetailedInfoTable.contact',
    defaultMessage: 'Contact',
  },
  ownership: {
    id: 'app.components.DetailedInfoTable.ownership',
    defaultMessage: 'Ownership',
  },
  faxNumber: {
    id: 'app.components.DetailedInfoTable.faxNumber',
    defaultMessage: 'Fax Number',
  },
  emergencyServicesProvided: {
    id: 'app.components.DetailedInfoTable.emergencyServicesProvided',
    defaultMessage: 'Emergency Services Provided',
  },
  type: {
    id: 'app.components.DetailedInfoTable.type',
    defaultMessage: 'Type',
  },
  county: {
    id: 'app.components.DetailedInfoTable.county',
    defaultMessage: 'County',
  },
  affiliation: {
    id: 'app.components.DetailedInfoTable.affiliation',
    defaultMessage: 'Affiliation',
  },
  ncesSchoolID: {
    id: 'app.components.DetailedInfoTable.ncesSchoolID',
    defaultMessage: 'NCES School ID',
  },
  schoolDistrict: {
    id: 'app.components.DetailedInfoTable.schoolDistrict',
    defaultMessage: 'School District',
  },
  schoolAuthority: {
    id: 'app.components.DetailedInfoTable.schoolAuthority',
    defaultMessage: 'School Authority',
  },
  greatSchoolsRating: {
    id: 'app.components.DetailedInfoTable.greatSchoolsRating',
    defaultMessage: 'GreatSchools Rating',
  },
  schoolDistrictNumber: {
    id: 'app.components.DetailedInfoTable.schoolDistrictNumber',
    defaultMessage: 'School District Number',
  },
  schoolAuthorityNumber: {
    id: 'app.components.DetailedInfoTable.schoolAuthorityNumber',
    defaultMessage: 'School Authority Number',
  },
  greatSchoolsSchoolDetailsWebpage: {
    id: 'app.components.DetailedInfoTable.greatSchoolsSchoolDetailsWebpage',
    defaultMessage: 'GreatSchools School Details Webpage',
  },
  status: {
    id: 'app.components.DetailedInfoTable.status',
    defaultMessage: 'Status',
  },
  facilityID: {
    id: 'app.components.DetailedInfoTable.facilityID',
    defaultMessage: 'Facility ID',
  },
  emailAddress: {
    id: 'app.components.DetailedInfoTable.emailAddress',
    defaultMessage: 'Email Address',
  },
});
