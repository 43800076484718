/* eslint no-multi-spaces: "off" */
/* eslint react/jsx-equals-spacing: "off" */

/**
*
* GenericSchoolsBlock
*
*/

import React from 'react';
import { FormattedMessage } from 'react-intl';

import messages from './messages';
import styles from './styles.css';

import DetailedSchoolBlock from '../DetailedSchoolBlock';

function GenericSchoolsBlock(props) {
  const { schoolsType, schools, locale, displayScores, assignedSchoolsCount } = props;

  let schoolsHeader    = null;
  let schoolsParagraph = null;
  let schoolsContent   = [];

  switch (schoolsType) {
    case 'private':
      schoolsHeader = messages.privateSchoolsHeader;
      schoolsParagraph = messages.privateSchoolsParagraph;
      break;
    case 'catholicAssigned':
      schoolsHeader = messages.catholicAssignedSchoolsHeader;
      schoolsParagraph = locale === 'en-CA' ? messages.catholicSchoolsParagraphCA : messages.catholicSchoolsParagraph;
      break;
    case 'catholicUnassigned':
      schoolsHeader = messages.catholicUnassignedSchoolsHeader;
      schoolsParagraph = locale === 'en-CA' ? messages.catholicSchoolsParagraphCA : messages.catholicSchoolsParagraph;
      break;
    case 'assigned':
      schoolsHeader = messages.assignedSchoolsHeader;
      // TODO : add US paragraphs (currently only CA)
      schoolsParagraph = locale === 'en-CA' ? messages.publicSchoolsParagraphCA : messages.publicSchoolsParagraph;
      break;
    case 'unassigned':
      schoolsHeader = messages.unassignedSchoolsHeader;
      if (assignedSchoolsCount === 0) {
        schoolsParagraph = locale === 'en-CA' ? messages.publicSchoolsParagraphCA : messages.publicSchoolsParagraph;
      }
      break;
    case 'public':
    default:
      schoolsHeader = messages.publicSchoolsHeader;
      // TODO : add US paragraphs (currently only CA)
      schoolsParagraph = locale === 'en-CA' ? messages.publicSchoolsParagraphCA : messages.publicSchoolsParagraph;
  }

  Object.keys(schools).forEach((index) => {
    const item = schools[index];
    let score = null;
    if (displayScores) {
      if (item.features['SchoolQ Score']) {
        // NOTE : Asumes locale === 'en-CA'
        score = item.features['SchoolQ Score'];
      } else if (item.features['GreatSchools Rating']) {
        // NOTE : Asumes locale === 'en-US'
        score = item.features['GreatSchools Rating'];
      }
    }

    if (schoolsType === 'private') {
      schoolsContent.push(
        <DetailedSchoolBlock
          name      = {item.name}
          distance  = {item.distance}
          locale    = {locale}
          key       = {`${schoolsType}-${index}-${item.name}`}
        />
      );
    } else {
      schoolsContent.push(
        <DetailedSchoolBlock
          name      = {item.name}
          distance  = {item.distance}
          grades    = {item.features['Grade Level']}
          address   = {item.features.Address}
          paragraph = {item.description}
          url       = {item.url}
          info      = {item.features}
          locale    = {locale}
          score     = {score}
          key       = {`${schoolsType}-${index}-${item.name}`}
        />
      );
    }
  });

  let schoolsParagraphContent = null;
  if (schoolsParagraph) {
    schoolsParagraphContent = (<p><FormattedMessage {...schoolsParagraph} /></p>);
  }

  return (
    <div className={styles.genericSchoolsBlock}>
      <h2><FormattedMessage {...schoolsHeader} /></h2>
      {schoolsParagraphContent}
      {schoolsContent}
    </div>
  );
}



export default GenericSchoolsBlock;
