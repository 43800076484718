/*
 * Footer Messages
 *
 * This contains all the text for the Footer component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  disclaimer: {
    id: 'app.components.Footer.disclaimer',
    defaultMessage: 'Disclaimer: These materials have been prepared for {email} and are not intended to solicit buyers or sellers currently under contract with a brokerage. By accessing this information you have agreed to our terms of service, which are hereby incorporated by reference. This information may contain errors and omissions. You are not permitted to rely on the contents of this information and must take steps to independently verify its contents with the appropriate authorities (school boards, governments etc.). As a recipient of this information, you agree not to hold us, our licensors or the owners of the information liable for any damages, howsoever caused.',
  },
  disclaimerRealtorCA: {
    id: 'app.components.Footer.disclaimerRealtorCA',
    defaultMessage: 'Disclaimer: These materials have been prepared for {email} and are not intended to solicit buyers or sellers currently under contract with a brokerage. By accessing this information you have agreed to our terms of service, which are hereby incorporated by reference. This information may contain errors and omissions. You are not permitted to rely on the contents of this information and must take steps to independently verify its contents with the appropriate authorities (school boards, governments etc.). As a recipient of this information, you agree not to hold us, our licensors or the owners of the information liable for any damages, howsoever caused. The trademarks REALTOR®, REALTORS® and the REALTOR® logo are controlled by The Canadian Real Estate Association (CREA) and identify real estate professionals who are members of CREA.',
  },
});
