/**
*
* BasketballCourt
*
*/

import React from 'react';

function BasketballCourt() {
  return (
    <svg version="1.1" y="0px" x="0px" viewBox="0 0 69.375 69.25">
      <g fill="#01818E">
        <path d="m67.392 22.286l-0.15926-0.3149c-0.10135-0.20028-10.458-20.057-33.188-20.057-22.815 0-31.574 20.586-31.661 20.795l-0.1112 24.837 18.558 3.2559 1.2042 9.8587-0.03891 0.036194 0.049919 0.053688 0.83323 6.8221 2.6446-0.32334-0.30057-2.4611 19.73 0.034988-0.30932 2.373 2.9197 0.38125 2.196-16.851 17.632-3.5257v-24.915zm-44.049 27.108l-0.78226-6.4047 3.6128 3.3721-2.831 3.033zm4.984-1.024l3.4575 3.2271-3.4551 3.2121-3.2182-2.9936 3.216-3.445zm5.618 1.218l-3.6103-3.3696 3.1536-3.3789 3.8443 3.599-3.388 3.15zm5.542-1.133l3.2973 3.087-3.3461 3.1725-3.3355-3.1131 3.385-3.146zm-5.545 5.156l3.3566 3.1328-3.4118 3.2349-3.3963-3.1591 3.451-3.209zm-2.357 8.244l-6.4992-0.011612 3.2443-3.0161 3.255 3.028zm7.87-3.099l3.3418 3.1189-6.6191-0.011612 3.277-3.107zm2.14-2.029l3.3427-3.1693 1.3232 1.2389-0.73023 5.6037-3.936-3.674zm5.631-9.339l-0.028355-0.030014-2.2727 2.1548-3.2761-3.0671 4.673-4.3444h1.5932l-0.689 5.286zm-7.731-2.959l-2.4863-2.3278h4.9902l-2.504 2.328zm-11.312-0.22l-2.2592-2.1085h4.2272l-1.968 2.109zm-4.179 10.595l2.1646 2.0135-1.723 1.6019-0.442-3.616zm40.444-10.016l-14.286 2.8565 0.72238-5.5434h7.3213v-2.9438h-45.484v2.9438h7.2679l-0.21717 0.026543 0.68634 5.6194-15.241-2.674v-21.492c1.0303-2.2417 9.2828-18.724 28.828-18.724 19.425 0 29.069 15.791 30.404 18.155v21.776z" />
        <polygon points="17.002 36.21 19.946 36.21 19.946 20.639 50.349 20.639 50.349 36.206 53.293 36.206 53.293 17.695 17.002 17.695" />
      </g>
    </svg>
  );
}

export default BasketballCourt;
