import React from 'react';
import classNames from 'classnames';
import request from 'utils/request';

function fetchAccountDetails(authToken) {
  return request(`https://platform.hoodq.com/me.json?auth_token=${authToken}`)
    .then(payload => payload.data.user);
}

function fetchDomainList(authToken) {
  return request(`https://platform.hoodq.com/domains.json?auth_token=${authToken}`)
    .then(payload => payload.data.domains);
}

function addDomain(uri, authToken) {
  return fetch(`https://platform.hoodq.com/domains.json?domain=${uri}&auth_token=${authToken}`, {
    method: 'POST'
  });
}

function deleteDomain(domainId, authToken) {
  return fetch(`https://platform.hoodq.com/domains/${domainId}.json?auth_token=${authToken}`, {
    method: 'DELETE'
  });
}


class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      domains: [],
      user:    {},
      address: '',
      error:   null
    };
  }

  componentDidMount() {
    const { currentUser } = this.props;

    fetchAccountDetails(currentUser).then(user => {
      this.setState({ user });
      this.refreshDomainList();
    });

  }

  refreshDomainList = () => {
    const { currentUser } = this.props;
    fetchDomainList(currentUser).then(domains => {
      this.setState({ domains, address: domains.length > 0 ? '' : this.state.user.website });
    });
  }

  handleConnectClick = () => {
    const { address } = this.state;
    const { currentUser } = this.props;

    this.setState({ error: null });

    addDomain(address, currentUser)
      .then(response => {
        if (response.ok) {
          this.refreshDomainList();
          this.setState({ address: '' });
        } else {
          response.json().then(payload => {
            if (payload.error === "ActiveRecord::RecordInvalid") {
              if (payload.message.match(/has already been taken/)) {
                this.setState({ error: "You've already added this website to your list. Please note that you don't need to enter every listing page. You only need to enter the home page of your website." });
              } else if (payload.message.match(/invalid format/)) {
                this.setState({ error: `${address} is not a valid website URL. Please enter a valid URL. For example: mywebsite.com`});
              } else {
                this.setState({ error: "Something went wrong! Please try again in a few minutes."});
              }
            } else {
              this.setState({ error: "Something went wrong! Please try again in a few minutes."});
            }
          });
        }
      });
  }

  handleDeleteClick = (id) => {
    const { currentUser } = this.props;

    return () => {
      const domain = this.state.domains.find(domain => domain.id === id);

      if (window.confirm(`NOTE: This will only remove Address Reports from the widget on ${domain.domain}!\nIt will not remove the widget completely.\nIf you wish to remove the widget from ${domain.domain}, please contact your website provider.\n\nDo you wish to remove Address Reports from ${domain.domain}?`)) {
        deleteDomain(id, currentUser).then(this.refreshDomainList);
      }
    }
  }

  handleAddressChange = (ev) => {
    this.setState({ address: ev.target.value, error: null });
  }

  render() {
    const { domains, address, user } = this.state;

    return (
      <div className='row'>
        <div className="col-12">
          <h1 className="text-danger">NEW!</h1>
          <p>
            Automatically connect Address Reports to the Neighbourhood Highlights Widget
            on your website.
          </p>
          <p><span style={{ color: '#00AEEF', fontWeight: 'bold' }}>NOTE:</span> If you don't have the Neighbourhood Highlights widget installed on your website yet, <a target="_top" href="https://www.hoodq.com/neighbourhood-highlights-widget">get details here</a>.</p>
          { domains && domains.length == 0
            ? (user.website
                ? <p><strong>Simply click "Connect" to connect Address Reports to the Neighbourhood Highlights Widget on {user.website}!</strong></p>
                : <p><strong>Simply enter your website address below, then click "Connect".</strong></p>
            )
            : <p><strong>Have more than one website? Enter another website address in the text box, then click "Connect".</strong></p>
          }
        </div>
        <div className="col-4 font-weight-bold" style={{ height: '34px', display: 'flex', alignItems: 'center' }}>
          <label htmlFor="website-address" className="font-weight-bold">Website Address</label>
        </div>
        <div className="col-8">
          <div className={`form-group ${this.state.error && 'has-error has-feedback'}`}>
            <input className="form-control" type="text" name="website-address" onChange={this.handleAddressChange} value={address} />
            <span class="glyphicon glyphicon-remove form-control-feedback"></span>
            {this.state.error && <span className="text-danger">{this.state.error}</span>}
          </div>
        </div>
        <div className="col-12">
          <button onClick={this.handleConnectClick} className="btn btn-primary">Connect</button>
        </div>
        { domains && domains.length > 0 &&
        <div className="col-12">
          <p>Your connected websites:</p>
          <ul>
            {domains.map(domain => (
              <li>
                <div>
                  <div>{domain.domain} <span onClick={this.handleDeleteClick(domain.id)}>&times;</span></div>
                  {domain.deleteRequested &&
                  <div>
                    NOTE: This will only remove Address Reports from your widget. It will not remove the widget completely.<br/>
                    If you wish to remove the widget from your website, please contact your website provider.<br/>
                    Are you sure you wish to continue?
                    <button>Yes</button>
                    <button>No</button>
                  </div>
                  }
                </div>
              </li>
            ))}
          </ul>
        </div>
        }
      </div>
    );
  }
}

export default Index;