export function getPackageContents(report) {
    // TODO: move this logic to the backend.
    if (report.campaign) {
      return ['address', 'detailed'];
    } else if (report.branding === 'schoolq') {
      return []
      // TODO: also API generated reports should be empty package contents
    } else {
      return ['address', 'detailed'].concat(report.within_gta ? ['school'] : [])
    }
}

export default { getPackageContents };