import React from 'react';

import UnauthenticatedNav from '../UnauthenticatedNav';
// import SideAd from 'components/_shared/_navigation/SideAd';

function CampaignNav(props) {
  return (
    <UnauthenticatedNav {...props}>
      {React.Children.toArray(props.children)}
      {/* TODO: fix this. */}
      {/* <SideAd href={props.campaign.landing_page_url} /> */}
      <hr />
    </UnauthenticatedNav>
  );
}

export default CampaignNav;