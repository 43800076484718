import React from 'react';

import TermsAndConditions from '../items/TermsAndConditions';

function UnauthenticatedNav(props) {
  return (
    <ul className="nav nav-pills nav-stacked">
      {React.Children.toArray(props.children)}

    <li>{props.branding.AboutLink()}</li>
    </ul>
  );
}

export default UnauthenticatedNav;