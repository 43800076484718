/* eslint arrow-body-style: "off" */

/**
*
* MapLegend
*
*/

import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import messages from './messages';
import styles from './styles.css';

import elementaryLegend from 'assets/reports/detailed/legend-elementary.png';
import middleLegend from 'assets/reports/detailed/legend-middle.png';
import highLegend from 'assets/reports/detailed/legend-high.png';
import transitLegend from 'assets/reports/detailed/legend-transit.png';
import safetyLegend from 'assets/reports/detailed/legend-safety.png';
import convenienceLegend from 'assets/reports/detailed/legend-convenience.png';
import elementaryAttendanceLegend from 'assets/reports/detailed/legend-elementary-attendance.png';
import middleAttendanceLegend from 'assets/reports/detailed/legend-middle-attendance.png';
import highAttendanceLegend from 'assets/reports/detailed/legend-high-attendance.png';
import parksLegend from 'assets/reports/detailed/legend-parks.png';


function MapLegend() {
  const topRowLegend = [
    {
      styles:  'col-xs-6 col-md-2 col-md-offset-1',
      src:     elementaryLegend,
      alt:     'Elementary',
      message: messages.elementary,
    },
    {
      styles:  'col-xs-6 col-md-2',
      src:     middleLegend,
      alt:     'Middle',
      message: messages.middle,
    },
    {
      styles:  'col-xs-6 col-md-2',
      src:     highLegend,
      alt:     'High',
      message: messages.high,
    },
    {
      styles:  'col-xs-6 col-md-2',
      src:     transitLegend,
      alt:     'Transit',
      message: messages.transit,
    },
    {
      styles:  'col-xs-6 col-md-2',
      src:     safetyLegend,
      alt:     'Safety',
      message: messages.safety,
    },
  ];

  const bottomRowLegend = [
    {
      styles:  'col-xs-6 col-md-2 col-md-offset-1',
      src:     elementaryAttendanceLegend,
      alt:     'Elementary Attendance Zone',
      message: messages.elementaryZone,
    },
    {
      styles:  'col-xs-6 col-md-2',
      src:     middleAttendanceLegend,
      alt:     'Middle Attendance Zone',
      message: messages.middleZone,
    },
    {
      styles:  'col-xs-6 col-md-2',
      src:     highAttendanceLegend,
      alt:     'High Attendance Zone',
      message: messages.highZone,
    },
    {
      styles:  'col-xs-6 col-md-2',
      src:     parksLegend,
      alt:     'Parks',
      message: messages.parks,
    },
  ];

  const [
    topRowLegendContent,
    bottomRowLegendContent,
  ] = [
    topRowLegend,
    bottomRowLegend,
  ].map((rowLegend) => {
    return rowLegend.map((item) => (
      <div className={classNames(item.styles, styles.mapLegendBlock)} key={item.alt}>
        <img src={item.src} alt={item.alt} />
        <FormattedMessage {...item.message} />
      </div>
    ));
  });

  return (
    <div className={styles.mapLegend}>
      <div className={classNames('row', styles.mapLegendRow)}>
        {topRowLegendContent}
      </div>
      <div className={classNames('row', styles.mapLegendRow, styles.bottomLegendRow)}>
        {bottomRowLegendContent}
      </div>
    </div>
  );
}

export default MapLegend;
