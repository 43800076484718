/**
*
* QuestionMarkIcon
*
*/

import React from 'react';

function QuestionMarkIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 56.5 55.5" xmlSpace="preserve" viewBox="0 0 56.5 55.5" version="1.1" y="0px" x="0px" xmlnsXlink="http://www.w3.org/1999/xlink">
      <switch>
        <foreignObject y="0" x="0" height="1" width="1" requiredExtensions="http://ns.adobe.com/AdobeIllustrator/10.0/" />
        <g>
          <circle cy="27.176" cx="28.165" r="24.726" fill="#8C8D8E" />
          <path strokeLinejoin="round" d="m29.231 32.727h-2.5716v-2.2036c0-0.68534 0.079351-1.3042 0.23894-1.8552 0.1587-0.55102 0.37902-1.0466 0.66052-1.4873 0.28194-0.44108 0.60023-0.83872 0.95575-1.1938 0.35464-0.35464 0.71637-0.70396 1.0834-1.0471l1.0285-0.91807c0.61175-0.48984 1.1198-1.0528 1.5241-1.6899 0.40429-0.63613 0.60643-1.4323 0.60643-2.3876 0-0.6849-0.12323-1.2975-0.36794-1.8361s-0.5754-0.9921-0.99122-1.3592c-0.4167-0.3675-0.8999-0.64855-1.4514-0.84493-0.55058-0.1955-1.1446-0.29391-1.7812-0.29391-1.3224 0-2.3938 0.40429-3.2139 1.2124-0.82055 0.80769-1.3286 1.8361-1.5241 3.0854l-2.498-0.44108c0.3675-1.9097 1.2058-3.4094 2.5162-4.4995 1.3095-1.0892 2.92-1.6344 4.8297-1.6344 0.97969 0 1.9044 0.14097 2.7733 0.42246 0.86887 0.28194 1.6225 0.69775 2.2591 1.2488 0.63658 0.55102 1.1446 1.2306 1.5249 2.0387 0.37858 0.80813 0.5692 1.7262 0.5692 2.7547 0 0.7842-0.086002 1.4691-0.25711 2.0569-0.172 0.58781-0.4105 1.1145-0.71637 1.5795-0.30677 0.46546-0.66761 0.88793-1.0834 1.2674-0.4167 0.37991-0.85734 0.76558-1.3228 1.157-0.51423 0.4167-0.94866 0.79572-1.3042 1.1384-0.35464 0.34356-0.64278 0.7044-0.86266 1.0839-0.22076 0.37991-0.38035 0.80237-0.47788 1.2669-0.097527 0.46547-0.14629 1.0285-0.14629 1.6899v1.69zm0.955 5.29c0 0.51423-0.18352 0.97349-0.55146 1.3773-0.368 0.404-0.846 0.606-1.433 0.606-0.5887 0-1.0657-0.20214-1.4327-0.60599-0.3675-0.40384-0.55102-0.8631-0.55102-1.3773 0-0.51422 0.18353-0.97348 0.55102-1.3773 0.36705-0.40429 0.84404-0.60643 1.4327-0.60643 0.58693 0 1.0648 0.20214 1.4319 0.60643 0.368 0.403 0.552 0.862 0.552 1.377z" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.3618" fill="#fff" />
        </g>
      </switch>
    </svg>
  );
}

export default QuestionMarkIcon;
