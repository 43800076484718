/*
 * RentersOwners Messages
 *
 * This contains all the text for the RentersOwners component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.RentersOwners.header',
    defaultMessage: 'Renters vs. Owners',
  },
  rentersOwnersPct: {
    id: 'app.components.RentersOwners.rentersOwnersPct',
    defaultMessage: '{owners} Owned vs. {renters} Rented',
  },
});
