/**
*
* Cleaners
*
*/

import React from 'react';

function Cleaners() {
  return (
    <svg version="1.1" viewBox="0 0 69.5 40">
      <path d="m66.462 29.225c-0.26056-0.29033-0.57819-0.52607-0.93303-0.68736l-27.629-12.651v-1.2327c0.24566-0.23574 0.56081-0.51366 0.76677-0.67992 2.2383-1.4889 3.2582-4.7197 2.4219-7.6801-0.804-2.8484-3.124-4.6822-6.253-4.7939-2.938 0.099258-4.556 1.3375-5.3972 2.3574-1.6948 2.0571-1.4095 4.484-1.335 4.948 0.24318 1.5038 1.6551 2.5013 3.1291 2.2953 1.5087-0.19107 2.5832-1.5981 2.4393-3.1093-0.014889-0.16129 0.019852-0.45162 0.11415-0.55833 0.089333-0.10174 0.44915-0.2804 1.0397-0.3077 0.4417 0.01737 0.63277 0.14392 0.78662 0.52607 0.27792 0.68488 0.067001 1.4914-0.22085 1.7519-2.742 2.1117-3.1192 3.3673-3.1192 4.4095v1.7662l-28.222 12.475c-0.41937 0.18363-0.78662 0.46899-1.0695 0.82881-0.34244 0.43425-2.0249 2.7296-1.4318 5.2483 0.335 1.4144 1.3127 2.5683 2.7544 3.2457 0.3747 0.17618 0.78414 0.268 1.1985 0.268h57.609c0.23077 0 0.45907-0.027298 0.6824-0.08437 0.53848-0.134 3.2681-0.93303 4.0473-3.4492 0.348-1.13 0.393-2.894-1.379-4.887zm-57.453 2.792l25.067-11.08c0.3153 0.12221 0.652 0.2041 1.0103 0.2041 0.1889 0 0.35407-0.071962 0.53258-0.10794l23.989 10.984h-50.599z" fill="#ECB01F" />
    </svg>
  );
}

export default Cleaners;
