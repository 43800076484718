/* eslint no-multi-spaces: "off" */
/* eslint react/jsx-equals-spacing: "off" */

/**
*
* BarGraph
*
*/

import React from 'react';
import { Bar } from 'react-chartjs-2';

function BarGraph(props) {
  const {
    data,
    width,
    height,
    options,
    legend,
  } = props;

  // const defaultWidth   = 95;
  // const defaultHeight  = 240;
  const defaultOptions = {
    maintainAspectRatio: true,
  };
  const defaultLegend  = {
    position: 'bottom',
  };

  return (
    <Bar
      data    = {data}
      width   = {width   || false} // defaultWidth
      height  = {height  || false} // defaultHeight
      options = {options || defaultOptions}
      legend  = {legend  || defaultLegend}
    />
  );
}

export default BarGraph;
