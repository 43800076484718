import React from 'react';
import HoodQ from './hoodq';

import teranetLogo from 'assets/branding/teranetLogo.png';

export default {
  brandName: HoodQ.brandName,
  
  MobileLogo(props) {
    return HoodQ.MobileLogo();
  },

  DesktopLogo(props) {
    return HoodQ.DesktopLogo();
  },

  AboutLink(props) {
    return HoodQ.AboutLink();
  },

  FooterLogo(props) {
    return (
      <div>
        <div className="col-xs-12">
          {HoodQ.FooterLogo()}
        </div>
        <div className="col-xs-12" style={{ paddingLeft: '0', paddingRight: '0' }}>
          <img src={teranetLogo} alt="Teranet Logo" />
        </div>
      </div>
    );
  }
}