/* eslint no-multi-spaces: "off" */

/**
*
* DataSetsGallery
*
*/

import React from 'react';
// import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

// import messages from './messages';
import styles from './styles.css';

import DataSetImage from '../DataSetImage';

function DataSetsGallery(props) {
  // NOTE : availableDatasets is expected to have the keys: elementary, transit, safety, high, parks & convenience
  const { scores, availableDatasets } = props;

  const [
    elementary,
    transit,
    safety,
    high,
    parks,
    convenience,
  ] = Object.keys(availableDatasets).map(key => {
    if (availableDatasets[key]) {
      return (
        <DataSetImage dataSetType={key} score={scores[key]} key={key} />
      );
    }
    return false;
  });

  let topRowStyling = false;
  if ((!transit && safety) || (transit && !safety)) {
    topRowStyling = (<div className="col-xs-2" />);
  } else if (!transit && !safety) {
    topRowStyling = (<div className="col-xs-4" />);
  }

  let bottomRowStyling = false;
  if ((!parks && convenience) || (parks && !convenience)) {
    bottomRowStyling = (<div className="col-xs-2" />);
  } else if (!parks && !convenience) {
    bottomRowStyling = (<div className="col-xs-4" />);
  }

  return (
    <div className={styles.dataSetsGallery}>
      <div className={classNames('row', styles.dataSetsRow)}>
        {topRowStyling}
        {elementary}
        {transit}
        {safety}
      </div>
      <div className={classNames('row', styles.dataSetsRow)}>
        {bottomRowStyling}
        {high}
        {parks}
        {convenience}
      </div>
    </div>
  );
}



export default DataSetsGallery;
