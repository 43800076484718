/*
 * OverviewDescription Messages
 *
 * This contains all the text for the OverviewDescription component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  overview: {
    id: 'app.components.OverviewDescription.overview',
    defaultMessage: 'Overview',
  },
  householdsLabel: {
    id: 'app.reports.MarketDataReport.householdsLabel',
    defaultMessage: 'Households'
  },
  populationLabel: {
    id: 'app.reports.MarketDataReport.populationLabel',
    defaultMessage: 'Population'
  }
});
