import React from 'react';
import HoodQlogo from './HoodQlogo';

// import hoodQLogoPng from 'assets/branding/hoodqLogo.png';

const styles = require('./styles.css');

export default {
  brandName: 'HoodQ',

  MobileLogo(props) {
    return 'HoodQ';
  },

  DesktopLogo(props) {
    return (
      <a href="/">
        <div className={styles.hoodqLogo}>
          <HoodQlogo />
        </div>
      </a>
    );
  },

  AboutLink(props) {
    return (
      <a href="https://www.hoodq.com/" target="_blank">About HoodQ</a>
    );
  },

  FooterLogo(props) {
    // return (
    //   <img src={hoodQLogoPng} alt="HoodQ Logo" />
    // );
  }
}