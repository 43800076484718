import HoodQ from './hoodq';
import SchoolQ from './schoolq';
import Teranet from './teranet';

export default function(branding) {
  switch(branding) {
    case 'schoolq':
      return SchoolQ;
    case 'teranet':
      return Teranet;
    case 'hoodq':
    default:
      return HoodQ;
  }
}