/**
*
* pathnamePatternMatch utility
*
* This utility function is used to match the pathname sent to it (usually a
* currentLocationPathname / currentLocation.locationBeforeTransitions.pathname)
* against a regular expression (RegEx).
*
* The results from this utility function will be either one of these two:
* 1) Boolean -> When sent only a RegEx and a pathname string. By default it will
* 'test' the RegEx against the string.
* 2) Array -> When sent a RegEx, a pathname string AND a boolean with value 'true'.
* This will 'exec' the RegEx against the string and return an array-like variable
* with all captured groups and matched indices.
*
* All regular expressions should be defined within this function and the
* pathnamePatternMatch utility imported in any component requiring the test or
* execution of any RegEx.
*
*/

export function pathnamePatternMatch(regexSlug, pathname, exec = false) {
  const externalLinkPattern      = /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})$/i;
  // TODO : double check all patterns are correct
  // NOTE : IMPORTANT! Keep the () around package to keep the logic for the rest of the code working
  const packagePathPattern       = /^\/(package|listing|realtorca|remax|lonewolf|integra)\/([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})\/?\??/i;
  const listingPathPattern       = /^\/(places\/)?listing\/?\??/i; // listing for any type of report
  // NOTE : currently only available to realtor.ca && remax
  const clientPathPattern          = /^\/(realtorca|remax|lonewolf|integra)\/([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/i;
  const realtorcaPathPattern       = /^\/realtorca\/([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/i;
  const integraPathPattern         = /^\/integra\/([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/i;
  const highlightsPathPattern      = /\/highlights(\/(consumer|edit))?\/?\??.*$/i;
  const consumerHighPathPattern    = /\/highlights\/consumer\/?\??.*$/i;
  const editableHighPathPattern    = /\/highlights\/edit\/?\??.*$/i;
  const schoolsHighPathPattern      = /\/highlights\/schools\/?\??.*$/i;
  const expandedHighPathPattern    = /\/highlights\/expanded\/?\??.*$/i;
  const expandedSchoolsPathPattern = /\/highlights\/expanded\/schools\/?\??.*$/i;
  const marketHighPathPattern      = /\/highlights\/market\/?\??.*$/i;
  // const detailedPathPattern        = /\/report\/?\??.*$/i; // NOTE : old PDF report
  const detailedPathPattern        = /\/detailed\/?\??.*$/i; // NOTE : new HTML report
  const embedsPathPattern          = /^\/(package|listing)\/(.+)\/embeds\/?\??.*$/i;
  const widgetsPathPattern         = /^\/(package|listing)\/(.+)\/widgets\/?\??.*$/i;
  const packageCrmPathPattern      = /^\/(package|listing)\/(.+)\/crm\/?\??.*$/i;
  const dataSetReportPathPattern   = /^\/(package|listing)\/(.+)\/(header|maps|convenience|parks|safety|schools|transit|detailed)\/?\??.*$/i;
  const schoolsReportPathPattern   = /^\/(package|listing)\/(.+)\/schools\/?\??.*$/i;
  const hoodReportPathPattern      = /^\/(package|listing)\/(.+)\/hood\/?\??.*$/i;
  const crmPathPattern             = /^\/crm/i;
  const subscriptionPathPattern    = /^\/account\/manage/i;
  const customMastheadPathPattern  = /^\/account\/masthead/i;
  const accountPathPattern         = /^\/account/i;
  const homePathPattern            = /^\/$/;
  const signinPathPattern          = /^\/signin\/?$/i;
  const signupPathPattern          = /^\/signup\/?$/i;
  const contactPathPattern         = /^\/contact\/?$/i;
  const termsPathPattern           = /^\/terms\/?$/i;
  const welcomeEditPattern         = /^\/account\/edit\/welcome\/?/i;
  const editPageOnePattern         = /^\/account\/edit(\/welcome)?\/1\/?/i;
  // const editPageTwoPattern         = /^\/account\/edit(\/welcome)?\/2\/?/i;
  const oldEditAccountPattern      = /^\/account\/edit_account\/?/i;
  const oldManageAccountPattern    = /^\/account\/subscription\/?/i;

  const oldQuizRoutePattern           = /^\/places\/widgets\/?\??.*$/i;
  const oldQuizDecideRoutePattern     = /^\/places\/widgets\/decide\/?\??.*$/i;
  const oldQuizCityRoutePattern       = /^\/places\/widgets\/city\/?\??.*$/i;
  const oldQuizDecideFormRoutePattern = /^\/places\/widgets\/decide\/form\/?\??.*$/i;
  const oldQuizFormRoutePattern       = /^\/places\/widgets\/quiz\/form\/?\??.*$/i;

  const placesPattern                    = /^\/places\/?\??.*$/i; // neighbourhood Report Selection
  const placesHoodReportSelectionPattern = /^\/places\/report\/hoods\/?\??.*$/i; // neighbourhood Report Selection
  const placesHoodReportPattern          = /^\/reports\/(.+)\/market$/i;
  // const placesHoodReportPattern          = /^\/places\/(report|listing)\/(.+)\/(hood|preview|schools|investment)\/?\??.*$/i; // neighbourhood Report || Report Preview
  const placesHoodPreviewPattern         = /^\/places\/report\/(.+)\/preview\/?\??.*$/i; // neighbourhood Report Preview
  const placesHoodSchoolsPattern         = /^\/places\/(report|listing)\/(.+)\/schools\/?\??.*$/i; // neighbourhood Schools Report
  const placesHoodInvestPattern          = /^\/places\/report\/(.+)\/investment\/?\??.*$/i; // neighbourhood Investment Report
  const assessmentPattern                = /^\/places\/assessments\/(.+)\/?\??.*$/i;
  const quizQualifierPattern             = /^\/places\/(full|widget|hwidget|vwidget|responsive|abcrealty)?\/quiz(\/(city|getstarted|map))?\/?\??.*$/i;
  const quizWizardPattern                = /^\/places\/(full|widget|hwidget|vwidget|responsive|abcrealty)?\/quiz\/wizard\/?\??.*$/i;

  // Analytics URLs
  const analyticsPattern               = /^\/analytics\/?\??.*$/;
  const analyticsClientPattern         = /^\/analytics\/(td|bmo)\/?\??.*$/;
  const analyticsLeadInfoPattern       = /^\/analytics\/(td|bmo)\/(mortgage|marketing)\/lead\/(.+)\/?\??.*$/;
  const analyticsAssessmentInfoPattern = /^\/analytics\/(td|bmo)\/(mortgage|marketing)\/lead\/(.+)\/assessment\/(.+)\/?\??.*$/;

  // NOTE : the following regex piece should take care of all extra url params
  // \/?\??.*$
  let selectedRegex = null;

  switch (regexSlug) {
    case 'externalLink':
      selectedRegex = externalLinkPattern;
      break;
    case 'package':
      selectedRegex = packagePathPattern;
      break;
    case 'listing':
      selectedRegex = listingPathPattern;
      break;
    case 'client':
      selectedRegex = clientPathPattern;
      break;
    case 'realtorca':
      selectedRegex = realtorcaPathPattern;
      break;
    case 'integra':
      selectedRegex = integraPathPattern;
      break;
    case 'highlights':
      selectedRegex = highlightsPathPattern;
      break;
    case 'consumer':
      selectedRegex = consumerHighPathPattern;
      break;
    case 'editable':
      selectedRegex = editableHighPathPattern;
      break;
    case 'schoolsHigh':
      selectedRegex = schoolsHighPathPattern;
      break;
    case 'expanded':
      selectedRegex = expandedHighPathPattern;
      break;
    case 'expandedSchools':
      selectedRegex = expandedSchoolsPathPattern;
      break;
    case 'market':
      selectedRegex = marketHighPathPattern;
      break;
    case 'detailed':
      selectedRegex = detailedPathPattern;
      break;
    case 'embeds':
      selectedRegex = embedsPathPattern;
      break;
    case 'widgets':
      selectedRegex = widgetsPathPattern;
      break;
    case 'dataSetReport':
      selectedRegex = dataSetReportPathPattern;
      break;
    case 'schoolsReport':
      selectedRegex = schoolsReportPathPattern;
      break;
    case 'hoodReport':
      selectedRegex = hoodReportPathPattern;
      break;
    case 'crm':
      selectedRegex = crmPathPattern;
      break;
    case 'packageCrm':
      selectedRegex = packageCrmPathPattern;
      break;
    case 'subscription':
      selectedRegex = subscriptionPathPattern;
      break;
    case 'customMasthead':
      selectedRegex = customMastheadPathPattern;
      break;
    case 'account':
      selectedRegex = accountPathPattern;
      break;
    case 'home':
      selectedRegex = homePathPattern;
      break;
    case 'signin':
      selectedRegex = signinPathPattern;
      break;
    case 'signup':
      selectedRegex = signupPathPattern;
      break;
    case 'contact':
      selectedRegex = contactPathPattern;
      break;
    case 'terms':
      selectedRegex = termsPathPattern;
      break;
    case 'welcomeEdit':
      selectedRegex = welcomeEditPattern;
      break;
    case 'editPageOne':
      selectedRegex = editPageOnePattern;
      break;
    // case 'editPageTwo':
    //   selectedRegex = editPageTwoPattern;
    //   break;
    case 'oldEdit':
      selectedRegex = oldEditAccountPattern;
      break;
    case 'oldManage':
      selectedRegex = oldManageAccountPattern;
      break;

    case 'oldQuiz':
      selectedRegex = oldQuizRoutePattern;
      break;
    case 'oldQuizDecide':
      selectedRegex = oldQuizDecideRoutePattern;
      break;
    case 'oldQuizCity':
      selectedRegex = oldQuizCityRoutePattern;
      break;
    case 'oldQuizDecideForm':
      selectedRegex = oldQuizDecideFormRoutePattern;
      break;
    case 'oldQuizForm':
      selectedRegex = oldQuizFormRoutePattern;
      break;

    case 'places':
      selectedRegex = placesPattern;
      break;
    case 'placesHoodReportSelection':
      selectedRegex = placesHoodReportSelectionPattern;
      break;
    case 'placesHoodReport':
      selectedRegex = placesHoodReportPattern;
      break;
    case 'placesHoodPreview':
      selectedRegex = placesHoodPreviewPattern;
      break;
    case 'placesHoodSchools':
      selectedRegex = placesHoodSchoolsPattern;
      break;
    case 'placesHoodInvest':
      selectedRegex = placesHoodInvestPattern;
      break;
    case 'quizQualifier':
      selectedRegex = quizQualifierPattern;
      break;
    case 'quizWizard':
      selectedRegex = quizWizardPattern;
      break;
    case 'assessment':
      selectedRegex = assessmentPattern;
      break;

    case 'analytics':
      selectedRegex = analyticsPattern;
      break;
    case 'analyticsClient':
      selectedRegex = analyticsClientPattern;
      break;
    case 'analyticsLeadInfo':
      selectedRegex = analyticsLeadInfoPattern;
      break;
    case 'analyticsAssessmentInfo':
      selectedRegex = analyticsAssessmentInfoPattern;
      break;

    default:
      selectedRegex = null;
  }

  if (exec) {
    return selectedRegex.exec(pathname);
  }
  return selectedRegex.test(pathname);
}
