/**
*
* Bowling
*
*/

import React from 'react';

function Bowling() {
  return (
    <svg viewBox="0 0 59.5 52.25">
      <circle cy="19.852" cx="7.1063" r="4.9419" />
      <path d="m30.986 9.9022c1.9883-1.6355 2.2776-4.5712 0.64209-6.5596-1.6324-1.9875-4.5679-2.2752-6.5545-0.64318-1.991 1.6347-2.2803 4.5703-0.64521 6.556 1.632 1.9916 4.568 2.2776 6.557 0.6468z" />
      <path d="m41.284 22.992l-3.6763-5.7188c-0.23021-0.35803-0.55461-0.64579-0.93766-0.83156l-6.74-3.2678c-0.33032-0.16014-1.6418-0.52548-1.989-0.61078l-2.7356-0.67148c-0.3573-0.087775-1.4041-0.21204-1.5849-0.23181-0.22691-0.024731-0.46135-0.016198-0.69724 0.029916l-17.731 3.473c-1.2552 0.24575-2.0735 1.4625-1.8277 2.7177 0.21642 1.1048 1.1851 1.8712 2.2701 1.8712 0.14753 0 0.29734-0.014301 0.44756-0.043634l14.252-2.7906-2.6633 10.85c-0.051151 0.2083-0.077988 0.41742-0.082949 0.62486-0.097488 0.2734-0.15089 0.56765-0.15089 0.87448v8.592l-5.5647 6.8181c-0.90964 1.1147-0.74364 2.7559 0.37117 3.6657 0.48375 0.39489 1.0664 0.58707 1.6458 0.58707 0.75547 0 1.5048-0.32716 2.0199-0.95808l6.1515-7.5372c0.37962-0.46522 0.58691-1.0471 0.58691-1.6474v-5.7713l2.46 4.2537v11.351c0 1.4389 1.1664 2.6052 2.6052 2.6052s2.6052-1.1664 2.6052-2.6052v-12.05c0-0.45791-0.12062-0.90774-0.34994-1.3042l-2.7925-4.829c0.037283-0.10149 0.069231-0.20598 0.095501-0.31316l2.8719-11.699 3.9219 1.9016 3.3243 5.1711c0.44254 0.68841 1.1885 1.0639 1.9503 1.0637 0.4288 0 0.86258-0.11893 1.2501-0.36795 1.076-0.693 1.388-2.126 0.696-3.202z" />
      <path d="m43.071 38.766s-1.3537-2.1018-0.85508-4.845c0 0 1.3895-3.7049-1.14-3.6693-2.5294-0.035635-1.14 3.6693-1.14 3.6693 0.49873 2.7432-0.85509 4.845-0.85509 4.845-2.2443 4.097 0.78388 9.0845 0.78388 9.0845h2.4224c0.001 0 3.029-4.987 0.784-9.084z" />
      <path d="m49.859 36.559s-1.3538-2.1018-0.85507-4.845c0 0 1.3895-3.7049-1.14-3.6693-2.5295-0.035633-1.14 3.6693-1.14 3.6693 0.49875 2.7432-0.85521 4.845-0.85521 4.845-2.2442 4.097 0.78394 9.0845 0.78394 9.0845h2.4225c0.001-0.001 3.029-4.988 0.784-9.085z" />
      <path d="m56.647 38.766s-1.3538-2.1018-0.855-4.845c0 0 1.3894-3.7049-1.14-3.6693-2.5294-0.035635-1.1399 3.6693-1.1399 3.6693 0.49868 2.7432-0.85521 4.845-0.85521 4.845-2.2443 4.097 0.78387 9.0845 0.78387 9.0845h2.4225c0.001 0 3.029-4.987 0.784-9.084z" />
    </svg>
  );
}

export default Bowling;
