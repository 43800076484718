/**
*
* GasStations
*
*/

import React from 'react';

function GasStations() {
  return (
    <svg version="1.1" viewBox="0 0 46 72">
      <rect y="65.721" x="1.3972" height="4.999" width="39.992" fill="#ECB01F" />
      <path d="m41.389 10.272c-1.7091 0-3.0946 1.3855-3.0946 3.0946v1.0498c-0.27338-0.19597-0.59047-0.33568-0.95218-0.33568-0.22875 0-0.44634 0.046842-0.6444 0.13006l0.0093-5.8908c0-3.7863-3.0695-6.8557-6.8559-6.8557h-8.5715c-0.056723-0.000581-0.11066-0.008718-0.16738-0.008718-0.059511 0-0.11623 0.00802-0.17481 0.008718h-8.5585c-3.7864 0-6.8559 3.0694-6.8559 6.8557v46.99c0 3.7863 3.0695 6.8558 6.8559 6.8558h17.4c3.7864 0 6.8559-3.0695 6.8559-6.8558l0.012089-7.6234c0.95404 1.9009 2.3005 2.3389 3.314 2.3389 0.8499 0 1.5529-0.28919 2.0885-0.86013 1.6979-1.803 1.4394-6.0637 0.92429-14.552-0.090195-1.4927-0.18597-3.1483-0.28082-4.881 1.0554-0.49283 1.7909-1.5561 1.7909-2.7979v-13.569c0-1.709-1.386-3.094-3.095-3.094zm-25.709 29.279h-6.9033v-13.33h6.9033v13.33zm8.57 0h-6.9033v-13.33h6.9033v13.33zm8.331 0h-6.9033v-13.33h6.9033v13.33zm0.238-17.377h-24.042v-12.14h24.043v12.14zm7.845 25.687c-0.12088 0.12832-0.29942 0.26036-0.70298 0.26036-0.34033 0-0.85176-0.085548-1.3557-0.8248-1.6756-2.4586-1.2442-8.8356-0.92429-9.6539l-0.81456-0.47144-0.20271-0.22631 0.02232-14.669c0.20178 0.086826 0.42309 0.13646 0.65649 0.13646 0.36172 0 0.6788-0.13971 0.95218-0.33568v4.8586c0 1.5067 1.0777 2.7589 2.5041 3.0351 0.092056 1.6868 0.18504 3.3009 0.27431 4.7591 0.422 6.925 0.725 11.927-0.407 13.132z" fill="#ECB01F" />
    </svg>
  );
}

export default GasStations;
