/**
*
* Features
*
*/

import React from 'react';
import Immutable from 'immutable';

import styles from './styles.css';

import FeatureItem from '../FeatureItem';

function Features(props) {
  const { features, displayKeys, children } = props;

  let featuresContent = [];
  const hasChildrenProps = typeof children !== 'undefined';
  const hasDisplayKeys = typeof displayKeys !== 'undefined';

  if (hasChildrenProps) {
    featuresContent = [children];
  }

  if (hasDisplayKeys) {
    displayKeys.forEach((feature) => {
      let individualFeature = features[feature];
      if (Immutable.Iterable.isIterable(individualFeature)) {
        individualFeature = individualFeature.toArray();
      }
      if (individualFeature) {
        featuresContent.push(
          <FeatureItem
            label={feature}
            valueProp={individualFeature}
            key={feature}
          />
        );
      }
    });
  } else {
    Object.keys(features).forEach((feature) => {
      let individualFeature = features[feature];
      if (Immutable.Iterable.isIterable(individualFeature)) {
        individualFeature = individualFeature.toArray();
      }
      featuresContent.push(
        <FeatureItem
          label={feature}
          valueProp={individualFeature}
          key={feature}
        />
      );
    });
  }

  return (
    <div className={styles.features}>
      {featuresContent}
    </div>
  );
}



export default Features;
