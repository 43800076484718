/* eslint no-multi-spaces: "off" */
/* eslint react/jsx-equals-spacing: "off" */

/**
*
* MarketAltTwo
*
*/

import React from 'react';
import { Bar } from 'react-chartjs-2';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import textHelpers from 'utils/textHelpers';

import messages from './messages';
import { PropertyTypeMarketData } from '../../types';
const styles = require('./styles.css');

interface IProps {
  summary: any;
  pricesByPropertyType: {
    condo: PropertyTypeMarketData;
    row: PropertyTypeMarketData;
    duplex: PropertyTypeMarketData;
    bungalow: PropertyTypeMarketData;
  }
};

export default function NeighbourhoodInsights(props: IProps) {
  const {
    // value,
    summary,
    pricesByPropertyType
  } = props;

  const propertyTypes = ['condo', 'bungalow', 'duplex', 'row'];

  // const investmentData = { value, price, high, low };
  const investmentContent = ['avg_sale_price', 'high_sale_price', 'low_sale_price'].map(key => (
    <div className={classNames('col-xs-4 col-sm-2 text-center', styles.investmentNumber)}>
      <h5><FormattedMessage {...messages[`${key}Content`]} /></h5>
      <p>{textHelpers.toCurrency(summary[key], '$')}</p>
    </div>
  ));

  const currentValueColors = (alpha) => [`rgba(0,174,239,${alpha})`, `rgba(0,174,239,${alpha})`];
  const salePriceColors    = (alpha) => [`rgba(196,214,0,${alpha})`, `rgba(196,214,0,${alpha})`];

  const data = {
    labels: ['Condo', 'Bungalow', 'Duplex', 'Row'],
    datasets: [
      {
        label: 'Avg. Current Value',
        backgroundColor: currentValueColors(0.8),
        borderColor: currentValueColors(1),
        borderWidth: 1,
        hoverBackgroundColor: currentValueColors(1),
        hoverBorderColor: currentValueColors(1),
        data: [
          pricesByPropertyType.condo.avg_current_value,
          pricesByPropertyType.bungalow.avg_current_value,
          pricesByPropertyType.duplex.avg_current_value,
          pricesByPropertyType.row.avg_current_value
        ]
      },
      {
        label: 'Avg. Sale Price',
        backgroundColor: salePriceColors(0.8),
        borderColor: salePriceColors(1),
        borderWidth: 1,
        hoverBackgroundColor: salePriceColors(1),
        hoverBorderColor: salePriceColors(1),
        data: [
          pricesByPropertyType.condo.avg_sale_price,
          pricesByPropertyType.bungalow.avg_sale_price,
          pricesByPropertyType.duplex.avg_sale_price,
          pricesByPropertyType.row.avg_sale_price
        ]
      },
    ],
  };

  const options = {
    maintainAspectRatio: true,
    scales: {
      yAxes: [{
        ticks: {
          min: 100000,
          callback(v) {
            return `$${Number(v).toLocaleString('en')}`;
          },
        },
      }],
    },
  };

  const investmentGraphics = (
    <div className="col-xs-12 text-center">
      <div className="col-xs-12 col-sm-8">
        <h5><FormattedMessage {...messages.averagePrices} /></h5>
        <Bar
          data    = {data}
          width   = {100}
          height  = {35}
          options = {options}
          legend  = {{
            position: 'bottom',
          }}
        />
      </div>
      <div className="col-xs-12 col-sm-4">
        <h5><FormattedMessage {...messages.propertyTypes} /></h5>
        <img src="/assets/hood/investment/propTypes.png" alt="Property Types" style={{ width: '100%' }} />
        <div className={styles.propertyTypesAxis}>
          {
            propertyTypes.map(key => (
              <div>
                <p>{`${Math.floor((pricesByPropertyType[key].total_count / summary.total_count) * 100)}%`}</p>
                <span><FormattedMessage {...messages[key]} /></span>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );

  // let investmentInfoMessage = (<FormattedMessage {...messages.investmentInfo} />);
  const investmentInfoMessage = (
    <span>
      This report provides real estate activity and insights in the neighbourhood. The data provided is for a 4 month period (December 1st 2018 to March 31st 2019)
    </span>
  );

  const investment = ( // eslint-disable-line no-unused-vars
    <div className={classNames('row', styles.overview)}>
      <div className="col-xs-12">
        <h4 className={classNames('col-xs-12', styles.uppercase)}><FormattedMessage {...messages.yourInvestment} /></h4>
        <div className="col-xs-12 col-sm-6">
          {investmentInfoMessage}
        </div>
        {investmentContent}
      </div>
      {investmentGraphics}
    </div>
  );

  return (
    <div>
      {investment}
    </div>
  );
}
