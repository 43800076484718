/*
 * SafetyReport Messages
 *
 * This contains all the text for the SafetyReport component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.SafetyReport.header',
    defaultMessage: 'Safety',
  },
  description: {
    id: 'app.components.SafetyReport.description',
    defaultMessage: 'Every second matters in an emergency, and your neighborhood is part of a dedicated safety system of emergency agencies including Fire Stations, Police Stations, and Hospitals. See the closest facilities near you below:',
  },
  descriptionCA: {
    id: 'app.components.SafetyReport.descriptionCA',
    defaultMessage: 'Every second matters in an emergency, and your neighbourhood is part of a dedicated safety system of emergency agencies including Fire Stations, Police Stations, and Hospitals. See the closest facilities near you below:',
  },
  nearestPlace: {
    id: 'app.components.SafetyReport.nearestPlace',
    defaultMessage: 'The nearest {place} is {name}',
  },
});
