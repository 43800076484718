/* eslint indent: "off"*/

/**
*
* Footer
*
*/

import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import messages from './messages';
import styles from './styles.css';

// import WideHoodQLogo from './WideHoodQLogo';

import getBranding from 'components/branding';

function Footer(props) {
  const { requestOrigin, reportType, userEmail, teranet } = props;

  const branding = getBranding(props.branding);

  let buildingIcon = false;
  let disclaimerClass = 'col-xs-12 col-sm-10';
  if (reportType && reportType === 'schoolsHighlights') {
    disclaimerClass = 'col-xs-12 col-sm-7';
    buildingIcon = (
      <img
        className={classNames('d-xs-none hidden-sm', styles.buildingIcon)}
        src="/assets/highlights/schools/footerBuilding.jpg"
        alt="Building Icon"
      />
    );
  }

  let hoodqBrandingSrc = '/assets/hoodqLogo.png';
  let extraSrcStyle = {};

  if (teranet) {
    disclaimerClass = 'col-xs-12 col-sm-8';
    hoodqBrandingSrc = '/assets/schoolqLogo.png';
    extraSrcStyle = { position: 'relative', left: '-15px' };
  }

  const disclaimer = `Disclaimer: These materials have been prepared for ${userEmail} and are not intended to solicit buyers or sellers currently under contract with a brokerage. By accessing this information you have agreed to our terms of service, which are hereby incorporated by reference. This information may contain errors and omissions. You are not permitted to rely on the contents of this information and must take steps to independently verify its contents with the appropriate authorities (school boards, governments etc.). As a recipient of this information, you agree not to hold us, our licensors or the owners of the information liable for any damages, howsoever caused.`;
  const disclaimerRealtorCA = `Disclaimer: These materials have been prepared for ${userEmail} and are not intended to solicit buyers or sellers currently under contract with a brokerage. By accessing this information you have agreed to our terms of service, which are hereby incorporated by reference. This information may contain errors and omissions. You are not permitted to rely on the contents of this information and must take steps to independently verify its contents with the appropriate authorities (school boards, governments etc.). As a recipient of this information, you agree not to hold us, our licensors or the owners of the information liable for any damages, howsoever caused. The trademarks REALTOR®, REALTORS® and the REALTOR® logo are controlled by The Canadian Real Estate Association (CREA) and identify real estate professionals who are members of CREA.`;
  let disclaimerContent = (
    <div className={classNames(styles.disclaimer, styles.regularDisclaimer, styles.extendedDisclaimerContent)}>
      {disclaimer}
    </div>
  );

  if (requestOrigin === 'realtorca') {
    disclaimerContent = (
      <div className={classNames(styles.disclaimer, styles.extendedDisclaimer, styles.extendedDisclaimerContent)}>
        {disclaimerRealtorCA}
      </div>
    );
  }



  if (teranet) {
    return (
      <div className={classNames(styles.footer, styles.teranet, 'footer')}>
        <div className="col-xs-12 col-sm-4">
          <div className={classNames(styles.logo, 'text-center')}>
            <div className="col-xs-6">
              <img src={hoodqBrandingSrc} style={extraSrcStyle} alt="HoodQ Logo" />
            </div>
            <div className="col-xs-6" style={{ paddingLeft: '0', paddingRight: '0' }}>
              <img src="/assets/hood/investment/teranetLogo.png" alt="Teranet Logo" />
            </div>
          </div>
        </div>

        <div className={disclaimerClass}>
          {disclaimerContent}
        </div>

        {buildingIcon}
      </div>
    );
  }

  return (
    <div className={classNames(styles.footer, 'footer')}>
      <div className="col-xs-12 col-sm-2">
        <div className={classNames(styles.logo, 'text-center')}>
          {branding.FooterLogo()}
        </div>
      </div>

      <div className={disclaimerClass}>
        {disclaimerContent}
      </div>

      {buildingIcon}
    </div>
  );
}

export default Footer;
