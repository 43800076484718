/* eslint no-return-assign: "off" */
// NOTE: based on https://engineering.classdojo.com/blog/2017/01/12/integration-testing-react-redux/

import promiseFinally from 'promise-finally';
import request from 'utils/request';

let callsInProgress = 0;
let callbacks = [];

// When making API calls, this function should be called instead of directly
// calling request from 'utils/request'
export function makeTrackedApiCall(requestURL, options) {
  callsInProgress += 1;

  // this delegates to the API call library. i.e. request from 'utils/request'
  return promiseFinally(
    // Call our request helper (see 'utils/request')
    request(requestURL, options), () => callsInProgress -= 1
  );
}

// This function can be called in your tests in order to wait for all API calls
// to finish before continuing. If no API calls are in progress, it immediately
// returns a resolved promise. Otherwise, it returns a promise that will be
// resolved after all calls have finished - see below.
export function waitForApiCallsToFinish() {
  if (callsInProgress === 0) {
    return Promise.resolve();
  }
  return new Promise((resolve) => callbacks.push(resolve));
}

// In a standard redux app, the results of any API call will be dispatched
// in an action. Using a middleware similar to the one below, we can check
// after each action is dispatched to see if we've finished all API calls.
// We mount this middleware only in testing.
export function resolveAPICallbacksMiddleware() {
  return (next) => (action) => {
    // After this line, the store and views will have updated with any changes.
    // If we don't have any API calls outstanding at this point, it's safe to
    // say that we can continue with our tests.
    next(action);

    // `setTimeout` is in case the any code that runs as a result of this
    // action dispatches another API call.
    setTimeout(() => {
      if (callsInProgress === 0) {
        callbacks.forEach((callback) => callback());
        callbacks = [];
      }
    }, 0);
  };
}
