/**
*
* Generic
*
*/

import React from 'react';

function Generic() {
  return (
    <svg version="1.1" y="0px" x="0px" viewBox="0 0 62.5 43.75">
      <path d="m61.156 22.908l-2.9669-2.4142c-0.066223-0.12238-0.18097-0.23926-0.33099-0.34479-0.15057-0.10522-0.33582-0.19934-0.54382-0.27875-0.20795-0.078796-0.43933-0.1424-0.68164-0.1864-0.242-0.043884-0.49493-0.068176-0.74792-0.068176h-5.0616c0.00177-0.035706 0.010254-0.069397 0.010254-0.10553v-4.5256h5.5991c1.0269 0 1.8598-0.83289 1.8598-1.8601v-2.17c0-1.027-0.83289-1.8598-1.8598-1.8598h-5.5991v-1.5493h5.5991c1.0269 0 1.8598-0.83289 1.8598-1.8601v-2.1698c0-1.0271-0.83289-1.86-1.8598-1.86h-49.938c-1.027 0-1.8602 0.83289-1.8602 1.86v2.1698c0 1.0272 0.83319 1.8601 1.8602 1.8601h5.5051v1.5499h-5.5031c-1.027 0-1.8602 0.83276-1.8602 1.8598v2.17c0 1.0272 0.83319 1.8601 1.8602 1.8601h5.5051v4.6312h-4.957c-0.25311 0-0.50635 0.024292-0.74817 0.068176-0.24249 0.044006-0.47369 0.1076-0.6814 0.1864-0.20844 0.079407-0.39398 0.17352-0.54382 0.27875-0.15045 0.10553-0.2652 0.22241-0.33185 0.34479l-2.9666 2.4142c-0.078369 0.14484-0.096924 0.28406-0.063477 0.41071 0.033997 0.12738 0.12158 0.24243 0.25384 0.33942 0.13263 0.097351 0.31122 0.17645 0.52679 0.23065 0.216 0.054626 0.47022 0.085327 0.75244 0.085327h0.25922v15.859c0 0.96649 0.78351 1.3333 1.75 1.3333s1.75-0.20013 1.75-1.1666v-16.025h0.83331v10.109c0 0.96649 0.78351 1.3333 1.75 1.3333s1.75-0.20013 1.75-1.1666v-10.275h40.417v10.276c0 0.96649 0.78351 1.1666 1.75 1.1666s1.75-0.36682 1.75-1.3333v-10.11h0.83331v16.026c0 0.96649 0.78351 1.1666 1.75 1.1666s1.75-0.36682 1.75-1.3333v-15.86h0.10266c0.28332 0 0.5365-0.030701 0.75269-0.085327 0.21594-0.054199 0.39447-0.1333 0.52747-0.23065 0.13208-0.096985 0.21893-0.21204 0.25293-0.33942 0.034-0.127 0.016-0.266-0.063-0.411zm-14.323-13.813h-30.833v-1.5499h30.833v1.5499zm-30.833 5.8902h30.833v4.5256c0 0.036133 0.008484 0.069824 0.010254 0.10553h-30.843v-4.632z" fill="#01818E" />
    </svg>
  );
}

export default Generic;
