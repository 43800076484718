import HoodQAPI from 'hoodq-shared-js/lib/hoodq-api';

import { NeighbourhoodMarketReportData } from './types';
import { marketDataReportPresenter } from './presenters';

const PLATFORM_URL = process.env.PLATFORM_URL;
const AUTH_TOKEN   = '4M8aztXFyZ3vYTbEqUN9';

export function fetchMarketReportData(reportId: string): Promise<NeighbourhoodMarketReportData> {
  const api = new HoodQAPI({
    platformAuthToken: AUTH_TOKEN,
    platformUrl:       PLATFORM_URL
  });

  return api.reports.fetchNeighbourhoodMarketReport(reportId)
    .then(marketDataReportPresenter);
}