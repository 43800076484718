import React from 'react';
import ReactTooltip from 'react-tooltip';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import messages from './messages';
import styles from './styles.css';

import InformationIcon from './components/InformationIcon';
import DownCurvedArrow from './components/DownCurvedArrow';
import DetailedReportLink from './components/DetailedReportLink';

import FacilityIconMapping from 'components/reports/FacilityIconMapping';
import Distance from './components/Distance';
import WalkTime from './components/WalkTime';
import IconName from './components/IconName';
import SVGIcon from 'components/reports/SVGIcon';
import PNGIcon from './components/PNGIcon';
import Button from 'components/Button';

import FeatureItem from './components/FeatureItem';
import Features from './components/Features';

// import {
//   selectHighlightsLocale,
//   selectHighlightsUserReportCreatorEmail,
// } from 'containers/PackagePage/selectors';

import textHelpers from 'utils/textHelpers';

// NOTE : duplicate from components/highlights/dataSets/parks/Facilities
const facilitiesShortForms = {
  'Computer/Training Room':    'Computer Room',
  'Fitness/Weight Room':       'Fitness Room',
  'Preschool Activities Room': 'Preschool Room',
  'Lawn Bowling/Bocce Green':  'Lawn Bowling/Bocce',
};

// NOTE : this store is made accessible in app.js where the store is created/configured
// function store() {
//   return window.store;
// }

// function locale() {
//   return selectHighlightsLocale()(store().getState());
// }

// function userReportCreatorEmail() {
//   return selectHighlightsUserReportCreatorEmail()(store().getState());
// }

export default new class {
  // /////////// SHARED REGULAR AND EXPANDED CONTENT STARTS HERE ///////////////
  lookDeeperDisplay(showLooKDeeper, shiftLookDeeper) {
    const extraStyling = shiftLookDeeper ? styles.lookDeeperShifted : '';

    if (showLooKDeeper) {
      return (
        <div className={classNames('d-xs-none', extraStyling)}>
          <strong>
            <div className={styles.lookDeeperPartOne}>
              <FormattedMessage {...messages.lookDeeperPartOne} />
            </div>
            <div className={styles.lookDeeperPartTwo}>
              <FormattedMessage {...messages.lookDeeperPartTwo} />
            </div>
          </strong>
          <div className={styles.lookDeeperIcon}>
            <DownCurvedArrow />
          </div>
        </div>
      );
    }
    return null;
  }

  publicSchoolsInfoDisplay(showCatchmentInfo, showNoCatchmentInfo) {
    if (showCatchmentInfo || showNoCatchmentInfo) {
      const tooltipMessage = showCatchmentInfo ? messages.tooltipCatchment : messages.tooltipNoCatchment;
      return (
        <div style={{ marginTop: '-20px', marginBottom: '-10px' }} className="d-xs-none d-flex align-items-center justify-content-center">
            <div className={styles.nearbySchoolsText}>
              <FormattedMessage {...messages.nearbySchools} />
            </div>
          <div className={styles.nearbySchoolsIcon} data-tip data-for="tooltip">
            <InformationIcon />
          </div>
          <ReactTooltip id="tooltip" place="right" type="info" effect="solid">
            <FormattedMessage {...tooltipMessage} />
          </ReactTooltip>
        </div>
      );
    }
    return null;
  }

  primaryIconDisplay(iconType, logoType, primaryIconStyle) {
    if (iconType) {
      return (
        <div className={classNames(styles.iconContainer, primaryIconStyle)} key="primaryIconDisplay">
          <div className={styles.icon}>
            <SVGIcon iconType={iconType} />
          </div>
        </div>
      );
    } else if (logoType) {
      return (
        <div className={classNames(styles.iconContainer, primaryIconStyle)} key="primaryIconDisplay">
          <div className={styles.icon}>
            <PNGIcon logoType={logoType} />
          </div>
        </div>
      );
    }
    return null;
  }

  secondaryNameDisplay(secondaryName) {
    if (secondaryName) return <p>{secondaryName}</p>;
    return null;
  }

  schoolDesignation(catchmentDesignation, assignedGrades) {
    if (catchmentDesignation) {
      return assignedGrades ? <FormattedMessage {...messages.catchment} /> : <FormattedMessage {...messages.nonCatchment} />;
    }
    return null;
  }

  // NOTE : currently only used for schools data set
  schoolGradesDisplay(gradeOfferings, gradeLevels, assignedGrades, catchmentDesignation) {
    if (gradeOfferings || gradeLevels) {
      let schoolGrades = null;
      if (gradeOfferings && gradeOfferings.length) {
        schoolGrades = textHelpers.gradesToString(gradeOfferings, 'Grades ');
      } else if (gradeLevels && gradeLevels.length) {
        schoolGrades = textHelpers.toSentence(gradeLevels);
      }

      // NOTE : the following logic excludes CREA report from getting the 'assigned' text
      //        as well as schools highlights reports (which have catchmentDesignation = true)
      // if (assignedGrades && (!userReportCreatorEmail() || (userReportCreatorEmail() !== 'testhoodq+100@hoodq.com')) && !catchmentDesignation) {
      //   return <p className={styles.innerContent}>{schoolGrades} (Assigned)</p>;
      // }
      return <p className={styles.innerContent}>{schoolGrades}</p>;
    }
    return null;
  }

  walkTimeDisplay(distance, showWalkTime, walkRadius) {
    if (distance && showWalkTime && distance <= walkRadius) {
      return (
        <div className={styles.iconContainer} key="walkTimeDisplay">
          <WalkTime distance={distance} />
        </div>
      );
    }
    return null;
  }
  // /////////// SHARED REGULAR AND EXPANDED CONTENT STARTS HERE ///////////////

  // ////////////////// REGULAR-ONLY CONTENT STARTS HERE //////////////////////
  // NOTE : Same logic as primaryNameExpandedDisplay
  primaryNameDisplay(genericPrimaryName, specificPrimaryName, iconType) {
    if (genericPrimaryName && specificPrimaryName) {
      return <h5><IconName iconType={genericPrimaryName} locale='en-CA' /></h5>;
    } else if (genericPrimaryName) {
      return <h5><IconName iconType={genericPrimaryName} locale='en-CA' /></h5>;
    } else if (specificPrimaryName) {
      return <h5>{specificPrimaryName}</h5>;
    }
    // NOTE : this case should not happen, generally speaking
    return <h5><IconName iconType={iconType} locale='en-CA' /></h5>;
  }

  // NOTE : similar logic to addressExpandedDisplay()
  addressDisplay(address) {
    return address ? <p className={styles.innerContent}>{address}</p> : null;
  }

  // TEMP : commented out until further notice
  // // NOTE : similar logic to distanceExpandedDisplay()
  // distanceDisplay(distance) {
  //   if (distance >= 0) {
  //     return (
  //       <p className={styles.innerContent}>
  //         <Distance distance={distance} locale='en-CA' />
  //       </p>
  //     );
  //   }
  //   return null;
  // }

  facilitiesIconsDisplay(icons) {
    const iconFacilities = [];
    for (let icon of icons) {
      iconFacilities.push(
        <div className={styles.icon} key={icon}>
          {FacilityIconMapping[icon]}
          <SVGIcon iconType={icon} />
        </div>
      );
    }

    if (iconFacilities.length > 0) {
      return (
        <div className={styles.facilitiesIcons}>
          {iconFacilities}
        </div>
      );
    }
    return null;
  }
  // /////////////////// REGULAR-ONLY CONTENT ENDS HERE ///////////////////////

  // ////////////////// EXPANDED-ONLY CONTENT STARTS HERE //////////////////////
  // NOTE : Same logic as primaryNameDisplay
  primaryNameExpandedDisplay(genericPrimaryName, specificPrimaryName, iconType) {
    if (genericPrimaryName && specificPrimaryName) {
      return <h5>{specificPrimaryName}</h5>;
    } else if (genericPrimaryName) {
      return <h5><IconName iconType={genericPrimaryName} locale='en-CA' /></h5>;
    } else if (specificPrimaryName) {
      return <h5>{specificPrimaryName}</h5>;
    }
    // NOTE : this case should not happen, generally speaking
    return <h5><IconName iconType={iconType} locale='en-CA' /></h5>;
  }

  // NOTE : similar logic to addressDisplay()
  addressExpandedDisplay(address, featureKeys) {
    let addressDisplay = null;
    if (address && (featureKeys === undefined || (featureKeys && !featureKeys.includes('Address')))) {
      addressDisplay = (
        <p className={styles.innerContent}>{address}</p>
      );
    }
    return addressDisplay;
  }

  // NOTE : similar logic to distanceDisplay()
  distanceExpandedDisplay(distance, featureKeys) {
    if (featureKeys === undefined || (featureKeys && !featureKeys.includes('Distance'))) {
      if (distance >= 0) {
        return (
          <p className={styles.innerContent}>
            <Distance distance={distance} locale='en-CA' />
          </p>
        );
      }
      return null;
    }
    return null;
  }

  facilitiesAndIcons(facilities) {
    const icons = new Set();
    const expandedFacilities = [];

    if (facilities) {
      Object.keys(facilities).forEach((facility) => {
        const { icon, count } = facilities[facility];
        icons.add(icon);

        const facilityTerm = facilitiesShortForms[facility] || facility;

        expandedFacilities.push(
          <div className={styles.expandedFacility} key={facility}>
            <div className={styles.iconContainer}>
              <div className={styles.icon}>
                <SVGIcon iconType={icon} />
              </div>
            </div>

            <div className={styles.facilityCount}>
              {textHelpers.pluralize(count, facilityTerm)}
            </div>
          </div>
        );
      });

      if (expandedFacilities.length > 0) {
        return [<div>{expandedFacilities}</div>, icons];
      }
    }

    return [null, icons];
  }

  featuresDisplay(features, featureKeys, distance) {
    if (features && Object.keys(features).length > 0 && featureKeys) {
      let featureItems = null;
      if (featureKeys.includes('Distance') && distance >= 0) {
        featureItems = (
          <FeatureItem label="Distance">
            <Distance distance={distance} locale='en-CA' />
          </FeatureItem>
        );
      }

      if (featureItems) {
        return (
          <Features features={features} displayKeys={featureKeys} >
            {featureItems}
          </Features>
        );
      }
      return (
        <Features features={features} displayKeys={featureKeys} />
      );
    }
    return null;
  }

  primaryUrlDisplay(url, urlMessage, handleClick) {
    if (url) {
      let urlFormattedMessage = <FormattedMessage {...messages.url} />;

      // NOTE : in general, a urlMessage should be provided alongside a url
      if (urlMessage) {
        urlFormattedMessage = <FormattedMessage {...urlMessage} />;
      }

      return (
        <div className={styles.urlLink}>
          <Button onClick={handleClick} href={url} target="_blank">
            {urlFormattedMessage}
          </Button>
        </div>
      );
    }
    return null;
  }

  secondaryUrlDisplay(secondaryUrl, secondaryUrlMessage, handleSecondaryClick) {
    if (secondaryUrl) {
      let secondaryUrlFormattedMessage = <FormattedMessage {...messages.url} />;

      // NOTE : in general, a secondaryUrlMessage should be provided alongside a secondaryUrl
      if (secondaryUrlMessage) {
        secondaryUrlFormattedMessage = <FormattedMessage {...secondaryUrlMessage} />;
      }

      return (
        <div className={styles.urlLink}>
          <Button onClick={handleSecondaryClick} href={secondaryUrl} target="_blank">
            {secondaryUrlFormattedMessage}
          </Button>
        </div>
      );
    }
    return null;
  }

  detailedReportLinkDisplay(placeType, hideDetailedRepLink) {
    if (hideDetailedRepLink) return null;
    return <DetailedReportLink source={placeType} />;
  }
  // /////////////////// EXPANDED-ONLY CONTENT ENDS HERE ///////////////////////
};
