/**
*
* DetailedPlaceBlock
*
*/

import React from 'react';
// import classNames from 'classnames';
// import { FormattedMessage } from 'react-intl';

// import messages from './messages';
import styles from './styles.css';

import SVGIcon from '../../../SVGIcon';
import TimeLengthDistance from '../TimeLengthDistance';
import DetailedInfoTable from '../DetailedInfoTable';

import textHelpers from 'utils/textHelpers';

const TWENTY_MIN_WALK = 1.5;

function DetailedPlaceBlock(props) {
  const { icon, name, description, distance, hideWalkingPerson, address, paragraph, facilities, info, url, locale } = props;

  let distanceContent = null;
  if (distance) {
    if (distance <= TWENTY_MIN_WALK && !hideWalkingPerson) {
      distanceContent = (
        <div className={styles.distance}>
          <TimeLengthDistance distance={distance} locale={locale} />
        </div>
      );
    } else {
      distanceContent = (
        <div>
          <TimeLengthDistance distance={distance} locale={locale} hideWalkingPerson />
        </div>
      );
    }
  }

  const numberedFacilities = {};
  let facilitiesContent = '';

  if (facilities) {
    Object.keys(facilities).forEach((facility) => {
      if (numberedFacilities[facility]) {
        numberedFacilities[facility] += facilities[facility].count;
      } else {
        numberedFacilities[facility] = facilities[facility].count;
      }
    });

    // TODO : consider writing a helper for
    // pluralization + turn into sentence
    Object.keys(numberedFacilities).forEach((facility, index) => {
      if (Object.keys(numberedFacilities).length === 1) {
        facilitiesContent += textHelpers.pluralize(numberedFacilities[facility], facility);
      } else if (Object.keys(numberedFacilities).length === index + 1) {
        facilitiesContent += `and ${textHelpers.pluralize(numberedFacilities[facility], facility)}`;
      } else {
        facilitiesContent += `${textHelpers.pluralize(numberedFacilities[facility], facility)}, `;
      }
    });
  }

  let descriptionContent = null;
  if (description) {
    descriptionContent = (<p>{description}</p>);
  }

  let infoContent = null;
  if (info) {
    infoContent = (
      <p>
        <DetailedInfoTable info={info} />
      </p>
    );
  }

  let urlAnchor = null;
  if (url) {
    urlAnchor = (
      <p>
        <a href={url} target="_blank">{textHelpers.shortenUrl(url)}</a>
      </p>
    );
  }

  return (
    <div className={styles.detailedPlaceBlock}>
      <div className={styles.icon}>
        <SVGIcon iconType={icon} />
      </div>
      <h3>{name}</h3>
      {descriptionContent}
      {distanceContent}
      <p>{address}</p>
      <p>{paragraph}</p>
      <p>{facilitiesContent}</p>
      {infoContent}
      <p>{urlAnchor}</p>
    </div>
  );
}



export default DetailedPlaceBlock;
