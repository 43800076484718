/**
*
* Wide HoodQlogo
*
*/

import React from 'react';

function WideHoodQLogo() {
  return (
    <svg x="0px" y="0px" viewBox="0 0 218.666672 75.333336" enableBackground="new 0 0 218.666672 75.333336">
      <g>
        <g>
          <polygon
            fill="#8A8C8C"
            points="68.026711,52.114342 49.521858,52.114342 49.521858,36.814075 44.404453,36.814075
            44.404453,73.409927 49.521858,73.409927 49.521858,56.662632 68.026711,56.662632 68.026711,73.409927 73.19548,73.409927
            73.19548,36.814075 68.026711,36.814075 		"
          />
          <path
            fill="#8A8C8C"
            d="M102.838448,51.855759c-1.189018-1.137074-2.59333-2.02422-4.212936-2.662022
            c-1.619606-0.637222-3.359276-0.956409-5.220146-0.956409c-1.860878,0-3.601692,0.319187-5.220726,0.956409
            c-1.620178,0.637802-3.015839,1.524948-4.186966,2.662022c-1.1717,1.137074-2.093483,2.490017-2.765335,4.057674
            c-0.671852,1.568237-1.007782,3.29982-1.007782,5.194752c0,1.895504,0.33593,3.635742,1.007782,5.220146
            c0.671852,1.585548,1.593636,2.955231,2.765335,4.109619c1.171127,1.154968,2.566788,2.050194,4.186966,2.687416
            c1.619034,0.637222,3.359848,0.956413,5.220726,0.956413c1.86087,0,3.60054-0.319191,5.220146-0.956413
            s3.023918-1.532448,4.212936-2.687416c1.189026-1.154388,2.119461-2.524071,2.791313-4.109619
            c0.671852-1.584404,1.007782-3.324642,1.007782-5.220146c0-1.894932-0.33593-3.626514-1.007782-5.194752
            C104.957909,54.345776,104.027473,52.992832,102.838448,51.855759z M101.027794,64.483612
            c-0.363632,1.065506-0.890038,2.010368-1.581512,2.835182c-0.691483,0.824234-1.547462,1.477043-2.566788,1.95842
            c-1.019897,0.481377-2.186989,0.721497-3.500107,0.721497c-1.31427,0-2.481354-0.24012-3.500099-0.721497
            c-1.020485-0.481377-1.875885-1.134186-2.566788-1.95842c-0.691475-0.824814-1.219032-1.769676-1.581512-2.835182
            c-0.363632-1.064342-0.544296-2.181213-0.544296-3.350033c0-1.167664,0.180664-2.284534,0.544296-3.350037
            c0.36248-1.064346,0.890038-2.000553,1.581512-2.808048c0.690903-0.807495,1.546303-1.451065,2.566788-1.932446
            c1.018745-0.48138,2.185829-0.722073,3.500099-0.722073c1.313118,0,2.480209,0.240692,3.500107,0.722073
            c1.019325,0.48138,1.875305,1.12495,2.566788,1.932446c0.691475,0.807495,1.21788,1.743702,1.581512,2.808048
            c0.362473,1.065502,0.544289,2.182373,0.544289,3.350037C101.572083,62.302399,101.390266,63.41927,101.027794,64.483612z"
          />
          <path
            fill="#8A8C8C"
            d="M135.401443,51.855759c-1.188446-1.137074-2.593338-2.02422-4.212357-2.662022
            c-1.620193-0.637222-3.359856-0.956409-5.220726-0.956409c-1.860878,0-3.60112,0.319187-5.220726,0.956409
            c-1.620186,0.637802-3.015266,1.524948-4.186394,2.662022c-1.172279,1.137074-2.093483,2.490017-2.765335,4.057674
            c-0.672432,1.568237-1.008354,3.29982-1.008354,5.194752c0,1.895504,0.335922,3.635742,1.008354,5.220146
            c0.671852,1.585548,1.593056,2.955231,2.765335,4.109619c1.171127,1.154968,2.566208,2.050194,4.186394,2.687416
            c1.619606,0.637222,3.359848,0.956413,5.220726,0.956413c1.86087,0,3.600533-0.319191,5.220726-0.956413
            c1.619019-0.637222,3.023911-1.532448,4.212357-2.687416c1.189026-1.154388,2.119461-2.524071,2.791306-4.109619
            c0.67186-1.584404,1.008362-3.324642,1.008362-5.220146c0-1.894932-0.336502-3.626514-1.008362-5.194752
            C137.520905,54.345776,136.590469,52.992832,135.401443,51.855759z M133.59079,64.483612
            c-0.363052,1.065506-0.890045,2.010368-1.580933,2.835182c-0.691483,0.824234-1.54747,1.477043-2.566788,1.95842
            c-1.020477,0.481377-2.186989,0.721497-3.500687,0.721497c-1.31369,0-2.480782-0.24012-3.500107-0.721497
            c-1.019897-0.481377-1.875305-1.134186-2.56678-1.95842c-0.691483-0.824814-1.21846-1.769676-1.581512-2.835182
            c-0.36306-1.064342-0.544296-2.181213-0.544296-3.350033c0-1.167664,0.181236-2.284534,0.544296-3.350037
            c0.363052-1.064346,0.89003-2.000553,1.581512-2.808048c0.691475-0.807495,1.546883-1.451065,2.56678-1.932446
            c1.019325-0.48138,2.186417-0.722073,3.500107-0.722073c1.313698,0,2.480209,0.240692,3.500687,0.722073
            c1.019318,0.48138,1.875305,1.12495,2.566788,1.932446c0.690887,0.807495,1.21788,1.743702,1.580933,2.808048
            c0.363052,1.065502,0.544296,2.182373,0.544296,3.350037C134.135086,62.302399,133.953842,63.41927,133.59079,64.483612z"
          />
          <path
            fill="#8A8C8C"
            d="M167.726685,51.855759c-1.188431-1.137074-2.593323-2.02422-4.212357-2.662022
            c-1.620193-0.637222-3.359848-0.956409-5.220718-0.956409c-1.860886,0-3.60112,0.319187-5.220734,0.956409
            c-1.620178,0.637802-3.015259,1.524948-4.186386,2.662022c-1.172287,1.137074-2.093475,2.490017-2.765335,4.057674
            c-0.67244,1.568237-1.008362,3.29982-1.008362,5.194752c0,1.895504,0.335922,3.635742,1.008362,5.220146
            c0.67186,1.585548,1.593048,2.955231,2.765335,4.109619c1.171127,1.154968,2.566208,2.050194,4.186386,2.687416
            c1.619614,0.637222,3.359848,0.956413,5.220734,0.956413c1.86087,0,3.600525-0.319191,5.220718-0.956413
            c1.619034-0.637222,3.023926-1.532448,4.212357-2.687416c1.189026-1.154388,2.119461-2.524071,2.791321-4.109619
            c0.671844-1.584404,1.008362-3.324642,1.008362-5.220146c0-1.894932-0.336517-3.626514-1.008362-5.194752
            C169.846146,54.345776,168.91571,52.992832,167.726685,51.855759z M165.916031,64.483612
            c-0.363052,1.065506-0.89003,2.010368-1.580933,2.835182c-0.691483,0.824234-1.547455,1.477043-2.566788,1.95842
            c-1.020477,0.481377-2.186981,0.721497-3.500687,0.721497c-1.31369,0-2.480774-0.24012-3.500107-0.721497
            c-1.019897-0.481377-1.87529-1.134186-2.566772-1.95842c-0.691483-0.824814-1.21846-1.769676-1.581512-2.835182
            c-0.363052-1.064342-0.544296-2.181213-0.544296-3.350033c0-1.167664,0.181244-2.284534,0.544296-3.350037
            c0.363052-1.064346,0.89003-2.000553,1.581512-2.808048s1.546875-1.451065,2.566772-1.932446
            c1.019333-0.48138,2.186417-0.722073,3.500107-0.722073c1.313705,0,2.480209,0.240692,3.500687,0.722073
            c1.019333,0.48138,1.875305,1.12495,2.566788,1.932446c0.690903,0.807495,1.21788,1.743702,1.580933,2.808048
            c0.363052,1.065502,0.544296,2.182373,0.544296,3.350037C166.460327,62.302399,166.279083,63.41927,165.916031,64.483612z"
          />
          <path
            fill="#8A8C8C"
            d="M218.490875,55.060341c0-2.859997-0.491196-5.47007-1.473007-7.830795
            c-0.982376-2.360146-2.334747-4.37571-4.057678-6.04784c-1.723495-1.670979-3.773117-2.971973-6.151154-3.902412
            c-2.377457-0.930435-4.962143-1.395657-7.753448-1.395657c-2.790741,0-5.367325,0.465221-7.727478,1.395657
            c-2.360733,0.930439-4.402267,2.231434-6.125183,3.902412c-1.723511,1.672131-3.067215,3.687695-4.031708,6.04784
            c-0.965073,2.360725-1.447021,4.970798-1.447021,7.830795c0,2.825943,0.481949,5.419277,1.447021,7.779427
            c0.964493,2.360725,2.308197,4.3936,4.031708,6.099213c1.722916,1.705605,3.76445,3.032578,6.125183,3.979752
            c2.360153,0.947754,4.936783,1.445488,7.727493,1.421623c3.256882-0.027855,5.270538-0.350563,7.753448-1.421616
            c0.878113-0.378792,1.730988-0.761002,2.527802-1.22084l3.088867,3.506233l3.547424-3.125504l-2.884094-3.273849
            c1.660309-1.67823,2.971542-3.664749,3.928818-5.965012C217.99968,60.479618,218.490875,57.886284,218.490875,55.060341z
             M205.038788,59.666641l-3.54744,3.125507l4.562134,5.178623c-0.403748,0.228821-0.823212,0.440071-1.26001,0.632278
            c-1.723495,0.758438-3.636322,1.137077-5.737885,1.137077c-2.102142,0-3.99765-0.378639-5.685364-1.137077
            c-1.688873-0.757858-3.136475-1.80027-4.342239-3.127235c-1.206329-1.326393-2.136765-2.885975-2.791306-4.678162
            c-0.654541-1.791035-0.981812-3.703857-0.981812-5.737312c0-1.998245,0.327271-3.884518,0.981812-5.659966
            c0.654541-1.774296,1.576889-3.333874,2.765335-4.677582c1.189026-1.344288,2.636627-2.404015,4.342239-3.179188
            c1.705597-0.775173,3.609192-1.163048,5.711334-1.163048c2.1362,0,4.057678,0.387875,5.76329,1.163048
            c1.706177,0.775173,3.161285,1.8349,4.368195,3.179188c1.205765,1.343708,2.1362,2.903286,2.790741,4.677582
            c0.654541,1.775448,0.982376,3.66172,0.982376,5.659966c0,2.033455-0.327835,3.946278-0.982376,5.737312
            c-0.564789,1.546482-1.347748,2.908951-2.323212,4.10865L205.038788,59.666641z"
          />
          <rect x="167.46405" y="36.814072" fill="#8A8C8C" width="4.415781" height="36.595852" />
        </g>
      </g>
      <g>
        <g>
          <g>
            <path
              fill="#C1D42F"
              d="M39.943333,35.758991l-6.734158-8.754225c0.02198-0.284876,0.03297-0.566236,0.03297-0.841002v-9.828667
              c0-6.137587-4.993244-11.130833-11.130831-11.130833c-5.10112,0-9.401189,3.454014-10.711267,8.14315h7.012274v-3.90254
              c0-0.131886,0.106829-0.238715,0.238716-0.238715h6.72756c0.131887,0,0.238716,0.106829,0.238716,0.238715v23.707148
              c0,0.131889-0.106829,0.238716-0.238716,0.238716h-6.72756c-0.131887,0-0.238716-0.106827-0.238716-0.238716V19.844175h-7.431399
              v6.31959c0,6.137589,4.993246,11.130833,11.130393,11.130833c2.328247,0,4.539993-0.705154,6.42598-2.04425l1.806414,1.372505
              c0.106829,0.081333,0.237396,0.125294,0.371922,0.125294h8.740601c0.234318,0,0.447975-0.133205,0.551285-0.342907
              C40.110828,36.195099,40.086208,35.944515,39.943333,35.758991z"
            />
            <path
              fill="#6ACFF6"
              d="M11.400047,13.347416H7.204993v-13.1087C7.204993,0.106829,7.098165,0,6.966278,0H0.238716
              C0.106829,0,0,0.106829,0,0.238716V32.44379c0,0.131886,0.106829,0.238716,0.238716,0.238716h6.727562
              c0.131887,0,0.238716-0.106831,0.238716-0.238716V19.844175h3.775928v-3.509077
              C10.980922,15.298961,11.133965,14.299806,11.400047,13.347416z"
            />
            <path
              fill="#50B848"
              d="M18.412321,33.152023c0,0.131889,0.106829,0.238716,0.238716,0.238716h6.72756
              c0.131887,0,0.238716-0.106827,0.238716-0.238716V9.444876c0-0.131886-0.106829-0.238715-0.238716-0.238715h-6.72756
              c-0.131887,0-0.238716,0.106829-0.238716,0.238715v3.90254h-7.012274c-0.266082,0.95239-0.419126,1.951545-0.419126,2.987682
              v3.509077h7.431399V33.152023z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default WideHoodQLogo;
