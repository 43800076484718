/**
*
* Population
*
*/

import React from 'react';
import { FormattedMessage } from 'react-intl';

import messages from './messages';
// import styles from './styles.css';

import textHelpers from 'utils/textHelpers';

function Population(props) {
  const { populationNum } = props;

  return (
    <div style={{ marginBottom: '10px' }}>
      <h3><FormattedMessage {...messages.header} /></h3>
      <div>{textHelpers.numberWithCommas(populationNum)}</div>
    </div>
  );
}

export default Population;
