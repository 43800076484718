import { Map } from 'immutable';

export enum marketDataReportActions {
  LOAD_MARKET_DATA_REPORT         = 'LOAD_MARKET_DATA_REPORT',
  LOAD_MARKET_DATA_REPORT_SUCCESS = 'LOAD_MARKET_DATA_REPORT_SUCCESS',
  LOAD_MARKET_DATA_REPORT_ERROR   = 'LOAD_MARKET_DATA_REPORT_ERROR'
}

export type MarketDataReportActionPayload = {
  neighbourhoodReportId?: string;
}

export type PropertyTypeMarketData = {
  avg_current_value: number | null;
  avg_sale_price:    number | null;
  avg_tenure:        number | null;
  high_sale_price:   number | null;
  low_sale_price:    number | null;
  median_sale_price: number | null;
  mkt_shift:         number | null;
  total_count:       number | null;
}

// platform: engines/reports/app/serializers/reports/neighbourhood_market_report_serializer.rb
export interface NeighbourhoodMarketReportData {
  id:          string;
  name:        string;
  description: string;
  branding:    string;
  package_contents: string[];
  user:        any; // TODO
  condo:       PropertyTypeMarketData;
  duplex:      PropertyTypeMarketData;
  row:         PropertyTypeMarketData;
  bungalow:    PropertyTypeMarketData;

  construction_period: {
    'pre 1960':  number;
    '1961-1980': number;
    '1981-1990': number;
    '1991-2000': number;
    '2001-2005': number;
    '2006-2010': number;
    '2011-2016': number;
  }

  language: {
    english: number;
    other:   number;
  }

  percent_rented: number;
  percent_owned:  number;

  population_by_age: {
    '0-4':    number;
    '5-9':    number;
    '10-14':  number;
    '15-19':  number;
    '20-24':  number;
    '25-29':  number;
    '30-34':  number;
    '35-39':  number;
    '40-44':  number;
    '45-49':  number;
    '50-54':  number;
    '55-59':  number;
    '60-64':  number;
    '65-69':  number;
    '70-74+': number;
  }

  total_population: number;
  total_households: number;

  household_sizes: {
    '1':  number;
    '2':  number;
    '3':  number;
    '4':  number;
    '5+': number;
  }

  summary:     {
    avg_sale_price:  number;
    high_sale_price: number;
    low_sale_price:  number;
    total_count:     number;
  }
}

export interface MarketDataReportAction<T> {
  type: marketDataReportActions;
  payload: T;
};

export interface IState extends Map<string, any> {
  requestingData:    boolean;
  report:            NeighbourhoodMarketReportData;
}