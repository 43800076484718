function shouldCopyText(target) {
  let message;
  const copyTarget = document.querySelector(target.getAttribute('data-copy'));
  copyTarget.focus();
  copyTarget.setSelectionRange(0, copyTarget.value.length);

  try {
    const success = document.execCommand('copy');

    if (success) {
      message = 'Copied!';
    } else {
      message = 'Error copying - try pressing Ctrl+C instead.';
    }
  } catch (err) {
    message = 'Error copying - try pressing Ctrl+C instead.';
  }
  $(target).popover({
    placement: "top",
    title:     message
  }).popover('show');

}


function handleClick(event) {
  const { target } = event;

  if (target.hasAttribute('data-copy')) {
    shouldCopyText(target);
  }
}

function start() {
  document.addEventListener('click', handleClick);
}

export default { start };