/* eslint indent: "off"*/

/**
*
* DetailedReportLink
*
*/

import React from 'react';

import { FormattedMessage } from 'react-intl';
import messages from './messages';
import styles from './styles.css';

import Button from 'components/Button';


class DetailedReportLink extends React.Component { // eslint-disable-line react/prefer-stateless-function
  openDetailedReportPage = () => {
    // this.openRoute(`/package/${packageID}/detailed`, source);
  }

  render() {
    const { children } = this.props;
    let linkContent = (<FormattedMessage {...messages.defaultContent} />);

    if (children) {
      linkContent = ({ children });
    }

    let detailedReportButton = (
      <Button className={styles.detailedReportLink} onClick={this.openDetailedReportPage}>
        {linkContent}
      </Button>
    );

    let wrapperStyle = '';

    return (
      <div className={wrapperStyle}>
        {detailedReportButton}
      </div>
    );
  }
}

export default DetailedReportLink;
