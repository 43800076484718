
const prices = {
  monthly: 2495,
  yearly:  23995
}


export default class SignUpForm {
  constructor() {
    this.selectedPlan = window.params.plan || 'monthly';
    this.coupon = null;
    this.rewardfulInitialized = false;

    this.initializeStripe();
    this.initializePlanSelector();
    this.initializeRewardful();
    this.updatePrices();
  }

  initializeStripe() {
    const stripeEl = document.querySelector('div[data-stripe]');

    if (stripeEl) {
      const stripe = Stripe(window.stripe_pk);
      const elements = stripe.elements();

      const card = elements.create('card');
      card.mount(stripeEl);
    }
  }

  initializePlanSelector() {
    const plans = document.querySelectorAll('div[data-plan]');

    plans.forEach(planEl => {
      planEl.addEventListener('click', this.handlePlanSelection.bind(this));
    });

  }

  initializeRewardful() {
    window.rewardful('ready', () => {
      if (!this.rewardfulInitialized) {
        window.Rewardful.Forms.attach();
        if (window.Rewardful.coupon) {
          prices.monthly -= prices.monthly * (window.Rewardful.coupon.percent_off / 100);
          prices.yearly -= prices.yearly * (window.Rewardful.coupon.percent_off / 100);
          this.coupon = window.Rewardful.coupon;
          this.affiliate = window.Rewardful.affiliate;
          this.updatePrices();
        }
        this.rewardfulInitialized = true;
      }
    })
  }
  
  updatePrices() {
    const payingPriceEl = document.querySelector('.terms span.price');
    payingPriceEl.innerHTML = this.priceForDisplay(prices[this.selectedPlan]);

    document.querySelectorAll('.plan-card').forEach(elem => {
      const price = prices[elem.getAttribute('data-plan')];

      if (this.coupon) {
        const benefitsListEl = elem.querySelector('ul.benefits');

        const discountEl = document.createElement('li');
        discountEl.innerText = `${this.coupon.percent_off}% off for ${this.affiliate.first_name} customers!`;
        benefitsListEl.insertAdjacentElement('afterbegin', discountEl);
      }

      elem.querySelectorAll('.price span.amount').forEach(amountEl => {
        let existingAmountEl = amountEl;
        if (this.coupon) {
          existingAmountEl.classList.add('red');

          amountEl = document.createElement('span');
          amountEl.classList = existingAmountEl.classList;
          amountEl.classList.remove('red');
          existingAmountEl.insertAdjacentElement('afterend', amountEl);
        }        
      })

      elem.querySelectorAll('span.amount:not(.red)').forEach(amountEl => {
        if (amountEl.classList.contains('monthly')) {
          amountEl.innerHTML = this.priceForDisplay(Math.round(price / 12, 2));
        } else {
          amountEl.innerHTML = this.priceForDisplay(price);
        }
      });
    });

  }

  priceForDisplay(price) {
    return `$${price / 100}`;
  }
}