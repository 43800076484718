/**
*
* Convenience
*
*/

import React from 'react';

function Convenience(props) {
  const { x, y, height, width } = props;

  return (
    <svg x={x} y={y} height={height} width={width} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 216 216">
      <circle className="cls-1" fill="#ecb020" cx="108.20204" cy="109" r="99.9975" />
      <polygon className="cls-2" fill="#fff" points="66.589 80.112 75.572 112.992 130.281 112.992 128.219 118.511 74.499 119.17 74.499 128.229 135.47 128.229 145.687 79.782 163.813 79.782 163.813 66.929 139.095 66.929 136.129 80.112 66.589 80.112" />
      <circle className="cls-2" fill="#fff" cx="86.69326" cy="144.0488" r="8.2393" />
      <circle className="cls-2" fill="#fff" cx="120.6392" cy="144.0488" r="8.2393" />
    </svg>
  );
}



export default Convenience;
