/**
*
* Convenience
*
*/

import React from 'react';

function Convenience(props) {
  const { x, y, height, width } = props;

  return (
    <svg x={x} y={y} height={height} width={width} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 216 216">
      <circle className="cls-1" fill="#ecb020" cx="108.20204" cy="109" r="99.9975" />
      <path className="cls-2" fill="#fff" d="M101.69165,90.48807a6.80337,6.80337,0,0,1-6.57676,6.47924v52.90174c-0.001,7.53353-10.2407,7.53353-10.25615,0V96.96731a6.69714,6.69714,0,0,1-6.72447-6.92046V60.89556a1.84517,1.84517,0,1,1,3.68036.09848V82.53648h3.04411V60.79612a1.76927,1.76927,0,1,1,3.53457.09944V82.53649h3.13873V60.84536a1.72809,1.72809,0,1,1,3.43513.09848V82.53648h3.14259V60.84536a1.79482,1.79482,0,1,1,3.58285.09848V90.48807h-0.001Z" />
      <path className="cls-2" fill="#fff" d="M128.65582,67.18851v82.20171a5.1381,5.1381,0,1,1-10.25518,0V116.75457h-5.44813V67.18851c-0.00579-11.54022,15.71682-11.54022,15.70331,0h0Z" />
    </svg>
  );
}



export default Convenience;
