/*
 * PropertyTypesPriceDistribution Messages
 *
 * This contains all the text for the PropertyTypesPriceDistribution component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  propertyTypePriceDistro: {
    id: 'app.components.PropertyTypesPriceDistribution.propertyTypePriceDistro',
    defaultMessage: 'Property Type Price Distribution',
  },
});
