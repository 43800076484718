/**
*
* mapMarkerGenerator utility
*
* Explanation here...
*
*/


import pinBoat from 'images/markers/pin-boat.png';
import pinElementary from 'images/markers/pin-elementary.png';
import pinFireStations from 'images/markers/pin-fire-stations.png';
import pinGasStations from 'images/markers/pin-gas-stations.png';
import pinGrocery from 'images/markers/pin-grocery.png';
import pinHealthCare from 'images/markers/pin-health-care.png';
import pinHigh from 'images/markers/pin-high.png';
import pinHome from 'images/markers/pin-home';
import pinMiddle from 'images/markers/pin-middle.png';
import pinPharmacies from 'images/markers/pin-pharmacies.png';
import pinPolice from 'images/markers/pin-police.png';
import pinRailTransit from 'images/markers/pin-rail-transit.png';
import pinShadow from 'images/markers/pin-shadow.png';
import pinStreetTransit from 'images/markers/pin-street-level-transit.png';
import pinParks from '../../../scss/library/icons/png/parks.png';

const PINS = {
  elementary: pinElementary,
  'fire-stations': pinFireStations,
  'gas-stations': pinGasStations,
  grocery: pinGrocery,
  'health-care': pinHealthCare,
  parks: pinParks,
  high: pinHigh,
  home: pinHome,
  middle: pinMiddle,
  pharmacies: pinPharmacies,
  police: pinPolice,
  'rail-transit': pinRailTransit,
  shadow: pinShadow,
  'street-level-transit': pinStreetTransit
}
export function mapMarkerGenerator(places, latitude, longitude) {
  const markers = [];
  const polygons = [];

  Object.keys(places).forEach((key) => {
    const placeContent = places[key];

    if (placeContent) {
      Object.keys(placeContent).forEach((index) => {
        const item = placeContent[index];

        let customPin = PINS[item.place_category_key];
        let gradeLevel = null; // Only used by public and catholic schools

        // NOTE : schools (public assigned OR public unassigned OR catholic)
        if (key === 'A' || key === 'AA' || key === 'B' || key === 'BB') {
          // NOTE : Pin rules for schools
          // Elementary -> Elementary
          // Middle -> Middle
          // High -> High
          // Elementary/Middle -> Elementary
          // Elementary/Middle/High -> Elementary
          // Middle/High -> High

          if (item.features['Grade Level']) {
            if (item.features['Grade Level'].length === 1) {
              gradeLevel = item.features['Grade Level'][0].toLowerCase();
            } else if (item.features['Grade Level'].includes('Elementary')) {
              gradeLevel = 'elementary';
            } else if (item.features['Grade Level'].includes('High')) {
              gradeLevel = 'high';
            } else {
              gradeLevel = 'elementary';
            }
          }

          if (gradeLevel) {
            customPin = PINS[gradeLevel];
          } else { // NOTE : should not happen
            // console.log(`Unable to retrieve grade level for ${item.name}`);
            customPin = PINS.shadow;
          }
        }

        if (item.geometry) { // NOTE : logic for school catchments & park polygons
          // const geometry = JSON.parse(item.geometry); // OLD
          const geometry = item.geometry;

          if ((key === 'A' || key === 'AA') && item.place_category_key === 'school-attendance-zones') {
            let strokeColor = null;
            let strokeWeight = null;
            let strokeOpacity = null;

            switch (gradeLevel) {
              case 'middle':
                strokeColor = '#01808C';
                strokeWeight = 3;
                strokeOpacity = 1;
                break;
              case 'high':
                strokeColor = '#6A6BB1';
                strokeWeight = 4;
                strokeOpacity = 0.8;
                break;
              case 'elementary':
              default:
                strokeColor = '#CE509D';
                strokeWeight = 5;
                strokeOpacity = 0.6;
            }

            // TODO : refactor this if-else statement
            if (geometry.type === 'Polygon') {
              const placePolygon = {
                paths: [],
                options: {
                  strokeColor,
                  strokeWeight,
                  strokeOpacity,
                  fillOpacity: 0,
                },
                key: `${item.name}-${item.place_category_key}`,
              };

              geometry.coordinates[0].forEach((coord) => {
                placePolygon.paths.push({ lat: coord[1], lng: coord[0] });
              });

              polygons.push(placePolygon);
            } else if (geometry.type === 'MultiPolygon') {
              geometry.coordinates.forEach((polygon, polygonIndex) => {
                const placePolygon = {
                  paths: [],
                  options: {
                    strokeColor,
                    strokeWeight,
                    strokeOpacity,
                    fillOpacity: 0,
                  },
                  key: `${item.name}-${polygonIndex}`,
                };

                polygon[0].forEach((coord) => {
                  placePolygon.paths.push({ lat: coord[1], lng: coord[0] });
                });

                polygons.push(placePolygon);
              });
            }
          } else if (key === 'C') { // NOTE : parks
            // TODO : refactor this if-else statement
            if (geometry.type === 'Polygon') {
              const placePolygon = {
                paths: [],
                options: {
                  strokeColor: 'green',
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: 'green',
                  fillOpacity: 0.35,
                },
                key: `${item.name}-${item.place_category_key}`,
              };

              geometry.coordinates[0].forEach((coord) => {
                placePolygon.paths.push({ lat: coord[1], lng: coord[0] });
              });

              polygons.push(placePolygon);
            } else if (geometry.type === 'MultiPolygon') {
              geometry.coordinates.forEach((polygon, polygonIndex) => {
                const placePolygon = {
                  paths: [],
                  options: {
                    strokeColor: 'green',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: 'green',
                    fillOpacity: 0.35,
                  },
                  key: `${item.name}-${polygonIndex}`,
                };

                polygon[0].forEach((coord) => {
                  placePolygon.paths.push({ lat: coord[1], lng: coord[0] });
                });

                polygons.push(placePolygon);
              });
            } else if (geometry.type === 'Point') {
              // Deal with a shitload of points
            }
          }
        } else if (item.centroid && key === 'C') {
          // const centroid = JSON.parse(item.centroid); // OLD
          const centroid = item.centroid;
          if (centroid.type === 'Point') {
            markers.push({
              position: {
                lat: centroid.coordinates[1],
                lng: centroid.coordinates[0],
              },
              key: `${index}-${item.place_category_key}-marker`,
              // label: `${key}`,
              icon: customPin,
            });
          }
        }

        if (item.closest_latitude && item.closest_longitude) {
          markers.push({
            position: {
              lat: item.closest_latitude,
              lng: item.closest_longitude,
            },
            key: `${index}-${item.place_category_key}-marker`,
            // label: `${key}`,
            icon: customPin,
          });
        } else if (item.centroid || item.geometry) {
          // const geometry = JSON.parse(item.centroid || item.geometry); // OLD
          const geometry = item.centroid || item.geometry;

          if (geometry.type === 'Point') {
            markers.push({
              position: {
                lat: geometry.coordinates[1],
                lng: geometry.coordinates[0],
              },
              key: `${index}-${item.place_category_key}-marker`,
              // label: `${key}`,
              icon: customPin,
            });
          } else {
            // console.log(`No lat/long point available for ${item.name}`);
          }
        } else {
          // console.log(`No lat/long available for ${item.name}`);
        }
      });
    } else {
      // console.log('NOT inside if(placeContent)');
    }
  });

  // NOTE - Add home pin (default Center)
  markers.push({
    position: {
      lat: latitude,
      lng: longitude,
    },
    key: 'home-marker',
    // label: 'Z',
    icon: PINS.home
  });

  return [markers, polygons];
}
