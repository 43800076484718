/* eslint no-multi-spaces: "off" */
/* eslint no-unused-expressions: off */

export default new class {
  compareSchoolRatings(a, b) {
    const ratingA = a.features['SchoolQ Score'] || a.features['GreatSchools Rating'] || 0;
    const ratingB = b.features['SchoolQ Score'] || b.features['GreatSchools Rating'] || 0;

    if (ratingA > ratingB) {
      return -1;
    }
    if (ratingA < ratingB) {
      return 1;
    }
    return 0;
  }

  compareAlphabetically(a, b) {
    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
    const nameB = b.name.toUpperCase(); // ignore upper and lowercase

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0; // names must be equal
  }

  selectPublicSchoolsByType(publicSchoolsArray, maxLength) {
    const sortedPublicSchools = publicSchoolsArray.slice(0, maxLength);

    // NOTE : count instances of each school type and keep track
    // of their index inside publicSchoolTypes, to make sure there's
    // at least 1 elementary, 1 middle, and 1 high school when available
    const publicSchoolTypes = {
      Elementary: [],
      Middle: [],
      High: [],
    };

    sortedPublicSchools.forEach((school, index) => {
      school.features['Grade Level'].forEach((gradeLevel) => {
        if (publicSchoolTypes[gradeLevel]) {
          publicSchoolTypes[gradeLevel].push(index);
        } else {
          publicSchoolTypes[gradeLevel] = [index];
        }
      });
    });

    const missingSchoolType = [];
    Object.keys(publicSchoolTypes).forEach((schoolType) => {
      if (publicSchoolTypes[schoolType] && publicSchoolTypes[schoolType].length < 1) {
        missingSchoolType.push(schoolType);
      }
    });

    // TODO : make this function recurring so that all missing school types are accounted for
    //
    // maybe start with something like...
    // missingSchoolType.forEach(...)
    //
    if (missingSchoolType.length > 0) {
      const unlistedPublicSchools  = publicSchoolsArray.slice(maxLength, publicSchoolsArray.length);

      // NOTE : currently assumes only one missing school type is ever possible
      // TODO : recurring function
      const schoolToExchange       = unlistedPublicSchools.find(i => i.features['Grade Level'].includes(missingSchoolType[0]));

      if (schoolToExchange) {
        const mostFrequentSchoolType = {
          type: null,
          count: 0,
          indexToExchange: null,
        };

        Object.keys(publicSchoolTypes).forEach((type) => {
          if (mostFrequentSchoolType.count < publicSchoolTypes[type].length) {
            const lowestScoreIndex                 = publicSchoolTypes[type].length - 1;
            mostFrequentSchoolType.indexToExchange = publicSchoolTypes[type][lowestScoreIndex];
            mostFrequentSchoolType.count           = publicSchoolTypes[type].length;
            mostFrequentSchoolType.type            = type;
          }
        });

        sortedPublicSchools[mostFrequentSchoolType.indexToExchange] = schoolToExchange;
      }
    } // NOTE : else we have everything we need

    return sortedPublicSchools.sort((a, b) => compareAlphabetically(a, b)); // eslint-disable-line no-undef
  }

  selectCathPrivSchoolsByType(catholicPrivateSchoolsArray, maxLength) {
    // TODO : select at least: one public, one private
    const sortedCatholicPrivateSchools = catholicPrivateSchoolsArray.slice(0, maxLength);

    // NOTE : count instances of each school type and keep track
    // of their index inside cathPrivSchoolTypes, to make sure there's
    // at least 1 catholic and 1 private school when available
    const cathPrivSchoolTypes = {
      Private: [],
      Catholic: [],
    };

    sortedCatholicPrivateSchools.forEach((school, index) => {
      school.features['School Type'].forEach((schoolType) => {
        schoolType === 'Private' && cathPrivSchoolTypes[schoolType].push(index);
      });
      school.features.Orientation.forEach((schoolType) => {
        schoolType === 'Catholic' && cathPrivSchoolTypes[schoolType].push(index);
      });
    });

    const missingSchoolType = [];
    Object.keys(cathPrivSchoolTypes).forEach((schoolType) => {
      if (cathPrivSchoolTypes[schoolType].length < 1) {
        missingSchoolType.push(schoolType);
      }
    });

    if (missingSchoolType.length > 0) {
      const unlistedCathPrivSchools = catholicPrivateSchoolsArray.slice(maxLength, catholicPrivateSchoolsArray.length);
      const schoolToExchange        = unlistedCathPrivSchools.find(i =>
        i.features['School Type'].includes(missingSchoolType[0]) || i.features.Orientation.includes(missingSchoolType[0])
      );

      if (schoolToExchange) {
        const mostFrequentSchoolType  = {
          type: null,
          count: 0,
          indexToExchange: null,
        };

        Object.keys(cathPrivSchoolTypes).forEach((type) => {
          if (mostFrequentSchoolType.count < cathPrivSchoolTypes[type].length) {
            const lowestScoreIndex                 = cathPrivSchoolTypes[type].length - 1;
            mostFrequentSchoolType.indexToExchange = cathPrivSchoolTypes[type][lowestScoreIndex];
            mostFrequentSchoolType.count           = cathPrivSchoolTypes[type].length;
            mostFrequentSchoolType.type            = type;
          }
        });

        sortedCatholicPrivateSchools[mostFrequentSchoolType.indexToExchange] = schoolToExchange;
      }
    } // NOTE : else we have everything we need

    return sortedCatholicPrivateSchools.sort((a, b) => compareAlphabetically(a, b)); // eslint-disable-line no-undef
  }

  // NOTE : assumes a MAX_SCHOOLS_DISPLAY of 9 for the default variable values
  // NOTE : original logic
  //
  // A) If Includes middle-only schools
  // - Up to 3 Elementary or Elementary/Middle schools
  // - up to 2 or 3 Middle-only schools
  // - up to 3 Middle/High or High schools.
  //
  // B) No middle-only schools
  //  - Up to 4 Elementary and/or elementary/high
  //  - up to 4 high and/or middle/high
  trimSchoolsByGrade(
    assignedSchoolsArray,
    unassignedSchoolsArray = [],
    maxElementaryCount = 3,
    maxMiddleCount = 3,
    maxHighCount = 3,
  ) {
    // NOTE : count instances of each school type and keep track
    // of their index inside publicSchoolTypes, to make sure there's
    // at MOST: maxElementaryCount elementary,
    // maxMiddleCount middle, and maxHighCount high school
    const schoolsByGrade = {
      Elementary: [],
      Middle:     [],
      High:       [],
    };

    const maxGradeCounts = {
      Elementary: maxElementaryCount,
      Middle:     maxMiddleCount,
      High:       maxHighCount,
    };

    // TODO : figure this one out later
    // if (unassignedSchoolsArray.length < 1) {
    //   // only work with assignedSchoolsArray
    // }

    // TODO : maybe assigned the initial var differently?
    const schoolsArray = assignedSchoolsArray;

    assignedSchoolsArray.forEach((school, index) => {
      school.features['Grade Level'].forEach((gradeLevel) => {
        if (schoolsByGrade[gradeLevel]) {
          schoolsByGrade[gradeLevel].push(index);
        } else {
          schoolsByGrade[gradeLevel] = [index];
        }
      });
    });

    // const excessGrade = [];
    Object.keys(schoolsByGrade).forEach((gradeLevel) => {
      if (schoolsByGrade[gradeLevel] && schoolsByGrade[gradeLevel].length <= maxGradeCounts[gradeLevel]) {
        maxGradeCounts[gradeLevel] -= schoolsByGrade[gradeLevel].length;
      } else {
        maxGradeCounts[gradeLevel] = 0;
        // excessGrade.push(gradeLevel);
      }
    });

    unassignedSchoolsArray.forEach((school) => {
      school.features['Grade Level'].forEach((gradeLevel) => {
        if (maxGradeCounts[gradeLevel] && maxGradeCounts[gradeLevel] > 0) {
          schoolsArray.push(school);
          maxGradeCounts[gradeLevel] -= 1;
        }
      });
    });

    return schoolsArray;
  }
};
