/*
 * TransitReport Messages
 *
 * This contains all the text for the TransitReport component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.TransitReport.header',
    defaultMessage: 'Transit',
  },
  description: {
    id: 'app.components.TransitReport.description',
    defaultMessage: 'The public transit system can be an easy, cost-effective and efficient way of getting around the city, accessible from your neighborhood. See the closest Public Transit stops near you below:',
  },
  descriptionCA: {
    id: 'app.components.TransitReport.descriptionCA',
    defaultMessage: 'The public transit system can be an easy, cost-effective and efficient way of getting around the city, accessible from your neighbourhood. See the closest Public Transit stops near you below:',
  },
  nearestPlace: {
    id: 'app.components.TransitReport.nearestPlace',
    defaultMessage: 'The nearest {place} is {name}',
  },
});
