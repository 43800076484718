/**
*
* BlueBadge
*
*/

import React from 'react';

function BlueBadge() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 36.666668 38.666668" viewBox="0 0 36.666668 38.666668" version="1.1" y="0px" x="0px">
      <g>
        <path d="m27.658 2.0285c-0.31423-0.006981-23.57 0-23.57 0s-1.4928 0-1.4928 1.9642v24.434s0.078573 7.6996 6.5212 7.621c6.364 0.31427 18.228 0.15713 18.228 0.15713s7.071-0.2357 7.071-7.7782v-18.385s0.314-7.8564-6.757-8.0135z" fill="#00ACEC" />
      </g>
    </svg>
  );
}

export default BlueBadge;
