/* eslint no-multi-spaces: "off" */
/* eslint react/jsx-equals-spacing: "off" */

/**
*
* RentersOwners
*
*/

import React from 'react';
import { FormattedMessage } from 'react-intl';

import messages from './messages';
import styles from './styles.css';

import PieGraph from '../PieGraph';

import textHelpers from 'utils/textHelpers';

function RentersOwners(props) {
  const {
    owners,
    renters,
  } = props;

  const rentersOwnersNumbers = [owners, renters];
  const rentersOwnersPctData = [owners / 100, renters / 100];
  const labels = ['Owned', 'Rented'];

  // OLD
  // let mainClassName = 'col-xs-7';
  // let mainStyle = { borderRight: 'dotted 3px gray', marginBottom: '10px' };
  //
  // if (hoodID === 'canada-toronto-bathurst-manor') {
  //   mainClassName = 'col-xs-12';
  //   mainStyle = { marginBottom: '10px' };
  // }

  const mainClassName = 'col-xs-12';
  const mainStyle = { marginBottom: '10px' };

  // const graphColors = [
  //   (alpha) => `rgba(0,174,239,${alpha})`, // incomeDistroTradeAreaColors
  //   (alpha) => `rgba(196,214,0,${alpha})`, // incomeDistroBenchmarkColors
  //   (alpha) => `rgba(236,176,32,${alpha})`, // incomeDistroAltColors
  // ];

  // TODO : implement more permanent solution
  const rentersOwnersColors = (alpha) => [
    `rgba(0,174,239,${alpha})`, // Owned
    `rgba(255,255,255,${alpha})`, // Rented
    `rgba(236,176,32,${alpha})`, // Rented
  ];

  const rentersOwnersColorsAlt = (alpha) => [
    `rgba(0,174,239,${alpha})`, // Owned
    `rgba(0,174,239,${alpha})`, // Rented
    `rgba(236,176,32,${alpha})`, // Rented
  ];

  // TODO : fix colours
  const data = {
    labels,
    datasets: [
      {
        // backgroundColor:      graphColors[0](0.8), // rentersOwnersColors(0.8),
        backgroundColor:      rentersOwnersColors(0.8),
        // borderColor:          graphColors[1](0.8), // rentersOwnersColorsAlt(1),
        borderColor:          rentersOwnersColorsAlt(1),
        borderWidth:          3,
        // hoverBackgroundColor: graphColors[0](0.8), // rentersOwnersColors(1),
        hoverBackgroundColor: rentersOwnersColors(1),
        // hoverBorderColor:     graphColors[1](0.8), // rentersOwnersColorsAlt(1),
        hoverBorderColor:     rentersOwnersColorsAlt(1),
        data:                 rentersOwnersNumbers,
      },
    ],
  };

  const graphHeight = 50;
  const graphWidth = 125;

  const options = {
    maintainAspectRatio: true,
    responsive:          true,
  };

  const legend = {
    display: false,
  };

  return (
    <div className={mainClassName} style={mainStyle}>
      <h5><FormattedMessage {...messages.header} /></h5>
      <div className={styles.subheaderMediumAlt}>
        <FormattedMessage
          {...messages.rentersOwnersPct}
          values={{
            owners: textHelpers.decimalToPercent(rentersOwnersPctData[0]),
            renters: textHelpers.decimalToPercent(rentersOwnersPctData[1]),
          }}
        />
      </div>
      <div className="row">
        <PieGraph
          data    = {data}
          width   = {graphWidth}
          height  = {graphHeight}
          options = {options}
          legend  = {legend}
        />
      </div>
    </div>
  );
}

export default RentersOwners;
