// /**
// *
// * ShareModal
// *
// */

// import React from 'react';
// import ReactTooltip from 'react-tooltip';
// import { FormattedMessage } from 'react-intl';

// import messages from './messages';
// import styles from './styles.css';

// import Modal from '../Modal';
// import SVGIcon from 'components/reports/SVGIcon';
// // 
// class ShareModal extends React.Component { // eslint-disable-line react/prefer-stateless-function
//   constructor(props) {
//     super(props);
//     this.state = {
//       message: 'tooltip',
//     };
//   }

//   setTooltipCopied() {
//     this.setState({ message: 'copied' });
//   }

//   copyToClipboard(str) {
//     const el = document.createElement('textarea');

//     el.value = str;
//     document.body.appendChild(el);
//     el.select();

//     document.execCommand('copy');
//     document.body.removeChild(el);

//     this.setTooltipCopied();
//   }

//   render() {
//     const { address, packageURL } = this.props;

//     const tooltip = (
//       <ReactTooltip id="clipboard_tooltip" place="left" type="info" effect="solid">
//         <FormattedMessage {...messages[this.state.message]} />
//       </ReactTooltip>
//     );

//     return (
//       <Modal>
//         <h3>
//           <FormattedMessage {...messages.headerIntro} /> {address}
//         </h3>

//         <p>
//           <FormattedMessage {...messages.body} />
//         </p>

//         <div className="row">
//           <div className="col-xs-10">
//             <a href={packageURL} target="_blank">
//               {packageURL}
//             </a>
//           </div>
//           <div className="col-xs-2">
//             <button className={styles.clipboardButton} onClick={() => this.copyToClipboard(packageURL)} data-tip data-for="clipboard_tooltip">
//               <SVGIcon iconType="clipboard" width={25} />
//             </button>
//             {tooltip}
//           </div>
//         </div>
//       </Modal>
//     );
//   }
// }

// ShareModal.propTypes = {
//   address:    React.PropTypes.string.isRequired,
//   packageURL: React.PropTypes.string.isRequired,
// };

// export default ShareModal;
