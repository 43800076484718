import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import CustomHeaderLayout    from '../banners/CustomHeaderLayout';
import Footer                from '../Footer';

import NeighbourhoodInsights          from './components/NeighbourhoodInsights';
import PropertyTypesPriceDistribution from './components/PropertyTypesPriceDistribution';
import PeriodHomeConstruction         from './components/PeriodHomeConstruction';
import Language                       from './components/Language';
import RentersOwners                  from './components/RentersOwners';
import PopulationByAge                from './components/PopulationByAge';
import HouseholdSize                  from './components/HouseholdSize';

import ReportBanner from '../banners/ReportBanner';
import NeighbourhoodReportBanner from '../banners/NeighbourhoodReportBanner';

import ReportHelmet from '../ReportHelmet';
import { NeighbourhoodMarketReportData } from './types';
import { fetchMarketReportData } from './data';
import messages from './messages';
import textHelpers from 'utils/textHelpers';

import Navigation from 'components/navigation/Navigation';
import ReportNav from 'components/navigation/ReportNav';

const styles = require('./styles.css');
const marketDemographicsStyles = require('./components/MarketDemographics/styles.css');

import { getPackageContents } from '../utils';

interface IProps {
  report: any;
  hideSidebar: boolean;
  // name:        string;
  // description: string;
}

interface IState {
  loading: boolean;
  report:  NeighbourhoodMarketReportData;
}

class MarketDataReport extends React.Component<IProps, IState> {
  reportTitle = 'SchoolQ Neighbourhood Real Estate Report™';
  state: IState = {
    loading: false,
    report:  {} as NeighbourhoodMarketReportData
  };

  componentWillMount() {
    // const { dispatch } = this.props;
    // const { neighbourhoodReportId } = this.props.params;
    const neighbourhoodReportId = null;
  
    // dispatch(displayHighlightsLoader());
    
    // // no fucks given.
    // dispatch({ type: 'UPDATE_BRANDING', branding: 'schoolq' });

    this.setState({ loading: true });
    fetchMarketReportData(neighbourhoodReportId).then(report => {
      this.setState({
        report: {
          ...report,
          package_contents: [],
        },
        loading: false
      });
      // dispatch(hideLoader());
    })
  }

  renderPeriodOfHomeConstruction = () => {
    return <PeriodHomeConstruction
      hoodName         = {this.state.report.name}
      hoodConstruction = {this.state.report.construction_period}
    />
  }

  renderPropertyTypePriceDistribution = () => {
    return <PropertyTypesPriceDistribution
      priceDistroCondo    = {this.state.report.condo}
      priceDistroBungalow = {this.state.report.bungalow}
      priceDistroDuplex   = {this.state.report.duplex}
      priceDistroRow      = {this.state.report.row}
    />;
  }

  renderFirstLanguage = () => {
    return <Language language={this.state.report.language} />;
  }

  renderRentersVsOwners = () => {
    return <RentersOwners 
      renters = {this.state.report.percent_rented}
      owners  = {this.state.report.percent_owned}
    />
  }

  renderPopulationByAge = () => {
    return <PopulationByAge
      populationByAge={this.state.report.population_by_age}
    />
  }

  renderHouseholdSize = () => {
    return <HouseholdSize
      hoodName      = {this.state.report.name}
      householdSize = {this.state.report.household_sizes}
    />
  }

  render() {
    const { report } = this.state;
    const {
      name, 
      description,
      user,
      summary,
      branding,
      condo, bungalow, duplex, row, package_contents
    } = report;

    const packageID = null;
    const hideSidebar = true;

    if (!name) { return null; }

    const customHeaderImg = user.custom_header_image || '/assets/branding/customExceptions/daveschoolqhoodqcom.png';

    return (
      <div className="row">
        <div className={`d-print-none col-sm-4 col-md-3${hideSidebar && ' hidden'}`}>
          <Navigation user={user} campaign={null} address={name} branding={branding}>
            <ReportNav packageContents={package_contents} packageID={packageID} />
          </Navigation>
        </div>
        <div className={`col-print-sm-12 col-sm-8 col-md-9${hideSidebar && ' col-lg-12'}`}>
          <ReportHelmet branding={branding} reportTitle={this.reportTitle} reportName={name} />          
          <div className={styles.marketDataReport}>
            <ReportBanner
              user={user}
              address={name}
              campaign={null}
              BannerComponent={NeighbourhoodReportBanner}
              reportTitle={this.reportTitle}
            />
            <div>
              <div className="col-xs-12">
                <h5><FormattedMessage {...messages.overview} /></h5>
                <p>{description}</p>
              </div>
            </div>

            <NeighbourhoodInsights 
              summary={summary}
              pricesByPropertyType={{ condo, bungalow, duplex, row }}
            />

            <div className={classNames(marketDemographicsStyles.MarketDemographics, 'col-xs-12')}>
              <div className="row" style={{ borderBottom: 'solid 3px gray' }}>
                {this.renderPeriodOfHomeConstruction()}
                {this.renderPropertyTypePriceDistribution()}
              </div>
              <div className="row" style={{ marginTop: '5px' }}>
                <div className="col-xs-12 col-sm-4">
                  {this.renderFirstLanguage()}
                  {this.renderRentersVsOwners()}
                </div>
                <div className="col-xs-12 col-sm-8" style={{ paddingTop: '5px', borderLeft: 'dotted 3px gray' }}>
                  {this.renderPopulationByAge()}
                  <div className="col-xs-6 text-left">
                    <div className="row">
                      <div className="col-xs-6">
                        <h3><FormattedMessage {...messages.populationLabel} /></h3>
                        <div>{textHelpers.numberWithCommas(this.state.report.total_population)}</div>
                      </div>
                      <div className="col-xs-6">
                        <h3><FormattedMessage {...messages.householdsLabel} /></h3>
                        <div>{textHelpers.numberWithCommas(this.state.report.total_households)}</div>
                      </div>
                    </div>
                    <div className="row">
                      {this.renderHouseholdSize()}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Footer teranet />
          </div>
        </div>
      </div>
    );
  }
}

export default MarketDataReport;
