/**
*
* ShoppingMalls
*
*/

import React from 'react';

function ShoppingMalls() {
  return (
    <svg viewBox="0 0 50.666668 38">
      <path d="m7.1026 8.681c0.2173-3.217 2.8969-5.7699 6.1684-5.7699s5.9503 2.5529 6.1675 5.7699h1.9108c-0.22-4.2707-3.754-7.6802-8.078-7.6802s-7.8588 3.4095-8.0792 7.6802h1.9108z" />
      <polygon points="48.978 36.296 20.985 36.296 22.113 19.201 47.85 19.201" />
      <polygon points="20.423 17.886 24.18 17.886 24.18 10.184 2.2008 10.184 2.2008 33.291 19.406 33.291" />
      <path d="m28.518 17.698c0.21725-3.2171 2.8968-5.7699 6.1681-5.7699 3.2712 0 5.9503 2.5529 6.1675 5.7699h1.9108c-0.22028-4.2708-3.7537-7.6802-8.0783-7.6802s-7.8586 3.4095-8.0789 7.6802h1.91z" />
    </svg>
  );
}

export default ShoppingMalls;
