/*
 * DataSubSet Messages
 *
 * This contains all the text for the DataSubSet component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  public: {
    id: 'app.components.DataSubSet.public',
    defaultMessage: 'Public',
  },
  catholic: {
    id: 'app.components.DataSubSet.catholic',
    defaultMessage: 'Catholic',
  },
  private: {
    id: 'app.components.DataSubSet.private',
    defaultMessage: 'Private',
  },
  catholicPrivate: {
    id: 'app.components.DataSubSet.catholicPrivate',
    defaultMessage: 'Catholic & Private',
  },
});
