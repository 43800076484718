/**
*
* FireStations
*
*/

import React from 'react';

function FireStations() {
  return (
    <svg version="1.1" viewBox="0 0 37.5 66.125">
      <path d="m17.806 13.064s14.293 8.4788 17.013 25.519-6.346 23.303-9.881 25.664 5.5296-9.9573 3.4447-19.915-10.788-12.934-10.788-12.934 2.2662 2.669-0.1813 6.5698-14.686 11.599-3.989 26.073-12.328-5.5433-10.878-19.504 20.335-13.611 15.259-31.473z" fill="#E36526" />
      <path d="m8.486 26.083s2.0467-0.06362 0.38302-10.691c-1.0505-6.7105 3.0126-9.9923 5.5887-13.891 0 0-0.55092 2.3469-0.78097 5.5585-0.291 4.0615 4.005 13.556-5.191 19.024z" fill="#E36526" />
    </svg>
  );
}

export default FireStations;
