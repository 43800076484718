/**
*
* DetailedInfoTable
*
*/

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { pathnamePatternMatch } from 'utils/pathnameChecker';

import messages from './messages';
import styles from './styles.css';

import textHelpers from 'utils/textHelpers';

function DetailedInfoTable(props) {
  const { info } = props;

  const allowedKeys = {
    type:                             'Type',
    county:                           'County',
    // status:                           'Status', // NOTE : no longer necessary
    address:                          'Address',
    language:                         'Language',
    affiliation:                      'Affiliation',
    dateOpened:                       'Date Opened',
    gradeLevel:                       'Grade Level',
    orientation:                      'Orientation',
    facilityID:                       'Facility ID',
    schoolCode:                       'School Code',
    schoolType:                       'School Type',
    contact:                          'Contact',
    contactName:                      'Contact Name',
    ownership:                        'Ownership',
    faxNumber:                        'Fax Number',
    phoneNumber:                      'Phone Number',
    emailAddress:                     'Email Address',
    // ratingTrend:                      'Rating Trend',
    schoolBoard:                      'School Board',
    schoolNumber:                     'School Number',
    gradesOffered:                    'Grades Offered',
    ncesSchoolID:                     'NCES School ID',
    schoolDistrict:                   'School District',
    schoolAuthority:                  'School Authority',
    // mostRecentRank:                   'Most Recent Rank',
    additionalDetails:                'Additional Details',
    greatSchoolsRating:               'GreatSchools Rating',
    // mostRecentRating:                 'Most Recent Rating',
    schoolBoardNumber:                'School Board Number',
    schoolDistrictNumber:             'School District Number',
    schoolAuthorityNumber:            'School Authority Number',
    emergencyServicesProvided:        'Emergency Services Provided',
    greatSchoolsSchoolDetailsWebpage: 'GreatSchools School Details Webpage',
    districtDescription:              'District Description',
    // fiveYearsRank:                    'Rank in the Most Recent Five Years',
    // fraserInstituteWebpage:           'Fraser Institute School Details Webpage',
    // fiveYearsAverageRating:           'Rating Average in the Most Recent Five Years',
  };

  let infoTable = [];
  let infoContent = [];

  Object.keys(allowedKeys).forEach((key) => {
    const allowedValue = allowedKeys[key]; // TODO : reconsider this logic
    const infoValue = info[key] || info[allowedValue];

    if ((!Array.isArray(infoValue) && infoValue) || (Array.isArray(infoValue) && infoValue.length > 0)) {
      // TODO : set infoValueIsURL dynamically
      const infoValueIsURL = pathnamePatternMatch('externalLink', infoValue);
      let infoValueContent = infoValue;

      if (infoValueIsURL) {
        const infoDisplayValue = textHelpers.shortenUrl(infoValue);
        // infoValueContent = <a href={infoValue} target="_blank">{infoValue}</a>;
        infoValueContent = <a href={infoValue} target="_blank">{infoDisplayValue}</a>;
      }

      if (Array.isArray(infoValue)) {
        if (key === 'gradesOffered') {
          infoValueContent = textHelpers.gradesToString(infoValue);
        } else {
          infoValueContent = textHelpers.toSentence(infoValue);
        }
      }

      infoTable.push(
        <tr key={key} className={styles.tableRow}>
          <td className={styles.tableKey}>
            <FormattedMessage {...messages[key]} />
          </td>
          <td className={styles.tableValue}>{infoValueContent}</td>
        </tr>
      );

      infoContent.push(
        <div key={key}>
          <div className={styles.contentKey}>
            <FormattedMessage {...messages[key]} />:
          </div>
          <span>{infoValueContent}</span>
        </div>
      );
    }
  });

  return (
    <div className={styles.detailedInfoTable}>
      <table className="d-none d-sm-block">
        <tbody>
          {infoTable}
        </tbody>
      </table>
      <div className="d-block d-sm-none">
        {infoContent}
      </div>
    </div>
  );
}



export default DetailedInfoTable;
