import React from 'react';
import classNames from 'classnames';

import textHelpers from 'utils/textHelpers';

const styles = require('./styles.css');
const mobStyles = require('../mobileStylesWNav.css');

import schoolsImg from 'assets/reports/school/schools.png';

export default function SchoolReportBanner(props) {
  const address = textHelpers.truncateWords(
    props.address.split(',').slice(0, 1).join(','), 25
  );

  const city = textHelpers.truncateWords(
    props.address.split(',').slice(1, 2).join(','), 25
  );

  const state = textHelpers.truncateWords(
    props.address.split(',').slice(2, 3).join(','), 3
  );

  const cityState = `${city}, ${state}`;

  return (
    <div className='row'>
      <div className={classNames('d-xs-block d-none d-print-none', mobStyles.mobileAgentBranding)}>
        <div className="col-xs-12 col-sm-4">
          {props.agentSlug}
        </div>
      </div>

      <div className={classNames('col-xs-12 col-sm-8', styles.headerContent)}>
        <img src={schoolsImg} className={styles.schoolsPNG} alt="Schools" style={{ width: '100px', position: 'absolute', top: '5px' }} />
        <div className={classNames(styles.headerContentTitle, styles.headerContentWithPin)}>
          <h2>{props.reportTitle}</h2>
          <h3 className={styles.city}>{address}</h3>
          {cityState}
        </div>
      </div>

      <div className={classNames('d-xs-none', styles.printableAgentBranding)}>
        <div className="col-xs-12 col-sm-4">
          {props.agentSlug}
        </div>
      </div>

    </div>
  )
}