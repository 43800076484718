import shareToFacebook from './Facebook';
import shareToTwitter from './Twitter';
import shareToPinterest from './Pinterest';

const shareFunctionMap = {
  facebook:  shareToFacebook,
  twitter:   shareToTwitter,
  pinterest: shareToPinterest
}

export default function(product) {
  const shareModal = $("#share-modal");

  if (shareModal.find("h5.modal-title").text().trim() === "") {
    shareModal.find("h5.modal-title").text(`Share your ${ product.descriptive_noun }`);
  }

  shareModal.find("p.instructions").text(`Select a social network to share your ${product.humanized_name}.`)

  Object.keys(shareFunctionMap).forEach(key => {
    shareModal.find(`a#share-${key}`).on('click', () => {
      shareFunctionMap[key](product);
    });
  });


  shareModal.modal('show');
}