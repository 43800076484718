import React from 'react';
import NavLink from '../NavLink';

// import AddressSearchWrapper from 'components/_shared/_addresses/AddressSearchWrapper';
import ContactUs from '../items/ContactUs';
// import { displayReportLoader, hideLoader, displayFlashMessage } from 'containers/App/actions';
// import { createReport } from 'containers/HomePage/index';
// import { packageError } from 'containers/PackagePage/actions';
// import { createStructuredSelector } from 'reselect';
// import { selectCurrentUser } from 'containers/App/selectors';
// import { selectAddressSearchEditMode } from 'containers/PackagePage/selectors';

function AuthenticatedNav(props) {
  function generatePackage(address) {
    const { currentUser } = props;

    // dispatch(displayReportLoader());

    // createReport(address, currentUser).then(report => {
      // dispatch(push(`/package/${report.id}/highlights`));
      // dispatch(hideLoader());
    // }).catch(() => {
      // dispatch(displayFlashMessage('error', 'errorFlashMessage', true));
      // dispatch(packageError(response.err ? response.err.response || 'Undefined Error' : 'Unknown error'));
      // dispatch(hideLoader());
    // });
  }

  return (
    <ul className="nav nav-pills nav-stacked">
      {props.hideSearch ? null : <li className="d-xs-none">
        <AddressSearchWrapper searchAction={generatePackage} />
      </li>}

      {props.isInSearchEditMode && <hr />}
      {!props.isInSearchEditMode && React.Children.toArray(props.children)}

      <li><NavLink to='/account'>My Account</NavLink></li>
      <li><ContactUs /></li>
    </ul>
  )
}

// const mapStateToProps = createStructuredSelector({
//   currentUser:        selectCurrentUser(),
//   isInSearchEditMode: selectAddressSearchEditMode()
// });

export default AuthenticatedNav;