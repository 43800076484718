/**
*
* Gyms
*
*/

import React from 'react';

function Gyms() {
  return (
    <svg version="1.1" viewBox="0 0 72 39">
      <path d="m9.5179 5.8863v8.0708h-3.9723c-2.6813 0-4.855 2.1735-4.855 4.855s2.1737 4.855 4.855 4.855h3.9723v8.0708h5.8009v-25.852h-5.8011z" fill="#ECB01F" />
      <path d="m66.454 13.957h-3.0895v-8.0707h-5.8008v25.852h5.8008v-8.0708h3.0895c2.6814 0 4.8551-2.1735 4.8551-4.855s-2.173-4.855-4.855-4.855z" fill="#ECB01F" />
      <rect y="13.911" x="28.842" height="9.6775" width="15.585" fill="#ECB01F" />
      <rect y="1.5937" x="17.153" height="34.563" width="8.9234" fill="#ECB01F" />
      <rect y="1.5937" x="46.814" height="34.563" width="8.9234" fill="#ECB01F" />
    </svg>
  );
}

export default Gyms;
