/**
*
* PedestrianIcon
*
*/

import React from 'react';

function PedestrianIcon() {
  return (
    <svg enableBackground="new 0 0 35.25 58.25" viewBox="0 0 35.25 58.25" y="0px" x="0px">
      <g>
        <circle cy="5.6667" cx="20.333" r="4.4572" />
        <path d="m33.125 55.167c-1.8059-0.31604-2.5231-0.65192-2.5231-0.65192l-8.7267-25.336 0.18762-7.6946 3.687 3.422 5.8215 4.2724s0.027832 0.008972 0.073975 0.021301c0.26916 0.1676 0.58423 0.2688 0.9248 0.2688 0.97082 0 1.7578-0.78699 1.7578-1.7578 0-0.53485-0.2439-1.008-0.62085-1.3304-0.13171-0.12451-0.28369-0.25311-0.47803-0.39191-1.314-0.938-8.23-6.905-8.23-6.905l-2.7881-4.8692c-0.40344-2.004-2.1724-3.5136-4.2952-3.5136-1.283 0-2.4261 0.56091-3.2277 1.4395-0.93091 0.73511-1.4542 1.4624-1.5016 1.4225l-3.6987 5.4823s-1.126 1.5952-1.689 3.9411-2.0645 7.413-2.0645 7.413 0.006836 0.006958 0.013428 0.013733c-0.016602 0.097412-0.030029 0.19611-0.030029 0.29828 0 0.97082 0.78699 1.7578 1.7578 1.7578 0.85864 0 1.5709-0.61633 1.7249-1.4302 0.032471-0.099915 0.064697-0.21826 0.099731-0.35809 0.28149-1.126 1.8767-6.85 1.8767-6.85l0.65686-2.4398 1.783-2.2521v10.322c-0.75073 1.5952-4.5042 11.917-4.5042 11.917s-6.9438 9.3836-7.5068 10.228c-0.56311 0.84448-0.93848 1.5952 0.28149 2.4397 1.2197 0.84454 6.9438 3.1904 6.9438 3.1904s0.84448-1.5952-0.18774-2.2521-1.689-1.3137-1.689-1.3137l5.7239-8.3514s1.6891-2.3459 2.4397-4.8795c0.75073-2.5336 2.6274-7.0377 2.6274-7.0377l7.6665 22.55c0.099854 0.5191 0.52734 1.1791 2.0077 1.2393 0.039917 0.0047 0.083618 0.01709 0.12122 0.01709 0.7915 0 7.0951-0.066101 7.0951-0.066101s0.156-1.682-1.511-1.973z" />
      </g>
    </svg>
  );
}

export default PedestrianIcon;
