/**
*
* HandballCourt
*
*/

import React from 'react';

function HandballCourt() {
  return (
    <svg version="1.1" y="0px" x="0px" viewBox="0 0 51 61">
      <circle cy="6.8713" cx="33.318" r="4.7294" fill="#01818E" />
      <polyline strokeLinejoin="round" strokeLinecap="round" stroke="#01818E" points="34.833 15.833 45.583 15.083 42.667 5.6667" strokeMiterlimit="10" strokeWidth="4.4479" fill="none" />
      <polyline strokeLinejoin="round" strokeLinecap="round" stroke="#01818E" points="29.065 14.893 16.947 16.646 7.7511 12.152" strokeMiterlimit="10" strokeWidth="4.4479" fill="none" />
      <circle strokeWidth="1.8636" stroke="#01818E" cy="11.049" cx="7.9028" strokeMiterlimit="10" r="3.7273" fill="#fff" />
      <path stroke="#01818E" strokeWidth="1.112" strokeMiterlimit="10" d="m32.536 33.171c-0.63 2.1192-2.8587 3.3265-4.9779 2.6965l-2.4515-0.72879c-2.1192-0.63-3.3265-2.8587-2.6965-4.9779l4.1615-13.999c0.63-2.1192 1.5119-3.5164 4.9779-2.6965l2.7215 0.61755c3.52 0.51375 3.8125 1.75 2.4265 5.0891l-4.162 13.998z" fill="#01818E" />
      <polyline strokeLinejoin="round" strokeLinecap="round" stroke="#01818E" points="25.994 32.469 38.087 39.002 30.998 54.848" strokeMiterlimit="10" strokeWidth="7" fill="none" />
      <polyline strokeLinejoin="round" strokeLinecap="round" stroke="#01818E" points="24.81 32.778 19.394 45.611 9.5833 43.083" strokeMiterlimit="10" strokeWidth="7" fill="none" />
    </svg>
  );
}

export default HandballCourt;
