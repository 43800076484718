/**
*
* Schools
*
*/

import React from 'react';

function Schools(props) {
  const { x, y, height, width } = props;

  return (
    <svg x={x} y={y} height={height} width={width} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 216 216">
      <circle className="cls-1" fill="#2bace2" cx="108.02298" cy="109.2404" r="99.55434" />
      <rect className="cls-2" fill="#fff" x="47.85751" y="132.69251" width="120.63705" height="3.67324" />
      <rect className="cls-2" fill="#fff" x="47.85751" y="139.42649" width="120.63705" height="3.67324" />
      <rect className="cls-2" fill="#fff" x="47.55141" y="97.49058" width="33.0592" height="31.22258" />
      <rect className="cls-1" fill="#2bace2" x="53.06127" y="103.30655" width="3.97935" height="7.65259" />
      <rect className="cls-1" fill="#2bace2" x="62.24438" y="103.30655" width="3.97935" height="7.65259" />
      <rect className="cls-1" fill="#2bace2" x="71.27445" y="103.30655" width="3.97935" height="7.65259" />
      <rect className="cls-1" fill="#2bace2" x="53.06127" y="116.31596" width="3.97935" height="7.65259" />
      <rect className="cls-1" fill="#2bace2" x="62.24438" y="116.31596" width="3.97935" height="7.65259" />
      <rect className="cls-1" fill="#2bace2" x="71.27445" y="116.31596" width="3.97935" height="7.65259" />
      <rect className="cls-2" fill="#fff" x="135.09708" y="97.49058" width="33.0592" height="31.22258" />
      <rect className="cls-1" fill="#2bace2" x="140.60694" y="103.30655" width="3.97935" height="7.65259" />
      <rect className="cls-1" fill="#2bace2" x="149.79006" y="103.30655" width="3.97935" height="7.65259" />
      <rect className="cls-1" fill="#2bace2" x="158.82012" y="103.30655" width="3.97935" height="7.65259" />
      <rect className="cls-1" fill="#2bace2" x="140.60694" y="116.31596" width="3.97935" height="7.65259" />
      <rect className="cls-1" fill="#2bace2" x="149.79006" y="116.31596" width="3.97935" height="7.65259" />
      <rect className="cls-1" fill="#2bace2" x="158.82012" y="116.31596" width="3.97935" height="7.65259" />
      <rect className="cls-2" fill="#fff" x="83.05944" y="81.87929" width="50.20101" height="47.13998" />
      <polygon className="cls-2" fill="#fff" points="135.097 80.043 108.466 67.186 80.305 80.043 135.097 80.043" />
      <rect className="cls-2" fill="#fff" x="107.62426" y="43.76937" width="1.22441" height="24.4883" />
      <path className="cls-2" fill="#fff" d="M96.68106,53.25859c0.45916,0.61221,2.44883,1.37747,5.20376.3061a9.52159,9.52159,0,0,1,5.66292-.15305V45.606a12.9472,12.9472,0,0,0-6.12207,1.37747,4.792,4.792,0,0,1-4.59156-.61221Z" />
      <circle className="cls-2" fill="#fff" cx="108.23647" cy="43.46327" r="1.23936" />
      <rect className="cls-1" fill="#2bace2" x="90.40593" y="87.69526" width="3.8263" height="13.92772" />
      <rect className="cls-1" fill="#2bace2" x="101.11956" y="87.69526" width="3.8263" height="13.92772" />
      <rect className="cls-1" fill="#2bace2" x="112.06277" y="87.69526" width="3.8263" height="13.92772" />
      <rect className="cls-1" fill="#2bace2" x="122.7764" y="87.69526" width="3.8263" height="13.92772" />
      <rect className="cls-1" fill="#2bace2" x="122.7764" y="107.89811" width="3.8263" height="13.92772" />
      <rect className="cls-1" fill="#2bace2" x="90.17635" y="107.89811" width="3.8263" height="13.92772" />
      <rect className="cls-1" fill="#2bace2" x="101.11956" y="108.05116" width="14.53993" height="17.90707" />
    </svg>
  );
}



export default Schools;
