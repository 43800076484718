/* eslint no-multi-spaces: "off" */
/* eslint react/jsx-equals-spacing: "off" */

/**
*
* TransitReport
*
*/

import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import messages from './messages';
import styles from './styles.css';

import DetailedPlaceBlock from '../DetailedPlaceBlock';
import SVGIcon from '../../../SVGIcon';

function TransitReport(props) {
  // TODO : consider dynamic descriptions for this section
  const { transit, locale, score } = props; // paragraph,

  // NOTE : comes filtered from DataSetReport component
  const filteredTransit = Object.keys(transit).map((key) => transit[key]);
  let transitContent = [];

  // TODO : check "hideWalkingPerson" issue here
  // NOTE : if distance === 0, walkingMan will NOT show up
  transitContent = filteredTransit.map((item) => {
    let itemName = '';

    // TODO : properly internationalize
    switch (item.place_category_key) {
      case 'street-level-transit':
        itemName = (
          <FormattedMessage
            {...messages.nearestPlace}
            values={{
              place: 'street-level transit stop',
              name: item.name,
            }}
          />
        );
        break;
      case 'rail-transit':
        itemName = (
          <FormattedMessage
            {...messages.nearestPlace}
            values={{
              place: 'rail transit stop',
              name: item.name,
            }}
          />
        );
        break;
      default:
        itemName = item.name;
    }

    return (
      <DetailedPlaceBlock
        icon       = {item.place_category_key}
        name       = {itemName}
        distance   = {item.distance}
        url        = {item.url}
        locale     = {locale}
        key        = {item.name}
      />
    );
  });

  let scoreDisplay = null;
  if (score) {
    scoreDisplay = (
      <div>
        <div className={classNames(styles.iconXS, 'd-xs-block d-none')}>
          <SVGIcon iconType="circle" />
          <div className={styles.score}>
            <span>{score}</span>
          </div>
        </div>
        <div className={classNames(styles.icon, 'd-xs-none')}>
          <SVGIcon iconType="circle" />
          <div className={styles.score}>
            <span>{score}</span>
          </div>
        </div>
      </div>
    );
  }

  const paragraph = locale === 'en-CA' ? (
    <FormattedMessage {...messages.descriptionCA} />
  ) : (
    <FormattedMessage {...messages.description} />
  );

  return (
    <div className={classNames('row', styles.transitReport)}>
    <div className="offset-1 col-10">
        {scoreDisplay}
        <div className={styles.titleParagraph}>
          <h2>
            <FormattedMessage {...messages.header} />
          </h2>
          <p>{paragraph}</p>
        </div>
        {transitContent}
      </div>
    </div>
  );
}



export default TransitReport;
