/*
 * HarveyKallesLayout Messages
 *
 * This contains all the text for the HarveyKallesLayout component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.HarveyKallesLayout.header',
    defaultMessage: 'This is the HarveyKallesLayout component !',
  },
});
