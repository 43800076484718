/**
*
* YoungerAdults
*
*/

import React from 'react';

function YoungerAdults() {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 216 216">
      <path className="cls-2" strokeMiterlimit="10" fill="none" strokeWidth="1.64508px" stroke="#00AEEF" d="M82.329,101.80125h19.19254" />
      <path className="cls-3" fill="#FFF" d="M88.26957,103.03505c-0.01219.46738,0.09021,5.035,0.08427,5.51628-0.1469,11.89339.1468,12.92118,3.08347,13.068s3.67076-3.08347,3.524-5.286c-0.11018-1.65186-.16282-12.83563-0.11141-17.20536" />
      <path className="cls-3" fill="#FFF" d="M119.13037,84.09527a7.99734,7.99734,0,0,0-2.76321-4.68013,1.73321,1.73321,0,0,1-.19338-2.315,1.77494,1.77494,0,0,1-.22866-0.46361l-12.17389.119A10.8241,10.8241,0,0,0,94.52076,79.839c-3.804,3.94492-5.96211,13.41582-6.25119,24.4984l6.54725-1.67954a25.369,25.369,0,0,1,.29136-3.37712c0.58726-3.08349,2.49608-11.80647,2.49608-11.80647V155.518h0.10464a5.13481,5.13481,0,0,0,10.1468,0h0.0268v-0.26607a2.73278,2.73278,0,0,0,0-1.09221v-42.605h2.05556v42.96983c-0.00666.10752-.0321,0.20966-0.0321,0.319s0.02544,0.2114.0321,0.31892v0.3464h0.03513a5.12786,5.12786,0,0,0,10.176,0h0.06724V85.63974A1.91447,1.91447,0,0,1,119.13037,84.09527Z" />
      <path className="cls-3" fill="#FFF" d="M123.14016,79.91517a10.82418,10.82418,0,0,0-9.25047-3.08349l-8.76542-.08564a1.84627,1.84627,0,0,1-.31559.471,38.65763,38.65763,0,0,1-7.36406,6.06749V155.585h0.06709a5.12794,5.12794,0,0,0,10.17618,0h0.035v-0.3464c0.00666-.10752.0321-0.20966,0.0321-0.31892s-0.02544-.21148-0.0321-0.319V111.63086h2.05571v42.605a2.73278,2.73278,0,0,0,0,1.09221v0.26607h0.02665a5.13472,5.13472,0,0,0,10.14665,0h0.10479V87.55044s0.635,7.58414,1.099,11.0642a17.13236,17.13236,0,0,0,1.7517,5.78823l6.37248-2.27067C128.99095,91.04961,126.94417,83.86008,123.14016,79.91517Z" />
      <circle className="cls-3" fill="#FFF" cx="109.20394" cy="64.56855" r="9.10358" />
      <path className="cls-4" fill="#00AEEF" strokeMiterlimit="10" stroke="#00AEEF" strokeWidth="0.97722px" d="M110.58484,60.9143a3.72166,3.72166,0,0,1-2.53173.04051,18.24777,18.24777,0,0,0-4.35458-.60762,14.3262,14.3262,0,0,0-3.24062.48609l0.14178,1.114c0.46584-.06076.50635,0.54685,0.54685,0.74939s0.14178,0.74939.22279,1.114a3.00355,3.00355,0,0,0,1.25574,2.04564,5.1361,5.1361,0,0,0,2.633.28355c2.32919-.54685,2.59249-1.8431,3.22036-3.20011s1.21523-.56711,1.37726-0.28355a8.986,8.986,0,0,0,1.21523,2.39,4.5816,4.5816,0,0,0,4.86092.89117c0.93168-.44558,1.33675-2.795,1.41777-3.36214a0.479,0.479,0,0,1,.50635-0.50635l0.06076-1.15447A16.358,16.358,0,0,0,110.58484,60.9143Zm-2.81528,2.22792a3.06641,3.06641,0,0,1-1.519,2.1064c-0.2633.20254-2.20767,0.95193-3.36214,0.20254s-1.0532-2.23805-1.03295-2.98744a1.36817,1.36817,0,0,1,1.114-1.40764,5.76349,5.76349,0,0,1,2.06589-.14178,7.17016,7.17016,0,0,1,1.519.243C107.54677,61.40039,108.05311,61.7042,107.76956,63.14222Zm7.88887,2.29881c-1.15447.74939-3.09884,0-3.36214-.20254a3.06641,3.06641,0,0,1-1.519-2.1064c-0.28355-1.438.22279-1.74183,1.21523-1.98488a7.17016,7.17016,0,0,1,1.519-.243,5.76349,5.76349,0,0,1,2.06589.14178,1.36817,1.36817,0,0,1,1.114,1.40764C116.71163,63.203,116.8129,64.69164,115.65843,65.441Z" />
      <path className="cls-3" fill="#FFF" d="M123.10971,103.57427c0.642,1.2078,4.95781,3.77214,5.40379,3.95314,2.39079-.38276,6.21.01343,7.41741-2.66758s1.41773-.49468-1.3709-0.91393c-1.63713-.24613-1.614-2.03457-5.47861-3.71774" />
      <polygon className="cls-5" stroke="#00AEEF" fill="#FFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.3709px" points="110.035 106.635 133.568 99.473 149.939 103.156 124.974 111.956 110.035 106.635" />
      <polygon className="cls-5" stroke="#00AEEF" fill="#FFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.3709px" points="131.514 92.75 123.691 110.63 146.545 102.284 153.448 85.134 131.514 92.75" />
      <polygon className="cls-6" fill="#00AEEF" points="123.691 110.63 124.974 111.956 129.686 111.029 147.964 103.413 147.964 100.976 123.691 110.63" />
      <ellipse className="cls-7" strokeMiterlimit="10" stroke="#00AEEF" fill="#FFF" strokeWidth="1.32808px" cx="137.3038" cy="106.52493" rx="3.97057" ry="2.66529" transform="translate(-33.48554 74.41737) rotate(-27.15846)" />
    </svg>
  );
}

export default YoungerAdults;
