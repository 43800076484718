/* eslint no-multi-spaces: "off" */
/* eslint key-spacing: ["error", {
  "align": {
    "beforeColon": true,
    "afterColon": true,
    "on": "colon"
  }
}] */

/**
*
* PNGIcon
*
*/

import React from 'react';

function PNGIcon(props) {
  const iconKeyComponentPairs = {
    // Icons for quiz
    esso                  : <img src="/assets/logos/esso.png" alt="Esso" />,
    'goodlife-fitness'    : <img src="/assets/logos/goodlife-fitness.png" alt="GoodLife" />,
    'bally-total-fitness' : <img src="/assets/logos/bally-total-fitness.png" alt="Bally" />,
    loblaws               : <img src="/assets/logos/loblaws.png" alt="Loblaws" />,
    'shoppers-drug-mart'  : <img src="/assets/logos/shoppers-drug-mart.png" alt="Shoppers" />,
    'sketchley-cleaners'  : <img src="/assets/logos/sketchley-cleaners.png" alt="Sketchley" />,
    starbucks             : <img src="/assets/logos/starbucks.png" alt="Starbucks" />,
  };

  const componentToRender = iconKeyComponentPairs[props.logoType] || <img src="/assets/logos/starbucks.png" alt="Starbucks" />;

  return (
    <div>
      {componentToRender}
    </div>
  );
}



export default PNGIcon;
