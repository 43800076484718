import React from 'react';
import classNames from 'classnames';

import HarveyKallesLayout from './HarveyKallesLayout';
import CustomBanner from '../CustomBanner';

import Regex from 'utils/regexCollection';

const styles = require('../styles.css');

export default class ReportBanner extends React.Component {
  renderAgentSlug = () => {
    const { user, showSlug } = this.props;

    if (!showSlug) return null;
    
    const websiteDisplay = user.website ? Regex.url.exec(user.website) : null;
    const phoneDisplay   = Regex.phoneDisplay.exec(user.office_phone_number);
    const nameDisplay = user.first_name === undefined ? '' : `${user.first_name} ${user.last_name}`;
    return (
      <div className={classNames('row', styles.agentBranding)}>
        <div className={classNames('col-xs-9', styles.agentInfo)}>
          <p className={styles.agentBrandingP}>{nameDisplay}</p>
          {phoneDisplay && phoneDisplay.length > 2
            ? <p className={styles.agentBrandingP}>{phoneDisplay[1]}.{phoneDisplay[2]}.{phoneDisplay[3]}</p>
            : user.office_phone_number
          }
          {websiteDisplay && websiteDisplay.length > 4
            ? <a className={styles.agentBrandingA} href={user.website} target="_blank">
                {websiteDisplay[3]}.{websiteDisplay[4]}
              </a>
            : null
          }
        </div>

        <div className={classNames('col-xs-3', styles.agentPic)}>
          {user.profile_picture && user.profile_picture.medium
            ? <img className={styles.agentBrandingImg} src={user.profile_picture.medium} alt="Profile" />
            : null
          }
        </div>        
      </div>
    );
  }

  render() {
    const { user, address, campaign, BannerComponent } = this.props;

    // I hate myself.
    // TODO: actually upload these images to the user's accounts in the backend.
    if (user.email === 'eric@homesbyeric.ca') {
      user.use_custom_masthead = true;
      user.custom_header_image = '/assets/branding/customExceptions/erichomesbyericca.jpg';
    } else if (user.email === 'results@lilithomes.com') {
      user.use_custom_masthead = true;
      user.custom_header_image = '/assets/branding/customExceptions/resultslilithomescom.png';
    } else if (user.email === 'fatima@athomeintoronto.com' || user.email === 'dave+fatima@hoodq.com' || user.email === 'aldo@hoodq.com' || user.email === 'best@test.com') {
      user.use_custom_masthead = true;
      user.custom_header_image = '/assets/branding/customExceptions/fatimaathomeintorontocom.png';
    } else if (user.email === 'selene@therichardsgroup.ca') {
      user.use_custom_masthead = true;
      user.custom_header_image = '/assets/branding/customExceptions/selenetherichardsgroupca.png';
    } else if (user.email === 'csconza@kw.com') {
      user.use_custom_masthead = true;
      user.custom_header_image = '/assets/branding/customExceptions/csconzakwcom.jpg';
    } else if (user.email === 'zan@zanmolko.com') {
      user.use_custom_masthead = true;
      user.custom_header_image = '/assets/branding/customExceptions/zanzanmolkocom.png';
    } else if (user.email === 'dave+schoolq@hoodq.com') {
      user.use_custom_masthead = true;
      user.custom_header_image = '/assets/branding/customExceptions/daveschoolqhoodqcom.png';
    }

    let banner = null;

    if (campaign && campaign.campaign_id === 'HarveyKallesCampaign') {
      banner = (
        <HarveyKallesLayout
          user={user}
          userEmail={user.email}
          address={address}
          customStyle={{}}
          customHeaderImage={'/assets/branding/harveyKallesBanner.png'}
        />
      );
    } else if (user.use_custom_masthead) {
      banner = <CustomBanner user={user} address={address} />
    } else {
      banner = (
        <BannerComponent
          reportTitle={this.props.reportTitle}
          agentSlug={this.renderAgentSlug()}
          address={address}
        />
      )
    }

    return (
      <div className={classNames('row', styles.header)}>
        {banner}
      </div>
    )
  }
}