/**
*
* ScoresExplained
*
*/

import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import messages from './messages';
import styles from './styles.css';

import SVGIcon from '../../../SVGIcon';

function ScoresExplained() {
  return (
    <div className={classNames('row', styles.scoresExplained)}>
      <div className="col-xs-offset-1 col-xs-10">
        <div className={classNames(styles.iconXS, 'd-xs-block d-none')}>
          <SVGIcon iconType="circle" />
          <span>?</span>
        </div>
        <div className={classNames(styles.icon, 'd-xs-none')}>
          <SVGIcon iconType="circle" />
          <span>?</span>
        </div>
        <h2><FormattedMessage {...messages.header} /></h2>
        <p><FormattedMessage {...messages.schoolRatings} /></p>
        <p><FormattedMessage {...messages.parkRatings} /></p>
        <p><FormattedMessage {...messages.transitScore} /></p>
        <p><FormattedMessage {...messages.safetyScore} /></p>
        <p><FormattedMessage {...messages.convenienceScore} /></p>
      </div>
    </div>
  );
}

export default ScoresExplained;
