/*
 * AltSchools Messages
 *
 * This contains all the text for the AltSchools component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.AltSchools.header',
    defaultMessage: 'Schools',
  },
  url: {
    id: 'app.components.AltSchools.url',
    defaultMessage: 'Go to the school\'s website',
  },
  urlGreatSchools: {
    id: 'app.components.AltSchools.urlGreatSchools',
    defaultMessage: 'Go to the school\'s GreatSchools website',
  },
  otherLocalSchools: {
    id: 'app.components.AltSchools.otherLocalSchools',
    defaultMessage: 'Other Local Schools',
  },
  publicSchoolsHeader: {
    id: 'app.components.AltSchools.publicSchoolsHeader',
    defaultMessage: 'Public Schools',
  },
  publicSchoolsParagraph: {
    id: 'app.components.AltSchools.publicSchoolsParagraph',
    defaultMessage: 'Your neighbourhood is part of a community of Public Schools offering Elementary, Middle, and High School programming.',
  },
  catholicSchoolsHeader: {
    id: 'app.components.AltSchools.catholicSchoolsHeader',
    defaultMessage: 'Catholic Schools',
  },
  catholicSchoolsParagraph: {
    id: 'app.components.AltSchools.catholicSchoolsParagraph',
    defaultMessage: 'Your neighbourhood is part of a community of Catholic Schools offering Elementary, Middle, and High School programming.',
  },
  privateSchoolsHeader: {
    id: 'app.components.AltSchools.privateSchoolsHeader',
    defaultMessage: 'Private Schools',
  },
  privateSchoolsParagraph: {
    id: 'app.components.AltSchools.privateSchoolsParagraph',
    defaultMessage: 'The private schools nearest to you are:',
  },
  alternativeSchoolsHeader: {
    id: 'app.components.AltSchools.alternativeSchoolsHeader',
    defaultMessage: 'Alternative & Special Schools',
  },
  alternativeSchoolsParagraph: {
    id: 'app.components.AltSchools.alternativeSchoolsParagraph',
    defaultMessage: 'Nearby alternative and special education schools include:',
  },
});
