function waitForPinUtils() {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (window.PinUtils && window.PinUtils.pinOne) {
        resolve();
      }
    }, 1000)
  })
}

function importPinitJS() {
  if (window.PinUtils && window.PinUtils.pinOne) {
    return Promise.resolve();
  } else {
    return new Promise((resolve, reject) => {


      const script = document.createElement('script');
      script.src = "//assets.pinterest.com/js/pinit.js";

      script.onload = function() {
        waitForPinUtils().then(() => resolve());
      }

      document.body.appendChild(script);
    });
  }
}

export default function(product) {
  importPinitJS().then(_ => { 
    PinUtils.pinOne({
      url:         product.share_url, // TODO: use address report URL!
      media:       product.share_url,
      description: ""                // TODO: add a description!
    });
  });
}