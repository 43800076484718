/**
*
* HoodQlogo
*
*/

import React from 'react';

function HoodQlogo(props) {
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 172.75 156.3125"
      enableBackground="new 0 0 172.75 156.3125"
      className={props.className}
    >
      <g>
        <g>
          <path
            fill="#C1D42F"
            d="M110.33667,98.778145l-18.601959-24.18206c0.060715-0.786926,0.09108-1.564133,0.09108-2.32312V45.122936
            c0-16.954041-13.792999-30.747036-30.74704-30.747036c-14.090973,0-25.969185,9.541126-29.588055,22.494068h19.370213V26.089867
            c0-0.364315,0.295097-0.659412,0.659412-0.659412h18.583744c0.364319,0,0.659416,0.295097,0.659416,0.659412v65.486969
            c0,0.364319-0.295097,0.659416-0.659416,0.659416H51.520321c-0.364315,0-0.659412-0.295097-0.659412-0.659416v-36.76067H30.332932
            v17.456799c0,16.954041,13.792995,30.747032,30.745819,30.747032c6.431389,0,12.540962-1.947876,17.750679-5.646896
            l4.989914,3.791313c0.295097,0.224663,0.655769,0.3461,1.027367,0.3461h24.144424c0.647263,0,1.237457-0.367958,1.522835-0.94722
            C110.799355,99.982819,110.731346,99.290619,110.33667,98.778145z"
          />
          <path
            fill="#6ACFF6"
            d="M31.490696,36.869968H19.902571V0.659412C19.902571,0.295096,19.607475,0,19.243158,0H0.659412
            C0.295096,0,0,0.295096,0,0.659412v88.961052c0,0.364319,0.295096,0.659416,0.659412,0.659416h18.583746
            c0.364317,0,0.659412-0.295097,0.659412-0.659416V54.816166h10.430361v-9.69323
            C30.332932,42.26078,30.75569,39.500786,31.490696,36.869968z"
          />
          <path
            fill="#50B848"
            d="M50.860909,91.576836c0,0.364319,0.295097,0.659416,0.659412,0.659416h18.583744
            c0.364319,0,0.659416-0.295097,0.659416-0.659416V26.089867c0-0.364315-0.295097-0.659412-0.659416-0.659412H51.520321
            c-0.364315,0-0.659412,0.295097-0.659412,0.659412v10.780102H31.490696c-0.735006,2.630817-1.157764,5.390812-1.157764,8.252968
            v9.69323h20.527977V91.576836z"
          />
        </g>
      </g>
      <g>
        <g>
          <polygon
            fill="#8A8C8C"
            points="23.410675,133.3405 5.071569,133.3405 5.071569,118.177269 0,118.177269 0,154.445343
            5.071569,154.445343 5.071569,137.848053 23.410675,137.848053 23.410675,154.445343 28.533155,154.445343 28.533155,118.177269
            23.410675,118.177269"
          />
          <path
            fill="#8A8C8C"
            d="M57.910618,133.084229c-1.178371-1.126892-2.570107-2.006088-4.175205-2.638168
            c-1.605103-0.631516-3.329185-0.947845-5.173389-0.947845c-1.844208,0-3.569435,0.31633-5.173965,0.947845
            c-1.605671,0.63208-2.988827,1.511276-4.149464,2.638168c-1.161209,1.126892-2.074734,2.467712-2.74057,4.021332
            c-0.665836,1.554199-0.998753,3.270264-0.998753,5.148224c0,1.878525,0.332916,3.60318,0.998753,5.173386
            c0.665836,1.57135,1.579361,2.928772,2.74057,4.072815c1.160637,1.144623,2.543793,2.03183,4.149464,2.663345
            c1.60453,0.631516,3.329758,0.947845,5.173965,0.947845c1.844204,0,3.568287-0.31633,5.173389-0.947845
            c1.605099-0.631516,2.996834-1.518723,4.175205-2.663345c1.178371-1.144043,2.100475-2.501465,2.766312-4.072815
            c0.665836-1.570206,0.998753-3.294861,0.998753-5.173386c0-1.87796-0.332916-3.594025-0.998753-5.148224
            C60.011093,135.551941,59.088989,134.211121,57.910618,133.084229z M56.116177,145.598984
            c-0.360374,1.055954-0.882061,1.992355-1.567348,2.809784c-0.685284,0.816849-1.533596,1.463806-2.543793,1.940872
            c-1.010765,0.477081-2.1674,0.715042-3.468754,0.715042c-1.302502,0-2.459133-0.237961-3.468758-0.715042
            c-1.011341-0.477066-1.859081-1.124023-2.543793-1.940872c-0.685287-0.817429-1.208115-1.75383-1.567348-2.809784
            c-0.360374-1.05481-0.539417-2.161682-0.539417-3.320038c0-1.157196,0.179043-2.264069,0.539417-3.320023
            c0.359234-1.05481,0.882061-1.982635,1.567348-2.782898c0.684711-0.800262,1.532452-1.43808,2.543793-1.915146
            c1.009624-0.477066,2.166256-0.715591,3.468758-0.715591c1.301353,0,2.457989,0.238525,3.468754,0.715591
            c1.010197,0.477066,1.858509,1.114883,2.543793,1.915146c0.685287,0.800262,1.206974,1.728088,1.567348,2.782898
            c0.35923,1.055954,0.539421,2.162827,0.539421,3.320023C56.655598,143.437302,56.475407,144.544174,56.116177,145.598984z"
          />
          <path
            fill="#8A8C8C"
            d="M90.181953,133.084229c-1.177795-1.126892-2.570107-2.006088-4.174637-2.638168
            c-1.605675-0.631516-3.32975-0.947845-5.173958-0.947845s-3.568863,0.31633-5.173965,0.947845
            c-1.605675,0.63208-2.988258,1.511276-4.148895,2.638168c-1.161781,1.126892-2.07473,2.467712-2.74057,4.021332
            c-0.666405,1.554199-0.999321,3.270264-0.999321,5.148224c0,1.878525,0.332916,3.60318,0.999321,5.173386
            c0.66584,1.57135,1.578789,2.928772,2.74057,4.072815c1.160637,1.144623,2.543221,2.03183,4.148895,2.663345
            c1.605103,0.631516,3.329758,0.947845,5.173965,0.947845s3.568283-0.31633,5.173958-0.947845
            c1.60453-0.631516,2.996841-1.518723,4.174637-2.663345c1.178368-1.144043,2.100471-2.501465,2.766312-4.072815
            c0.665833-1.570206,0.999329-3.294861,0.999329-5.173386c0-1.87796-0.333496-3.594025-0.999329-5.148224
            C92.282425,135.551941,91.360321,134.211121,90.181953,133.084229z M88.387512,145.598984
            c-0.359802,1.055954-0.882065,1.992355-1.566772,2.809784c-0.685287,0.816849-1.5336,1.463806-2.543793,1.940872
            c-1.011345,0.477081-2.167404,0.715042-3.46933,0.715042c-1.301933,0-2.458565-0.237961-3.468758-0.715042
            c-1.010765-0.477066-1.858505-1.124023-2.543793-1.940872c-0.685287-0.817429-1.207542-1.75383-1.567345-2.809784
            c-0.35981-1.05481-0.539421-2.161682-0.539421-3.320038c0-1.157196,0.179611-2.264069,0.539421-3.320023
            c0.359802-1.05481,0.882057-1.982635,1.567345-2.782898s1.533028-1.43808,2.543793-1.915146
            c1.010193-0.477066,2.166824-0.715591,3.468758-0.715591c1.301926,0,2.457985,0.238525,3.46933,0.715591
            c1.010193,0.477066,1.858505,1.114883,2.543793,1.915146c0.684708,0.800262,1.20697,1.728088,1.566772,2.782898
            c0.359802,1.055954,0.539421,2.162827,0.539421,3.320023C88.926933,143.437302,88.747314,144.544174,88.387512,145.598984z"
          />
          <path
            fill="#8A8C8C"
            d="M122.217674,133.084229c-1.177803-1.126892-2.570107-2.006088-4.174637-2.638168
            c-1.605675-0.631516-3.329758-0.947845-5.173965-0.947845s-3.568855,0.31633-5.173958,0.947845
            c-1.605675,0.63208-2.988258,1.511276-4.148895,2.638168c-1.161781,1.126892-2.07473,2.467712-2.74057,4.021332
            c-0.666405,1.554199-0.999329,3.270264-0.999329,5.148224c0,1.878525,0.332924,3.60318,0.999329,5.173386
            c0.66584,1.57135,1.578789,2.928772,2.74057,4.072815c1.160637,1.144623,2.543221,2.03183,4.148895,2.663345
            c1.605103,0.631516,3.32975,0.947845,5.173958,0.947845s3.568291-0.31633,5.173965-0.947845
            c1.60453-0.631516,2.996834-1.518723,4.174637-2.663345c1.178368-1.144043,2.100471-2.501465,2.766304-4.072815
            c0.66584-1.570206,0.999329-3.294861,0.999329-5.173386c0-1.87796-0.333488-3.594025-0.999329-5.148224
            C124.318146,135.551941,123.396042,134.211121,122.217674,133.084229z M120.423233,145.598984
            c-0.35981,1.055954-0.882065,1.992355-1.56678,2.809784c-0.68528,0.816849-1.533592,1.463806-2.543793,1.940872
            c-1.011337,0.477081-2.167397,0.715042-3.46933,0.715042c-1.301926,0-2.458557-0.237961-3.468758-0.715042
            c-1.010765-0.477066-1.858505-1.124023-2.543793-1.940872c-0.68528-0.817429-1.207542-1.75383-1.567345-2.809784
            c-0.359802-1.05481-0.539421-2.161682-0.539421-3.320038c0-1.157196,0.179619-2.264069,0.539421-3.320023
            c0.359802-1.05481,0.882065-1.982635,1.567345-2.782898c0.685287-0.800262,1.533028-1.43808,2.543793-1.915146
            c1.010201-0.477066,2.166832-0.715591,3.468758-0.715591c1.301933,0,2.457993,0.238525,3.46933,0.715591
            c1.010201,0.477066,1.858513,1.114883,2.543793,1.915146c0.684715,0.800262,1.20697,1.728088,1.56678,2.782898
            c0.359802,1.055954,0.539413,2.162827,0.539413,3.320023C120.962646,143.437302,120.783035,144.544174,120.423233,145.598984z"
          />
          <path
            fill="#8A8C8C"
            d="M172.527176,136.260117c0-2.834381-0.486801-5.421082-1.459808-7.760666
            c-0.973587-2.339005-2.313843-4.336517-4.021332-5.993668c-1.708069-1.656013-3.739334-2.945358-6.096069-3.867462
            c-2.356171-0.922104-4.917694-1.383156-7.684006-1.383156c-2.765732,0-5.31926,0.461052-7.658264,1.383156
            c-2.339584,0.922104-4.362823,2.211449-6.070328,3.867462c-1.708069,1.65715-3.039734,3.654663-3.99559,5.993668
            c-0.956421,2.339584-1.434067,4.926285-1.434067,7.760666c0,2.800629,0.477646,5.370728,1.434067,7.709747
            c0.955856,2.339569,2.287521,4.354248,3.99559,6.044571c1.707504,1.690338,3.730743,3.005417,6.070328,3.944107
            c2.339005,0.93927,4.892456,1.400925,7.658279,1.40889c2.625381,0.007568,5.21814-0.298187,7.684006-1.408875
            c0.864151-0.389206,1.715485-0.754181,2.505173-1.209915l3.061188,3.474838l3.515656-3.097519l-2.858261-3.244522
            c1.645432-1.663193,2.944931-3.631927,3.893631-5.911575C172.040375,141.630844,172.527176,139.060745,172.527176,136.260117z
             M159.195572,140.82515l-3.515671,3.097519l4.521286,5.132233c-0.400131,0.226776-0.815857,0.436142-1.248734,0.626617
            c-1.708069,0.751648-3.603745,1.126892-5.686493,1.126892c-2.083313,0-3.961838-0.375244-5.634445-1.126892
            c-1.673737-0.751068-3.108383-1.784149-4.303329-3.099228c-1.195541-1.314514-2.117645-2.860123-2.766312-4.636261
            c-0.648682-1.774994-0.973022-3.670685-0.973022-5.685913c0-1.980362,0.324341-3.849731,0.973022-5.609283
            c0.648666-1.758392,1.562759-3.304008,2.74057-4.635681c1.17836-1.332245,2.613007-2.382484,4.303329-3.150711
            c1.690338-0.768227,3.576874-1.152634,5.660187-1.152634c2.117065,0,4.021332,0.384407,5.71167,1.152634
            c1.690903,0.768227,3.13298,1.818466,4.329071,3.150711c1.194962,1.331673,2.117065,2.877289,2.765747,4.635681
            c0.648666,1.759552,0.973587,3.628922,0.973587,5.609283c0,2.015228-0.324921,3.910919-0.973587,5.685913
            c-0.559723,1.532639-1.335678,2.882904-2.302399,4.071854L159.195572,140.82515z"
          />
          <rect x="121.957367" y="118.177277" fill="#8A8C8C" width="4.37623" height="36.26807" />
        </g>
      </g>
    </svg>
  );
}



export default HoodQlogo;
