/**
*
* DetailedMap
*
*/

import React from 'react';
// import { FormattedMessage } from 'react-intl';
import { withGoogleMap, GoogleMap, Marker, Polygon } from 'react-google-maps';
// import update from 'immutability-helper';

// import messages from './messages';
import styles from './styles.css';

import MapLegend from '../MapLegend';

class DetailedMap extends React.Component { // eslint-disable-line react/prefer-stateless-function
  render() {
    const SimpleMapExampleGoogleMap = withGoogleMap((props) => (
      <GoogleMap
        defaultZoom={14}
        defaultCenter={props.defaultCenter}
        defaultOptions={{
          scrollwheel: false,
        }}
      >
        {props.markers.map(marker => (
          <Marker {...marker} />
        ))}
        {props.polygons.map(polygon => (
          <Polygon
            {...polygon}
          />
        ))}
      </GoogleMap>
    ));

    return (
      <div className={styles.detailedMap}>
        <SimpleMapExampleGoogleMap
          containerElement={
            <div className={styles.containerElement} />
          }
          mapElement={
            <div className={styles.mapElement} />
          }
          markers={this.props.markers}
          polygons={this.props.polygons}
          defaultCenter={this.props.defaultCenter}
        />
        <MapLegend />
      </div>
    );
  }
}



export default DetailedMap;
