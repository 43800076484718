/**
*
* TimeLengthDistance
*
*/

import React from 'react';
import { FormattedMessage } from 'react-intl';

import messages from './messages';
import styles from './styles.css';

import SVGIcon from '../../../SVGIcon';

const KM_TO_MILES = 0.621;

function TimeLengthDistance(props) {
  const { distance, locale, hideWalkingPerson } = props;

  let icon = null;
  if (!hideWalkingPerson) {
    icon = <SVGIcon iconType="pedestrian" />;
  }

  let walkTime = Math.round((distance / 4.5) * 60);
  if (walkTime === 0) {
    walkTime = 1;
  }

  let distanceWithUnits = null;
  if (locale === 'en-US') {
    distanceWithUnits = `${Math.round(distance * KM_TO_MILES * 100) / 100} miles`;
  } else {
    distanceWithUnits = `${Math.round(distance * 100) / 100} KM`;
  }

  let distanceContent = null;
  if (hideWalkingPerson) {
    distanceContent = `${distanceWithUnits} away`;
  } else {
    distanceContent = `about a ${walkTime} minute walk - ${distanceWithUnits}`;
  }

  return (
    <div className={styles.timeLengthDistance}>
      {icon}
      {distanceContent}
    </div>
  );
}



export default TimeLengthDistance;
