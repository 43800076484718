/**
*
* Circle
*
*/

import React from 'react';

function Circle() {
  return (
    <svg>
      <circle cx="50%" cy="50%" r="50%" />
    </svg>
  );
}

export default Circle;
