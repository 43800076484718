/*
 * ShareModal Messages
 *
 * This contains all the text for the ShareModal component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  headerIntro: {
    id: 'app.components.ShareModal.headerIntro',
    defaultMessage: 'Share your HoodQ Marketing Package for',
  },
  body: {
    id: 'app.components.ShareModal.body',
    defaultMessage: 'To share your HoodQ Marketing Package, simply copy and paste this link into an email:',
  },
  tooltip: {
    id: 'app.components.ShareModal.tooltip',
    defaultMessage: 'Click to copy',
  },
  copied: {
    id: 'app.components.ShareModal.copied',
    defaultMessage: 'Copied',
  },
});
