/* eslint key-spacing: ["error", {
  "align": {
    "beforeColon": true,
    "afterColon": true,
    "on": "colon"
  }
}] */

export default {
  'Indoor Pool'               : 'pool',
  'Outdoor Pool'              : 'pool',
  Swimming                    : 'pool',
  Pool                        : 'pool',
  'Leash-Free Dog Area'       : 'dogPark',
  'Dog Park'                  : 'dogPark',
  Playground                  : 'playground',
  'Tot Lot'                   : 'playground',
  'Wading Pool'               : 'wadingPool',
  'Cricket Field'             : 'sportsField',
  'Football Field'            : 'sportsField',
  'Indoor Soccer Field'       : 'sportsField',
  'Lacrosse Field'            : 'sportsField',
  'Rugby Field'               : 'sportsField',
  'Soccer Field'              : 'sportsField',
  'Sports Field'              : 'sportsField',
  'Ultimate Frisbee Field'    : 'sportsField',
  'Tennis Court'              : 'tennisCourt',
  'Basketball Court'          : 'basketballCourt',
  'Volleyball Court'          : 'volleyballCourt',
  'Handball Court'            : 'handballCourt',
  'Spray Park'                : 'splashPad',
  'Splash Pad'                : 'splashPad',
  'Ball Diamond'              : 'ballDiamond',
  'Curling Rink'              : 'rink',
  'Indoor Rink'               : 'rink',
  'Outdoor Rink'              : 'rink',
  'Skating Rink'              : 'rink',
  Rink                        : 'rink',
  Arena                       : 'arenas',
  'Community Rec. Centre'     : 'communityCentre',
  'Fitness/Leisure Centre'    : 'communityCentre',
  'Community Centre'          : 'communityCentre',
  'Skateboard Park'           : 'skateboardPark',
  'Golf Course'               : 'golfGeneric',
  'Mini Golf'                 : 'golfGeneric',
  'Driving Range'             : 'golfGeneric',
  Track                       : 'track',
  'Indoor Track'              : 'track',
  'Outdoor Track'             : 'track',
  'Cross-Country Skiing'      : 'skiingSnowboarding',
  'Ski Terrain Park'          : 'skiingSnowboarding',
  Skiing                      : 'skiingSnowboarding',
  Snowboarding                : 'skiingSnowboarding',
  'Skiing or Snowboarding'    : 'skiingSnowboarding',
  'Fitness Trails'            : 'trail',
  'Bicycle Trails'            : 'trail',
  Hiking                      : 'trail',
  Trails                      : 'trail',
  Trail                       : 'trail',
  'Archery Range'             : 'generic',
  'Lawn Bowling/Bocce Green'  : 'generic',
  'BMX Track'                 : 'generic',
  'Athletic Park'             : 'generic',
  Equestrian                  : 'generic',
  'Horseshoe Pit'             : 'generic',
  'Shooting Range'            : 'generic',
  Shuffleboard                : 'generic',
  'Disc Golf'                 : 'generic',
  'Indoor Dry Pad'            : 'generic',
  'Climbing Wall'             : 'generic',
  Stadium                     : 'generic',
  'Luge Track'                : 'generic',
  'Sledding Hill'             : 'generic',
  Aquarium                    : 'generic',
  'Botanical Garden'          : 'generic',
  Arboretum                   : 'generic',
  Boardwalk                   : 'generic',
  Campground                  : 'generic',
  'Community Garden'          : 'generic',
  Farm                        : 'generic',
  'Model Aircraft Field'      : 'generic',
  'Rodeo Grounds'             : 'generic',
  Zoo                         : 'generic',
  'Mushing Facilities'        : 'generic',
  Beach                       : 'generic',
  'Boating Facilities'        : 'generic',
  'Docking Facilities'        : 'generic',
  Fishing                     : 'generic',
  Pier                        : 'generic',
  'Yachting Facilities'       : 'generic',
  Fairgrounds                 : 'generic',
  'Nature Centre'             : 'generic',
  'Country Club'              : 'generic',
  'Picnic Facilities'         : 'generic',
  'Fitness/Weight Room'       : 'generic',
  Gym                         : 'generic',
  'Boxing Gym'                : 'generic',
  Amphitheatre                : 'generic',
  Auditorium                  : 'generic',
  Gallery                     : 'generic',
  Stage                       : 'generic',
  Museum                      : 'generic',
  'Preschool Activities Room' : 'generic',
  'Games Room'                : 'generic',
  'Craft Room'                : 'generic',
  'Computer/Training Room'    : 'generic',
  'Community Hall'            : 'generic',
  Cabin                       : 'generic',
  Clubhouse                   : 'generic',
  'Pro Shop'                  : 'generic',
  Sauna                       : 'generic',
  Room                        : 'generic',
};
