/**
 *
 * InformationIcon
 *
 **/

import React from 'react';

function InformationIcon() {
  return (
    <svg x="0px" y="0px" width="286.91379" height="286.91379" viewBox="0 0 496.304 496.303" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path fill="#F9423A" strokeWidth="14" d="m286.914124,143.457626c0,79.229126 -64.227829,143.457047 -143.456863,143.457047c-79.229042,0 -143.45688,-64.227921 -143.45688,-143.457047c0,-79.229103 64.227838,-143.457026 143.45688,-143.457026c79.229034,0 143.456863,64.2279 143.456863,143.457026z" id="path3794-8-2" />
        <text fontStyle="normal" transform="matrix(2.30007, 0, 0, 1.89667, -234.306, -37.2391)" fontWeight="bold" xmlSpace="preserve" textAnchor="middle" fontFamily="Times New Roman" fontSize="135" id="svg_1" y="134.749207" x="165.326462" strokeWidth="0" stroke="#000000" fill="#ffffff">i</text>
      </g>
    </svg>
  );
}

export default InformationIcon;
