/**
*
* StreetLevelTransit
*
*/

import React from 'react';

function StreetLevelTransit() {
  return (
    <svg version="1.1" viewBox="0 0 72 39.375">
      <path d="m62.951 8.3438h6.9534v-4.3752c0-0.89495-0.72557-1.6205-1.6205-1.6205h-64.494c-0.89489 0-1.6205 0.72551-1.6205 1.6205v25.441c0 0.89495 0.72557 1.6205 1.6205 1.6205h4.7177c0.25019-3.7108 3.3375-6.6439 7.1117-6.6439s6.8615 2.933 7.1117 6.6439h27.423c0.25019-3.7108 3.3375-6.6439 7.1117-6.6439s6.8615 2.933 7.1117 6.6439h3.9075c0.89489 0 1.6205-0.72551 1.6205-1.6205v-10.209h-6.9534c-0.6792 0-1.2299-0.65689-1.2299-1.4672v-7.923c-0.002-0.8103 0.549-1.4672 1.228-1.4672zm-39.878 9.3902c0 0.81029-0.5507 1.4672-1.2299 1.4672h-7.7491c-0.6792 0-1.2299-0.65689-1.2299-1.4672v-7.923c0-0.81031 0.5507-1.4672 1.2299-1.4672h7.7491c0.6792 0 1.2299 0.65687 1.2299 1.4672v7.923zm11.91 0c0 0.81029-0.5507 1.4672-1.2299 1.4672h-7.7491c-0.6792 0-1.2299-0.65689-1.2299-1.4672v-7.923c0-0.81031 0.5507-1.4672 1.2299-1.4672h7.7491c0.6792 0 1.2299 0.65687 1.2299 1.4672v7.923zm10.519 10.533c0 0.81029-0.89489 1.4672-1.9986 1.4672h-4.2971c-1.1037 0-1.9986-0.65689-1.9986-1.4672v-18.456c0-0.81031 0.89489-1.4672 1.9986-1.4672h4.2971c1.1037 0 1.9986 0.65687 1.9986 1.4672v18.456zm13.302-10.533c0 0.81029-0.5507 1.4672-1.2299 1.4672h-7.7491c-0.6792 0-1.2299-0.65689-1.2299-1.4672v-7.923c0-0.81031 0.5507-1.4672 1.2299-1.4672h7.7491c0.6792 0 1.2299 0.65687 1.2299 1.4672v7.923z" fill="#6A6CB0" />
      <circle cy="32.57" cx="15.538" r="5.2665" fill="#6A6CB0" />
      <circle cy="32.57" cx="57.346" r="5.2665" fill="#6A6CB0" />
    </svg>
  );
}

export default StreetLevelTransit;
