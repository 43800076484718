/**
*
* SplashPad
*
*/

import React from 'react';

function SplashPad() {
  return (
    <svg version="1.1" y="0px" x="0px" viewBox="0 0 72 64">
      <g fill="#01818E">
        <path d="m25.24 18.966s-0.42538-8.3379-6.0257-14.941c-3.8491-4.1382-8.8893-2.7974-10.62-0.30963s1.1068 6.5228 5.6673 7.9156 9.054 4.492 10.979 7.335z" />
        <path d="m66.216 5.1202c-7.0811-4.9158-21.847-3.4919-28.005 11.357-8.3815 23.157-1.247 45.806-1.247 45.806 2.3132-9.4887 11.217-22.206 21.993-30.441 10.777-8.235 14.34-21.806 7.259-26.722zm-15.297 12.835c-4.2727 1.742-8.3305 5.1225-9.9367 8.0311 0 0-0.32104-8.0723 4.497-14.927 3.3465-4.3254 8.3212-3.4752 10.207-1.2295 1.887 2.2465-0.494 6.3835-4.767 8.1255z" />
        <path d="m31.641 46.6s3.8377-16.242-14.081-22.907c-11.291-3.416-15.722 7.176-15.061 10.223s2.9663 9.3038 12.811 5.3865c4.161-2.023 12.316-1.405 16.331 7.298z" />
      </g>
    </svg>
  );
}

export default SplashPad;
