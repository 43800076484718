import fetchFromApi from 'hoodq-shared-js/lib/core/fetchFromApi';

const API_BASE_URL = process.env.HOODQ_API_BASE_URL || '';

export default class HoodQAPI {
  key: string;
  url: string = API_BASE_URL;

  constructor({ key, url = API_BASE_URL }) {
    this.key = key;
    this.url = url;
  }

  fetch<T>({ path, method, params }: { path: string, method?: string, params: object }) {
    return fetchFromApi<T>({
      url: this.url,
      method,
      path,
      params: { ...params, key: this.key }
    });
  }

  fetchAddressTypeaheadResults(query: string): Promise<string[]> {
    return this.fetch<string[]>({
      path: '/addresses.json',
      params: { q: query }
    });
  }

  createPackage(address: string): Promise<any> {
    return this.fetch<any>({
      path: '/packages.json',
      method: 'POST',
      params: { address }
    });
  }
}