/**
*
* ParksRec
*
*/

import React from 'react';

function ParksRec(props) {
  const { x, y, height, width } = props;

  return (
    <svg x={x} y={y} height={height} width={width} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 216 216" enableBackground="new 0 0 216 216" xmlSpace="preserve">
      <circle fill="#FFFFFF" stroke="#51B848" strokeWidth="4" strokeMiterlimit="10" cx="108.5" cy="108.5" r="98.5" />
      <g>
        <g>
          <g>
            <path fill="#51B848" d="M74.6,78.3c-0.3,0-0.7-0.2-0.8-0.5c-0.2-0.5,0-1,0.4-1.2c0.1-0.1,3.3-1.6,6.7-0.5c0.9-4.1,4.8-5.6,5-5.6 c0.5-0.2,1,0.1,1.2,0.6c0.2,0.5-0.1,1-0.6,1.2c-0.1,0.1-3.7,1.4-4,5.3c0,0.3-0.2,0.6-0.5,0.7c-0.3,0.2-0.6,0.2-0.9,0 c-2.9-1.6-6.1,0-6.1,0C74.9,78.3,74.7,78.3,74.6,78.3z" />
          </g>
          <path fill="#51B848" d="M132.9,72.5c0-8.8-7.2-16-16-16c-2.6,0-5,0.6-7.2,1.7c-1.6-1-3.4-1.5-5.4-1.5c-5.9,0-10.6,4.8-10.6,10.6 c0,1.4,0.3,2.8,0.8,4c-3.4,2.6-5.5,6.7-5.5,11.2c0,7.1,5.3,13,12.1,14c0.9,3.1,3.2,5.5,6.2,6.6v15.9l5.9,0.2l-0.5-15.7 c4.5-0.9,7.9-4.9,7.9-9.7c0-2-0.6-4-1.7-5.5C126.8,87.4,132.9,80.7,132.9,72.5z" />
          <path fill="#51B848" d="M141.6,95.1c0.9-1.5,1.4-3.2,1.4-5c0-5.5-4.4-9.9-9.9-9.9c-1.8,0-3.6,0.5-5,1.4c-0.8-0.2-1.7-0.3-2.6-0.3 c-6.8,0-12.2,5.5-12.2,12.2c0,5.6,3.7,10.3,8.9,11.7c0.6,1.7,2,3.1,3.7,3.8c-0.3,3.1-0.9,11.7-0.9,11.7l3.6,0.4l0.3-11.7 c2.5-0.3,4.5-2,5.3-4.3c0.8,0.4,1.7,0.6,2.7,0.6c3.5,0,6.4-2.9,6.4-6.4C143.3,97.8,142.7,96.3,141.6,95.1z" />
          <g>
            <rect x="69.7" y="98.5" transform="matrix(0.9956 -9.340600e-02 9.340600e-02 0.9956 -9.062 8.1167)" fill="#51B848" width="24.9" height="4.7" />
            <rect x="70.3" y="104.4" transform="matrix(0.9956 -9.340600e-02 9.340600e-02 0.9956 -9.6061 8.1935)" fill="#51B848" width="24.9" height="4.7" />
          </g>
          <path fill="#51B848" d="M139.9,122.9c-12.6-3.3-26.2-4.3-38.5-4.2c0.2-0.3,0.3-0.6,0.3-1l-1.1-11.5c-0.1-0.8-0.8-1.4-1.7-1.4 c-0.8,0.1-1.4,0.8-1.4,1.7l0.3,2.9l-29.6,2.8l-0.3-2.9c-0.1-0.8-0.8-1.4-1.7-1.4c-0.8,0.1-1.4,0.8-1.4,1.7l1.1,11.5 c0,0.3,0.2,0.6,0.3,0.8c-4.2,0.7-6.7,1.2-6.8,1.3c-0.5,0.1-0.8,0.6-0.7,1.1c0.1,0.4,0.5,0.7,0.9,0.7c0.1,0,0.1,0,0.2,0 c0.4-0.1,44-9.6,79.4-0.2c0.5,0.1,1-0.2,1.1-0.6C140.7,123.6,140.4,123.1,139.9,122.9z M68.5,115.1l29.6-2.8l0.5,5.8 c0,0.3,0.2,0.5,0.3,0.8c-12.1,0.3-22.8,1.6-30,2.7c0.1-0.2,0.1-0.4,0.1-0.6L68.5,115.1z" />
          <g>
            <circle fill="#51B848" cx="138.1" cy="70.7" r="7.8" />
            <g>
              <g>
                <path fill="#51B848" d="M136.6,59.5c-0.6,0-1.1-0.5-1.2-1.1l-0.6-6.4c-0.1-0.7,0.4-1.3,1.1-1.3c0.7-0.1,1.3,0.4,1.3,1.1l0.6,6.4 c0.1,0.7-0.4,1.3-1.1,1.3C136.6,59.5,136.6,59.5,136.6,59.5z" />
              </g>
              <g>
                <path fill="#51B848" d="M143,60.4c-0.2,0-0.3,0-0.5-0.1c-0.6-0.3-0.9-1-0.6-1.6l2.6-5.9c0.3-0.6,1-0.9,1.6-0.6 c0.6,0.3,0.9,1,0.6,1.6l-2.6,5.9C143.9,60.1,143.5,60.4,143,60.4z" />
              </g>
              <g>
                <path fill="#51B848" d="M148.4,64.7c-0.4,0-0.8-0.2-1-0.5c-0.4-0.6-0.2-1.3,0.3-1.7l5.3-3.6c0.6-0.4,1.3-0.2,1.7,0.3 c0.4,0.6,0.2,1.3-0.3,1.7l-5.3,3.6C148.8,64.6,148.6,64.7,148.4,64.7z" />
              </g>
              <g>
                <path fill="#51B848" d="M150.5,70.8c-0.6,0-1.2-0.5-1.2-1.1c0-0.7,0.5-1.2,1.1-1.3l6.4-0.4c0.7,0,1.2,0.5,1.3,1.1 c0,0.7-0.5,1.2-1.1,1.3L150.5,70.8C150.5,70.8,150.5,70.8,150.5,70.8z" />
              </g>
              <g>
                <path fill="#51B848" d="M155.1,80c-0.2,0-0.4,0-0.5-0.1l-5.8-2.8c-0.6-0.3-0.9-1-0.6-1.6c0.3-0.6,1-0.9,1.6-0.6l5.8,2.8 c0.6,0.3,0.9,1,0.6,1.6C156,79.8,155.6,80,155.1,80z" />
              </g>
              <g>
                <path fill="#51B848" d="M148.3,87.8c-0.4,0-0.8-0.2-1-0.6l-3.4-5.4c-0.4-0.6-0.2-1.3,0.4-1.7c0.6-0.4,1.3-0.2,1.7,0.4l3.4,5.4 c0.4,0.6,0.2,1.3-0.4,1.7C148.7,87.7,148.5,87.8,148.3,87.8z" />
              </g>
              <g>
                <path fill="#51B848" d="M139,90.9c-0.7,0-1.2-0.5-1.2-1.2l-0.2-6.4c0-0.7,0.5-1.2,1.2-1.3c0.7,0,1.2,0.5,1.3,1.2l0.2,6.4 C140.2,90.3,139.7,90.9,139,90.9L139,90.9z" />
              </g>
              <g>
                <path fill="#51B848" d="M129.4,88.8c-0.2,0-0.4,0-0.6-0.1c-0.6-0.3-0.8-1-0.5-1.6l3-5.7c0.3-0.6,1-0.8,1.6-0.5 c0.6,0.3,0.8,1,0.5,1.6l-3,5.7C130.3,88.6,129.9,88.8,129.4,88.8z" />
              </g>
              <g>
                <path fill="#51B848" d="M121.8,81.8c-0.4,0-0.8-0.2-1-0.6c-0.3-0.6-0.2-1.3,0.4-1.7l5.5-3.3c0.6-0.3,1.3-0.2,1.7,0.4 c0.3,0.6,0.2,1.3-0.4,1.7l-5.5,3.3C122.3,81.7,122.1,81.8,121.8,81.8z" />
              </g>
              <g>
                <path fill="#51B848" d="M125.6,72.3L125.6,72.3l-6.4,0c-0.7,0-1.2-0.6-1.2-1.2c0-0.7,0.5-1.2,1.2-1.2h0l6.4,0 c0.7,0,1.2,0.6,1.2,1.2C126.8,71.7,126.3,72.3,125.6,72.3z" />
              </g>
              <g>
                <path fill="#51B848" d="M127.2,66c-0.2,0-0.4-0.1-0.6-0.2l-5.6-3.2c-0.6-0.3-0.8-1.1-0.5-1.7c0.3-0.6,1.1-0.8,1.7-0.5l5.6,3.2 c0.6,0.3,0.8,1.1,0.5,1.7C128,65.7,127.6,66,127.2,66z" />
              </g>
              <g>
                <path fill="#51B848" d="M132,61.1c-0.4,0-0.8-0.2-1.1-0.6l-3.1-5.6c-0.3-0.6-0.1-1.3,0.5-1.6c0.6-0.3,1.3-0.1,1.6,0.5l3.1,5.6 c0.3,0.6,0.1,1.3-0.5,1.6C132.4,61,132.2,61.1,132,61.1z" />
              </g>
            </g>
          </g>
        </g>
      </g>
      <g>
        <path fill="#51B848" d="M55.3,149.2c0,0.7-0.1,1.3-0.4,1.8s-0.6,0.9-1.1,1.2c-0.5,0.3-1,0.5-1.6,0.7c-0.6,0.1-1.2,0.2-1.9,0.2h-1.5 v4.7h-3v-12.4h4.5c0.7,0,1.3,0.1,1.9,0.2c0.6,0.1,1.1,0.3,1.5,0.6c0.4,0.3,0.8,0.7,1,1.2C55.2,147.9,55.3,148.5,55.3,149.2z M52.3,149.2c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.3-0.5-0.4c-0.2-0.1-0.4-0.2-0.7-0.2c-0.3,0-0.5-0.1-0.8-0.1h-1.3v2.9h1.3 c0.3,0,0.5,0,0.8-0.1c0.3,0,0.5-0.1,0.7-0.2c0.2-0.1,0.4-0.3,0.5-0.5C52.3,149.8,52.3,149.5,52.3,149.2z" />
        <path fill="#51B848" d="M65.4,157.8l-1-2.4h-4.8l-0.9,2.4h-3.3l5.2-12.4h2.9l5.1,12.4H65.4z M62,148.7l-1.6,4.3h3.1L62,148.7z" />
        <path fill="#51B848" d="M77.2,157.8l-2.7-4.9h-1v4.9h-2.9v-12.4h4.7c0.6,0,1.2,0.1,1.7,0.2c0.6,0.1,1.1,0.3,1.5,0.6 c0.4,0.3,0.8,0.7,1.1,1.2c0.3,0.5,0.4,1.1,0.4,1.8c0,0.8-0.2,1.5-0.7,2.1s-1.1,1-1.9,1.2l3.2,5.3H77.2z M77.1,149.2 c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.3-0.5-0.4c-0.2-0.1-0.4-0.2-0.7-0.2c-0.2,0-0.5-0.1-0.7-0.1h-1.6v2.9h1.4 c0.2,0,0.5,0,0.8-0.1s0.5-0.1,0.7-0.2c0.2-0.1,0.4-0.3,0.5-0.5S77.1,149.5,77.1,149.2z" />
        <path fill="#51B848" d="M90.6,157.8l-4.6-5.7h0v5.7h-2.9v-12.4H86v5H86l4.4-5h3.7l-5.3,5.7l5.7,6.7H90.6z" />
        <path fill="#51B848" d="M102.9,148.6c-0.3-0.3-0.6-0.6-1-0.8c-0.4-0.2-0.8-0.3-1.2-0.3c-0.2,0-0.4,0-0.6,0.1c-0.2,0-0.4,0.1-0.6,0.2 c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.2,0.4-0.2,0.6c0,0.2,0,0.4,0.1,0.5c0.1,0.1,0.2,0.3,0.4,0.4c0.2,0.1,0.4,0.2,0.6,0.3 c0.2,0.1,0.5,0.2,0.8,0.3c0.4,0.1,0.9,0.3,1.3,0.5c0.5,0.2,0.9,0.4,1.2,0.7c0.4,0.3,0.7,0.6,0.9,1c0.2,0.4,0.4,0.9,0.4,1.5 c0,0.7-0.1,1.3-0.4,1.8c-0.3,0.5-0.6,0.9-1.1,1.3c-0.4,0.3-1,0.6-1.5,0.8c-0.6,0.2-1.2,0.2-1.8,0.2c-0.9,0-1.7-0.2-2.6-0.5   c-0.8-0.3-1.5-0.8-2.1-1.3l2-2c0.3,0.4,0.7,0.7,1.2,0.9s1,0.4,1.5,0.4c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.6-0.2 c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.2-0.3-0.3-0.5-0.4s-0.5-0.3-0.8-0.4 c-0.3-0.1-0.7-0.2-1.1-0.4c-0.4-0.1-0.8-0.3-1.2-0.5c-0.4-0.2-0.7-0.4-1-0.7c-0.3-0.3-0.5-0.6-0.7-1c-0.2-0.4-0.3-0.9-0.3-1.4 c0-0.7,0.1-1.3,0.4-1.8c0.3-0.5,0.6-0.9,1.1-1.2c0.5-0.3,1-0.5,1.5-0.7c0.6-0.1,1.1-0.2,1.7-0.2c0.7,0,1.4,0.1,2.1,0.4   c0.7,0.3,1.4,0.6,1.9,1.1L102.9,148.6z" />
        <path fill="#51B848" d="M121.6,157.8l-1.2-1.2c-0.4,0.4-0.9,0.8-1.5,1c-0.6,0.3-1.3,0.4-2.2,0.4c-0.6,0-1.1-0.1-1.6-0.2 c-0.5-0.1-1-0.4-1.4-0.7c-0.4-0.3-0.7-0.7-1-1.2c-0.3-0.5-0.4-1-0.4-1.6c0-0.4,0.1-0.8,0.2-1.2c0.1-0.4,0.3-0.7,0.6-1 c0.2-0.3,0.5-0.5,0.8-0.7s0.6-0.4,1-0.5c-0.4-0.4-0.7-0.8-0.9-1.2s-0.4-0.9-0.4-1.5c0-0.6,0.1-1,0.4-1.4c0.2-0.4,0.5-0.7,0.9-1 c0.4-0.3,0.8-0.4,1.2-0.6c0.5-0.1,0.9-0.2,1.4-0.2c0.5,0,0.9,0.1,1.4,0.2c0.5,0.1,0.9,0.3,1.2,0.6c0.4,0.3,0.6,0.6,0.9,1  c0.2,0.4,0.3,0.9,0.3,1.4c0,0.4-0.1,0.8-0.2,1.1c-0.1,0.3-0.3,0.6-0.5,0.9s-0.5,0.5-0.8,0.7c-0.3,0.2-0.6,0.4-1,0.6l1.6,1.6 l1.2-1.9h3.2l-2.7,3.6l3.1,3.1H121.6z M116.5,152.6c-0.3,0.1-0.7,0.3-0.9,0.6c-0.3,0.3-0.4,0.6-0.4,1.1c0,0.2,0,0.5,0.1,0.7 c0.1,0.2,0.2,0.4,0.4,0.5c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.1,0.6,0.1c0.4,0,0.7-0.1,1-0.2c0.3-0.2,0.6-0.4,0.8-0.7 L116.5,152.6z M118.7,148.3c0-0.3-0.1-0.6-0.3-0.8s-0.5-0.3-0.9-0.3c-0.3,0-0.6,0.1-0.8,0.3s-0.3,0.5-0.3,0.8  c0,0.3,0.1,0.6,0.2,0.8c0.2,0.3,0.4,0.5,0.7,0.8c0.4-0.2,0.7-0.4,1-0.6S118.7,148.7,118.7,148.3z" />
        <path fill="#51B848" d="M139.3,157.8l-2.7-4.9h-1v4.9h-2.9v-12.4h4.7c0.6,0,1.2,0.1,1.7,0.2c0.6,0.1,1.1,0.3,1.5,0.6 c0.4,0.3,0.8,0.7,1.1,1.2c0.3,0.5,0.4,1.1,0.4,1.8c0,0.8-0.2,1.5-0.7,2.1s-1.1,1-1.9,1.2l3.2,5.3H139.3z M139.1,149.2 c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.3-0.5-0.4c-0.2-0.1-0.4-0.2-0.7-0.2c-0.2,0-0.5-0.1-0.7-0.1h-1.6v2.9h1.4 c0.2,0,0.5,0,0.8-0.1s0.5-0.1,0.7-0.2c0.2-0.1,0.4-0.3,0.5-0.5S139.1,149.5,139.1,149.2z" />
        <path fill="#51B848" d="M145.1,157.8v-12.4h8.3v2.5H148v2.3h5.1v2.4H148v2.6h5.8v2.6H145.1z" />
        <path fill="#51B848" d="M165.5,157.6c-0.8,0.4-1.7,0.6-2.8,0.6c-1,0-1.8-0.2-2.7-0.5c-0.8-0.3-1.5-0.8-2.1-1.3 c-0.6-0.6-1.1-1.3-1.4-2.1c-0.3-0.8-0.5-1.7-0.5-2.7c0-1,0.2-1.9,0.5-2.7c0.3-0.8,0.8-1.5,1.4-2.1c0.6-0.6,1.3-1,2.1-1.3 c0.8-0.3,1.7-0.5,2.6-0.5c0.9,0,1.7,0.2,2.6,0.5c0.8,0.3,1.5,0.8,2.1,1.4l-2,2c-0.3-0.4-0.6-0.7-1.1-0.9c-0.5-0.2-0.9-0.3-1.4-0.3 c-0.5,0-1,0.1-1.4,0.3c-0.4,0.2-0.8,0.5-1.1,0.8c-0.3,0.3-0.6,0.7-0.7,1.2c-0.2,0.5-0.3,1-0.3,1.5c0,0.6,0.1,1.1,0.3,1.5   c0.2,0.5,0.4,0.9,0.7,1.2c0.3,0.3,0.7,0.6,1.1,0.8c0.4,0.2,0.9,0.3,1.4,0.3c0.6,0,1.1-0.1,1.6-0.3s0.8-0.5,1.1-0.9l2.1,2 C167,156.7,166.3,157.2,165.5,157.6z" />
        <path fill="#51B848" d="M172.8,156.3c0,0.5-0.2,0.9-0.5,1.2c-0.3,0.3-0.8,0.5-1.2,0.5c-0.2,0-0.5,0-0.7-0.1c-0.2-0.1-0.4-0.2-0.6-0.4 c-0.2-0.2-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.4-0.1-0.7c0-0.2,0-0.5,0.1-0.7c0.1-0.2,0.2-0.4,0.4-0.6c0.2-0.2,0.4-0.3,0.6-0.4 c0.2-0.1,0.4-0.1,0.7-0.1c0.5,0,0.9,0.2,1.2,0.5C172.6,155.4,172.8,155.8,172.8,156.3z" />
      </g>
    </svg>
  );
}



export default ParksRec;
