import FacilityMapping from 'components/reports/FacilityMapping';
import FacilityIconMapping from 'components/reports/FacilityIconMapping';

const TWENTY_MIN_WALK = 1.5; // km

function incrementKey(object, key) {
  const newObject = object;
  if (newObject[key]) {
    newObject[key] += 1;
  } else {
    newObject[key] = 1;
  }
}

export function addFacilities(parks, maxDistance = TWENTY_MIN_WALK) {
  // TODO : remove the use of these variables and the use of all the garbage
  // functions found under componentWillMount. Replace this by data sent directly
  // from the backend. No calculations should happen on the frontend
  const mappedFacilities = {};
  const remainingFacilities = {};
  const mappedNearbyFacilities = {};
  const remainingNearbyFacilities = {};

  parks.forEach((park) => {
    const facilities     = park.features.Facilities;
    const mappedCount    = {};
    const remainderCount = {};

    if (facilities) {
      facilities.forEach((facility) => {
        const mappedFacility = FacilityMapping[facility];

        // NOTE : in order to change the distance of the facilities
        // change constant 'TWENTY_MIN_WALK' for new distance
        if (mappedFacility) {
          incrementKey(mappedCount, mappedFacility);
          incrementKey(mappedFacilities, mappedFacility);
          if (!maxDistance || (maxDistance && park.distance <= maxDistance)) {
            incrementKey(mappedNearbyFacilities, mappedFacility);
          }
        } else {
          incrementKey(remainderCount, facility);
          incrementKey(remainingFacilities, facility);
          if (!maxDistance || (maxDistance && park.distance <= maxDistance)) {
            incrementKey(remainingNearbyFacilities, facility);
          }
        }
      });
    }

    // TODO : check how to add this to its own node in the state tree
    const finalMapping = {};

    Object.keys(FacilityMapping).forEach((facility) => {
      const mappedFacility = FacilityMapping[facility];

      if (mappedCount[mappedFacility]) {
        finalMapping[mappedFacility] = {
          count: mappedCount[mappedFacility],
          icon: FacilityIconMapping[facility],
          iconType: FacilityIconMapping[facility], // TODO : remove redundancy
        };
      }
    });

    Object.keys(remainderCount).forEach((facility) => {
      finalMapping[facility] = {
        count: remainderCount[facility],
        icon: 'Generic',
        iconType: 'Generic', // TODO : remove redundancy
      };
    });

    park.features.Facilities = finalMapping; // eslint-disable-line no-param-reassign
  });

  const totalFacilities = {};

  Object.keys(FacilityMapping).forEach((facility) => {
    if (mappedNearbyFacilities[facility]) {
      totalFacilities[facility] = {
        name: facility,
        count: mappedNearbyFacilities[facility],
        icon: FacilityIconMapping[facility],
        iconType: FacilityIconMapping[facility], // TODO : remove redundancy
      };
    }
  });

  Object.keys(remainingNearbyFacilities).forEach((facility) => {
    totalFacilities[facility] = {
      name: facility,
      count: remainingNearbyFacilities[facility],
      icon: 'Generic',
      iconType: 'Generic', // TODO : remove redundancy
    };
  });

  if (maxDistance) {
    return totalFacilities;
  }

  return Object.keys(totalFacilities).map(k => totalFacilities[k]);
}