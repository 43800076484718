/*
 * HouseholdSize Messages
 *
 * This contains all the text for the HouseholdSize component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.HouseholdSize.header',
    defaultMessage: 'Household Size',
  },
  graphName: {
    id: 'app.components.HouseholdSize.graphName',
    defaultMessage: 'Number of People Per Household',
  },
});
