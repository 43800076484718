/**
*
* DogPark
*
*/

import React from 'react';

function DogPark() {
  return (
    <svg version="1.1" y="0px" x="0px" viewBox="0 0 72 51.875">
      <g fill="#01818E">
        <path d="m49.862 32.977c0.036896-0.11036 0.064453-0.18853 0.080269-0.23443l0.01244-0.029148c0.02108-0.053909 0.029144-0.10972 0.047115-0.16491 1.1754-1.8957 1.8206-4.293 2.1492-5.9226-3.9748-3.1252-6.4608-6.0584-7.7735-7.8418-8.781 1.2253-17.857-2.6205-24.132-0.75277-3.8527-1.2439-8.4166-3.8186-11.784-9.1471-0.68026-1.0715-2.1003-1.3853-3.17-0.70503-1.0709 0.67836-1.3872 2.099-0.70508 3.1705 3.204 5.0166 7.2493 8.0148 11.073 9.8063-0.047116 0.095501-0.098613 0.20586-0.14941 0.31374-1.03 1.4641-1.86 3.4093-2.4327 6.0156-0.083055 0.38016-0.98225 10.129-1.2815 16.857-0.071563 1.5813 1.1553 2.925 2.739 2.9963 0.04375 0.002476 0.087437 0.002476 0.1315 0.002476 1.5254 0.000637 2.7954-1.203 2.8667-2.7408 0.19722-4.4343-0.28803-8.0923 0.2846-11.084 0.029781 0.014286 0.059883 0.028507 0.090231 0.04343 0.36835 3.1017 1.5633 7.1621 4.434 12.307 0.5243 0.94257 1.5016 1.474 2.5093 1.474 0.47249 0 0.95247-0.11785 1.3949-0.36403 1.3856-0.77201 1.8817-2.5207 1.1103-3.9041-1.7468-3.1352-2.7154-5.6621-3.2518-7.6725 0.38823 0.04464 0.75779 0.075626 1.0771 0.075626h18.357c-0.063881 0.64616-0.11227 1.3246-0.11379 2.0618-0.001526 2.5442 0.47776 5.6106 2.0925 8.6597 0.51592 0.97292 1.5106 1.5273 2.5406 1.5273 0.453 0 0.91278-0.10788 1.3416-0.33362 1.4004-0.74229 1.9338-2.481 1.1915-3.8825-1.0839-2.0451-1.4237-4.1286-1.4253-5.9709 0-1.425 0.21082-2.685 0.4201-3.5612 0.107-0.436 0.208-0.778 0.279-0.997z" />
        <path d="m65.017 17.641c-2.2903-0.43655-5.7837-7.639-5.322-6.8713 0.4617 0.76769-1.5288-2.592 2.6841-4.4176 1.1977-0.51966-0.83494-1.8677-3.0955-1.5354-2.6884 0.39566-3.6037 1.9608-4.0948 4.0803-4.7931 0.12154-7.6095 5.85-8.6631 8.5189 0.32182 0.43096 0.7094 0.92644 1.1853 1.4883 1.2969 1.5304 3.1957 3.485 5.7778 5.5418 1.5596-0.96923 3.3572-1.8522 4.5379-1.691 0.94009 1.6445 5.5579 2.4401 8.6839 0.22638 2.954-2.093 1.042-4.819-1.694-5.34z" />
      </g>
    </svg>
  );
}

export default DogPark;
