/**
*
* CommunityCenter
*
*/

import React from 'react';

function CommunityCenter() {
  return (
    <svg version="1.1" x="0px" y="0px" viewBox="0 0 63.333332 54">
      <path
        fill="#01818E"
        d="M58.683617,47.335625V13.759312L36.147366,2.164577v24.778675H4.945309v20.392374H2.908083v3.972431
        h11.712674V41.092262h5.617927v10.215794h1.560553V41.092262h5.618073v10.215794h1.352459V41.092262h5.617928v10.215794h27.278973
        v-3.972431H58.683617z M47.014355,41.291382h-5.994026v-4.670513h5.994026V41.291382z M47.014355,34.453548h-5.994026v-4.670509
        h5.994026V34.453548z M47.014355,27.615864h-5.994026v-4.670513h5.994026V27.615864z M47.014355,20.778175h-5.994026v-4.670511
        h5.994026V20.778175z M55.19278,41.291382h-5.994026v-4.670513h5.994026V41.291382z M55.19278,34.453548h-5.994026v-4.670509
        h5.994026V34.453548z M55.19278,27.615864h-5.994026v-4.670513h5.994026V27.615864z M55.19278,20.778175h-5.994026v-4.670511
        h5.994026V20.778175z"
      />
    </svg>
  );
}

export default CommunityCenter;
