/**
*
* Safety
*
*/

import React from 'react';

function Safety(props) {
  const { x, y, height, width } = props;

  return (
    <svg x={x} y={y} height={height} width={width} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 216 216" enableBackground="new 0 0 216 216" xmlSpace="preserve">
      <circle fill="#FFFFFF" stroke="#E36726" strokeWidth="4" strokeMiterlimit="10" cx="108.5" cy="108.5" r="98.5" />
      <g>
        <path fill="#E36726" d="M79.9,148.9c-0.3-0.3-0.6-0.6-1-0.8s-0.8-0.3-1.2-0.3c-0.2,0-0.4,0-0.6,0.1c-0.2,0-0.4,0.1-0.6,0.2 c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.2,0.4-0.2,0.6c0,0.2,0,0.4,0.1,0.5c0.1,0.1,0.2,0.3,0.4,0.4c0.2,0.1,0.4,0.2,0.6,0.3 c0.2,0.1,0.5,0.2,0.8,0.3c0.4,0.1,0.9,0.3,1.3,0.5s0.9,0.4,1.2,0.7s0.7,0.6,0.9,1s0.4,0.9,0.4,1.5c0,0.7-0.1,1.3-0.4,1.8 c-0.3,0.5-0.6,0.9-1.1,1.3c-0.4,0.3-1,0.6-1.5,0.8c-0.6,0.2-1.2,0.2-1.8,0.2c-0.9,0-1.7-0.2-2.6-0.5c-0.8-0.3-1.5-0.7-2.1-1.3l2-2 c0.3,0.4,0.7,0.7,1.2,0.9c0.5,0.3,1,0.4,1.5,0.4c0.2,0,0.4,0,0.6-0.1s0.4-0.1,0.6-0.2c0.2-0.1,0.3-0.2,0.4-0.4 c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.2-0.3-0.3-0.5-0.4c-0.2-0.1-0.5-0.3-0.8-0.4c-0.3-0.1-0.7-0.2-1.1-0.4 c-0.4-0.1-0.8-0.3-1.2-0.5s-0.7-0.4-1-0.7s-0.5-0.6-0.7-1c-0.2-0.4-0.3-0.9-0.3-1.4c0-0.7,0.1-1.3,0.4-1.8c0.3-0.5,0.6-0.9,1.1-1.2 c0.5-0.3,1-0.5,1.5-0.7c0.6-0.1,1.1-0.2,1.7-0.2c0.7,0,1.4,0.1,2.1,0.4c0.7,0.3,1.4,0.6,1.9,1.1L79.9,148.9z" />
        <path fill="#E36726" d="M93,158.1l-1-2.4h-4.8l-0.9,2.4h-3.3l5.2-12.4h2.9l5.1,12.4H93z M89.7,149l-1.6,4.3h3.1L89.7,149z" />
        <path fill="#E36726" d="M101.2,148.2v2.6h4.8v2.5h-4.8v4.8h-3v-12.4h8.2v2.6H101.2z" />
        <path fill="#E36726" d="M109.2,158.1v-12.4h8.3v2.5h-5.4v2.3h5.1v2.4h-5.1v2.6h5.8v2.6H109.2z" />
        <path fill="#E36726" d="M126,148.2v9.8h-3v-9.8h-3.5v-2.6h10v2.6H126z" />
        <path fill="#E36726" d="M137.6,152.8v5.2h-3v-5.2l-4.7-7.1h3.6l2.6,4.6l2.6-4.6h3.5L137.6,152.8z" />
      </g>
      <g>
        <path fill="#E36726" d="M142.8,74.9h-18.4v-4.2c0-3.5-2.9-6.4-6.4-6.4h-18c-3.5,0-6.4,2.9-6.4,6.4v4.2H75.8c-1.9,0-3.5,1.6-3.5,3.5 V121c0,1.9,1.6,3.5,3.5,3.5h67c1.9,0,3.5-1.6,3.5-3.5V78.4C146.3,76.5,144.7,74.9,142.8,74.9z M98.7,70.8c0-0.7,0.6-1.3,1.3-1.3h18 c0.7,0,1.3,0.6,1.3,1.3v4.2H98.7V70.8z" />
        <polygon fill="#FFFFFF" points="122.6,95 114,95 114,86.4 104.6,86.4 104.6,95 96,95 96,104.4 104.6,104.4 104.6,113 114,113 114,104.4 122.6,104.4" />
      </g>
    </svg>
  );
}



export default Safety;
