import React from 'react';

import NavLink from './NavLink';
import Button from 'components/Button';
import ButtonWithSocialLinks from 'components/ButtonWithSocialLinks';

import Browser from 'utils/browserType';

class ReportNav extends React.Component {
  onPrintClicked = () => {
    if (Browser.Firefox === true) {
      window.print();
    } else {
      try {
        window.document.execCommand('print', false, null);
      } catch (e) {
        window.print();
      }
    }
  }

  onShareClicked = () => {
    // TODO: handle this.
    // const { dispatch } = this.props;
    // dispatch(displayModal('share'));
  }

  onEnableScoresClicked = () => {
    // TODO: handle this.
    // const { dispatch } = this.props;
    // dispatch(setScoresSearchOption(true));
  }

  onDisableScoresClicked = () => {
    // TODO: handle this.
    // const { dispatch } = this.props;
    // dispatch(setScoresSearchOption(false));
  }

  render() {
    return (
      <div>
        {this.props.packageContents.includes('address') && 
          <li><NavLink to={`${window.location.origin}/package/${this.props.packageID}/highlights`}>One-Page Address Report</NavLink></li>
        }
        {this.props.packageContents.includes('detailed') &&
          (
            <li>
              <NavLink to={`/package/${this.props.packageID}/detailed`}>Detailed Address Report</NavLink>
              {this.props.currentUser && this.props.reportType == 'detailed' && <div>
                <label htmlFor="enableScores">
                  <input name="enableScores" type="checkbox" checked={this.props.scoresEnabled} onChange={this.onEnableScoresClicked} />
                  With Scores
                </label>

                <label htmlFor="disableScores">
                  <input name="disableScores" type="checkbox" checked={!this.props.scoresEnabled} onChange={this.onDisableScoresClicked} />
                  Without Scores
                </label>
              </div>}
            </li>
          )
        }
        {this.props.packageContents.includes('school') &&
          <li><NavLink to={`/package/${this.props.packageID}/highlights/schools`}>One-Page School Report</NavLink></li>
        }
        <hr/>
        {window.innerWidth > 430 && <li><Button onClick={this.onPrintClicked}>Save & Print</Button></li>}
        <li>
          <ButtonWithSocialLinks onClick={this.onShareClicked} displaySocialButtons={true} />
        </li>
        {this.props.currentUser && <li>
          <NavLink to={`/package/${this.props.packageID}/highlights/embeds/concrete`}>Website Buttons</NavLink>
        </li>}
        <hr/>
      </div>
    );
  }
}

// const mapStateToProps = createStructuredSelector({
//   currentUser: selectCurrentUser(),
//   scoresEnabled: selectSearchOptions()
// });

export default ReportNav;