import React from 'react';
import {CardElement, Elements, ElementsConsumer} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {Button, Form} from 'react-bootstrap';
import * as yup from 'yup';
import {Formik} from 'formik';

import PlanButton from './PlanButton';
import FeedbackFormField from './FeedbackFormField';
import HoodQLogo from './HoodQLogo';

import humanizedMoney from '../utils/humanized-money';

import {Affiliate, Coupon, PLAN_PRICES, PROVINCES, SubscriptionPlan} from './types';

declare global {
  interface Window { 
    routes:    any;
    rewardful: any;
    Rewardful: any;
  }
}

interface UserRegistration {
  email:                 string;
  password:              string;
  password_confirmation: string;
  stripe_token?:         string;
  plan:                  string;
  province?:             string;
  referral_id?:          string;
  payment_card?:         boolean;
}

interface IProps {
  stripePublicKey: string;
  csrfToken:       string;
  selectedPlan?:   string;
  coupon?:         string;
}

interface IElementsConsumerProps {
  elements: any;
  stripe:   any;
}

interface IState {
  selectedPlan:  SubscriptionPlan;
  coupon?:       Coupon;
  affiliate?:    Affiliate;
  user:          UserRegistration;
  prices: {
    [SubscriptionPlan.MONTHLY]: number;
    [SubscriptionPlan.YEARLY]:  number;
  };
}


function priceWithCoupon(price, coupon) {
  if (!coupon) return price;
  return price - price * (coupon.percent_off / 100);
}

class SignUpForm extends React.Component<IProps & IElementsConsumerProps, IState> {

  formSchema = yup.object({
    email:                 yup.string()
                            .email("Please enter a valid email address.")
                            .required("Please enter an email address."),
    password:              yup.string()
                            .required("Please enter a password.")
                            .min(6, "Please enter a password with at least 6 characters."),
    password_confirmation: yup.string()
                            .required("Please confirm your password.")
                            .min(6, "Please enter a password with at least 6 characters.")
                            .oneOf([yup.ref('password'), null], "Please ensure your passwords match."),
    stripe_coupon:         yup.string().optional(),
    plan:                  yup.string().required(),
    province:              yup.string().oneOf(Object.keys(PROVINCES)),
    referral_id:           yup.string().optional()
  });

  constructor(props) {
    super(props);
    this.state = {
      selectedPlan:  props.selectedPlan || SubscriptionPlan.MONTHLY,
      coupon:        props.coupon,
      user:          {
        email:                 '',
        password:              '',
        password_confirmation: '',
        plan:                  props.selectedPlan || SubscriptionPlan.MONTHLY,
        payment_card:          true,
      },
      affiliate:     null,
      prices: {
        [SubscriptionPlan.MONTHLY]: priceWithCoupon(PLAN_PRICES[SubscriptionPlan.MONTHLY], props.coupon),
        [SubscriptionPlan.YEARLY]:  priceWithCoupon(PLAN_PRICES[SubscriptionPlan.YEARLY], props.coupon)
      }
    };
  }

  componentDidMount() {
    window.rewardful('ready', () => {
      if (!window.Rewardful.coupon || !window.Rewardful.affiliate) return;
      this.setState({
        coupon:    window.Rewardful.coupon,
        affiliate: window.Rewardful.affiliate,
        prices: {
          [SubscriptionPlan.MONTHLY]: priceWithCoupon(PLAN_PRICES[SubscriptionPlan.MONTHLY], window.Rewardful.coupon),
          [SubscriptionPlan.YEARLY]:  priceWithCoupon(PLAN_PRICES[SubscriptionPlan.YEARLY], window.Rewardful.coupon)
        }
      });
    });
  }

  handleStripeChange = (formikProps, event) => {
    formikProps.setFieldValue('payment_card', event.empty);
    formikProps.setFieldError('payment_card', event.error && event.error.message);
  }

  handlePlanSelection = (selectedPlan) => {
    return () => {
      this.setState({ selectedPlan });
    }
  }

  paymentRequired = () => this.state.selectedPlan != SubscriptionPlan.MARKETING_CENTRE

  handleSubmit = async (values) => {
    const { stripe, elements } = this.props;
    let payload;

    if (this.paymentRequired()) {
      if (!stripe || !elements) {
        return;
      }

      const cardElement = elements.getElement(CardElement);
      payload = await stripe.createToken(cardElement);
    } else {
      payload = { token: { id: null } };
    }

    // TODO: handle stripe error.

    const response = await fetch(window.routes.users_path, {
      method: "POST",
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({
        ...values,
        plan: this.state.selectedPlan,
        stripe_token: payload.token.id,
        stripe_coupon: this.state.coupon && this.state.coupon.id,
        authenticity_token: this.props.csrfToken
      })
    });

    // TODO: handle signup error
    if (response.ok) {
      const responsePayload = await response.json();
      window.location.href = responsePayload.next;
    }
  }

  renderPlanButtons = (props={}) => {
    const {
      selectedPlan,
      prices,
      coupon,
      affiliate
    } = this.state;

    if (!this.paymentRequired()) return;

    return (
      <>
        <PlanButton
          {...props}
          selected={selectedPlan == SubscriptionPlan.MONTHLY}
          plan={SubscriptionPlan.MONTHLY}
          billedAmount={prices[SubscriptionPlan.MONTHLY]}
          isDiscounted={coupon !== null}
          onClick={this.handlePlanSelection(SubscriptionPlan.MONTHLY)}
        >
          <ul className="benefits">
            {affiliate && <li>{coupon.percent_off}% off for {affiliate.first_name} customers!</li>}
            <li>Unlimited reports</li>
            <li>Billed {humanizedMoney(prices[SubscriptionPlan.MONTHLY])} + tax at sign-up</li>
            <li>Cancel any time</li>
            <li>Automatic monthly renewal</li>
          </ul>
        </PlanButton>
        <PlanButton
          {...props}
          selected={selectedPlan == SubscriptionPlan.YEARLY}
          plan={SubscriptionPlan.YEARLY}
          billedAmount={prices[SubscriptionPlan.YEARLY]}
          isDiscounted={coupon !== null}
          onClick={this.handlePlanSelection(SubscriptionPlan.YEARLY)}
        >
          <ul className="benefits">
            {affiliate && <li>{coupon.percent_off}% off for {affiliate.first_name} customers!</li>}
            <li>Unlimited reports</li>
            <li>Billed {humanizedMoney(prices[SubscriptionPlan.YEARLY])} + tax at sign-up</li>
            <li>Cancel after 1 year</li>
            <li>Automatic yearly renewal</li>
          </ul>
        </PlanButton>
      </>
    )
  }

  renderPaymentField = (props) => {
    if (!this.paymentRequired()) return;

    return (
      <>
        <CardElement
          onChange={(e) => this.handleStripeChange(props, e)}
        />
        <FeedbackFormField
          type="hidden"
          name="payment_card"
          value={props.values.payment_card.empty}
          touched={props.touched.payment_card}
          errors={props.errors.payment_card}
        />
        <div className="secure">
          <i className="fa fa-lock" />
          <span>Secure payments processed by <a href="https://www.stripe.com/" target="_blank">Stripe</a>.</span>
        </div>
      </>
    )


  }

  render() {
    const { 
      selectedPlan,
      prices
    } = this.state;

    const { stripe, elements } = this.props;

    return (
      <div className={`row${!this.paymentRequired() ? ' no-plans' : ''}`}>
        <div className="form-container">
          <HoodQLogo />
          <h2 className="text-primary">Create Your Account</h2>
          <h5>Instant access to unlimited detailed reports for your listing presentations, buyer tours, and more.</h5>

          <p className="existing-user">
            Already have an account? <a href={window.routes.signin_path}>Sign in</a>.
          </p>

          <div className="plan-container mobile-only">
            {this.renderPlanButtons({ collapse: true })}
          </div>

          <Formik
            validationSchema={this.formSchema}
            onSubmit={this.handleSubmit}
            initialValues={ { ...this.state.user, plan: selectedPlan } }
          >
            {props => (
              <Form onSubmit={props.handleSubmit}>
                <FeedbackFormField
                  type="email"
                  name="email"
                  disabled={props.isSubmitting}
                  value={props.values.email}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  touched={props.touched.email}
                  errors={props.errors.email}
                  placeholder="Email"
                />
                <FeedbackFormField
                    type="password"
                    name="password"
                    disabled={props.isSubmitting}
                    value={props.values.password}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    touched={props.touched.password}
                    errors={props.errors.password}
                    placeholder="Your new password"
                  />
                <FeedbackFormField
                    type="password"
                    name="password_confirmation"
                    disabled={props.isSubmitting}
                    value={props.values.password_confirmation}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    touched={props.touched.password_confirmation}
                    errors={props.errors.password_confirmation}
                    placeholder="Confirm your password"
                  />
                {
                  this.paymentRequired() && <FeedbackFormField
                    type="select"
                    name="province"
                    disabled={props.isSubmitting}
                    value={props.values.province}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    touched={props.touched.province}
                    errors={props.errors.province}
                    placeholder="Select your province"
                    options={Object.keys(PROVINCES).map(key => [PROVINCES[key], key])}
                  />
                }

                {
                  this.state.coupon &&
                    <Form.Control
                      type="hidden"
                      name="stripe_coupon"
                      value={this.state.coupon.id}
                    />
                }
                <Form.Control
                  type="hidden"
                  name="plan"
                  value={props.values.plan}
                />
                {this.renderPaymentField(props)}
                <Button type="submit" disabled={!stripe || !elements || props.isSubmitting}>
                  {props.isSubmitting && <i className="fas fa-spinner fa-spin mr-3" />}
                  Get Started
                </Button>
                <div className="terms">
                  By creating an account, you agree to our <a href="https://hoodq.com/terms-conditions">Terms and Conditions</a>
                  {this.paymentRequired() && ` and will be billed ${humanizedMoney(prices[selectedPlan])} + tax upon signup.`}
                </div>
              </Form>
            )}
          </Formik>
        </div>

        <div className="plan-container">
          {this.renderPlanButtons()}
        </div>
      </div>
    )
  }


}

export default class InjectedSignUpForm extends React.Component<IProps> {
  stripePromise: any;

  constructor(props) {
    super(props);
    this.stripePromise = loadStripe(props.stripePublicKey);
  }

  render() {
    return (
      <Elements stripe={this.stripePromise}>
        <ElementsConsumer>
          {({ elements, stripe }) => (
            <SignUpForm 
              {...this.props}
              elements={elements}
              stripe={stripe}
            />
          )}
        </ElementsConsumer>
      </Elements>
    );
  }
}