/**
*
* Rink
*
*/

import React from 'react';

function Rink() {
  return (
    <svg version="1.1" y="0px" x="0px" viewBox="0 0 72 72">
      <g fill="#01818E">
        <path d="m70.206 66.639s-39.161 5.3195-67.569-0.11318c0.79227-2.49 1.9241-4.3009 1.9241-4.3009l3.8482-9.2809s12.563 1.9241 15.393 2.0373c-1.245 3.8482-1.4714 6.1118-1.4714 6.1118s17.656 1.0186 18.335 0.67909c0.67909-0.33955 1.245-2.7164 1.0186-3.8482 1.9241 0.33954 9.1677 0.67909 9.1677 0.67909s-1.3582 2.3768-1.3582 3.1691 11.205 0.56591 10.413-1.6977-0.67909-1.8109-0.67909-1.8109l7.1304-0.56591 3.847 8.942z" />
        <path d="m7.0355 30.022c-0.7903 6.047-1.1841 12.734-0.73 19.611 2.2117 0.47399 8.5891 1.536 15.54 2.6388 2.467-17.177-13.469-21.886-14.81-22.25z" />
        <path d="m66.178 42.729c-5.2064-0.67907-7.3568-0.56594-7.3568-0.56594s-0.45274-3.8482-7.0172-5.3195c-6.5645-1.4713-19.128-2.7164-17.996-15.845l0.11312-0.45265-1.5845 0.11312 0.22641-3.5086 3.6218-9.2809s-4.7536-2.3768-7.47 1.5845-3.3955 5.7722-3.3955 5.7722l-5.9986-1.1318s-0.56594-9.5073-1.3582-10.865c-0.79227-1.3582-2.0373-3.5086-4.5272 0.67907 0 0-4.0398 10.082-6.118 24.091 0.84586 0.23243 19.3 5.4603 16.504 24.585 7.4148 1.1666 15.092 2.3214 18.815 2.8215 7.5832 1.0186 22.41 0.56586 26.711-1.0187 0.568-1.245 2.039-10.979-3.168-11.658zm-38.311-18.392c0-1.0939 0.88673-1.9807 1.9807-1.9807 1.0939 0 1.9807 0.88681 1.9807 1.9807s-0.88681 1.9807-1.9807 1.9807c-1.095 0-1.981-0.887-1.981-1.981zm1.471 5.207c0-1.094 0.88681-1.9807 1.9807-1.9807s1.9807 0.88673 1.9807 1.9807c0 1.0939-0.88681 1.9807-1.9807 1.9807s-1.981-0.888-1.981-1.981zm5.036 5.942c-1.0939 0-1.9807-0.88673-1.9807-1.9806 0-1.094 0.88681-1.9807 1.9807-1.9807 1.094 0 1.9807 0.88673 1.9807 1.9807s-0.887 1.981-1.981 1.981zm4.415 2.942c-1.0939 0-1.9807-0.88681-1.9807-1.9807s0.88681-1.9807 1.9807-1.9807 1.9807 0.88681 1.9807 1.9807-0.888 1.981-1.981 1.981zm4.98 1.924c-1.0939 0-1.9807-0.88681-1.9807-1.9807s0.88681-1.9807 1.9807-1.9807c1.094 0 1.9807 0.88681 1.9807 1.9807s-0.887 1.981-1.981 1.981zm5.206 1.585c-1.0939 0-1.9807-0.88681-1.9807-1.9807s0.88681-1.9807 1.9807-1.9807 1.9807 0.88681 1.9807 1.9807-0.887 1.981-1.981 1.981zm5.093 1.584c-1.0939 0-1.9807-0.88681-1.9807-1.9807s0.88681-1.9807 1.9807-1.9807 1.9807 0.88681 1.9807 1.9807-0.887 1.981-1.981 1.981z" />
      </g>
    </svg>
  );
}

export default Rink;
