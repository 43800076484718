/**
*
* Pharmacies
*
*/

import React from 'react';

function Pharmacies() {
  return (
    <svg version="1.1" viewBox="0 0 72 72">
      <path d="m36.312 54.802c17.7 0 32.048-14.349 32.048-32.048h-64.097c0 17.7 14.348 32.048 32.049 32.048z" fill="#ECB01F" />
      <path d="m36.311 55.718c-7.7268 0-13.99 6.2639-13.99 13.991h27.981c0-7.727-6.264-13.991-13.991-13.991z" fill="#ECB01F" />
      <path d="m52.152 18.77l8.9445-4.2162c3.3212-1.9686 4.4167-6.2551 2.4481-9.5744-1.9686-3.3189-6.2551-4.4135-9.5764-2.4449l-23.795 16.235h21.979z" fill="#ECB01F" />
    </svg>
  );
}

export default Pharmacies;
