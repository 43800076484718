/*
 * PeriodHomeConstruction Messages
 *
 * This contains all the text for the PeriodHomeConstruction component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.PeriodHomeConstruction.header',
    defaultMessage: 'Period Of Home Construction',
  },
});
