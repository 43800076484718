import React from 'react';
// import Helmet from 'react-helmet';
import getBranding from 'components/branding';

const openGraphTags = require("open-graph-meta/tags");

export default function ReportHelmet(props) {
  const branding = getBranding(props.branding);
  const title = `${branding.brandName} | ${props.reportTitle} | ${props.reportName}`;

  return null;
}