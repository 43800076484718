import React from 'react';

import Button from 'components/Button';

export default function ContactUs(props) {
  return (
    <li>
      <Button href="https://hoodq.com/main-support">Contact Us</Button>
    </li>    
  );
}