/*
 * Households Messages
 *
 * This contains all the text for the Households component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.Households.header',
    defaultMessage: 'Households',
  },
});
