/**
*
* HomePin
*
*/

import React from 'react';

function HomePin() {
  return (
    <svg enableBackground="new 0 0 62.5 85.25" viewBox="0 0 62.5 85.25" y="0px" x="0px">
      <path d="m31.353 3.009c-15.674 0-28.426 12.753-28.426 28.427 0 4.3548 1.0097 8.6804 2.9295 12.572-0.005151 0.34086 23.726 37.466 23.726 37.466 0.32111 0.50141 0.87576 0.80535 1.4699 0.80793h0.008587c0.59243 0 1.1454-0.29879 1.4699-0.7942l22.311-34.045 0.25071-0.38636c3.0669-4.6527 4.6879-10.054 4.6879-15.621 0-15.674-12.753-28.427-28.428-28.427z" fill="#00AEEF" />
      <path d="m31.061 84.041h-0.008587c-1.2049-0.00573-2.3046-0.6096-2.95-1.617-18.81-29.427-22.886-35.898-23.764-37.666l-0.057955 0.028618c-2.0349-4.1255-3.111-8.7419-3.111-13.35-0.0004-16.646 13.54-30.187 30.184-30.187s30.187 13.541 30.187 30.187c0 5.9128-1.7215 11.65-4.9784 16.588l-0.24327 0.37492-22.316 34.053c-0.654 0.994-1.753 1.588-2.942 1.588zm-23.574-40.703c1.0732 1.903 14.596 23.139 23.574 37.186l22.557-34.422c2.8842-4.376 4.4046-9.4416 4.4046-14.665 0-14.706-11.964-26.669-26.669-26.669s-26.667 11.963-26.667 26.669c0 4.0712 0.95017 8.1495 2.7475 11.793l0.0526 0.108z" fill="#C4D32E" />
      <polygon points="47.019 22.799 31.354 13.336 15.689 22.976 18.89 22.958 18.89 48.397 43.65 48.397 43.65 22.818" fill="#fff" />
      <rect y="15.268" x="37.923" height="4.5322" width="3.1377" fill="#fff" />
      <rect y="25.378" x="24.791" height="4.8809" width="3.7188" fill="#00AEEF" />
      <rect y="25.378" x="34.553" height="4.8809" width="3.7188" fill="#00AEEF" />
      <rect y="40.137" x="22.699" height="8.3672" width="5.1133" fill="#00AEEF" />
      <rect y="38.975" x="30.834" height="5.3457" width="8.832" fill="#00AEEF" />
    </svg>
  );
}

export default HomePin;
