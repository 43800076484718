/*
 * TimeLengthDistance Messages
 *
 * This contains all the text for the TimeLengthDistance component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  distance: {
    id: 'app.components.TimeLengthDistance.distance',
    defaultMessage: 'about a {walkTime} minute walk - {distanceWithUnits} away',
  },
  distanceNoWalktime: {
    id: 'app.components.TimeLengthDistance.distanceNoWalktime',
    defaultMessage: '{distanceWithUnits} away',
  },
});
