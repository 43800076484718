/**
*
* Transit
*
*/

import React from 'react';

function Transit(props) {
  const { x, y, height, width } = props;

  return (
    <svg x={x} y={y} height={height} width={width} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 216 216" enableBackground="new 0 0 216 216" xmlSpace="preserve">
      <circle fill="#FFFFFF" stroke="#6B6CB2" strokeWidth="4" strokeMiterlimit="10" cx="108.5" cy="108.5" r="98.5" />
      <g>
        <g>
          <path fill="#6B6CB2" d="M143.4,80.9c0,0-0.9-7.2-1.5-12.2c-0.7-5-2.9-7-9.8-8.9c-6.3-1.7-13.4-1.7-14.5-1.7l0-0.2c0,0-7.4,0-14.2,1.9 c-6.8,1.9-9.1,3.9-9.8,8.9s-1.5,12.2-1.5,12.2s-0.7,6.2-0.5,10.3s0.2,22.4,0.2,22.4H96v5c0,1.9,1.6,3.5,3.5,3.5 c1.9,0,3.5-1.6,3.5-3.5v-5h13.9h1.7h13.7v5c0,1.9,1.6,3.5,3.5,3.5s3.5-1.6,3.5-3.5v-5h4.5c0,0,0-18.3,0.2-22.4 S143.4,80.9,143.4,80.9z" />
          <path fill="#FFFFFF" d="M136.1,89.2l-36.7-0.3c-2,0-3.7-1.7-3.7-3.7l2.4-13.3c0-2,1.7-3.7,3.7-3.7h31.9c2,0,3.7,1.7,3.7,3.7l2.4,13.6 C139.8,87.6,138.2,89.2,136.1,89.2z" />
          <path fill="#FFFFFF" d="M129.6,65.7h-23.9c-0.2,0-0.4-0.2-0.4-0.4v-2.4c0-0.2,0.2-0.4,0.4-0.4h23.9c0.2,0,0.4,0.2,0.4,0.4v2.4 C130.1,65.5,129.9,65.7,129.6,65.7z" />
          <circle fill="#FFFFFF" cx="99.3" cy="102" r="3.7" />
          <circle fill="#FFFFFF" cx="135.8" cy="102" r="3.7" />
          <rect x="71" y="90.1" fill="#6B6CB2" width="2.7" height="25.7" />
          <g>
            <polygon fill="#6B6CB2" points="152.2,124.7 78.9,124.7 78.9,117.5 64.2,117.5 64.2,113.4 83,113.4 83,120.6 152.2,120.6" />
          </g>
          <circle fill="#6B6CB2" cx="72.7" cy="83.6" r="8.4" />
        </g>
      </g>
      <g>
        <path fill="#6B6CB2" d="M75.8,148v9.8h-3V148h-3.5v-2.6h10v2.6H75.8z" />
        <path fill="#6B6CB2" d="M88.2,157.8l-2.7-4.9h-1v4.9h-2.9v-12.4h4.7c0.6,0,1.2,0.1,1.7,0.2c0.6,0.1,1.1,0.3,1.5,0.6 c0.4,0.3,0.8,0.7,1.1,1.2c0.3,0.5,0.4,1.1,0.4,1.8c0,0.8-0.2,1.5-0.7,2.1s-1.1,1-1.9,1.2l3.2,5.3H88.2z M88.1,149.2 c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.3-0.5-0.4c-0.2-0.1-0.4-0.2-0.7-0.2c-0.2,0-0.5-0.1-0.7-0.1h-1.6v2.9h1.4 c0.2,0,0.5,0,0.8-0.1s0.5-0.1,0.7-0.2c0.2-0.1,0.4-0.3,0.5-0.5S88.1,149.5,88.1,149.2z" />
        <path fill="#6B6CB2" d="M102.5,157.8l-1-2.4h-4.8l-0.9,2.4h-3.3l5.2-12.4h2.9l5.1,12.4H102.5z M99.1,148.7l-1.6,4.3h3.1L99.1,148.7z" />
        <path fill="#6B6CB2" d="M115.6,157.8l-5-8.1h-0.1l0.1,8.1h-2.9v-12.4h3.4l5,8.1h0.1l-0.1-8.1h2.9v12.4H115.6z" />
        <path fill="#6B6CB2" d="M128.9,148.6c-0.3-0.3-0.6-0.6-1-0.8c-0.4-0.2-0.8-0.3-1.2-0.3c-0.2,0-0.4,0-0.6,0.1c-0.2,0-0.4,0.1-0.6,0.2 c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.2,0.4-0.2,0.6c0,0.2,0,0.4,0.1,0.5c0.1,0.1,0.2,0.3,0.4,0.4c0.2,0.1,0.4,0.2,0.6,0.3 c0.2,0.1,0.5,0.2,0.8,0.3c0.4,0.1,0.9,0.3,1.3,0.5c0.5,0.2,0.9,0.4,1.2,0.7c0.4,0.3,0.7,0.6,0.9,1c0.2,0.4,0.4,0.9,0.4,1.5 c0,0.7-0.1,1.3-0.4,1.8c-0.3,0.5-0.6,0.9-1.1,1.3c-0.4,0.3-1,0.6-1.5,0.8c-0.6,0.2-1.2,0.2-1.8,0.2c-0.9,0-1.7-0.2-2.6-0.5 c-0.8-0.3-1.5-0.8-2.1-1.3l2-2c0.3,0.4,0.7,0.7,1.2,0.9s1,0.4,1.5,0.4c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.6-0.2 c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.2-0.3-0.3-0.5-0.4s-0.5-0.3-0.8-0.4 c-0.3-0.1-0.7-0.2-1.1-0.4c-0.4-0.1-0.8-0.3-1.2-0.5c-0.4-0.2-0.7-0.4-1-0.7c-0.3-0.3-0.5-0.6-0.7-1c-0.2-0.4-0.3-0.9-0.3-1.4 c0-0.7,0.1-1.3,0.4-1.8c0.3-0.5,0.6-0.9,1.1-1.2c0.5-0.3,1-0.5,1.5-0.7c0.6-0.1,1.1-0.2,1.7-0.2c0.7,0,1.4,0.1,2.1,0.4 c0.7,0.3,1.4,0.6,1.9,1.1L128.9,148.6z" />
        <path fill="#6B6CB2" d="M133.6,157.8v-12.4h3v12.4H133.6z" />
        <path fill="#6B6CB2" d="M145.3,148v9.8h-3V148h-3.5v-2.6h10v2.6H145.3z" />
      </g>
    </svg>
  );
}



export default Transit;
