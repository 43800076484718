/*
 * MapLegend Messages
 *
 * This contains all the text for the MapLegend component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  elementary: {
    id: 'app.components.MapLegend.elementary',
    defaultMessage: 'Elementary Schools',
  },
  middle: {
    id: 'app.components.MapLegend.middle',
    defaultMessage: 'Middle Schools',
  },
  high: {
    id: 'app.components.MapLegend.high',
    defaultMessage: 'High Schools',
  },
  parks: {
    id: 'app.components.MapLegend.parks',
    defaultMessage: 'Parks',
  },
  transit: {
    id: 'app.components.MapLegend.transit',
    defaultMessage: 'Transit',
  },
  safety: {
    id: 'app.components.MapLegend.safety',
    defaultMessage: 'Safety',
  },
  convenience: {
    id: 'app.components.MapLegend.convenience',
    defaultMessage: 'Convenience',
  },
  elementaryZone: {
    id: 'app.components.MapLegend.elementaryZone',
    defaultMessage: 'Elementary Attendance Zone',
  },
  middleZone: {
    id: 'app.components.MapLegend.middleZone',
    defaultMessage: 'Middle Attendance Zone',
  },
  highZone: {
    id: 'app.components.MapLegend.highZone',
    defaultMessage: 'High Attendance Zone',
  },
});
