/*
 * DetailedReportLink Messages
 *
 * This contains all the text for the DetailedReportLink component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  defaultContent: {
    id: 'app.components.DetailedReportLink.defaultContent',
    defaultMessage: 'Read more in our detailed report',
  },
});
