/**
*
* HealthCare
*
*/

import React from 'react';

function HealthCare() {
  return (
    <svg version="1.1" viewBox="0 0 69.25 69.125">
      <polygon points="67.793 23.058 46.567 23.058 46.567 1.8324 23.347 1.8324 23.347 23.058 2.1209 23.058 2.1209 46.278 23.347 46.278 23.347 67.504 46.567 67.504 46.567 46.278 67.793 46.278" fill="#E36526" />
    </svg>
  );
}

export default HealthCare;
