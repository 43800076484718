import React from 'react';
import { IntlProvider } from 'react-intl';

import { translationMessages } from './i18n';

import SchoolReport from './components/reports/SchoolReport';
import DetailedAddressReport from './components/reports/DetailedAddressReport';
// import MarketDataReport from './components/reports/MarketDataReport';
import NeighbourhoodReport from './components/reports/NeighbourhoodReport';

export default function(props) {
  let component;
  
  if (props.componentName) {
    switch (props.componentName) {
      case 'SchoolReport':
        component = <SchoolReport {...props.componentProps} />;
        break;
      case 'DetailedReport':
        component = <DetailedAddressReport {...props.componentProps} />;
        break;
      // case 'MarketDataReport':
      //   component = <MarketDataReport {...props.componentProps} />;
      //   break;
      case 'NeighbourhoodReport':
        component = <NeighbourhoodReport {...props.componentProps } />;
        break;
    }

    return (
      <IntlProvider locale='en' messages={translationMessages['en']}>
        {component}
      </IntlProvider>
    )
  }

  return null;
}