/*
 * GenericSchoolsBlock Messages
 *
 * This contains all the text for the GenericSchoolsBlock component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  publicSchoolsHeader: {
    id: 'app.components.GenericSchoolsBlock.publicSchoolsHeader',
    defaultMessage: 'Public Schools',
  },
  assignedSchoolsHeader: {
    id: 'app.components.GenericSchoolsBlock.assignedSchoolsHeader',
    defaultMessage: 'Public Schools (Assigned)',
  },
  unassignedSchoolsHeader: {
    id: 'app.components.GenericSchoolsBlock.unassignedSchoolsHeader',
    defaultMessage: 'Public Schools (Nearby)',
  },
  publicSchoolsParagraph: {
    id: 'app.components.GenericSchoolsBlock.publicSchoolsParagraph',
    defaultMessage: 'Your neighborhood is part of a community of Public Schools offering Elementary, Middle, and High School programming. See the closest Public Schools near you below:',
  },
  publicSchoolsParagraphCA: {
    id: 'app.components.GenericSchoolsBlock.publicSchoolsParagraphCA',
    defaultMessage: 'Your neighbourhood is part of a community of Public Schools offering Elementary, Middle, and High School programming. See the closest Public Schools near you below:',
  },
  // catholicSchoolsHeader: {
  //   id: 'app.components.GenericSchoolsBlock.catholicSchoolsHeader',
  //   defaultMessage: 'Catholic Schools',
  // },
  catholicAssignedSchoolsHeader: {
    id: 'app.components.GenericSchoolsBlock.catholicAssignedSchoolsHeader',
    defaultMessage: 'Catholic Schools (Assigned)',
  },
  catholicUnassignedSchoolsHeader: {
    id: 'app.components.GenericSchoolsBlock.catholicUnassignedSchoolsHeader',
    defaultMessage: 'Catholic Schools (Nearby)',
  },
  catholicSchoolsParagraph: {
    id: 'app.components.GenericSchoolsBlock.catholicSchoolsParagraph',
    defaultMessage: 'Your neighborhood is part of a community of Catholic Schools offering Elementary, Middle, and High School programming. See the closest Catholic Schools near you below:',
  },
  catholicSchoolsParagraphCA: {
    id: 'app.components.GenericSchoolsBlock.catholicSchoolsParagraphCA',
    defaultMessage: 'Your neighbourhood is part of a community of Catholic Schools offering Elementary, Middle, and High School programming. See the closest Catholic Schools near you below:',
  },
  privateSchoolsHeader: {
    id: 'app.components.GenericSchoolsBlock.privateSchoolsHeader',
    defaultMessage: 'Private Schools',
  },
  privateSchoolsParagraph: {
    id: 'app.components.GenericSchoolsBlock.privateSchoolsParagraph',
    defaultMessage: 'See a list of the closest private schools near you:',
  },
});
