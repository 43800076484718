/**
*
* XIcon
*
*/

import React from 'react';

function XIcon() {
  return (
    <svg viewBox="0 0 56.5 55.5" version="1.1" y="0px" x="0px">
      <g>
        <circle cy="27.492" cx="28.422" r="24.726" />
        <path
          strokeLinejoin="round"
          d="m25.604 26.42l-6.7559-7.6012c-0.61873-0.69614-0.12455-1.7965 0.80682-1.7965h1.612c0.31996 0 0.62344 0.14195 0.82853 0.38754l5.4988 6.5847c0.43155 0.51677 1.2257 0.51671 1.6572-0.000126l5.4969-6.5844c0.20509-0.24567 0.50861-0.38767 0.82864-0.38767h1.3589c0.93036 0 1.4249 1.0983 0.80824 1.7949l-6.6116 7.4687c-0.36103 0.40783-0.36167 1.0207-0.001472 1.4293l7.4525 8.4536c0.61454 0.69709 0.11959 1.7933-0.80972 1.7933h-1.674c-0.32157 0-0.62639-0.14337-0.83146-0.39106l-6.0794-7.3431c-0.42781-0.51673-1.2184-0.52221-1.6533-0.011465l-6.274 7.365c-0.20509 0.24084-0.5055 0.3796-0.82184 0.3796h-1.3695c-0.92969 0-1.4245-1.097-0.80917-1.7939l7.3431-8.3167c0.361-0.41 0.36-1.024-0.002-1.432z"
          stroke="#fff"
          strokeLinecap="round"
          strokeMiterlimit="10"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default XIcon;
