/**
*
* School
*
*/

import React from 'react';

function School() {
  return (
    <svg version="1.1" id="Layer_0_xA0_Image_1_" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 126.9 95" enableBackground="new 0 0 126.9 95" xmlSpace="preserve">
      <g>
        <path
          d="M84.8,0.2c1.3,0,2.5,0,3.8,0c0.2,0.1,0.5,0.2,0.7,0.3C91.9,1,94.5,1.3,97,1.9c4.8,1.2,9.2,3.4,13.4,6.1
          c0.7,0.4,0.8,0.9,0.8,1.6c0,6.5-0.1,13-0.1,19.5c0,14.9-0.1,29.9-0.1,44.8c0,0.4,0,0.7,0,1.2c-0.4-0.1-0.6-0.1-0.8-0.1
          c-1.7-0.7-3.3-1.4-5-2.1c-4.7-2-9.7-2.9-14.8-3.1c-1.3,0-2.7,0-4,0.2c-2.6,0.4-5.3,0.8-7.8,1.5c-4.8,1.4-9,3.9-12.8,7.2
          c-0.2,0.2-0.6,0.3-1,0.5c0-0.5-0.1-0.9-0.1-1.2c0-21.1,0-42.2,0-63.3c0-0.2,0-0.4,0-0.6c-0.2-1.5,0.5-2.6,1.5-3.6
          c0.1-0.1,0.2-0.3,0.4-0.4C71.2,4.6,76.9,1.3,84,0.5C84.3,0.5,84.6,0.3,84.8,0.2z"
        />
        <path
          d="M38.3,0.2c1.3,0,2.7,0,4,0c0.3,0.1,0.6,0.2,0.9,0.3c2.2,0.5,4.6,0.8,6.7,1.6c4.8,1.9,8.7,5.1,11.8,9.2
          c0.4,0.6,0.7,1.4,0.7,2.1c0,21.6,0,43.2,0,64.8c0,0.3,0,0.7-0.1,1c-0.2-0.1-0.4,0-0.4-0.1c-3-2.7-6.4-4.9-10.1-6.5
          c-3.2-1.4-6.5-2.4-9.9-2.7c-2.8-0.3-5.6-0.3-8.4,0c-2.6,0.2-5.1,0.7-7.6,1.5c-2.9,0.9-5.7,2.1-8.5,3.2c-1.2,0.5-1.2,0.5-1.2-0.7
          c-0.1-20.9-0.1-41.9-0.2-62.8c0-2.6,0-2.6,2.2-4C18.5,7,18.8,6.9,19,6.8C24,4,29.2,1.7,34.9,0.9C36,0.7,37.2,0.4,38.3,0.2z"
        />
        <path
          d="M127.1,14c0,24.7,0,49.4,0,74c-0.2,0-0.5-0.1-0.7-0.1c-14.8,0-29.5,0-44.3,0c-1,0-1.4,0.3-1.3,1.4c0.1,1.9,0,3.9,0,5.8
          c-11.7,0-23.3,0-35,0c0-1.8,0-3.6,0-5.3c0-2.1,0.3-1.8-1.8-1.8c-14.3,0-28.6,0-42.8,0c-0.3,0-0.6,0.1-0.9,0.1c0-24.7,0-49.4,0-74
          c0.4,0,0.8,0.1,1.1,0.1c2.8,0,5.6,0,8.4,0c1.2,0,1.4,0.3,1.4,1.4c0.1,20.5,0.2,41,0.2,61.4c0,3,2.7,5.3,6,3.7
          c1.4-0.7,2.8-1.4,4.2-2c5.5-2.6,11.3-3.9,17.5-3.6c2.7,0.1,5.3,0.6,7.9,1.4c5,1.6,9.3,4.3,13,8c0.4,0.4,0.7,1,1.2,1.3
          c2,1.4,3.9,1.2,5.6-0.6c2.7-2.9,5.8-5.2,9.3-6.9c5.1-2.5,10.5-3.6,16.2-3.1c3.5,0.3,7,0.9,10.3,2.3c2.7,1.1,5.3,2.4,8,3.6
          c2.6,1.1,5.4-0.7,5.5-3.5c0-2.3,0.1-4.7,0.1-7c0-17.7,0.1-35.5,0.1-53.2c0-1-0.3-2.4,0.2-2.8c0.6-0.5,1.9-0.3,2.9-0.3
          C121.9,14,124.5,14,127.1,14z"
        />
      </g>
    </svg>
  );
}

export default School;
