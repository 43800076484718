/**
*
* ConvenienceAlt
*
*/

import React from 'react';

function ConvenienceAlt(props) {
  const { x, y, height, width } = props;

  return (
    <svg x={x} y={y} height={height} width={width} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 216 216" enableBackground="new 0 0 216 216" xmlSpace="preserve">
      <circle fill="#FFFFFF" stroke="#ECB020" strokeWidth="4" strokeMiterlimit="10" cx="108.5" cy="108.5" r="98.5" />
      <g>
        <g>
          <path fill="#ECB020" d="M105.8,76.2c0,2.5-2.3,5.1-5.2,5.1v41.9c0,6-8.1,6-8.1,0V81.3c-2.8,0-5.3-2.2-5.3-5.5V52.7 c0-2,2.9-2.1,2.9,0.1v17.1h2.4V52.6c0-1.8,2.8-2,2.8,0.1v17.2h2.5V52.7c0-1.9,2.7-2,2.7,0.1v17.1h2.5V52.7c0-1.9,2.8-2,2.8,0.1 L105.8,76.2L105.8,76.2z" />
          <path fill="#ECB020" d="M127.1,57.7v65.2c0,5.8-8.1,5.7-8.1,0V97h-4.3V57.7C114.7,48.5,127.1,48.5,127.1,57.7L127.1,57.7z" />
        </g>
      </g>
      <g>
        <path fill="#ECB020" d="M49.4,156.7c-0.8,0.4-1.7,0.6-2.8,0.6c-1,0-1.8-0.2-2.7-0.5c-0.8-0.3-1.5-0.8-2.1-1.3s-1.1-1.3-1.4-2.1 c-0.3-0.8-0.5-1.7-0.5-2.7c0-1,0.2-1.9,0.5-2.7s0.8-1.5,1.4-2.1c0.6-0.6,1.3-1,2.1-1.3c0.8-0.3,1.7-0.5,2.6-0.5 c0.9,0,1.7,0.2,2.6,0.5c0.8,0.3,1.5,0.8,2.1,1.4l-2,2c-0.3-0.4-0.6-0.7-1.1-0.9s-0.9-0.3-1.4-0.3c-0.5,0-1,0.1-1.4,0.3 s-0.8,0.5-1.1,0.8c-0.3,0.3-0.6,0.7-0.7,1.2c-0.2,0.5-0.3,1-0.3,1.5c0,0.6,0.1,1.1,0.3,1.5c0.2,0.5,0.4,0.9,0.7,1.2 c0.3,0.3,0.7,0.6,1.1,0.8s0.9,0.3,1.4,0.3c0.6,0,1.1-0.1,1.6-0.3s0.8-0.5,1.1-0.9l2.1,2C50.9,155.8,50.2,156.3,49.4,156.7z" />
        <path fill="#ECB020" d="M66.3,150.7c0,1-0.2,1.9-0.5,2.7c-0.3,0.8-0.8,1.5-1.4,2.1s-1.3,1-2.1,1.3c-0.8,0.3-1.7,0.5-2.7,0.5 s-1.9-0.2-2.7-0.5c-0.8-0.3-1.5-0.8-2.1-1.3s-1.1-1.3-1.4-2.1c-0.3-0.8-0.5-1.7-0.5-2.7c0-1,0.2-1.9,0.5-2.7c0.3-0.8,0.8-1.5,1.4-2 c0.6-0.6,1.3-1,2.1-1.3c0.8-0.3,1.7-0.5,2.7-0.5s1.9,0.2,2.7,0.5c0.8,0.3,1.5,0.7,2.1,1.3c0.6,0.6,1.1,1.2,1.4,2 C66.1,148.8,66.3,149.7,66.3,150.7z M63.1,150.7c0-0.5-0.1-1-0.3-1.5s-0.4-0.9-0.7-1.2c-0.3-0.3-0.7-0.6-1.1-0.8s-0.9-0.3-1.4-0.3 c-0.5,0-1,0.1-1.4,0.3s-0.8,0.5-1.1,0.8c-0.3,0.3-0.6,0.7-0.7,1.2s-0.3,1-0.3,1.5c0,0.6,0.1,1.1,0.3,1.5s0.4,0.9,0.7,1.2 c0.3,0.3,0.7,0.6,1.1,0.8c0.4,0.2,0.9,0.3,1.4,0.3c0.5,0,1-0.1,1.4-0.3c0.4-0.2,0.8-0.5,1.1-0.8c0.3-0.3,0.6-0.7,0.7-1.2 S63.1,151.2,63.1,150.7z" />
        <path fill="#ECB020" d="M77,156.9l-5-8.1H72l0.1,8.1h-2.9v-12.4h3.4l5,8.1h0.1l-0.1-8.1h2.9v12.4H77z" />
        <path fill="#ECB020" d="M90,156.9h-3l-4.7-12.4h3.4l2.9,8.8h0.1l2.9-8.8h3.3L90,156.9z" />
        <path fill="#ECB020" d="M96.6,156.9v-12.4h8.3v2.5h-5.4v2.3h5.1v2.4h-5.1v2.6h5.8v2.6H96.6z" />
        <path fill="#ECB020" d="M116,156.9l-5-8.1H111l0.1,8.1h-2.9v-12.4h3.4l5,8.1h0.1l-0.1-8.1h2.9v12.4H116z" />
        <path fill="#ECB020" d="M122.8,156.9v-12.4h3v12.4H122.8z" />
        <path fill="#ECB020" d="M129.3,156.9v-12.4h8.3v2.5h-5.4v2.3h5.1v2.4h-5.1v2.6h5.8v2.6H129.3z" />
        <path fill="#ECB020" d="M148.6,156.9l-5-8.1h-0.1l0.1,8.1h-2.9v-12.4h3.4l5,8.1h0.1l-0.1-8.1h2.9v12.4H148.6z" />
        <path fill="#ECB020" d="M164.3,156.7c-0.8,0.4-1.7,0.6-2.8,0.6c-1,0-1.8-0.2-2.7-0.5c-0.8-0.3-1.5-0.8-2.1-1.3 c-0.6-0.6-1.1-1.3-1.4-2.1c-0.3-0.8-0.5-1.7-0.5-2.7c0-1,0.2-1.9,0.5-2.7c0.3-0.8,0.8-1.5,1.4-2.1c0.6-0.6,1.3-1,2.1-1.3 c0.8-0.3,1.7-0.5,2.6-0.5c0.9,0,1.7,0.2,2.6,0.5c0.8,0.3,1.5,0.8,2.1,1.4l-2,2c-0.3-0.4-0.6-0.7-1.1-0.9c-0.5-0.2-0.9-0.3-1.4-0.3 c-0.5,0-1,0.1-1.4,0.3s-0.8,0.5-1.1,0.8c-0.3,0.3-0.6,0.7-0.7,1.2s-0.3,1-0.3,1.5c0,0.6,0.1,1.1,0.3,1.5s0.4,0.9,0.7,1.2 c0.3,0.3,0.7,0.6,1.1,0.8c0.4,0.2,0.9,0.3,1.4,0.3c0.6,0,1.1-0.1,1.6-0.3s0.8-0.5,1.1-0.9l2.1,2 C165.8,155.8,165.1,156.3,164.3,156.7z" />
        <path fill="#ECB020" d="M168.5,156.9v-12.4h8.3v2.5h-5.4v2.3h5.1v2.4h-5.1v2.6h5.8v2.6H168.5z" />
      </g>
    </svg>
  );
}



export default ConvenienceAlt;
