/**
*
* ButtonWithSocialLinks
*
*/

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { pathnamePatternMatch } from 'utils/pathnameChecker';
import { FaEnvelope, FaFacebook, FaTwitter } from 'react-icons/lib/fa';
import classNames from 'classnames';

import styles from './styles.css';

// import {
//   selectLocationState,
// } from 'containers/App/selectors';

// import {
//   selectHighlightsUserReportCreator,
// } from 'containers/PackagePage/selectors';

import Button from '../Button';

class ButtonWithSocialLinks extends React.Component { // eslint-disable-line react/prefer-stateless-function
  trackSocialShare = (socialPlatform) => {
    // TODO : replace this with proper kissmetrics implementation
    const properties = {
      'Social Platform': socialPlatform,
    };
  };

  render() {
    const { user } = this.props;

    let fbshareUrl = `https://www.facebook.com/sharer/sharer.php?u=${window.location.host}${window.location.pathname}`;
    let twshareUrl = `https://twitter.com/home?status=Check%20out%20%40HoodQReports%20for%20this%20listing!%20${window.location.host}${window.location.pathname}`;

    const currentLocationPathname = window.location.pathname;
    const viewingHighlights = pathnamePatternMatch('highlights', currentLocationPathname);
    const viewingConsumerHigh = pathnamePatternMatch('consumer', currentLocationPathname);

    // TODO : remove duplication from PackagePage/sagas
    const freemiumAgentEmails = [
      'testhoodq+colorado@hoodq.com',
      'best@test.com', // TEMP
    ];
    const freemiumConsumerEmails = [
      'testhoodq+colorado+consumer@hoodq.com',
    ];

    const shareConsumerLink = user && (freemiumAgentEmails.includes(user.email) || freemiumConsumerEmails.includes(user.email));

    if (shareConsumerLink && viewingHighlights && !viewingConsumerHigh) {
      fbshareUrl = `${fbshareUrl}/consumer`;
      twshareUrl = `${twshareUrl}/consumer`;
    }

    let socialButtons = (
      <div className={styles.shareIconsWrapper} onClick={this.props.onClick}>
        <a className={classNames(styles.socialBtn, styles.socialBtnEmail)}>
          <FaEnvelope />
        </a>
      </div>
    );

    if (this.props.displaySocialButtons) {
      socialButtons = (
        <div className={styles.shareIconsWrapper}>
          <a className={classNames(styles.socialBtn, styles.socialBtnEmail)} onClick={this.props.onClick}>
            <FaEnvelope />
          </a>
          <a className={classNames(styles.socialBtn, styles.socialBtnFB)} href={fbshareUrl} target="_blank" onClick={() => this.trackSocialShare('facebook')}>
            <FaFacebook />
          </a>
          <a className={classNames(styles.socialBtn, styles.socialBtnTwitter)} href={twshareUrl} target="_blank" onClick={() => this.trackSocialShare('twitter')}>
            <FaTwitter />
          </a>
        </div>
      );
    }

    return (
      <div className={styles.buttonWithSocialLinks}>
        <Button onClick={this.props.onClick}>Share</Button>
        {socialButtons}
      </div>
    );
  }
}

// const mapStateToProps = createStructuredSelector({
//   currentLocation: selectLocationState(),
//   user:            selectHighlightsUserReportCreator(),
// });

export default ButtonWithSocialLinks;
