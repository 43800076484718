/**
*
* SkiingSnowboarding
*
*/

import React from 'react';

function SkiingSnowboarding() {
  return (
    <svg version="1.1" y="0px" x="0px" viewBox="0 0 60 72.5">
      <g fill="#01818E">
        <circle cy="10.419" cx="52.814" r="5.498" />
        <path d="m52.231 31.189l-7.363-2.612 0.042419-0.55768 2.5627 1.0783c0.36743 0.15411 0.79077-0.018311 0.94544-0.38611 0.15362-0.36505-0.018066-0.79175-0.3855-0.94586l-3.0071-1.2653 0.53815-7.0774c0.003052-0.040283-0.004272-0.078552-0.00293-0.11853 0.36328-0.89233 0.58209-1.8483 0.57611-2.8503-0.007324-3.2811-3.7383-5.975-8.2629-4.1341-3.078 1.2524-5.8958 2.7997-8.5602 4.5812l-17.149-13.434c-0.31396-0.2453-0.7677-0.19037-1.0137 0.12384-0.029541 0.037537-0.054749 0.077332-0.075562 0.11822-0.15332 0.30109-0.077881 0.6792 0.19873 0.89569l16.796 13.156c-0.47009 0.33368-0.94159 0.66516-1.4029 1.0139l-23.057-9.7018c-0.3677-0.1541-0.791 0.0184-0.9457 0.3862-0.0186 0.044-0.0324 0.0889-0.0418 0.1339-0.068726 0.33081 0.10346 0.67578 0.42731 0.81189l22.32 9.3914c-1.5585 1.2446-3.0798 2.5632-4.5754 3.9669-1.5782 1.4813-2.3259 3.4811-2.5789 5.365-0.3457 1.2922 0.06311 2.7244 1.1683 3.6224l13.207 10.731-8.0595 9.3751-13.622-8.608c-1.6348-1.0308-3.7959-0.54492-4.8291 1.0894-1.0322 1.6343-0.54492 3.7959 1.0889 4.8286l31.771 20.077c0.58106 0.3667 1.2275 0.5415 1.8672 0.5415 1.1602 0 2.2959-0.57715 2.9619-1.6309 1.0322-1.6343 0.54492-3.7959-1.0889-4.8286l-12.167-7.6887 9.7318-11.321c0.61621-0.7168 0.91602-1.6523 0.83203-2.5938-0.083984-0.94092-0.5459-1.8086-1.2793-2.4043l-10.292-8.36c1.0777-1.2417 2.2304-2.3755 3.4486-3.3867 2.1619-1.7726 3.6557-2.7147 4.7339-3.2748l1.7313 1.3561-0.276 3.631c-0.095276 1.2558 0.66205 2.4199 1.8491 2.841l9.3737 3.3254c0.99902 0.35425 2.0656 0.10559 2.8016-0.55676 0.33429-0.30084 0.60016-0.68683 0.76086-1.1401 0.514-1.451-0.246-3.046-1.698-3.561z" />
      </g>
    </svg>
  );
}

export default SkiingSnowboarding;
