/*
 * CancellationModal Messages
 *
 * This contains all the text for the CancellationModal component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.CancellationModal.header',
    defaultMessage: 'Are you sure you want to cancel your account?',
  },
  body: {
    id: 'app.components.CancellationModal.body',
    defaultMessage: 'We will be sad to see you go, but if you are certain, click on "Confirm Cancellation" to cancel your membership.',
  },
  cancelSubscriptionButton: {
    id: 'app.components.CancellationModal.cancelSubscriptionButton',
    defaultMessage: 'Confirm Cancellation',
  },
});
