import React from 'react';
import humanizedMoney from 'utils/humanized-money';

import { SubscriptionPlan, PLAN_PRICES } from './types';

interface IProps {
  selected:     boolean;
  plan:         SubscriptionPlan;
  billedAmount: number;
  isDiscounted: boolean;
  onClick:      () => void;
  collapse?:    boolean;
}

interface IState {
  collapsed: boolean;
}

export default class PlanButton extends React.Component<IProps, IState> {
  state: IState = {
    collapsed: true
  };

  renderPriceSpan = (price, billed) => {
    const discounted = price !== billed;
    return (
      <>
        <span className={`amount ${discounted && 'red'}`}>{humanizedMoney(price)}</span>
        {discounted && <span className="amount">{humanizedMoney(billed)}</span>}
      </>
    );
  }

  render() {
    const { 
      selected,
      plan,
      billedAmount,
      isDiscounted,
      onClick,
      collapse
    } = this.props;

    const { collapsed } = this.state;

    const monthlyAmount = plan == SubscriptionPlan.YEARLY
      ? PLAN_PRICES[plan] / 12
      : PLAN_PRICES[plan];

    const discountedMonthlyAmount = plan == SubscriptionPlan.YEARLY
      ? billedAmount / 12
      : billedAmount;

    

    return (
      <div className={`plan-card ${selected && 'active'}`} onClick={onClick}>
        <div className="price">
          {this.renderPriceSpan(monthlyAmount, discountedMonthlyAmount)}
          <span className="period">/mo + tax</span>
          <div className={`price-details ${plan != SubscriptionPlan.YEARLY && 'mb-3'}`}>
            {
              plan == SubscriptionPlan.YEARLY && <>
                  Billed {this.renderPriceSpan(PLAN_PRICES[plan], billedAmount)} annually
                </>
            }
          </div>
        </div>
        {this.props.children}
      </div>
    )
  }
}