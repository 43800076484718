import HoodQAPI from 'hoodq-shared-js/lib/hoodq-api';
import { NeighbourhoodReportData } from './types';

const PLATFORM_URL = process.env.PLATFORM_URL;
const AUTH_TOKEN = '4M8aztXFyZ3vYTbEqUN9';
const HOODQ_API_KEY = 'l_bLPUEKWi1eVhZwWNYyhASbOmAUTsTfPFaS7MdbZeM';

export function fetchNeighbourhoodReportData(reportId: string): Promise<NeighbourhoodReportData> {
  const api = new HoodQAPI({
    platformAuthToken: AUTH_TOKEN,
    platformUrl:       PLATFORM_URL
  });

  return api.reports.fetchNeighbourhoodReport(reportId) as Promise<NeighbourhoodReportData>;
}

export function fetchNeighbourhoodHighlights(neighbourhoodSlug: string) {
  const api = new HoodQAPI({
    apiKey:      HOODQ_API_KEY,
    hoodqApiUrl: 'https://api.hoodq.com/v1'
  });

  return api.fetchHighlights({ slug: neighbourhoodSlug });
}