import { NeighbourhoodMarketReportData } from './types';
import _ from 'lodash';

export function marketDataReportPresenter(reportData): NeighbourhoodMarketReportData {
  let avgSalePrices = [
    reportData.condo.avg_sale_price,
    reportData.duplex.avg_sale_price,
    reportData.row.avg_sale_price,
    reportData.single.avg_sale_price,
    reportData.semi.avg_sale_price].filter(price => price !== null);

  
  return {
    ...reportData,
    bungalow: {
      avg_current_value: (reportData.single.avg_current_value + reportData.semi.avg_current_value) / 2,
      avg_sale_price:    (reportData.single.avg_sale_price + reportData.semi.avg_sale_price) / 2,
      avg_tenure:        (reportData.single.avg_tenure + reportData.semi.avg_tenure) / 2,
      high_sale_price:   Math.max(reportData.single.high_sale_price, reportData.semi.high_sale_price),
      low_sale_price:    Math.min(reportData.single.low_sale_price, reportData.semi.low_sale_price),
      median_sale_price: (reportData.single.median_sale_price + reportData.semi.median_sale_price) / 2,
      mkt_shift: 0,
      total_count: reportData.single.total_count + reportData.semi.total_count
    },
    summary: {
      avg_sale_price: _.sum(avgSalePrices) / avgSalePrices.length,
      high_sale_price: Math.max(
        reportData.condo.high_sale_price,
        reportData.duplex.high_sale_price,
        reportData.row.high_sale_price,
        reportData.single.high_sale_price,
        reportData.semi.high_sale_price
      ),
      low_sale_price: Math.min(...[
          reportData.condo.low_sale_price,
          reportData.duplex.low_sale_price,
          reportData.row.low_sale_price,
          reportData.single.low_sale_price,
          reportData.semi.low_sale_price
      ].filter(value => value !== null)),
      total_count: ((reportData.condo.total_count || 0) +
        (reportData.duplex.total_count || 0) +
        (reportData.row.total_count || 0) +
        (reportData.single.total_count || 0) +
        (reportData.semi.total_count || 0))
    },
    household_sizes: {
      '1':  ((reportData.household_sizes['1'] || 0) / reportData.total_households) * 100,
      '2':  ((reportData.household_sizes['2'] || 0) / reportData.total_households) * 100,
      '3':  ((reportData.household_sizes['3'] || 0) / reportData.total_households) * 100,
      '4':  ((reportData.household_sizes['4'] || 0) / reportData.total_households) * 100,
      '5+': ((reportData.household_sizes['5+'] || 0) / reportData.total_households) * 100,
    }
  }
}