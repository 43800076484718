// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "../marketing_centre/index.scss";
import "bootstrap/js/dist/carousel";
import fitty from "fitty/dist/fitty.module";
import "background-blur";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// Support component names relative to this directory:



class ProductStatusLink {
  constructor({ element }) {
    this.element = element;
    this.timer = null;
    this.setInitialLinkState();
    this.tick();
    
  }

  setInitialLinkState() {
    this.originalLink = this.element.getAttribute('href');
    this.element.setAttribute('href', '');
    this.element.classList.add('text-muted');
    
    const loader = document.createElement('i');
    loader.classList.add('fa', 'fa-spinner', 'fa-spin', 'ml-2');
    this.element.appendChild(loader);
  }

  tick() {
    const url = `${this.originalLink}.json?status=1&t=${Date.now()}`

    fetch(url).then(response => {
      if (response.ok) {
        response.json().then(payload => {
          if (payload.state === 'fulfilled') {
            this.setCompletedState();
          } else if (payload.state === 'error') {
            this.setErrorState();
          } else {
            this.timer = setTimeout(this.tick.bind(this), 1000);
          }
        })
      }
    });
  }

  setErrorState() {
    const i = this.element.querySelector('i');
    i.classList.remove('fa-spin', 'fa-spinner');
    i.classList.add('fa-exclamation-triangle')
    this.element.classList.remove('text-muted');
    this.element.setAttribute('href', this.originalLink);
  }

  setCompletedState() {
    const i = this.element.querySelector('i');
    i && i.remove();
    this.element.classList.remove('text-muted');
    this.element.setAttribute('href', this.originalLink);
  }
}

class MarketingCentre {
  onPageLoad() {
    this.fitAllText();
    this.createProgressBars();
    this.blurBackgrounds();
    this.attachProductStatusLinks();
  }

  fitAllText() {
    const selectorsToFit = [
      '.agent-card.vertical .details .name',
      '.agent-card.vertical .details .email',
      '.agent-card.vertical .details .url',
    ];

    fitty('.listing-stats');

    selectorsToFit.forEach(selector => {
      let el = document.querySelector(selector);
      let fontSize = Number(window.getComputedStyle(el).getPropertyValue('font-size').replace('px', '').replace('pt', ''));
      fitty(selector, { maxSize: fontSize });
    })
  }

  createProgressBars() {
    document.querySelectorAll('.hq-progress-bar').forEach(progressBar => {
      const speed     = parseInt(progressBar.dataset.speed || 1000);
      const itemCount = progressBar.querySelectorAll('.progress-content li').length;

      const bar = new HQProgressBar({
        element:   progressBar,
        speed:     speed,
        itemCount: itemCount
      });
    });
  }

  blurBackgrounds() {
    $('.blurred').each((index, elem) => {
      $(elem).backgroundBlur({
        imageURL:     $(elem).data('photo-src'),
        imageClass:   'blurred-background',
        overlayClass: 'blurred-background-overlay',
        blurAmount:   10
      });
    });
  }

  attachProductStatusLinks() {
    document.querySelectorAll('a[data-product-status]').forEach(link => {
      new ProductStatusLink({ element: link });
    });
  }
}

let marketingCentre = new MarketingCentre();

document.fonts.ready.then(marketingCentre.fitAllText.bind(marketingCentre));
document.addEventListener('turbolinks:load', marketingCentre.onPageLoad.bind(marketingCentre));
