import React from 'react';

import classNames from 'classnames';

const styles = require('./styles.css');

export default function CustomBanner(props) {
  const { address, user } = props;

  return (
    <div className={classNames('text-center row', styles.customHeaderLayout)}>
      <img src={user.custom_header_image} className={classNames('d-xs-block d-none', styles.ownMastheadOne, styles.noPrint)} />
      <img src={user.custom_header_image} className={classNames('d-xs-none', styles.ownMastheadTwo, styles.noPrint)} />
      <img src={user.custom_header_image} className={classNames(styles.ownMastheadTwo, styles.printOnly)} />
      {address
        ? <div className={classNames('col-xs-12', styles.addressContent)}>
            <h2 className="d-xs-none">{address}</h2>
            <h4 className="d-xs-block d-none" style={{ margin: '0' }}>{address}</h4>
          </div>
        : null
      }
    </div>
  );
}