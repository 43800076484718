import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import Humanize from 'humanize-plus';
import L from 'leaflet';

import { NeighbourhoodHighlights } from 'hoodq-shared-js/lib/core/types';

import FacilityIconMapping from '../FacilityIconMapping';
import CustomHeaderLayout from '../banners/CustomHeaderLayout';

import ReportBanner from '../banners/ReportBanner';
import NeighbourhoodReportBanner from '../banners/NeighbourhoodReportBanner';
import ReportHelmet from '../ReportHelmet';

import { NeighbourhoodReportData } from './types';
import { fetchNeighbourhoodReportData, fetchNeighbourhoodHighlights } from './data';
import { getPackageContents } from '../utils';

import Navigation from 'components/navigation/Navigation';
import ReportNav from 'components/navigation/ReportNav';

import SVGIcon from '../SVGIcon';

const styles = require('./styles.css');

import 'leaflet/dist/leaflet.css';

interface IState {
  loading:    boolean;
  highlights: NeighbourhoodHighlights;
  report:     NeighbourhoodReportData;
}

interface IProps {
  hideSidebar: boolean;
}

class NeighbourhoodReport extends React.Component<IProps, IState> {
  state: IState = {
    loading:    false,
    highlights: {} as NeighbourhoodHighlights,
    report:     {} as NeighbourhoodReportData
  };
  map: L.Map;
  reportTitle = 'HoodQ Neighbourhood Highlights Report™';

  componentWillMount() {
    // const { neighbourhoodReportId } = this.props.params;
    const neighbourhoodReportId = null;

    // TODO: replace this.
    // dispatch(displayHighlightsLoader());
    // dispatch({ type: 'UPDATE_BRANDING', branding: 'schoolq' });
    this.setState({ loading: true });

    fetchNeighbourhoodReportData(neighbourhoodReportId).then(report => {
      fetchNeighbourhoodHighlights(report.address).then(highlights => {
        this.setState({ 
          highlights, 
          report: {
            ...report,
            package_contents: []
          },
          loading: false
        });
        // dispatch(hideLoader());
      })
      
    });
  }

  renderSchoolItem = (school) => {
    return <li className={styles.schoolItem}>
      <div className={classNames(styles.schoolScore, school.score ? '' : styles.schoolScoreHidden)}>{school.score}</div>
      <span>{school.name}</span>
    </li>;
  }

  renderSchools = () => {
    const { highlights } = this.state;
    const publicSchools = highlights.public_schools;
    const privateAndCatholicSchools = [...(highlights.private_schools || []), ...(highlights.catholic_schools || [])];

    return (
      <div className={styles.placesList}>
        <h5>Public</h5>
        <ul>
          {publicSchools.map(this.renderSchoolItem)}
        </ul>
        {
          privateAndCatholicSchools.length > 0 ?
            <div className={styles.secondList}>
              <h5>Catholic & Private</h5>
              <ul>
                {privateAndCatholicSchools.map(this.renderSchoolItem)}
              </ul>
            </div> : null
        }
      </div>
    );
  }

  renderParks = () => {
    const { highlights } = this.state;
    const parks = highlights.parks;
    const groupedFacilities = _.groupBy(highlights.facilities, facility => facility);

    return (
      <div className={styles.placesList}>
        <ul>
          {
            parks.map(park => {
              return (
                <li className={styles.parkItem}>
                  <SVGIcon iconType='parks' />
                  <span>{park.name}</span>
                </li>
              );
            })
          }
        </ul>
        <ul className={styles.secondList}>
          {
            Object.keys(groupedFacilities).map(k => {
              return <li key={k} className={styles.facilityItem}>
                <SVGIcon iconType={FacilityIconMapping[k] || 'parks'} />
                {groupedFacilities[k].length} {k.endsWith('s') ? k : Humanize.pluralize(groupedFacilities[k].length, k)}
              </li>
            })
          }
        </ul>
      </div>
    )
  }

  renderTransit = () => {
    const { rail_transit } = this.state.highlights;
    
    return (
      <div className={styles.placesList}>
        <ul>
          {rail_transit.map(transit => <li className={styles.transitItem}>
            <SVGIcon iconType='rail-transit' />
            <span>{transit.name}</span>
          </li>)}
        </ul>
      </div>
    )
  }

  popularConvenience = (items): [string | null, number] => {

      if (!items) return [null, 0];

      const popular: [string, number][] = _.reverse(
          _.sortBy(
              _.map(
                  _.groupBy(
                      _.filter(items, (item): boolean => !!item.major_brand),
                      (item): string => item.name
                  ),
                  (items, key: string): [string, number] => [key, items.length]
              ),
              (item: [string, number]) => item[1]
          )
      );

      if (popular.length > 0) {
          return [`${popular[0][1]} ${popular[0][0]}`, items.length - popular[0][1]];
      } else {
          return [null, items.length];
      }
  }

  renderConvenienceItem = (item: [string | null, number], descriptor: string, plural?: string) => {
      if (item[0] === null && item[1] === 0) return null;

      const iconDescriptorMap = {
        'grocery store': 'grocery',
        'coffee shop':   'coffee_shops',
        'pharmacy':      'pharmacies',
        'gym':           'gym'
      };

      return <li className={styles.convenienceItem}>
        <SVGIcon iconType={iconDescriptorMap[descriptor] || 'convenience'} />
        {item[0] === null
            ? `${item[1]} ${Humanize.pluralize(item[1], descriptor, plural)}`
            : `${item[0]}${item[1] > 0 ? ` and ${item[1]} other ${Humanize.pluralize(item[1], descriptor, plural)}` : ''}`
        }
      </li>;
  }

  renderConvenience = () => {
      const { convenience } = this.state.highlights as NeighbourhoodHighlights;

      const groupedByCategory = _.groupBy(convenience, item => item.category);

      const popularGrocery  = this.popularConvenience(groupedByCategory.grocery);
      const popularCoffee   = this.popularConvenience(groupedByCategory['coffee shops']);
      const popularPharmacy = this.popularConvenience(groupedByCategory.pharmacies);
      const popularGym      = this.popularConvenience(groupedByCategory.gyms);

      return <div className={styles.placesList}>
          <ul>
              {this.renderConvenienceItem(popularGrocery, "grocery store")}
              {this.renderConvenienceItem(popularCoffee, 'coffee shop')}
              {this.renderConvenienceItem(popularPharmacy, 'pharmacy', 'pharmacies')}
              {this.renderConvenienceItem(popularGym, 'gym')}
          </ul>
      </div>

  }

  componentDidUpdate() {
    if (!this.map && this.state.report.geom) {
      const polygon = L.geoJSON(this.state.report.geom);
      
      this.map = L.map('map-container');
      
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors'
      }).addTo(this.map);

      polygon.addTo(this.map);
      this.map.fitBounds(polygon.getBounds());
    }
  }

  render() {
    const { report, highlights } = this.state;
    const { description } = report;

    if (this.state.loading) return <div />;

    return (
      <div className={styles.neighbourhoodReport}>
        <div className="row">
          <div className="col-12">
            <div style={{ height: '200px' }} id="map-container"></div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h5>Overview</h5>
            <p>{description}</p>
          </div>
        </div>

        <div className="row">
          <div className={classNames(styles.dataSet, styles.dataSetBlue, 'col-12 col-md-4')}>
            <div className={styles.dataSetIcon}>
              <SVGIcon iconType='schools-desktop' />
            </div>
            <h3>Schools</h3>
            <p>{highlights.schools_description}</p>
            {this.renderSchools()}
          </div>

          <div className={classNames(styles.dataSet, styles.dataSetGreen, 'col-12 col-md-4')}>
            <div className={styles.dataSetIcon}>
              <SVGIcon iconType="parksRec-desktop" />
            </div>
            <h3>Parks & Rec.</h3>
            <p>{highlights.parks_description}</p>
            {this.renderParks()}
          </div>

          <div className="col-12 col-md-4">
            <div className="row">
              <div className={classNames(styles.dataSet, styles.dataSetPurple, 'col-12')}>
                <div className={styles.dataSetIcon}>
                  <SVGIcon iconType="transit-desktop" />
                </div>
                <h3>Transit</h3>
                <p>{highlights.transit_description}</p>
                {this.renderTransit()}
              </div>
              <div className={classNames(styles.dataSet, styles.dataSetOrange, 'col-12')}>
                <div className={styles.dataSetIcon}>
                  <SVGIcon iconType="convenience-desktop" />
                </div>
                <h3>Convenience</h3>
                <p>{highlights.convenience_description}</p>
                {this.renderConvenience()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}

export default NeighbourhoodReport;