/* eslint no-multi-spaces: "off" */
/* eslint react/jsx-equals-spacing: "off" */

/**
*
* Language
*
*/

import React from 'react';
import { FormattedMessage } from 'react-intl';

import messages from './messages';
// import styles from './styles.css';

import PieGraph from '../PieGraph';

const languageLabels = {
  english: 'English',
  other:   'French/Other',
};

function Language(props) {
  const {
    language,
  } = props;

  const labels = Object.keys(language).map(l => `${languageLabels[l]} ${language[l]}%`);
  const languagesData = Object.values(language);

  // TEMP commented out
  // const graphColors = [
  //   (alpha) => `rgba(0,174,239,${alpha})`, // incomeDistroTradeAreaColors
  //   (alpha) => `rgba(196,214,0,${alpha})`, // incomeDistroBenchmarkColors
  //   (alpha) => `rgba(236,176,32,${alpha})`, // incomeDistroAltColors
  // ];

  // TODO : implement more permanent solution
  const rentersOwnersColors = (alpha) => [
    `rgba(0,174,239,${alpha})`, // Owned
    `rgba(255,255,255,${alpha})`, // Rented
    `rgba(236,176,32,${alpha})`, // Rented
  ];

  const rentersOwnersColorsAlt = (alpha) => [
    `rgba(0,174,239,${alpha})`, // Owned
    `rgba(0,174,239,${alpha})`, // Rented
    `rgba(236,176,32,${alpha})`, // Rented
  ];

  const data = {
    labels,
    datasets: [
      {
        // backgroundColor:      graphColors[0](0.8), // rentersOwnersColors(0.8),
        backgroundColor:      rentersOwnersColors(0.8),
        // borderColor:          graphColors[1](0.8), // rentersOwnersColorsAlt(1),
        borderColor:          rentersOwnersColorsAlt(1),
        borderWidth:          3,
        // hoverBackgroundColor: graphColors[0](0.8), // rentersOwnersColors(1),
        hoverBackgroundColor: rentersOwnersColors(1),
        // hoverBorderColor:     graphColors[1](0.8), // rentersOwnersColorsAlt(1),
        hoverBorderColor:     rentersOwnersColorsAlt(1),
        data:                 languagesData,
      },
    ],
  };

  const graphHeight = 50;
  const graphWidth  = 125;

  const options = {
    responsive: true,
    maintainAspectRatio: true,
  };

  const legend = {
    display: false,
  };

  return (
    <div className="col-xs-12" style={{ borderBottom: 'dotted 3px gray', paddingLeft: '15px', paddingRight: '15px', marginBottom: '5px' }}>
      <h5><span><FormattedMessage {...messages.header} /></span></h5>
      <p>{languagesData[0]}% English vs. {languagesData[1]}% French/Other</p>
      <PieGraph
        data    = {data}
        height  = {graphHeight}
        width   = {graphWidth}
        options = {options}
        legend  = {legend}
      />
    </div>
  );
}

export default Language;
