/*
 * DetailedMap Messages
 *
 * This contains all the text for the DetailedMap component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.DetailedMap.header',
    defaultMessage: 'Detailed Map Header',
  },
});
