/**
*
* ParksRec
*
*/

import React from 'react';

function ParksRec(props) {
  const { x, y, height, width } = props;

  return (
    <svg x={x} y={y} height={height} width={width} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 216 216">
      <circle className="cls-1" fill="#51b848" cx="108.54452" cy="108.0484" r="99.55434" />
      <path className="cls-2" fill="#fff" d="M67.56152,89.11506a1.17413,1.17413,0,0,1-.5259-2.22435,12.15615,12.15615,0,0,1,8.58631-.63979,10.59459,10.59459,0,0,1,6.41547-7.24329,1.17454,1.17454,0,0,1,.79037,2.21212,8.06553,8.06553,0,0,0-5.09385,6.79919,1.17428,1.17428,0,0,1-1.73438.95242c-3.72254-2.0302-7.87314-.00153-7.91366.01987A1.173,1.173,0,0,1,67.56152,89.11506Z" />
      <path className="cls-2" fill="#fff" d="M142.61359,81.63977a20.57029,20.57029,0,0,0-29.82813-18.37156A13.68417,13.68417,0,0,0,93.09725,80.17942a18.22446,18.22446,0,0,0,8.503,32.50194,12.7992,12.7992,0,0,0,7.96678,8.5221v20.4847l7.56663,0.261-0.65317-20.24277a12.79035,12.79035,0,0,0,8.03328-19.64712A20.572,20.572,0,0,0,142.61359,81.63977Z" />
      <path className="cls-2" fill="#fff" d="M153.87028,110.75684A12.77076,12.77076,0,0,0,136.3296,93.32528a15.73772,15.73772,0,0,0-7.63733,30.51569,8.27109,8.27109,0,0,0,4.704,4.85727c-0.38945,3.96944-1.13014,15.07726-1.13014,15.07726l4.69637,0.52188,0.43073-15.06732a8.25688,8.25688,0,0,0,6.84849-5.48731A8.24733,8.24733,0,0,0,153.87028,110.75684Z" />
      <rect className="cls-2" fill="#fff" x="55.86066" y="127.20655" width="3.91364" height="18.78547" rx="0.42965" ry="0.42965" transform="translate(-12.50608 5.99751) rotate(-5.35943)" />
      <rect className="cls-2" fill="#fff" x="97.8133" y="123.27071" width="3.91364" height="18.78547" rx="0.42965" ry="0.42965" transform="translate(-11.95525 9.899) rotate(-5.35952)" />
      <rect className="cls-2" fill="#fff" x="57.42575" y="131.01708" width="41.74549" height="3.65273" transform="translate(-12.06606 7.89433) rotate(-5.35958)" />
      <rect className="cls-2" fill="#fff" x="61.26529" y="115.12951" width="32.09185" height="6.00091" transform="translate(-10.69605 7.73778) rotate(-5.35958)" />
      <rect className="cls-2" fill="#fff" x="61.97204" y="122.6628" width="32.09185" height="6.00091" transform="translate(-11.39662 7.83673) rotate(-5.35958)" />
      <path className="cls-3" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.34818px" d="M48.51437,147.95011s56.35642-12.52365,102.79828-.26091" />
      <circle className="cls-2" fill="#fff" cx="149.29468" cy="79.32064" r="9.97978" />
      <path className="cls-2" fill="#fff" d="M147.33633,64.84017a1.56589,1.56589,0,0,1-1.557-1.41717l-0.78273-8.21864a1.56562,1.56562,0,1,1,3.11715-.29658l0.78273,8.21864a1.56573,1.56573,0,0,1-1.41029,1.70687C147.4357,64.83788,147.386,64.84017,147.33633,64.84017Z" />
      <path className="cls-2" fill="#fff" d="M155.636,65.98827a1.56681,1.56681,0,0,1-1.43092-2.19989l3.34188-7.549a1.56565,1.56565,0,1,1,2.86338,1.26735l-3.34188,7.549A1.5662,1.5662,0,0,1,155.636,65.98827Z" />
      <path className="cls-2" fill="#fff" d="M162.5315,71.5568a1.56552,1.56552,0,0,1-.88516-2.858l6.81447-4.65968a1.56574,1.56574,0,0,1,1.76725,2.58514l-6.81447,4.65968A1.56109,1.56109,0,0,1,162.5315,71.5568Z" />
      <path className="cls-2" fill="#fff" d="M165.271,79.39631a1.56561,1.56561,0,0,1-.08867-3.12862l8.24158-.47392a1.56576,1.56576,0,1,1,.18039,3.12633l-8.24158.47392Q165.31691,79.39631,165.271,79.39631Z" />
      <path className="cls-2" fill="#fff" d="M171.23247,91.26947a1.5539,1.5539,0,0,1-.68565-0.159l-7.4191-3.62241a1.56536,1.56536,0,0,1,1.37436-2.81293l7.4191,3.62241A1.56543,1.56543,0,0,1,171.23247,91.26947Z" />
      <path className="cls-2" fill="#fff" d="M162.39314,101.31117a1.565,1.565,0,0,1-1.3262-.73075l-4.40055-6.98493a1.56573,1.56573,0,1,1,2.64935-1.66941l4.40055,6.98493A1.56533,1.56533,0,0,1,162.39314,101.31117Z" />
      <path className="cls-2" fill="#fff" d="M150.44966,105.27755a1.56527,1.56527,0,0,1-1.56393-1.51883l-0.24613-8.25228a1.56491,1.56491,0,0,1,1.51806-1.61132,1.54672,1.54672,0,0,1,1.61132,1.51806l0.24613,8.25228a1.56491,1.56491,0,0,1-1.51806,1.61132Z" />
      <path className="cls-2" fill="#fff" d="M138.16985,102.65648a1.56539,1.56539,0,0,1-1.38583-2.29086l3.82574-7.31591a1.56555,1.56555,0,1,1,2.77471,1.4508l-3.82574,7.31591A1.56622,1.56622,0,0,1,138.16985,102.65648Z" />
      <path className="cls-2" fill="#fff" d="M128.37887,93.54122a1.56547,1.56547,0,0,1-.79954-2.9123l7.10341-4.2064a1.56551,1.56551,0,1,1,1.596,2.69369l-7.10341,4.2064A1.559,1.559,0,0,1,128.37887,93.54122Z" />
      <path className="cls-2" fill="#fff" d="M133.25411,81.33326h-0.01223l-8.25533-.06268a1.56548,1.56548,0,0,1,.01223-3.13091H125.011l8.25533,0.06268A1.56548,1.56548,0,0,1,133.25411,81.33326Z" />
      <path className="cls-2" fill="#fff" d="M135.243,73.19564a1.56154,1.56154,0,0,1-.77585-0.20638l-7.16685-4.09786a1.56569,1.56569,0,1,1,1.55475-2.71815l7.16685,4.09786A1.56552,1.56552,0,0,1,135.243,73.19564Z" />
      <path className="cls-2" fill="#fff" d="M141.49262,66.90935a1.56434,1.56434,0,0,1-1.37742-.81865l-3.9381-7.25629a1.5655,1.5655,0,0,1,2.75178-1.4936l3.9381,7.25629A1.56543,1.56543,0,0,1,141.49262,66.90935Z" />
    </svg>
  );
}



export default ParksRec;
