/**
*
* DownCurvedArrow
*
*/

import React from 'react';

function DownCurvedArrow() {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 400 400" enableBackground="new 0 0 400 400" xmlSpace="preserve">
      <g transform="translate(0.000000,400.000000) scale(0.100000,-0.100000)">
        <path
          d="M375,3950l-45-12l100-37c264-100,497-254,712-469c417-417,722-1030,862-1727c30-152,70-437,70-500v-35h-367h-368l585-585
          L2509,0l583,582l582,583l-358,3l-358,2l-12,128c-27,281-100,625-190,892c-72,212-106,295-203,490c-370,741-941,1214-1578,1305
          C807,4010,534,3994,375,3950z"
        />
      </g>
    </svg>
  );
}

export default DownCurvedArrow;
