/* eslint no-multi-spaces: ["error", { exceptions: { "VariableDeclarator": true } }] */
/* eslint key-spacing: [2, { "align": "value" }] */

const Browser = {
  IE:      false,
  Firefox: false,
  Chrome:  false,
};

const userAgent  = navigator.userAgent;
const vendorName = window.navigator.vendor;
const isOpera    = window.navigator.userAgent.indexOf('OPR') > -1;
const isIEedge   = window.navigator.userAgent.indexOf('Edge') > -1;
const isChromium = window.chrome;
const isIE       = userAgent.indexOf('.NET CLR') >= 0;
const isFirefox  = userAgent.indexOf('Firefox') >= 0;
const isChrome   = isChromium !== null && isChromium !== undefined && vendorName === 'Google Inc.' && isOpera === false && isIEedge === false;

if (isIE) {
  Browser.IE = true;
} else if (isFirefox) {
  Browser.Firefox = true;
} else if (isChrome) {
  Browser.Chrome = true;
}

module.exports = Browser;
