import Croppie from 'croppie';
import { DirectUpload } from '@rails/activestorage';

export default class EditProfile {
  constructor(element) {
    this.element = element;
    this.croppie = null;
    this.file = null;
    this.photoChanged = false;

    this.element.querySelector('input#user_profile_picture').addEventListener('change', this.handleFileChanged.bind(this));
    this.element.querySelector('input.btn.btn-primary').addEventListener('click', this.handleFormSubmit.bind(this));

    this.directUploadUrl = element.getAttribute('data-upload-url');
    this.fileName        = element.getAttribute('data-profile-photo-name');

    const previewEl = this.element.querySelector('.image-preview');

    this.croppie = new Croppie(previewEl, {
      enableExif: true,
      url: element.getAttribute('data-profile-photo-url'),
      viewport: {
        width: 200,
        height: 200,
        type: 'circle'
      },
      boundary: {
        width: 200,
        height: 200
      }
    });

    previewEl.addEventListener('update', () => { this.photoChanged = true; });
  }

  handleFileChanged(event) {
    const field = event.target;

    if (field.files && field.files[0]) {
      var reader = new FileReader();
      reader.onload = this.handleImageLoaded.bind(this);
      this.file = field.files[0];
      this.fileName = this.file.name;
      this.photoChanged = true;
      reader.readAsDataURL(field.files[0]);
    }
  }

  handleImageLoaded(event) {
    this.croppie.bind({ url: event.target.result });
  }

  uploadProfilePhoto() {
    if (this.photoChanged) {
      return new Promise((resolve, reject) => {
        this.croppie.result('blob').then(blob => {
          blob.name = this.fileName;
          const upload = new DirectUpload(blob, this.directUploadUrl);
          upload.create((error, blob) => {
            if (error) {
              reject(error);
            } else {
              resolve(blob);
            }
          });
        });
      });
    } else {
      return Promise.resolve(null);
    }
  }
  handleFormSubmit(event) {
    event.preventDefault();
    const form = document.querySelector('form');

    this.uploadProfilePhoto().then(blob => {
      if (blob && blob.signed_id) {
        const hiddenField = document.createElement('input');
        hiddenField.setAttribute('type', 'hidden');
        hiddenField.setAttribute('value', blob.signed_id);
        hiddenField.name = 'user[profile_picture]'
        form.appendChild(hiddenField);
      }

      form.submit();
    });
  }
}