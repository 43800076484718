/*
 * IconName Messages
 *
 * This contains all the text for the IconName component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  cleaners: {
    id: 'app.components.IconName.cleaners',
    defaultMessage: 'Cleaner',
  },
  coffeeShops: {
    id: 'app.components.IconName.coffeeShops',
    defaultMessage: 'Coffee',
  },
  fireStations: {
    id: 'app.components.IconName.fireStations',
    defaultMessage: 'Fire Station',
  },
  gasStations: {
    id: 'app.components.IconName.gasStations',
    defaultMessage: 'Gas Station',
  },
  grocery: {
    id: 'app.components.IconName.grocery',
    defaultMessage: 'Grocery',
  },
  gyms: {
    id: 'app.components.IconName.gyms',
    defaultMessage: 'Gym',
  },
  railTransit: {
    id: 'app.components.IconName.railTransit',
    defaultMessage: 'Nearest Rail Transit Stop',
  },
  pharmacies: {
    id: 'app.components.IconName.pharmacies',
    defaultMessage: 'Pharmacy',
  },
  police: {
    id: 'app.components.IconName.police',
    defaultMessage: 'Police Station',
  },
  shoppingMalls: {
    id: 'app.components.IconName.shoppingMalls',
    defaultMessage: 'Shopping Mall',
  },
  streetLevelTransit: {
    id: 'app.components.IconName.streetLevelTransit',
    defaultMessage: 'Nearest Street Level Transit Stop',
  },
  waterTransit: {
    id: 'app.components.IconName.waterTransit',
    defaultMessage: 'Nearest Water Transit Stop',
  },
  recreationCenters: {
    id: 'app.components.IconName.recreationCenters',
    defaultMessage: 'Recreation Center',
  },
  recreationCentres: {
    id: 'app.components.IconName.recreationCentres',
    defaultMessage: 'Recreation Centre',
  },
  swimming: {
    id: 'app.components.IconName.swimming',
    defaultMessage: 'Swimming Pool',
  },
  skatingRinks: {
    id: 'app.components.IconName.skatingRinks',
    defaultMessage: 'Skating Rink',
  },
  skiResorts: {
    id: 'app.components.IconName.skiResorts',
    defaultMessage: 'Skiing',
  },
  arenas: {
    id: 'app.components.IconName.arenas',
    defaultMessage: 'Arena',
  },
  yoga: {
    id: 'app.components.IconName.yoga',
    defaultMessage: 'Yoga',
  },
  martialArts: {
    id: 'app.components.IconName.martialArts',
    defaultMessage: 'Martial Arts',
  },
  bowling: {
    id: 'app.components.IconName.bowling',
    defaultMessage: 'Bowling',
  },

});
