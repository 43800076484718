import React from 'react';

import homePin from 'images/home_pin.svg';

import { User } from './types';

interface IProps {
  streetAddress: string;
  city:          string;
  state:         string;
  reportTitle:   string;
  user:          User;
}
export default function(props: IProps) {
  return (
    <div className="col-12 report-banner">
      <div className="row report-banner-container">
        <div className="col-12 col-sm-8 banner-content">
          <div className="row">
            <div className="col-sm-2 home-pin d-none d-sm-block">
              <div dangerouslySetInnerHTML={{ __html: homePin }} />
            </div>
            <div className="col-12 col-sm-10 header-content">
              <h1>{props.streetAddress}</h1>
              <h4>{`${props.city}, ${props.state}`}</h4>
              <h5 className="report-title">{props.reportTitle}</h5>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-4 mt-2">
          <div className="row agent-slug">
            <div className="agent-details">
              <p className="mb-0">{props.user.name}</p>
              {props.user.phone_number && <p className="mb-0">{props.user.phone_number}</p>}
              {props.user.website_address && <p className="mb-0">{props.user.website_address}</p>}
            </div>
            <div className="agent-photo">
              {props.user.profile_picture && <img src={props.user.profile_picture} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}