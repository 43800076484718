import React from 'react';

import schoolQLogo from 'assets/branding/schoolqLogo.png';

export default {
  brandName: 'SchoolQ',
  
  MobileLogo(props) {
    return 'SchoolQ';
  },

  DesktopLogo(props) {
    return (
      <a href="https://www.schoolq.ca/" target="_blank">
        <img src={schoolQLogo} alt="SchoolQ" style={{width: '200px'}}/>
      </a>
    );
  },

  AboutLink(props) {
    return (
      <a href="https://www.schoolq.ca/" target="_blank">About SchoolQ</a>
    );
  },

  FooterLogo(props) {
    return (
      <img src={schoolQLogo} alt="SchoolQ Logo" />
    );
  }
}