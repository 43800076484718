/**
*
* Schools
*
*/

import React from 'react';

function Schools(props) {
  const { x, y, height, width } = props;

  return (
    <svg x={x} y={y} height={height} width={width} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 216 216" enableBackground="new 0 0 216 216" xmlSpace="preserve">
      <circle fill="#FFFFFF" stroke="#2BACE2" strokeWidth="4" strokeMiterlimit="10" cx="108.5" cy="108.5" r="98.5" />
      <g>
        <g>
          <g>
            <rect x="61.2" y="117.4" fill="#2BACE2" width="94.4" height="2.9" />
          </g>
          <g>
            <rect x="61.2" y="122.7" fill="#2BACE2" width="94.4" height="2.9" />
          </g>
          <g>
            <rect x="61" y="89.8" fill="#2BACE2" width="25.9" height="24.4" />
            <rect x="65.3" y="94.4" fill="#FFFFFF" width="3.1" height="6" />
            <rect x="72.5" y="94.4" fill="#FFFFFF" width="3.1" height="6" />
            <rect x="79.5" y="94.4" fill="#FFFFFF" width="3.1" height="6" />
            <rect x="65.3" y="104.6" fill="#FFFFFF" width="3.1" height="6" />
            <rect x="72.5" y="104.6" fill="#FFFFFF" width="3.1" height="6" />
            <rect x="79.5" y="104.6" fill="#FFFFFF" width="3.1" height="6" />
          </g>
          <g>
            <rect x="129.5" y="89.8" fill="#2BACE2" width="25.9" height="24.4" />
            <rect x="133.8" y="94.4" fill="#FFFFFF" width="3.1" height="6" />
            <rect x="141" y="94.4" fill="#FFFFFF" width="3.1" height="6" />
            <rect x="148.1" y="94.4" fill="#FFFFFF" width="3.1" height="6" />
            <rect x="133.8" y="104.6" fill="#FFFFFF" width="3.1" height="6" />
            <rect x="141" y="104.6" fill="#FFFFFF" width="3.1" height="6" />
            <rect x="148.1" y="104.6" fill="#FFFFFF" width="3.1" height="6" />
          </g>
          <rect x="88.8" y="77.6" fill="#2BACE2" width="39.3" height="36.9" />
          <polygon fill="#2BACE2" points="129.5,76.2 108.7,66.1 86.6,76.2" />
          <rect x="108" y="47.8" fill="#2BACE2" width="1" height="19.2" />
          <path fill="#2BACE2" d="M99.4,55.2c0.4,0.5,1.9,1.1,4.1,0.2c2.2-0.8,4.4-0.1,4.4-0.1v-6.1c-0.6-0.1-2.9,0.1-4.8,1.1 c-1.9,0.8-3.6-0.5-3.6-0.5L99.4,55.2z" />
          <circle fill="#2BACE2" cx="108.5" cy="47.5" r="1" />
          <g>
            <rect x="94.5" y="82.2" fill="#FFFFFF" width="3" height="10.9" />
            <rect x="102.9" y="82.2" fill="#FFFFFF" width="3" height="10.9" />
            <rect x="111.5" y="82.2" fill="#FFFFFF" width="3" height="10.9" />
            <rect x="119.9" y="82.2" fill="#FFFFFF" width="3" height="10.9" />
          </g>
          <rect x="119.9" y="98" fill="#FFFFFF" width="3" height="10.9" />
          <rect x="94.3" y="98" fill="#FFFFFF" width="3" height="10.9" />
          <rect x="102.9" y="98.1" fill="#FFFFFF" width="11.4" height="14" />
        </g>
      </g>
      <g>
        <path fill="#2BACE2" d="M70.1,147.7c-0.3-0.3-0.6-0.6-1-0.8c-0.4-0.2-0.8-0.3-1.2-0.3c-0.2,0-0.4,0-0.6,0.1s-0.4,0.1-0.6,0.2 c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.2,0.4-0.2,0.6c0,0.2,0,0.4,0.1,0.5c0.1,0.1,0.2,0.3,0.4,0.4c0.2,0.1,0.4,0.2,0.6,0.3 c0.2,0.1,0.5,0.2,0.8,0.3c0.4,0.1,0.9,0.3,1.3,0.5s0.9,0.4,1.2,0.7c0.4,0.3,0.7,0.6,0.9,1s0.4,0.9,0.4,1.5c0,0.7-0.1,1.3-0.4,1.8 c-0.3,0.5-0.6,0.9-1.1,1.3c-0.4,0.3-1,0.6-1.5,0.8c-0.6,0.2-1.2,0.2-1.8,0.2c-0.9,0-1.7-0.2-2.6-0.5c-0.8-0.3-1.5-0.7-2.1-1.3l2-2 c0.3,0.4,0.7,0.7,1.2,0.9c0.5,0.3,1,0.4,1.5,0.4c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.6-0.2s0.3-0.2,0.4-0.4 c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.2-0.3-0.3-0.5-0.4c-0.2-0.1-0.5-0.3-0.8-0.4c-0.3-0.1-0.7-0.2-1.1-0.4 c-0.4-0.1-0.8-0.3-1.2-0.5c-0.4-0.2-0.7-0.4-1-0.7c-0.3-0.3-0.5-0.6-0.7-1c-0.2-0.4-0.3-0.9-0.3-1.4c0-0.7,0.1-1.3,0.4-1.8 c0.3-0.5,0.6-0.9,1.1-1.2s1-0.5,1.5-0.7c0.6-0.1,1.1-0.2,1.7-0.2c0.7,0,1.4,0.1,2.1,0.4c0.7,0.3,1.4,0.6,1.9,1.1L70.1,147.7z" />
        <path fill="#2BACE2" d="M83.7,156.7c-0.8,0.4-1.7,0.6-2.8,0.6c-1,0-1.8-0.2-2.7-0.5c-0.8-0.3-1.5-0.8-2.1-1.3 c-0.6-0.6-1.1-1.3-1.4-2.1c-0.3-0.8-0.5-1.7-0.5-2.7c0-1,0.2-1.9,0.5-2.7c0.3-0.8,0.8-1.5,1.4-2.1c0.6-0.6,1.3-1,2.1-1.3 c0.8-0.3,1.7-0.5,2.6-0.5c0.9,0,1.7,0.2,2.6,0.5c0.8,0.3,1.5,0.8,2.1,1.4l-2,2c-0.3-0.4-0.6-0.7-1.1-0.9c-0.5-0.2-0.9-0.3-1.4-0.3 c-0.5,0-1,0.1-1.4,0.3s-0.8,0.5-1.1,0.8c-0.3,0.3-0.6,0.7-0.7,1.2s-0.3,1-0.3,1.5c0,0.6,0.1,1.1,0.3,1.5s0.4,0.9,0.7,1.2 c0.3,0.3,0.7,0.6,1.1,0.8c0.4,0.2,0.9,0.3,1.4,0.3c0.6,0,1.1-0.1,1.6-0.4c0.4-0.2,0.8-0.5,1.1-0.9l2.1,2 C85.2,155.8,84.5,156.3,83.7,156.7z" />
        <path fill="#2BACE2" d="M95.7,156.9v-5.2h-4.8v5.2h-3v-12.4h3v4.7h4.8v-4.7h3v12.4H95.7z" />
        <path fill="#2BACE2" d="M115,150.7c0,1-0.2,1.9-0.5,2.7c-0.3,0.8-0.8,1.5-1.4,2.1c-0.6,0.6-1.3,1-2.1,1.3s-1.7,0.5-2.7,0.5 s-1.9-0.2-2.7-0.5c-0.8-0.3-1.5-0.8-2.1-1.3c-0.6-0.6-1.1-1.3-1.4-2.1c-0.3-0.8-0.5-1.7-0.5-2.7c0-1,0.2-1.9,0.5-2.7 c0.3-0.8,0.8-1.5,1.4-2s1.3-1,2.1-1.3c0.8-0.3,1.7-0.5,2.7-0.5s1.9,0.2,2.7,0.5c0.8,0.3,1.5,0.7,2.1,1.3s1.1,1.2,1.4,2 C114.9,148.8,115,149.7,115,150.7z M111.8,150.7c0-0.5-0.1-1-0.3-1.5c-0.2-0.5-0.4-0.9-0.7-1.2c-0.3-0.3-0.7-0.6-1.1-0.8 s-0.9-0.3-1.4-0.3c-0.5,0-1,0.1-1.4,0.3s-0.8,0.5-1.1,0.8c-0.3,0.3-0.6,0.7-0.7,1.2c-0.2,0.5-0.3,1-0.3,1.5c0,0.6,0.1,1.1,0.3,1.5 s0.4,0.9,0.7,1.2c0.3,0.3,0.7,0.6,1.1,0.8c0.4,0.2,0.9,0.3,1.4,0.3c0.5,0,1-0.1,1.4-0.3c0.4-0.2,0.8-0.5,1.1-0.8 c0.3-0.3,0.6-0.7,0.7-1.2S111.8,151.2,111.8,150.7z" />
        <path fill="#2BACE2" d="M130.8,150.7c0,1-0.2,1.9-0.5,2.7c-0.3,0.8-0.8,1.5-1.4,2.1c-0.6,0.6-1.3,1-2.1,1.3s-1.7,0.5-2.7,0.5 s-1.9-0.2-2.7-0.5c-0.8-0.3-1.5-0.8-2.1-1.3c-0.6-0.6-1.1-1.3-1.4-2.1c-0.3-0.8-0.5-1.7-0.5-2.7c0-1,0.2-1.9,0.5-2.7 c0.3-0.8,0.8-1.5,1.4-2s1.3-1,2.1-1.3c0.8-0.3,1.7-0.5,2.7-0.5s1.9,0.2,2.7,0.5c0.8,0.3,1.5,0.7,2.1,1.3s1.1,1.2,1.4,2 C130.6,148.8,130.8,149.7,130.8,150.7z M127.6,150.7c0-0.5-0.1-1-0.3-1.5c-0.2-0.5-0.4-0.9-0.7-1.2c-0.3-0.3-0.7-0.6-1.1-0.8 s-0.9-0.3-1.4-0.3c-0.5,0-1,0.1-1.4,0.3s-0.8,0.5-1.1,0.8c-0.3,0.3-0.6,0.7-0.7,1.2c-0.2,0.5-0.3,1-0.3,1.5c0,0.6,0.1,1.1,0.3,1.5 s0.4,0.9,0.7,1.2c0.3,0.3,0.7,0.6,1.1,0.8c0.4,0.2,0.9,0.3,1.4,0.3c0.5,0,1-0.1,1.4-0.3c0.4-0.2,0.8-0.5,1.1-0.8 c0.3-0.3,0.6-0.7,0.7-1.2S127.6,151.2,127.6,150.7z" />
        <path fill="#2BACE2" d="M133.6,156.9v-12.4h3v9.8h4.8v2.6H133.6z" />
        <path fill="#2BACE2" d="M150.3,147.7c-0.3-0.3-0.6-0.6-1-0.8c-0.4-0.2-0.8-0.3-1.2-0.3c-0.2,0-0.4,0-0.6,0.1s-0.4,0.1-0.6,0.2 c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.2,0.4-0.2,0.6c0,0.2,0,0.4,0.1,0.5c0.1,0.1,0.2,0.3,0.4,0.4c0.2,0.1,0.4,0.2,0.6,0.3 c0.2,0.1,0.5,0.2,0.8,0.3c0.4,0.1,0.9,0.3,1.3,0.5s0.9,0.4,1.2,0.7c0.4,0.3,0.7,0.6,0.9,1s0.4,0.9,0.4,1.5c0,0.7-0.1,1.3-0.4,1.8 c-0.3,0.5-0.6,0.9-1.1,1.3c-0.4,0.3-1,0.6-1.5,0.8c-0.6,0.2-1.2,0.2-1.8,0.2c-0.9,0-1.7-0.2-2.6-0.5c-0.8-0.3-1.5-0.7-2.1-1.3l2-2 c0.3,0.4,0.7,0.7,1.2,0.9c0.5,0.3,1,0.4,1.5,0.4c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.6-0.2s0.3-0.2,0.4-0.4 c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.2-0.3-0.3-0.5-0.4c-0.2-0.1-0.5-0.3-0.8-0.4c-0.3-0.1-0.7-0.2-1.1-0.4 c-0.4-0.1-0.8-0.3-1.2-0.5c-0.4-0.2-0.7-0.4-1-0.7c-0.3-0.3-0.5-0.6-0.7-1c-0.2-0.4-0.3-0.9-0.3-1.4c0-0.7,0.1-1.3,0.4-1.8 c0.3-0.5,0.6-0.9,1.1-1.2s1-0.5,1.5-0.7c0.6-0.1,1.1-0.2,1.7-0.2c0.7,0,1.4,0.1,2.1,0.4c0.7,0.3,1.4,0.6,1.9,1.1L150.3,147.7z" />
      </g>
    </svg>
  );
}



export default Schools;
