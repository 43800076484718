import React from 'react';
import classNames from 'classnames';
import { Navbar, Nav, NavItem } from 'react-bootstrap';

import AuthenticatedNav from '../AuthenticatedNav';
import CampaignNav from '../CampaignNav';
import UnauthenticatedNav from '../UnauthenticatedNav';

import TermsAndConditions from '../items/TermsAndConditions';
// import ShareModal from '../ShareModal';
// import CancellationModal from '../CancellationModal';

// import NavBar from 'components/_shared/_navigation/NavBar';

import getBranding from 'components/branding';

import styles from './styles.css';
// const styles = require('./styles.css');

function Navigation(props) {
  let NavigationComponent = null;

  if (props.currentUser) {
    NavigationComponent = AuthenticatedNav;
  } else if (props.campaign) {
    NavigationComponent = CampaignNav;
  } else {
    NavigationComponent = UnauthenticatedNav;
  }

  const branding = getBranding(props.branding || 'hoodq');

  const shareURL = window.location.href.includes('detailed')
    ? `${window.location.href}${props.displayScores ? '?scores=show' : ''}`
    : window.location.href;
    
  return (
    <div style={props.hideLogo ? {'margin-top': '25px'} : null}>
      <Navbar className="d-xs-block d-none navbar-fixed-top" inverse>
        <Navbar.Brand>
          {branding.MobileLogo()}
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav className={classNames(styles.navigation, 'd-print-none')}>
            <NavigationComponent {...props} branding={branding} />
            <ul className="nav nav-pills nav-stacked">
              <TermsAndConditions />
            </ul>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div className={classNames(styles.navigation, 'd-xs-none', 'd-print-none')}>
        {props.hideLogo ? null : branding.DesktopLogo()}
        <NavigationComponent {...props} branding={branding} />
        <ul className="nav nav-pills nav-stacked">
          <TermsAndConditions /> 
        </ul>
      </div>
      {/* {props.modalVisible && props.modalType === 'share' ? <ShareModal address={props.address} packageURL={shareURL} /> : null}
      {props.modalVisible && props.modalType === 'cancelSubscription' ? <CancellationModal /> : null} */}
    </div>
  )
}

// const mapStateToProps = createStructuredSelector({
//   modalVisible:  selectModalVisibility(),
//   modalType:     selectModalType(),
//   currentUser:   selectCurrentUser(),
//   displayScores: selectSearchOptions()
// });

export default Navigation;