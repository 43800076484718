/**
*
* EstablishedFamilies
*
*/

import React from 'react';

function EstablishedFamilies() {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 216 216">
      <polygon className="cls-2" fill="#00AEEF" points="138.501 90.131 143.927 87.805 141.757 91.061 144.547 91.371 138.501 98.502 140.981 92.301 138.346 93.076 140.671 90.596 138.501 90.131" />
      <path className="cls-3" fill="#FFF" d="M122.62708,76.51336l17.0366,7.7259,4.7544,1.1886,6.9335-3.10357s0.26413-.5943,2.64133.7924,1.84893,2.1791.26413,3.962l-17.763,5.67887-14.55174-7.06669" />
      <path className="cls-3" fill="#FFF" d="M91.50089,97.97271c0.00159,0.45041.13244,5.16451,0.14044,5.62812,0.19767,11.45679.50979,12.43811,3.34182,12.49573s3.4468-3.07386,3.24264-5.19053c-0.1532-1.5875-.63672-11.6449-0.60439-15.70552" />
      <path className="cls-3" fill="#FFF" d="M121.12338,82.43572a7.70432,7.70432,0,0,0-2.662-4.50865,1.6697,1.6697,0,0,1-.1863-2.23014,1.7099,1.7099,0,0,1-.22029-0.44663l-11.72784.11459a10.42751,10.42751,0,0,0-8.91153,2.97051c-3.66463,3.80038-5.63627,10.72649-5.91476,21.403l6.2-2.58982a24.4395,24.4395,0,0,1,.28068-3.25338,77.52949,77.52949,0,0,1,2.40462-8.20428v68.4407h0.10081a4.94667,4.94667,0,0,0,9.775,0h0.02582V153.8753a2.63265,2.63265,0,0,0,0-1.0522V108.889h1.98024v44.2855c-0.00642.10358-.03093,0.202-0.03093,0.30731s0.02451,0.20366.03093,0.30723v0.33371h0.03385a4.94,4.94,0,0,0,9.80318,0h0.06477V83.9236A1.84432,1.84432,0,0,1,121.12338,82.43572Z" />
      <path className="cls-3" fill="#FFF" d="M123.7796,79.32864a5.07943,5.07943,0,0,1,.05465-1.94869c-3.01036-2.59617-7.78923-1.9994-7.83288-2.015l-8.4443-.08251a1.77451,1.77451,0,0,1-.304.45366,37.24075,37.24075,0,0,1-7.09426,5.84521V154.1228h0.06471a4.94,4.94,0,0,0,9.80324,0h0.03376v-0.33371c0.00638-.1036.031-0.202,0.031-0.30721s-0.02457-.20381-0.031-0.3074V108.88907h1.98042v43.934a2.63265,2.63265,0,0,0,0,1.05221v0.25633h0.02563a4.9466,4.9466,0,0,0,9.7749,0h0.10089V85.69086S124.26343,82.29085,123.7796,79.32864Z" />
      <circle className="cls-3" fill="#FFF" cx="111.56066" cy="63.62445" r="8.77003" />
      <path className="cls-4" strokeMiterlimit="10" fill="#FFF" stroke="#00AEEF" strokeWidth="1.5848px" d="M160.6083,106.2062q-0.00856-.69543-0.02621-1.37084l-0.00706-.0029c-0.292-10.59661-2.25977-17.48445-5.90827-21.26808a10.11958,10.11958,0,0,0-6.84586-3.0603c-0.0029-.00832-0.00938-0.01635-0.01219-0.02476l-0.669.00658c-0.05.00039-.09924,0.00039-0.147,0.00135l-6.07532.05939-3.60643-.03521c-0.0119.02467-.03473,0.04653-0.04759,0.071l-1.18251.01151a10.42759,10.42759,0,0,0-8.9115,2.97044c-3.66466,3.80037-5.63627,10.72651-5.91475,21.403q-0.0177.67541-.02621,1.37084c-0.14151,11.45769.14142,12.4478,2.97044,12.58922s3.5363-2.97053,3.39488-5.09227c-0.10611-1.59138-.29173-7.39973-0.13929-11.45759h0.0001a24.4393,24.4393,0,0,1,.28071-3.25337,69.68782,69.68782,0,0,1,2.17746-7.54357v63.01679h0.06461a4.95036,4.95036,0,0,0,4.90162,4.32531,4.89465,4.89465,0,0,0,2.59581-.74075,4.96647,4.96647,0,0,0,2.541-3.57576h0.02583v-0.25633a2.63265,2.63265,0,0,0,0-1.05221V114.118h1.7532v39.17969a2.63265,2.63265,0,0,0,0,1.05221v0.25633h0.02573a4.9672,4.9672,0,0,0,2.54077,3.57567,4.89453,4.89453,0,0,0,2.596.74084,4.95026,4.95026,0,0,0,4.90152-4.32531h0.06481V91.58124a69.6908,69.6908,0,0,1,2.17746,7.543,24.4393,24.4393,0,0,1,.28071,3.25337l0.00474,0.00193c0.14364,4.0507-.0385,9.74936-0.14355,11.32352-0.14142,2.12184.566,5.23388,3.39488,5.09227C160.46688,118.65391,160.74982,117.66379,160.6083,106.2062Z" />
      <circle className="cls-3" fill="#FFF" cx="141.31451" cy="68.85349" r="8.77003" />
      <line className="cls-5" strokeMiterlimit="10" stroke="#00AEEF" strokeWidth="1.5848px" fill="none" x1="122.62708" y1="91.56896" x2="129.36248" y2="94.93666" />
      <line className="cls-5" strokeMiterlimit="10" stroke="#00AEEF" strokeWidth="1.5848px" fill="none" x1="159.27558" y1="91.76706" x2="152.54018" y2="95.13476" />
      <path className="cls-5" strokeMiterlimit="10" stroke="#00AEEF" strokeWidth="1.5848px" fill="none" d="M135.10738,80.07916a7.54609,7.54609,0,0,0,6.20713,3.1696c3.36931-.16847,4.4407-2.06053,5.28267-3.3677" />
      <line className="cls-5" strokeMiterlimit="10" stroke="#00AEEF" strokeWidth="1.5848px" fill="none" x1="129.36248" y1="109.39795" x2="151.94588" y2="109.39795" />
      <path className="cls-3" fill="#FFF" d="M131.49905,70.89846c0,0.71457-1.01571,1.29384-2.26864,1.29384h0c-1.25294,0-2.26864-.57927-2.26864-1.29384V65.48785c0-.71457,1.01571-1.29384,2.26864-1.29384h0c1.25294,0,2.26864.57927,2.26864,1.29384v5.41061Z" />
      <path className="cls-3" fill="#FFF" d="M155.46915,70.89846c0,0.71457-1.01571,1.29384-2.26864,1.29384h0c-1.25294,0-2.26864-.57927-2.26864-1.29384V65.48785c0-.71457,1.01571-1.29384,2.26864-1.29384h0c1.25294,0,2.26864.57927,2.26864,1.29384v5.41061Z" />
      <path className="cls-6" stroke="#FFF" fill="none" strokeWidth="1.981px" d="M130.52327,66.75047a10.6974,10.6974,0,1,1,21.39451.11182" />
      <ellipse className="cls-7" fill="#FFF" stroke="#00AEEF" strokeWidth="1.41356px" cx="153.38437" cy="83.0699" rx="2.83684" ry="4.22613" transform="translate(-6.41065 153.01934) rotate(-52.08024)" />
      <path className="cls-3" fill="#FFF" d="M84.82532,92.34176c1.44679,7.45349,3.81059,19.63112,7.118,36.67012H82.50142c-0.01632.50244-.04016,0.8961-0.04027,1.28977q-0.00338,11.6172.00473,23.23438a4.357,4.357,0,0,1-1.43565,3.4564,4.38912,4.38912,0,0,1-7.32717-2.84735,13.33342,13.33342,0,0,1-.039-1.425q-0.00319-11.15861-.00149-22.31723v-1.27776h-1.3936v1.18071q0,11.46434-.00884,22.92866a6.735,6.735,0,0,1-.21028,1.80731,4.379,4.379,0,0,1-8.56411-1.00093c-0.02065-.33856-0.01324-0.679-0.01327-1.01861q-0.001-11.31147-.00049-22.623v-1.311H53.99569c1.164-6.2531,2.38389-12.35474,3.56832-18.463q1.77494-9.15348,3.54456-18.308l-0.22734-.07953c-0.20035.37781-.41621,0.74829-0.5987,1.13454a80.35866,80.35866,0,0,0-5.3164,15.79548c-0.92034,3.8592-.68418,3.61911-1.542,7.49377a4.483,4.483,0,0,1-.75147,1.74037,2.82922,2.82922,0,0,1-4.96565-2.15013c0.24529-1.64357.56351-3.27785,0.89921-4.906a81.28619,81.28619,0,0,1,3.23625-12.20963A53.21123,53.21123,0,0,1,57.00586,87.426a29.67286,29.67286,0,0,1,2.49326-3.33978,11.16863,11.16863,0,0,1,6.45568-3.6128,31.8384,31.8384,0,0,1,14.92459.23364,11.51989,11.51989,0,0,1,6.18339,4.067,33.76886,33.76886,0,0,1,4.54751,7.80911c2.35009,5.36784,3.163,6.22611,4.49051,11.9164,0.80135,3.43481,1.46664,6.90142,2.18476,10.35547a5.384,5.384,0,0,1,.11024,1.00986,2.84233,2.84233,0,0,1-5.60085.7737c-0.30469-1.251-.49173-2.53037-0.7455-3.79423-1.3037-6.493-1.99982-8.18838-4.30436-14.41116-0.71767-1.93788-1.61395-3.80968-2.43294-5.70981a3.89533,3.89533,0,0,0-.24629-0.42218Z" />
      <path className="cls-3" fill="#FFF" d="M72.91423,60.66535a8.56326,8.56326,0,1,1-8.52585,8.57076A8.53557,8.53557,0,0,1,72.91423,60.66535Z" />
      <polygon className="cls-2" fill="#00AEEF" points="138.501 90.131 143.927 87.805 141.757 91.061 144.547 91.371 138.501 98.502 140.981 92.301 138.346 93.076 140.671 90.596 138.501 90.131" />
    </svg>
  );
}

export default EstablishedFamilies;
