/* eslint no-multi-spaces: "off" */
/* eslint react/jsx-equals-spacing: "off" */

/**
*
* ConvenienceReport
*
*/

import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import messages from './messages';
import styles from './styles.css';

import DetailedPlaceBlock from '../DetailedPlaceBlock';
import SVGIcon from '../../../SVGIcon';

function ConvenienceReport(props) {
  // TODO : consider dynamic descriptions for this section
  const { convenience, locale, score } = props; // paragraph

  let convenienceContent = [];

  Object.keys(convenience).forEach((index) => {
    const item = convenience[index];

    convenienceContent.push(
      <DetailedPlaceBlock
        icon       = {item.place_category_key}
        name       = {item.name}
        distance   = {item.distance}
        address    = {item.address}
        paragraph  = {item.paragraph}
        info       = {item.features}
        facilities = {item.facilities}
        locale     = {locale}
        key        = {item.name}
      />
    );
  });

  let scoreDisplay = null;
  if (score) {
    scoreDisplay = (
      <div>
        <div className={classNames(styles.iconXS, 'd-xs-block d-none')}>
          <SVGIcon iconType="circle" />
          <div className={styles.score}>
            <span>{score}</span>
          </div>
        </div>
        <div className={classNames(styles.icon, 'd-xs-none')}>
          <SVGIcon iconType="circle" />
          <div className={styles.score}>
            <span>{score}</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={classNames('row', styles.convenienceReport)}>
      <div className="offset-1 col-10">
        {scoreDisplay}
        <div className={styles.titleParagraph}>
          <h2>
            Convenience
          </h2>
          <p>Being close to things you need every day makes life so much easier. See the conveniently located retailers near you below:</p>
        </div>
        {convenienceContent}
      </div>
    </div>
  );
}



export default ConvenienceReport;
