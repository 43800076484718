import shareWithFacebook  from './share-methods/Facebook';
import shareWithTwitter   from './share-methods/Twitter';
import shareWithPinterest from './share-methods/Pinterest';
import shareWithInstagram from './share-methods/Instagram';
import shareWebsite       from './share-methods/Website';
import shareAll           from './share-methods/All';

class ProductActions {
  handleSaveAction = (target, product) => {
    window.open(this.getVariantUrl(product.download_url, product.variant));
  }

  handlePrintAction = (target, product) => {
    const printWindow = window.open(this.getVariantUrl(`/products/${product.id}`, product.variant));
    printWindow.onload = () => {
      printWindow.print();
    };

    printWindow.onafterprint = () => {
      printWindow.close();
    }
  }

  handleShareAction = (target, product) => {
    switch (product.share_method) {
      case 'facebook':
        shareWithFacebook(product);
        break;
      case 'twitter':
        shareWithTwitter(product);
        break;
      case 'pinterest':
        shareWithPinterest(product);
        break;
      case 'instagram':
        shareWithInstagram(product);
        break;
      case 'website':
        shareWebsite(product);
        break;
      case 'all':
        shareAll(product);
        break;
    }
    
  }

  getVariantUrl = (baseUrl, variant) => {
    const qs = variant ? `?variant=${variant}` : "";
    return `${baseUrl}${qs}`;
  }

  findActionsParent = (target) => {
    return target.closest("[data-actions]");
  }

  handleClick = (event) => {
    const { target } = event;

    if (target.hasAttribute('data-action')) {
      event.preventDefault();
      const action = target.getAttribute('data-action');
      const actionsEl = this.findActionsParent(target);
      const product = JSON.parse(actionsEl.getAttribute('data-product'));
      const variant = target.getAttribute('data-variant');

      product.variant = variant;

      if (!target.hasAttribute('data-variant') && product.exportable_variants.length > 1) {
        this.showVariantSelectionModal(action);
        return;
      }

      switch(action) {
        case 'save':
          this.handleSaveAction(target, product);
          break;
        case 'print':
          this.handlePrintAction(target, product);
          break;
        case 'share':
          this.handleShareAction(target, product);
          break;
      }
    }
  }

  showVariantSelectionModal = (action) => {
    $('#variant-selection-modal h5.modal-title').text(`Choose a variant to ${action}`);


    $('#variant-selection-modal img').each(function() {
      $(this).attr('data-action', action);
    });

    $('#variant-selection-modal').modal('show');
  }

  start = () => {
    document.addEventListener('click', this.handleClick);
  }

}
export default new ProductActions();