/**
*
* OlderAdults
*
*/

import React from 'react';

function OlderAdults() {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 216 216">
      <circle className="cls-1" fill="#00aeef" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.93979px" cx="108.48018" cy="108.37213" r="98.36473" />
      <circle className="cls-2" fill="#fff" cx="127.18174" cy="63.53504" r="8.75631" />
      <path className="cls-2" fill="#fff" d="M147.14344,99.679q-0.00855-.69434-0.02617-1.36869l-0.00705-.0029c-0.29157-10.58-2.25624-17.4571-5.899-21.23481a10.10375,10.10375,0,0,0-6.83515-3.05551c-0.0029-.00831-0.00937-0.01632-0.01217-0.02472l-11.85552.03059s-5.54911-.35712-8.75154,3.04964c-3.65892,3.79442-5.62745,10.70973-5.9055,21.36953q-0.01767.67435-.02617,1.36869c-0.14129,11.43976.1412,12.42832,2.96579,12.56952s3.53076-2.96588,3.38957-5.08431c-0.10595-1.58889-.29128-7.38815-0.13907-11.43966h0.0001a24.40107,24.40107,0,0,1,.28027-3.24828,69.57881,69.57881,0,0,1,2.174-7.53176v69.49112h0.06451a4.94262,4.94262,0,0,0,4.894,4.31854,4.887,4.887,0,0,0,2.59174-.73959,4.9587,4.9587,0,0,0,2.537-3.57017h0.02579v-0.25593a2.62853,2.62853,0,0,0,0-1.05057V114.611h1.75046v38.65878a2.62853,2.62853,0,0,0,0,1.05057v0.25593H128.385a4.95943,4.95943,0,0,0,2.53679,3.57007,4.88687,4.88687,0,0,0,2.59194.73968,4.94252,4.94252,0,0,0,4.89385-4.31854h0.06471V85.07693a69.58173,69.58173,0,0,1,2.174,7.53118,24.40108,24.40108,0,0,1,.28027,3.24828l0.00473,0.00193c0.14342,4.04436-.03844,9.73411-0.14332,11.30581-0.1412,2.11852.56507,5.22569,3.38957,5.0843C147.00224,112.10724,147.28473,111.11868,147.14344,99.679Z" />
      <path className="cls-2" fill="#fff" d="M100.48824,92.64695c1.44452,7.44183,3.80463,19.60041,7.10687,36.61275H98.168c-0.0163.50166-.04009,0.8947-0.0402,1.28775q-0.00337,11.599.00472,23.198a4.35023,4.35023,0,0,1-1.4334,3.451,4.38225,4.38225,0,0,1-7.3157-2.8429,13.31255,13.31255,0,0,1-.039-1.42276q-0.00319-11.14116-.00149-22.28232v-1.27576H87.95148v1.17886q0,11.4464-.00883,22.89278a6.72445,6.72445,0,0,1-.21,1.80448,4.37217,4.37217,0,0,1-8.55071-.99937c-0.02062-.338-0.01322-0.678-0.01325-1.017q-0.001-11.29377-.00049-22.58756V129.336H69.70684c1.16222-6.24332,2.38016-12.33541,3.56274-18.43407q1.77216-9.13916,3.539-18.27933l-0.227-.07941c-0.2.37721-.41556,0.74712-0.59777,1.13277a80.23293,80.23293,0,0,0-5.30808,15.77077c-0.9189,3.85316-.68311,3.61345-1.53956,7.482a4.476,4.476,0,0,1-.7503,1.73765,2.8248,2.8248,0,0,1-4.95788-2.14677c0.24491-1.641.56263-3.27272,0.8978-4.89837A81.159,81.159,0,0,1,67.557,99.43074a53.128,53.128,0,0,1,5.15529-11.6919,29.62644,29.62644,0,0,1,2.48936-3.33455,11.15115,11.15115,0,0,1,6.44558-3.60715,31.78859,31.78859,0,0,1,14.90124.23327,11.50187,11.50187,0,0,1,6.17372,4.06062,33.716,33.716,0,0,1,4.5404,7.79689c2.34641,5.35944,3.158,6.21637,4.48348,11.89775,0.8001,3.42943,1.46434,6.89062,2.18134,10.33927a5.3756,5.3756,0,0,1,.11007,1.00828,2.83788,2.83788,0,0,1-5.59209.77249c-0.30421-1.24908-.491-2.52641-0.74433-3.7883-1.30166-6.48279-1.99669-8.17557-4.29763-14.38862-0.71655-1.93485-1.61142-3.80372-2.42913-5.70087a3.88925,3.88925,0,0,0-.24591-0.42152Z" />
      <path className="cls-2" fill="#fff" d="M88.59578,61.0201a8.54987,8.54987,0,1,1-8.51251,8.55735A8.52222,8.52222,0,0,1,88.59578,61.0201Z" />
    </svg>
  );
}

export default OlderAdults;
