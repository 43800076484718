/**
*
* Grocery
*
*/

import React from 'react';

function Grocery() {
  return (
    <svg version="1.1" viewBox="0 0 72 62.75">
      <polygon points="2.1008 10.43 8.3923 33.46 46.71 33.46 45.266 37.325 7.6408 37.786 7.6408 44.132 50.344 44.132 57.5 10.2 70.196 10.2 70.196 1.1973 52.883 1.1973 50.806 10.43" fill="#ECB01F" />
      <circle cy="55.211" cx="16.181" r="5.7708" fill="#ECB01F" />
      <circle cy="55.211" cx="39.957" r="5.7708" fill="#ECB01F" />
    </svg>
  );
}

export default Grocery;
