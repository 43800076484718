/**
*
* GolfGeneric
*
*/

import React from 'react';

function GolfGeneric() {
  return (
    <svg version="1.1" y="0px" x="0px" viewBox="0 0 51.333332 59.583332">
      <path d="m22.667 45.75c0 0.69036-0.55964 1.25-1.25 1.25s-1.25-0.55964-1.25-1.25v-42c0-0.69036 0.55964-1.25 1.25-1.25s1.25 0.55964 1.25 1.25v42z" fill="#01818E" />
      <path d="m24.512 19.192l8.9835-7.6202s1.1535-1.0137-0.27964-1.8177c-1.6941-0.95035-8.7155-5.1967-8.7155-5.1967l0.012 14.634z" fill="#01818E" />
      <ellipse rx="21.333" ry="10.333" stroke="#01818E" cy="44.333" cx="26.167" strokeMiterlimit="10" strokeWidth="3.2098" fill="none" />
      <circle cy="44.667" cx="28.667" r="2.9167" fill="#01818E" />
    </svg>
  );
}

export default GolfGeneric;
