/**
*
* Police
*
*/

import React from 'react';

function Police() {
  return (
    <svg version="1.1" viewBox="0 0 49.75 69.5">
      <path d="m24.997 0.875c-11.658 9.0541-22.636 8.1487-22.636 8.1487l0.1136 33.726c1.5845 16.524 22.408 25.125 22.408 25.125 22.522-9.0541 22.296-24.786 22.296-24.786l0.22644-33.953c-6.677-0.3387-14.26-1.6968-22.408-8.261zm7.802 33.021l1.8686 10.895-9.7847-5.144-9.7849 5.144 1.8688-10.895-7.9161-7.7163 10.94-1.5896 4.8923-9.9131 4.8923 9.9131 10.94 1.5896-7.916 7.716z" fill="#E36526" />
    </svg>
  );
}

export default Police;
