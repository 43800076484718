/* eslint indent: "off"*/
/* eslint no-loop-func: "off" */
/* eslint no-fallthrough: "off" */
/* eslint no-multi-spaces: "off" */
/* eslint react/jsx-equals-spacing: "off" */

/**
*
* AltSchools
*
*/

import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import messages from './messages';
import styles from './styles.css';

import Place from '../../../Place';

import greenBookImg from 'assets/reports/school/greenBook.png';
import appleBookImg from 'assets/reports/school/appleBook.jpg';

function AltSchools(props) {
  const { schools, assignedSchoolsCount, handleSchoolClick, handleGreatSchoolClick, expanded, layout, type } = props;

  let appleIcon = null;
  let bookIcon  = null;
  // let clearFix  = null;

  let slicedSchools     = schools;
  let placeContentClass = 'col-12 col-sm-3';

  switch (layout) {
    case 'ThreeQuartersRow':
      // NOTHING
      break;
    case 'OneQuarterRow':
      slicedSchools = schools.slice(0, 2);
      placeContentClass = classNames('col-12', styles.oneQuarterRow);
      break;
    case 'FullHorizontalRow':
    default:
      slicedSchools = schools.slice(0, 4);
      switch (slicedSchools.length) {
        case 4:
          placeContentClass = 'col-12 col-sm-3';
          break;
        case 3:
          placeContentClass = 'col-12 col-sm-4';
          break;
        case 2:
          placeContentClass = 'col-12 col-sm-6';
          break;
        case 1:
        default:
          placeContentClass = 'col-12';
      }
  }

  switch (type) {
    case 'privateSchools':
      bookIcon = <img className="d-xs-none" src={greenBookImg} style={{ width: '80px' }} alt="Green Book" />;
      break;
    case 'catholicSchools':
      // NOTHING
      break;
    case 'alternativeSchools':
      appleIcon = <img src={appleBookImg} style={{ width: '120px', marginLeft: '20px' }} alt="Apple & Book" />;
      break;
    case 'publicSchools':
    default:
      break;
  }

  const schoolColumnsSorting = [];
  let schoolItemsColumns = (<div></div>);

  if (type === 'publicSchools') {
    // NOTE : similar logic in ../AltSchools component
    const twoRowPlacement   = (index) => Math.floor(index / 2);

    // Sorts schools items in groups of 1, 2 or 3; under (up to) 3 different rows
    schools.forEach((item, index) => {
      const columnPlacement = slicedSchools.length < 4 // NOTE : 1 to 3 schools, only 1 row
                                           ? index // Row 0
                                           : twoRowPlacement(index);  // Row 0 or 1

      if (schoolColumnsSorting[columnPlacement]) {
        schoolColumnsSorting[columnPlacement].push(item);
      } else {
        schoolColumnsSorting[columnPlacement] = [item];
      }
    });

    let assignedTagPlaced   = false;
    let unassignedTagPlaced = false;
    let overallSchoolsIndex = 0;

    schoolItemsColumns = schoolColumnsSorting.map((schoolItems, columnIndex) => {
      // TODO : refactor this logic
      const schoolItemsColumn = schoolItems.map((item, index) => {

        const catchmentDesignation = assignedSchoolsCount > 0 && (!assignedTagPlaced || (assignedTagPlaced && !unassignedTagPlaced && assignedSchoolsCount !== overallSchoolsIndex));

        const placeContent = (
          <Place
            key                  = {`schoolItem-${index}`}
            placeType            = "school"
            specificPrimaryName  = {item.name}
            gradeOfferings       = {item.features['Grades Offered'] ? item.features['Grades Offered'] : false}
            gradeLevels          = {item.features['Grade Levels'] ? item.features['Grade Levels'] : false}
            assignedGrades       = {item.place_category_key === 'school-attendance-zones'}
            catchmentDesignation = {catchmentDesignation}
            address              = {item.features.Address ? item.features.Address.split(',').slice(0, 1).join(',') : ''}
            distance             = {item.distance}
            features             = {item.features}
            featureKeys          = {['Grade Level']}
            url                  = {item.url}
            urlMessage           = {messages.url}
            handleClick          = {handleSchoolClick}
            secondaryUrl         = {item.features['GreatSchools School Details Webpage'] ? item.features['GreatSchools School Details Webpage'] : false}
            secondaryUrlMessage  = {item.features['GreatSchools School Details Webpage'] ? messages.urlGreatSchools : false}
            handleSecondaryClick = {item.features['GreatSchools School Details Webpage'] ? handleGreatSchoolClick : false}
            showLooKDeeper       = {false}
            showCatchmentInfo    = {false}
            showNoCatchmentInfo  = {false}
            hideDetailedRepLink
            contentStyle         = {styles[`${type}Content`]}
            expandedInfoStyle    = {styles[`${type}Expanded`]}
            expanded             = {expanded}
          />
        );

        // NOTE : logic for Assigned / Unknown Assignment subheaders
        let schoolItemsContent = null;
        if (assignedSchoolsCount === 0) {
          if (!unassignedTagPlaced) {
            unassignedTagPlaced = true;
            // schoolItemsContent = (
            //   <div key={`schoolItem-${columnIndex}-${index}`}>
            //     <FormattedMessage {...messages.otherLocalSchools} />
            //     {placeContent}
            //   </div>
            // );
            schoolItemsContent = (
              <div key={`schoolItem-${columnIndex}-${index}`}>
                {placeContent}
              </div>
            );
          } else {
            schoolItemsContent = (
              <div key={`schoolItem-${columnIndex}-${index}`}>
                {placeContent}
              </div>
            );
          }
        } else if (assignedSchoolsCount > 0) {
          if (!assignedTagPlaced) {
            assignedTagPlaced = true;
            // schoolItemsContent = (
            //   <div key={`schoolItem-${columnIndex}-${index}`}>
            //     <p>Assigned</p>
            //     {placeContent}
            //   </div>
            // );
            schoolItemsContent = (
              <div key={`schoolItem-${columnIndex}-${index}`}>
                {placeContent}
              </div>
            );
          } else if (assignedTagPlaced && !unassignedTagPlaced && assignedSchoolsCount === overallSchoolsIndex) {
            unassignedTagPlaced = true;
            schoolItemsContent = (
              <div key={`schoolItem-${columnIndex}-${index}`}>
                <div className={styles.otherLocalSchools}>
                  <FormattedMessage {...messages.otherLocalSchools} />
                </div>
                {placeContent}
              </div>
            );
          } else {
            schoolItemsContent = (
              <div key={`schoolItem-${columnIndex}-${index}`}>
                {placeContent}
              </div>
            );
          }
        }

        overallSchoolsIndex += 1;
        return (schoolItemsContent);
        // return (placeContent);
      });

      return (
        <div key={`schoolColumn-${columnIndex}`} className="col-12 col-sm-4">
          {schoolItemsColumn}
        </div>
      );
    });
  } else if (type === 'catholicSchools') {
    let assignedTagPlaced   = false;
    let unassignedTagPlaced = false;
    let overallSchoolsIndex = 0;

    schoolItemsColumns = slicedSchools.map((item, index) => {
      const catchmentDesignation = assignedSchoolsCount > 0 && (!assignedTagPlaced || (assignedTagPlaced && !unassignedTagPlaced && assignedSchoolsCount !== overallSchoolsIndex));

      const placeContent = (
        <div className={placeContentClass} key={index}>
          <Place
            key                  = {`schoolItem-${index}`}
            placeType            = "school"
            specificPrimaryName  = {item.name}
            gradeOfferings       = {item.features['Grades Offered'] ? item.features['Grades Offered'] : false}
            gradeLevels          = {item.features['Grade Levels'] ? item.features['Grade Levels'] : false}
            assignedGrades       = {item.place_category_key === 'school-attendance-zones'}
            catchmentDesignation = {catchmentDesignation}
            address              = {item.features.Address ? item.features.Address.split(',').slice(0, 1).join(',') : ''}
            distance             = {item.distance}
            features             = {item.features}
            featureKeys          = {['Grade Level']}
            url                  = {item.url}
            urlMessage           = {messages.url}
            handleClick          = {handleSchoolClick}
            secondaryUrl         = {item.features['GreatSchools School Details Webpage'] ? item.features['GreatSchools School Details Webpage'] : false}
            secondaryUrlMessage  = {item.features['GreatSchools School Details Webpage'] ? messages.urlGreatSchools : false}
            handleSecondaryClick = {item.features['GreatSchools School Details Webpage'] ? handleGreatSchoolClick : false}
            showLooKDeeper       = {false}
            showCatchmentInfo    = {false}
            showNoCatchmentInfo  = {false}
            hideDetailedRepLink
            contentStyle         = {styles[`${type}Content`]}
            expandedInfoStyle    = {styles[`${type}Expanded`]}
            expanded             = {expanded}
          />
        </div>
      );

      if (assignedSchoolsCount === 0 && !unassignedTagPlaced) {
        unassignedTagPlaced = true;
      } else if (assignedSchoolsCount > 0) {
        if (!assignedTagPlaced) {
          assignedTagPlaced = true;
        } else if (assignedTagPlaced && !unassignedTagPlaced && assignedSchoolsCount === overallSchoolsIndex) {
          unassignedTagPlaced = true;
        }
      }

      overallSchoolsIndex += 1;
      return (placeContent);
    });
  } else {
    schoolItemsColumns = slicedSchools.map((item, index) => {
      const placeContent = (
        <div className={placeContentClass} key={index}>
          <Place
            key                  = {`schoolItem-${index}`}
            placeType            = "school"
            specificPrimaryName  = {item.name}
            gradeOfferings       = {item.features['Grades Offered'] ? item.features['Grades Offered'] : false}
            gradeLevels          = {item.features['Grade Levels'] ? item.features['Grade Levels'] : false}
            assignedGrades       = {item.place_category_key === 'school-attendance-zones'}
            catchmentDesignation = {type === 'publicSchools'}
            address              = {item.features.Address ? item.features.Address.split(',').slice(0, 1).join(',') : ''}
            distance             = {item.distance}
            features             = {item.features}
            featureKeys          = {['Grade Level']}
            url                  = {item.url}
            urlMessage           = {messages.url}
            handleClick          = {handleSchoolClick}
            secondaryUrl         = {item.features['GreatSchools School Details Webpage'] ? item.features['GreatSchools School Details Webpage'] : false}
            secondaryUrlMessage  = {item.features['GreatSchools School Details Webpage'] ? messages.urlGreatSchools : false}
            handleSecondaryClick = {item.features['GreatSchools School Details Webpage'] ? handleGreatSchoolClick : false}
            showLooKDeeper       = {false}
            showCatchmentInfo    = {false}
            showNoCatchmentInfo  = {false}
            hideDetailedRepLink
            contentStyle         = {styles[`${type}Content`]}
            expandedInfoStyle    = {styles[`${type}Expanded`]}
            expanded             = {expanded}
          />
        </div>/* NOTE : Only show 'Look Deeper' on first school item */
      );

      // NOTE : commented out since clearfix is not being used
      // if (schools.length > 3) {
      //   if ((schools.length === 4 && index === 1) ||
      //       (schools.length === 5 && index === 2) ||
      //       (schools.length === 6 && index === 2)) {
      //     return ([placeContent, clearFix]);
      //   }
      // }

      return (placeContent);
    });
  }

  const logoNameDescription = (
    <div className={classNames('row', styles.logoNameDescription, type == 'alternativeSchools' ? styles.altSchools : null )}>
      <div style={{ padding: '0' }} className={ type == 'privateSchools' ? 'col-4' : 'col-12'}>
        <h4 className={classNames(styles[type], styles.header)}>
          <FormattedMessage {...messages[`${type}Header`]} />
        </h4>

        <p className="description">
          <FormattedMessage {...messages[`${type}Paragraph`]} />
        </p>
      </div>

      { 
        type == 'privateSchools'
          ? <div className="col-4 offset-1">{bookIcon}</div>
          : null
      }
    </div>
  );

  return (
    <>
      {logoNameDescription}
      <div className="row">
        {schoolItemsColumns}
        {appleIcon}
      </div>
    </>
  );
}

export default AltSchools;
