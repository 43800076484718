import React from 'react';
import classNames from 'classnames';

import textHelpers from 'utils/textHelpers';
import SVGIcon from 'components/reports/SVGIcon';

const styles = require('../styles.css');
const mobStyles = require('../mobileStylesWNav.css');

export default function AddressReportBanner(props) {
  const address = textHelpers.truncateWords(
    props.address.split(',').slice(0, 1).join(','), 25
  );

  const city = textHelpers.truncateWords(
    props.address.split(',').slice(1, 2).join(','), 25
  );

  const state = textHelpers.truncateWords(
    props.address.split(',').slice(2, 3).join(','), 3
  );

  const cityState = `${city}, ${state}`;

  return (
    <div>
      <div className={classNames('d-xs-block d-none d-print-none', mobStyles.mobileAgentBranding)}>
        <div className="col-xs-12 col-sm-4">
          {props.agentSlug}
        </div>
      </div>
      <div className={classNames('col-xs-12 col-sm-8', styles.headerContent)}>
        <div className={classNames(styles.homePin, 'd-xs-none')}>
          <SVGIcon iconType="home-pin" />
        </div>
        <div className={classNames(styles.headerContentTitle, styles.headerContentWithPin)}>
          <h1 className={styles.address}>{address}</h1>
          <h5 className={styles.city}>{cityState}</h5>
          <h5 className={styles.headerTitle}>{props.reportTitle}</h5>
        </div>
      </div>
      <div className={classNames('d-xs-none', styles.printableAgentBranding)}>
        <div className="col-xs-12 col-sm-4">
          {props.agentSlug}
        </div>
      </div>
    </div>
  )
}