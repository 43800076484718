import React from 'react';

import Button from 'components/Button';

export default function TermsAndConditions(props) {
  return (
    <li>
      <Button href="https://hoodq.com/terms-conditions">Terms and Conditions</Button>
    </li> 
  );
}