/*
 * ScoresExplained Messages
 *
 * This contains all the text for the ScoresExplained component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.ScoresExplained.header',
    defaultMessage: 'How We Determine Our HoodQ Scores',
  },
  schoolRatings: {
    id: 'app.components.ScoresExplained.schoolRatings',
    defaultMessage: 'School Scores are based on publicly available provincial standardized testing results, aggregated at the school level, standardized, weighted, and rescaled to generate a score out of 10.',
  },
  parkRatings: {
    id: 'app.components.ScoresExplained.parkRatings',
    defaultMessage: 'The Parks & Recreation Score is based on two factors - the number of parks and recreation centres near this address and the number of facilities available at those locations.',
  },
  transitScore: {
    id: 'app.components.ScoresExplained.transitScore',
    defaultMessage: 'The Transit Score is based on proximity to public transit access points, both Street-Level and Rail, including buses, streetcars, subways, and trains.',
  },
  safetyScore: {
    id: 'app.components.ScoresExplained.safetyScore',
    defaultMessage: 'The Safety Score is based on proximity to emergency service agencies, specifically Fire Stations, Police Stations, and Hospitals.',
  },
  convenienceScore: {
    id: 'app.components.ScoresExplained.convenienceScore',
    defaultMessage: 'The Convenience Score is based on proximity to Gas Stations, Pharmacies, and Grocery Stores.',
  },
});
