/**
*
* HeaderDownArrow
*
*/

import React from 'react';

function HeaderDownArrow() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 3 264.5 97.5" enableBackground="new 0 0 264.5 97.5">
      <g>
        <polygon fill="#57585A" points="133.723145,94.013184 133.525879,93.98584 3.233398,75.805176 3.233398,2.5 6.233398,2.5 6.233398,73.194824 133.743652,90.986816 259.233398,75.177246 259.233398,2.5 262.233398,2.5 262.233398,77.822754" />
      </g>
    </svg>
  );
}

export default HeaderDownArrow;
