/*
 * Population Messages
 *
 * This contains all the text for the Population component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.Population.header',
    defaultMessage: 'Population',
  },
});
