/*
 * WalkTime Messages
 *
 * This contains all the text for the WalkTime component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  minutes: {
    id: 'app.components.WalkTime.minutes',
    defaultMessage: 'min',
  },
});
