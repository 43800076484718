/**
*
* PopulationByAge
*
*/

import React from 'react';
import { FormattedMessage } from 'react-intl';

import messages from './messages';
import styles from './styles.css';

function compareNumerically(a, b) { // sort nums large to small
  if (a > b) {
    return -1;
  }
  if (a < b) {
    return 1;
  }
  return 0; // numbers must be equal
}

function PopulationByAge(props) {
  const {
    populationByAge,
  } = props;

  const maxPopulationByAgeValues = Object.values(populationByAge).sort(
    (a, b) => compareNumerically(a, b)
  ).slice(0, 3);

  const highlightStyle = (value) => {
    if (maxPopulationByAgeValues.includes(value)) {
      return styles.rowHighlight;
    }

    return false;
  };

  const populationByAgeTable = (
    <table className={styles.dataTable}>
      <tbody>
        {Object.keys(populationByAge).map(range => (
          <tr className={highlightStyle(populationByAge[range])} key={range}>
            <td>{range}</td>
            <td>{populationByAge[range]}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <div className="col-xs-6">
      <h3><FormattedMessage {...messages.header} /></h3>
      {populationByAgeTable}
    </div>
  );
}

export default PopulationByAge;
