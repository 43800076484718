/* eslint no-multi-spaces: "off" */
/* eslint key-spacing: ["error", {
  "align": {
    "beforeColon": true,
    "afterColon": true,
    "on": "colon"
  }
}] */

/**
*
* SVGIcon
*
*/

import React from 'react';

import Cleaners           from './images/Cleaners';
import CoffeeShops        from './images/CoffeeShops';
import FireStations       from './images/FireStations';
import GasStations        from './images/GasStations';
import Grocery            from './images/Grocery';
import Gyms               from './images/Gyms';
import HealthCare         from './images/HealthCare';
// Parks ?
import Pharmacies         from './images/Pharmacies';
import Police             from './images/Police';
import RailTransit        from './images/RailTransit';
import School             from './images/School';
import StreetLevelTransit from './images/StreetLevelTransit';

// Facilities
import BallDiamond        from './images/facilities/BallDiamond';
import BasketballCourt    from './images/facilities/BasketballCourt';
import CommunityCenter    from './images/facilities/CommunityCenter';
import DogPark            from './images/facilities/DogPark';
import Generic            from './images/facilities/Generic';
import GolfGeneric        from './images/facilities/GolfGeneric';
import HandballCourt      from './images/facilities/HandballCourt';
import Playground         from './images/facilities/Playground';
import Pool               from './images/facilities/Pool';
import Rink               from './images/facilities/Rink';
import Stadium            from './images/facilities/Stadium';
import SkateboardPark     from './images/facilities/SkateboardPark';
import SkiingSnowboarding from './images/facilities/SkiingSnowboarding';
import SplashPad          from './images/facilities/SplashPad';
import SportsField        from './images/facilities/SportsField';
import SwimmingPool       from './images/facilities/SwimmingPool';
import TennisCourt        from './images/facilities/TennisCourt';
import Track              from './images/facilities/Track';
import Trail              from './images/facilities/Trail';
import VolleyballCourt    from './images/facilities/VolleyballCourt';
import WadingPool         from './images/facilities/WadingPool';

import MartialArts        from './images/others/MartialArts';
import Bowling            from './images/others/Bowling';
import Yoga               from './images/others/Yoga';

// others
import Circle             from './images/others/Circle';
import Clipboard          from './images/others/Clipboard';
import HomePin            from './images/others/HomePin';
import PedestrianIcon     from './images/others/PedestrianIcon';
import ShoppingMalls      from './images/others/ShoppingMalls';

// Icons for Hood Report
import Convenience         from './images/hood/report/Convenience';
import ParksRec            from './images/hood/report/ParksRec';
import Schools             from './images/hood/report/Schools';
import Transit             from './images/hood/report/Transit';
import ConvenienceDesktop  from './images/hood/desktop/Convenience';
import ParksRecDesktop     from './images/hood/desktop/ParksRec';
import SchoolsDesktop      from './images/hood/desktop/Schools';
import TransitDesktop      from './images/hood/desktop/Transit';
// import Professionals       from './images/lifestyle/Professionals';
import YoungerAdults       from './images/lifestyle/YoungerAdults';
import StartupFamilies     from './images/lifestyle/StartupFamilies';
import EstablishedFamilies from './images/lifestyle/EstablishedFamilies';
import OlderAdults         from './images/lifestyle/OlderAdults';
import MatureAdults        from './images/lifestyle/MatureAdults';

// Icons for Quiz
// import LSProfessionals       from './images/lifestyle/Professionals';
import LSYoungerAdults       from './images/lifestage/YoungerAdults';
import LSStartupFamilies     from './images/lifestage/StartupFamilies';
import LSEstablishedFamilies from './images/lifestage/EstablishedFamilies';
import LSOlderAdults         from './images/lifestage/OlderAdults';
import LSMatureAdults        from './images/lifestage/MatureAdults';

function SVGIcon(props) {
  const { iconType, x, y, height, width } = props;

  const iconKeyComponentPairs = {
    cleaners               : Cleaners,
    'coffee-shops'         : CoffeeShops,
    coffee_shops           : CoffeeShops, // places quiz
    'fire-stations'        : FireStations,
    'home-pin'             : HomePin,
    'gas-stations'         : GasStations,
    gas_stations           : GasStations, // places quiz
    // TODO : figure out a way to only have one instance of 'generic'
    Generic, // short-hand form
    generic                : Generic,
    parks                  : Generic,
    place_to_relax         : Generic, // places quiz
    grocery                : Grocery,
    grocery_stores         : Grocery, // places quiz
    gyms                   : Gyms,
    gym                    : Gyms, // places quiz
    school                 : School,
    'rail-transit'         : RailTransit,
    'health-care'          : HealthCare,
    pedestrian             : PedestrianIcon,
    pharmacies             : Pharmacies,
    police                 : Police,
    'shopping-malls'       : ShoppingMalls,
    'street-level-transit' : StreetLevelTransit,
    // Facilities
    ballDiamond            : BallDiamond,
    basketballCourt        : BasketballCourt,
    basketball             : BasketballCourt, // places quiz
    communityCentre        : CommunityCenter,
    community_centre       : CommunityCenter, // places quiz
    'recreation-centres'   : CommunityCenter,
    dogPark                : DogPark,
    dog_park               : DogPark, // places quiz
    // 'generic'              : Generic, // repeat
    golfGeneric            : GolfGeneric,
    handballCourt          : HandballCourt,
    playground             : Playground,
    pool                   : Pool,
    rink                   : Rink,
    rinks                  : Rink, // places quiz
    'skating-rinks'        : Rink,
    skateboardPark         : SkateboardPark,
    skiingSnowboarding     : SkiingSnowboarding,
    'ski-resorts'          : SkiingSnowboarding,
    splashPad              : SplashPad,
    sportsField            : SportsField,
    sports_field           : SportsField,  // places quiz
    swimming               : SwimmingPool,
    swimmingPool           : SwimmingPool,
    swimming_pool          : SwimmingPool, // places quiz
    tennisCourt            : TennisCourt,
    tennis                 : TennisCourt, // places quiz
    track                  : Track,
    running_track          : Track,
    trail                  : Trail,
    volleyballCourt        : VolleyballCourt,
    wadingPool             : WadingPool,
    wading_pool            : WadingPool, // places quiz
    'martial-arts'         : MartialArts,
    bowling                : Bowling,
    yoga                   : Yoga,
    yoga_studio            : Yoga, // places quiz
    arena                  : Stadium,
    arenas                 : Stadium,
    // Others
    circle                 : Circle,
    clipboard              : Clipboard,
    // Icons for Hood Report
    convenience            : Convenience,
    parksRec               : ParksRec,
    schools                : Schools,
    transit                : Transit,
    'convenience-desktop'  : ConvenienceDesktop,
    'parksRec-desktop'     : ParksRecDesktop,
    'schools-desktop'      : SchoolsDesktop,
    'transit-desktop'      : TransitDesktop,
    // professionals          : Professionals,
    youngerAdults          : YoungerAdults,
    startupFamilies        : StartupFamilies,
    establishedFamilies    : EstablishedFamilies,
    olderAdults            : OlderAdults,
    matureAdults           : MatureAdults,
    // Icons for quiz life-stage
    // professionalsLS        : LSProfessionals,
    youngerAdultsLS        : LSYoungerAdults,
    younger_adultsLS       : LSYoungerAdults,
    startupFamiliesLS      : LSStartupFamilies,
    startup_familiesLS     : LSStartupFamilies,
    establishedFamiliesLS  : LSEstablishedFamilies,
    established_familiesLS : LSEstablishedFamilies,
    olderAdultsLS          : LSOlderAdults,
    older_adultsLS         : LSOlderAdults,
    matureAdultsLS         : LSMatureAdults,
    mature_adultsLS        : LSMatureAdults,
  };

  const ComponentToRender = iconKeyComponentPairs[iconType] || Circle;

  // NOTE : x, y, height and width are only used by "Hood Report" icons
  // convenience, parksRec, schools, & transit
  return (
    <ComponentToRender x={x} y={y} height={height} width={width} />
  );
}

export default SVGIcon;
