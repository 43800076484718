/* eslint arrow-body-style: "off" */

// import Regex from 'utils/regexCollection';
import _ from 'lodash';
import Browser from 'utils/browserType';

const SINGLE_GRADES = ['Adult', 'Ungraded']; // TODO : internationalize ?
const RANGE_GRADES = [
  'PK',
  'K',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
];

function gradeRanger(grades) {
  let range = [];

  return RANGE_GRADES.reduce((memo, item, index, array) => {
    if (grades[0] === item) {
      range.push(grades.shift());

      if ((grades.length === 0) || (grades[0] !== array[index + 1])) {
        memo.push(range);
        range = [];
      }
    }

    return memo;
  }, []);
}

export default new class {
  pluralize(count, text) {
    let returnVar = null;
    if (/(s|ing)$/i.test(text)) {
      returnVar = `${count} ${text}`;
    } else if (count === 1) {
      returnVar = `1 ${text}`;
    } else if (/([^aeiouy]|qu)y$/i.test(text)) {
      // NOTE : removes ending 'y' and replaces it with 'ies'
      const textRoot = /(.+)y$/i.exec(text)[1];
      returnVar = `${count} ${textRoot}ies`;
    } else if (/(ch)$/i.test(text)) {
      returnVar = `${count} ${text}es`;
    } else if (/Facility/.test(text)) {
      returnVar = `${count} ${text.replace('Facility', 'Facilities')}`;
    } else {
      returnVar = `${count} ${text}s`;
    }
    return returnVar;
  }

  // NOTE : original toSentence method
  // toSentence(array) {
  //   return array.reduce((memo, item, index) => {
  //     let returnVar = null;
  //     if (index === 0) {
  //       returnVar = item;
  //     } else if (index === array.length - 1) {
  //       returnVar = `${memo} and ${item}`;
  //     } else {
  //       returnVar = `${memo}, and ${item}`;
  //     }
  //     return returnVar;
  //   }, '');
  // }

  toSentence(array) {
    return array.reduce((memo, item, index) => {
      let returnVar = null;
      if (index === 0) {
        returnVar = item;
      } else if (index === array.length - 1) {
        returnVar = `${memo} and ${item}`;
      } else {
        returnVar = `${memo}, ${item}`;
      }
      return returnVar;
    }, '');
  }

  truncateWords(text, maxLength) {
    let finalString = '';
    const parts = text.split(' ');

    for (const part of parts) {
      if (finalString.length + part.length <= maxLength) {
        finalString = `${finalString} ${part}`;
      } else {
        return finalString;
      }
    }

    return finalString;
  }

  toCurrency(value, currency) {
    // NOTE : change toFixed(0) to toFixed(2) to add 2 decimals [cents]
    return currency + value.toFixed(0).replace(/./g, (c, i, a) => {
      return i > 0 && c !== ',' && (a.length - i) % 3 === 0 ? `,${c}` : c;
    });
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  shortenUrl(url) {
    if (!url) {
      return null;
    }

    if (Browser.IE === true) {
      // NOTE : new URL(url) not supported by IE
      return url;
    }

    const parsedUrl = new URL(url);

    if (parsedUrl && parsedUrl.origin && parsedUrl.pathname && parsedUrl.pathname.length > 0) {
      return `${parsedUrl.origin}...`;
    }
    return url;

    // NOTE : commented out until further notice
    // console.log('========================');
    // console.log('inside shortenUrl(url)');
    // console.log('url');
    // console.log(url);
    // var parsedUrl = new URL(url);
    // console.log('parsedUrl');
    // console.log(parsedUrl);
    // console.log('Regex.urlShortener.test(url)');
    // console.log(Regex.urlShortener.test(url));
    // console.log('========================');
    //
    // // const urlArray = Regex.urlShortener.exec(url);
    //
    // // TODO : check if more logic is necessary for situations when url is
    // // short enough to be shown in its entirety
    // if (!urlArray) {
    //   return url;
    // }
    //
    // return `${urlArray[1]}...${urlArray[4]}`;
  }

  gradesToString(grades, openingText = '') {
    const ranges = [];
    const singles = [];

    grades.forEach((grade) => {
      if (_.includes(SINGLE_GRADES, grade)) {
        singles.push(grade);
      } else if (_.includes(RANGE_GRADES, grade)) {
        ranges.push(grade);
      }
    });

    const result = gradeRanger(ranges).reduce((memo, item) => {
      if (item.length === 1) {
        memo.push(item[0]);
      } else {
        memo.push(`${item[0]} to ${item[item.length - 1]}`);
      }

      return memo;
    }, []).concat(singles).join(', ');

    return `${openingText}${result}`; // NOTE : DOES NOT have the word 'Grades'
  }

  decimalToPercent(decimal) {
    return `${Math.trunc(decimal * 100)}%`;
  }
};
