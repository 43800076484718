/* eslint no-multi-spaces: "off" */
/* eslint react/jsx-equals-spacing: "off" */

/**
*
* MarketDemographicsAlt
*
*/

import React from 'react';
import classNames from 'classnames';

// import messages from './messages';
const styles = require('./styles.css');

// import AgeOfHousing                   from 'components/places/reports/_shared/_market/AgeOfHousing';
import Households                     from '../Households';
import HouseholdSize                  from '../HouseholdSize';
// import HousePriceIndex                from 'components/places/reports/_shared/_market/HousePriceIndex';
import Language                       from '../Language';
// import MarketShift                    from 'components/places/reports/_shared/_market/MarketShift';
import PeriodHomeConstruction         from '../PeriodHomeConstruction';
import Population                     from '../Population';
import PopulationByAge                from '../PopulationByAge';
import PropertyTypesPriceDistribution from '../PropertyTypesPriceDistribution';
import RentersOwners                  from '../RentersOwners';

import { PropertyTypeMarketData } from '../../types';
// import Tenure                         from 'components/places/reports/_shared/_market/Tenure';

interface IProps {
  hoodName: string;
  pricesByPropertyType: PropertyTypeMarketData
}

export default function MarketDemographics(props) {
  const {
    hoodName,
    hoodConstruction,
    cityConstruction,
    pricesByPropertyType,
    language,
    owners,
    renters,
    population,
    households,
    populationByAge,
    householdSize,
  } = props;

  const priceDistroCondo    = pricesByPropertyType.condo,
        priceDistroBungalow = pricesByPropertyType.bungalow,
        priceDistroDuplex   = pricesByPropertyType.duplex,
        priceDistroRow      = pricesByPropertyType.row;

  // TODO : get dynamically
  const cityName = 'City of Toronto';

  // NOTES : Section 1
  // const avgHousePriceIndex = (<HousePriceIndex avgHousePriceIndexNum={avgHousePriceIndexNum} />);
  const periodHomeConstruction = (
    <PeriodHomeConstruction
      hoodName         = {hoodName}
      cityName         = {cityName}
      hoodConstruction = {hoodConstruction}
      cityConstruction = {cityConstruction}
    />
  );
  const propertyTypePriceDistribution = (
    <PropertyTypesPriceDistribution
      priceDistroCondo    = {priceDistroCondo}
      priceDistroBungalow = {priceDistroBungalow}
      priceDistroDuplex   = {priceDistroDuplex}
      priceDistroRow      = {priceDistroRow}
    />
  );

  // NOTES : Section 2
  const languageContent = (<Language language={language} />);
  // const avgTenureAndMarketShift = (
  //   <div className="col-xs-12" style={{ borderBottom: 'dotted 3px gray', paddingLeft: '30px', paddingRight: '30px', marginBottom: '15px' }}>
  //     <Tenure avgTenure={avgTenure} />
  //     <MarketShift avgMarketShift={avgMarketShift} />
  //   </div>
  // );
  const rentersOwners = (
    <RentersOwners
      owners  = {owners}
      renters = {renters}
    />
  );
  // const ageOfHousing = (<AgeOfHousing ageOfHousingNum={ageOfHousingNum} />);

  // NOTES : Section 3
  const populationByAgeContent = (<PopulationByAge populationByAge={populationByAge} />);
  const populationContent      = (<Population populationNum={population} />);
  const householdsContent      = (<Households householdsNum={households} />);
  const householdSizeContent   = (
    <HouseholdSize
      hoodName      = {hoodName}
      householdSize = {householdSize}
    />
  );

  const sectionOne = (
    <div className="row" style={{ borderBottom: 'solid 3px gray' }}>
      {/* avgHousePriceIndex */}
      {periodHomeConstruction}
      {propertyTypePriceDistribution}
    </div>
  );

  const sectionTwo = (
    <div className="col-xs-12 col-sm-4">
      {languageContent}
      {/* avgTenureAndMarketShift */}
      {rentersOwners}
      {/* ageOfHousing */}
    </div>
  );

  const sectionThree = (
    <div className="col-xs-12 col-sm-8" style={{ paddingTop: '5px', borderLeft: 'dotted 3px gray' }}>
      {populationByAgeContent}
      <div className="col-xs-6 text-left">
        <div className="row">
          <div className="col-xs-6">
            {populationContent}
          </div>
          <div className="col-xs-6">
            {householdsContent}
          </div>
        </div>
        {householdSizeContent}
      </div>
    </div>
  );

  return (
    <div className={classNames(styles.marketDemographics, 'col-xs-12')}>
      {sectionOne}
      <div className="row" style={{ marginTop: '5px' }}>
        {sectionTwo}
        {sectionThree}
      </div>
    </div>
  );
}
