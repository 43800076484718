/**
*
* Distance
*
*/

import React from 'react';

import styles from './styles.css';

const KM_TO_MILES = 0.621;

function Distance(props) {
  let displayedDistance;

  if (props.locale === 'en-US') {
    displayedDistance = `${Math.round(props.distance * KM_TO_MILES * 100) / 100} miles`;
  } else {
    displayedDistance = `${Math.round(props.distance * 100) / 100} KM`;
  }

  return (
    <span className={styles.distance}>{displayedDistance}</span>
  );
}



export default Distance;
