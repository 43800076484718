/**
*
* VolleyballCourt
*
*/

import React from 'react';

function VolleyballCourt() {
  return (
    <svg version="1.1" y="0px" x="0px" viewBox="0 0 72 72">
      <g fill="#01818E">
        <path d="m51.533 6.0774c1.5388 1.0178 3.1246 1.972 4.6069 3.0663 3.4689 2.5609 6.2816 5.7384 8.5071 9.4284 0.19947 0.33075 0.33144 0.77136 0.31493 1.1532-0.45117 10.435-4.0947 19.52-11.501 26.99-1.775 1.7903-3.7558 3.3379-5.8992 4.6685-0.17072 0.10597-0.46052 0.15432-0.64303 0.087517-5.0562-1.8506-9.5934-4.5802-13.746-8.1567 6.0896-4.1744 10.35-9.8181 13.368-16.421 2.991-6.5451 5.1541-13.312 4.8006-20.655 0.063-0.0541 0.127-0.1079 0.191-0.1616z" />
        <path d="m61.755 57.285c-1.045 1.081-1.9268 2.0684-2.8872 2.9724-2.7598 2.5979-5.8598 4.7051-9.361 6.1724-0.51709 0.2167-1.1962 0.14683-1.7849 0.077606-9.4175-1.1074-17.659-4.882-24.758-11.121-4.8281-4.2436-8.5385-9.3425-11.034-15.282-0.64846-1.5435-1.1136-3.1649-1.6333-4.7602-0.058101-0.17836 0.005398-0.49794 0.13599-0.62113 2.5358-2.392 5.4736-3.7799 9.0444-3.5952 0.50973 0.026367 0.76403 0.22688 0.99514 0.66328 4.096 7.7345 9.9325 13.844 17.344 18.462 4.9995 3.1153 10.403 5.2382 16.2 6.2651 2.2713 0.40236 4.5979 0.4928 6.8995 0.72274 0.207 0.021 0.416 0.023 0.839 0.044z" />
        <path d="m30.774 41.044c-2.4925-2.5778-4.6135-5.4209-6.2811-8.5964-0.32417-0.6173-0.92494-1.2821-0.85504-1.8592 0.06954-0.57416 0.82318-1.0652 1.2762-1.5932 3.8829-4.5253 6.5992-9.6785 8.3522-15.367 1.124-3.6476 1.7534-7.3793 2.0862-11.295 1.3458 0.062933 2.6746 0.067881 3.9911 0.19828 2.944 0.2909 5.805 0.9605 8.575 2.0176 0.43238 0.165 0.52571 0.38177 0.53428 0.80818 0.11175 5.5624-1.1336 10.876-2.967 16.073-1.6993 4.8168-4.0929 9.2589-7.3689 13.201-2.0411 2.4562-4.398 4.5591-7.0829 6.2913-0.071 0.046-0.154 0.073-0.259 0.121z" />
        <path d="m42.979 68.627c-1.2226 0.17794-2.4404 0.40369-3.6686 0.52485-3.6816 0.3632-7.33 0.11885-10.936-0.71709-4.0842-0.94685-7.8508-2.6153-11.306-4.9882-4.67-3.208-8.2944-7.346-10.884-12.372-0.73638-1.4292-1.2732-2.9621-1.8795-4.4563-0.083622-0.20611-0.10093-0.48043-0.044507-0.69582 0.75736-2.891 1.8845-5.6222 3.5671-8.1073 0.043799-0.06469 0.10028-0.1208 0.14455-0.17343 6.2494 16.47 18.209 26.398 35.007 30.984z" />
        <path d="m3.0848 41.987c-0.63126-3.2306-0.79741-6.4753-0.34484-9.7096 0.31309-2.2376 0.8895-4.4392 1.3774-6.6505 0.065974-0.29902 0.25535-0.6036 0.46654-0.83289 3.3512-3.6385 7.3617-6.293 12.037-7.9045 4.1645-1.4355 8.4547-1.9327 12.845-1.5186 0.0595 0.005612 0.11689 0.03359 0.32937 0.097434-0.91123 1.8254-1.7487 3.6465-2.7117 5.3986-1.4708 2.676-3.3109 5.0928-5.4737 7.2524-0.1964 0.19611-0.573 0.3569-0.83572 0.31986-4.7377-0.66784-8.6789 0.90852-12.016 4.2112-2.4857 2.465-4.138 5.442-5.3279 8.701-0.067076 0.18372-0.13551 0.36713-0.21145 0.54726-0.0135 0.033-0.0671 0.048-0.1328 0.091z" />
        <path d="m67.535 24.491c0.37022 1.3579 0.78976 2.7045 1.1023 4.0755 1.4728 6.4613 1.0083 12.82-1.3182 18.998-0.80204 2.1299-2.0037 4.1102-3.0479 6.1461-0.086899 0.16944-0.36075 0.33398-0.5556 0.34372-4.2598 0.21302-8.4656-0.14408-12.595-1.252-0.078224-0.020985-0.15504-0.04723-0.313-0.095745 9.468-7.122 14.717-16.665 16.728-28.215z" />
        <path d="m32.528 2.5063c-0.24196 3.3013-0.78555 6.4062-1.6604 9.4556-0.098469 0.34324-0.27039 0.35671-0.56875 0.35029-2.3769-0.051144-4.7655-0.24729-7.1291-0.085767-5.6805 0.38819-10.785 2.3825-15.245 5.9591-0.16353 0.13113-0.32959 0.2591-0.53658 0.32536 5.8516-9.2259 14.172-14.58 25.14-16.004z" />
      </g>
    </svg>
  );
}

export default VolleyballCourt;
