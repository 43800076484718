/* eslint no-multi-spaces: "off" */

/**
*
* DataSetImage
*
*/

import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import messages from './messages';
import styles from './styles.css';

import SVGIcon from '../../../SVGIcon';

import convenienceImg from 'assets/reports/detailed/quad_convenience.jpg';
import elementaryImg  from 'assets/reports/detailed/quad_elementary.jpg';
import highImg from 'assets/reports/detailed/quad_high.jpg';
import parksImg from 'assets/reports/detailed/quad_parks.jpg';
import safetyImg from 'assets/reports/detailed/quad_safety.jpg';
import transitImg from 'assets/reports/detailed/quad_transit.jpg';


const IMAGE_DATATYPE_MAP = {
  convenience: convenienceImg,
  elementary:  elementaryImg,
  high:        highImg,
  parks:       parksImg,
  safety:      safetyImg,
  transit:     transitImg,
}

function DataSetImage(props) {
  const { dataSetType, score } = props;

  let iconsDisplay = null;

  switch (dataSetType) {
    case 'elementary':
      iconsDisplay = <SVGIcon iconType="school" key="school" />;
      break;
    case 'transit':
      iconsDisplay = [
        <SVGIcon iconType="street-level-transit" key="street-level-transit" />,
        <SVGIcon iconType="rail-transit"         key="rail-transit" />,
      ];
      break;
    case 'safety':
      iconsDisplay = [
        <SVGIcon iconType="fire-stations" key="fire-stations" />,
        <SVGIcon iconType="health-care"   key="health-care" />,
        <SVGIcon iconType="police"        key="police" />,
      ];
      break;
    case 'high':
      iconsDisplay = <SVGIcon iconType="school" key="school" />;
      break;
    case 'parks':
      iconsDisplay = <SVGIcon iconType="parks" key="parks" />;
      break;
    case 'convenience':
      iconsDisplay = [
        <SVGIcon iconType="grocery"      key="grocery" />,
        <SVGIcon iconType="gas-stations" key="gas-stations" />,
        <SVGIcon iconType="pharmacies"   key="pharmacies" />,
      ];
      break;
    default:
      iconsDisplay = <SVGIcon iconType="generic" key="generic" />;
  }

  let scoreDisplay = null;
  if (score) {
    scoreDisplay = (
      <div className={styles.scoreIcon} >
        <SVGIcon iconType="circle" />
        <div>
          <span>{score}</span>
        </div>
      </div>
    );
  }

  return (
    <div className={classNames('col-4 px-0', styles.dataSetImage)}>
      <img src={IMAGE_DATATYPE_MAP[dataSetType]} alt={dataSetType} />
      <div className={styles.nameAndIcon}>
        <span><FormattedMessage {...messages[dataSetType]} /></span>
        <div className={styles.iconsDisplay}>{iconsDisplay}</div>
      </div>
      {scoreDisplay}
    </div>
  );
}



export default DataSetImage;
