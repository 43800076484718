/* eslint no-multi-spaces: "off" */
/* eslint react/jsx-equals-spacing: "off" */

/**
*
* ParksReport
*
*/

import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import messages from './messages';
import styles from './styles.css';

import DetailedPlaceBlock from '../DetailedPlaceBlock';
import SVGIcon from '../../../SVGIcon';

const MAX_PARKS_NUMBER = 5;

function ParksReport(props) {
  // TODO : consider dynamic descriptions for this section
  const { parks, locale, score } = props; // paragraph,

  let parksContent = [];
  let parksNumber = MAX_PARKS_NUMBER;

  if (Object.keys(parks).length < MAX_PARKS_NUMBER) {
    parksNumber = Object.keys(parks).length;
  }

  // NOTE : only display first 5 results or less
  // TODO : check if needs to be sorted in any specific way
  for (let index = 0; index < parksNumber; index++) {
    const item = parks[index];

    // TODO : add features missing
    // through item.features...
    parksContent.push(
      <DetailedPlaceBlock
        icon       = {item.place_category_key}
        name       = {item.name}
        distance   = {item.distance}
        address    = {item.features.Address}
        paragraph  = {item.description}
        facilities = {item.features && item.features.Facilities ? item.features.Facilities : null}
        url        = {item.url}
        locale     = {locale}
        key        = {item.name}
      />
    );
  }

  let scoreDisplay = null;
  if (score) {
    scoreDisplay = (
      <div>
        <div className={classNames(styles.iconXS, 'd-xs-block d-none')}>
          <SVGIcon iconType="circle" />
          <div className={styles.score}>
            <span>{score}</span>
          </div>
        </div>
        <div className={classNames(styles.icon, 'd-xs-none')}>
          <SVGIcon iconType="circle" />
          <div className={styles.score}>
            <span>{score}</span>
          </div>
        </div>
      </div>
    );
  }

  const paragraph = locale === 'en-CA' ? (
    <FormattedMessage {...messages.descriptionCA} />
  ) : (
    <FormattedMessage {...messages.description} />
  );

  return (
    <div className={classNames('row', styles.parksReport)}>
      <div className="offset-1 col-10">
        {scoreDisplay}
        <div className={styles.titleParagraph}>
          <h2>
            <FormattedMessage {...messages.headerParks} />
            <span className={styles.andRec}>
              <FormattedMessage {...messages.headerRec} />
            </span>
          </h2>
          <p>{paragraph}</p>
        </div>
        {parksContent}
      </div>
    </div>
  );
}



export default ParksReport;
