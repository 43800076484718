import React from 'react';
import classNames from 'classnames';

import ReportBanner from '../banners/ReportBanner';
import AddressReportBanner from '../banners/AddressReportBanner';
import { addFacilities } from './helpers';

import request from 'utils/request';
import ScoresExplained from './components/ScoresExplained';

import ReportHelmet from '../ReportHelmet';
import Footer from '../Footer';
import DataSetsGallery from './components/DataSetsGallery';
import SchoolsReport from './components/SchoolsReport';
import ParksReport from './components/ParksReport';
import TransitReport from './components/TransitReport';
import SafetyReport from './components/SafetyReport';
import ConvenienceReport from './components/ConvenienceReport';
import { mapMarkerGenerator } from '../mapMarkers';
import Browser from 'utils/browserType';
import DetailedMap from './components/DetailedMap';

import { getPackageContents } from '../utils';
import getBranding from '../../branding';
import Navigation from 'components/navigation/Navigation';
import ReportNav from 'components/navigation/ReportNav';

const styles = require('./styles.css');

const TRANSIT_TYPE_DISPLAY_ORDER = ['rail-transit', 'street-level-transit', 'water-transit'];
const SAFETY_TYPE_DISPLAY_ORDER  = ['health-care', 'fire-stations', 'police'];

// TODO: move to shared module.
function fetchPackage(packageID, authToken) {
  return request(`${process.env.PLATFORM_URL}/reports/address/${packageID}.json?auth_token=${authToken}`)
    .then(payload => payload.data.branded_report);
}

function sortByDistance(places) {
  return places.sort((a, b) => a.distance > b.distance);
}

function closestPlacesByCategory(places, categories) {
  return categories.map(category => {
    return sortByDistance(places.filter(p => p.place_category_key === category))[0]
  }).filter(p => p !== undefined);
}

function injectGoogleMaps(libraries=[], key) {
  return new Promise((resolve, reject) => {
    const scriptTag = document.createElement('script');
    let params = { v: '3.exp', key };

    if (libraries.length > 0) {
      params.libraries = libraries.join(',');
    }

    const query = Object.keys(params).map(key => `${key}=${params[key]}`).join("&");
    scriptTag.src = `https://maps.googleapis.com/maps/api/js?${query}`;
    scriptTag.onload = resolve;
    document.body.append(scriptTag);
  });
}

class DetailedAddressReport extends React.Component {
  constructor(props) {
    super(props);

    window.setDetailedScores = this.setDetailedScores.bind(this);

    this.enableScoresEl = document.getElementById('enableScores');
    this.disableScoresEl = document.getElementById('disableScores');

    this.setDetailedScores(false);

    this.reportTitle = 'HoodQ Detailed Report™';

    this.state = {
      loading: false,
      displayScores: false,
      gmapsLoaded: false,
      scores: {
        elementary:  null,
        transit:     null,
        safety:      null,
        high:        null,
        parks:       null,
        convenience: null
      },
      availableDatasets: {
        elementary:  false,
        transit:     false,
        safety:      false,
        high:        false,
        parks:       false,
        convenience: false
      },
      report: {}
    };
  }

  setDetailedScores = (displayScores) => {
    this.setState({ displayScores });

    if (this.enableScoresEl) {
      this.enableScoresEl.checked = displayScores;
    }

    if (this.disableScoresEl) {
      this.disableScoresEl.checked = !displayScores;
    }
  }

  componentWillMount() {
    this.loadReport(JSON.parse(this.props.document));
    injectGoogleMaps([], 'AIzaSyC4bLc4BzS7iGTE_s5aDFD8Nwm7557df2M').then(_ => {
      this.setState({ gmapsLoaded: true });
    })
  }

  loadReport = (report) => {
    this.reportTitle = `${getBranding(report.branding).brandName} Detailed Report™`;
    
    this.setState({ 
      report: {
        ...report,
        package_contents: report.package
          ? report.package.options.package_contents
          : getPackageContents(report),
        public_schools: {
          assigned: sortByDistance(report.public_schools.assigned),
          unassigned: sortByDistance(report.public_schools.unassigned)
        },
        catholic_schools: {
          assigned: sortByDistance(report.catholic_schools.assigned),
          unassigned: sortByDistance(report.catholic_schools.unassigned)
        },
        private_schools: sortByDistance(report.private_schools),
        parks: sortByDistance(report.parks),
        facilities: addFacilities(report.parks),
        transit: closestPlacesByCategory(report.transit, TRANSIT_TYPE_DISPLAY_ORDER),
        safety:  closestPlacesByCategory(report.safety, SAFETY_TYPE_DISPLAY_ORDER),
      },
      scores: {
        elementary:  report.school_scores.elementary,
        transit:     report.transit_score,
        safety:      report.safety_score,
        high:        report.school_scores.high,
        parks:       report.parks_score,
        convenience: report.convenience_score
      },
      availableDatasets: {
        elementary:  true,
        transit:     report.transit.length > 0,
        safety:      report.safety.length > 0,
        high:        true,
        parks:       report.parks.length > 0,
        convenience: report.convenience.length > 0
      },
      loading: false
    });
  }

  renderSummary = () => {
    return <DataSetsGallery scores={this.state.displayScores ? this.state.scores : {}} availableDatasets={this.state.availableDatasets} />;
  }

  renderSchools = () => {
     const { public_schools, catholic_schools, private_schools, locale } = this.state.report;

     return <SchoolsReport
      assignedSchoolsContent           = {public_schools.assigned}
      unassignedSchoolsContent         = {public_schools.unassigned}
      catholicAssignedSchoolsContent   = {catholic_schools.assigned}
      catholicUnassignedSchoolsContent = {catholic_schools.unassigned}
      privateSchoolsContent            = {private_schools}
      locale                           = {locale}
      displayScores                    = {this.state.displayScores}
     />
  }

  renderParks = () => {
    const { parks, parks_paragraph, parks_score, locale } = this.state.report;

    return (parks && parks_paragraph)
      ? <ParksReport
          parks     = {parks}
          paragraph = {parks_paragraph}
          locale    = {locale}
          score     = {this.state.displayScores ? parks_score : false}
        />
      : null;
  }

  renderTransit = () => {
    const { transit, transit_paragraph, transit_score, locale } = this.state.report;
    return <TransitReport
      transit   = {transit}
      paragraph = {transit_paragraph}
      locale    = {locale}
      score     = {this.state.displayScores ? transit_score : false}
    />
  }

  renderSafety = () => {
    const { safety, safety_paragraph, safety_score, locale } = this.state.report;
    return <SafetyReport
      safety    = {safety}
      paragraph = {safety_paragraph}
      locale    = {locale}
      score     = {this.state.displayScores ? safety_score : false }
    />
  }

  renderConvenience = () => {
    const { convenience, convenience_paragraph, convenience_score, locale } = this.state.report;
    return <ConvenienceReport
      convenience = {convenience}
      paragraph   = {convenience_paragraph}
      locale      = {locale}
      score       = {this.state.displayScores ? convenience_score : false}
    />
  }

  renderScoresExplained = () => {
    return this.state.displayScores ? <ScoresExplained /> : null;
  }

  shouldShowMap = () => {
    const { public_schools, catholic_schools, parks, transit, safety, convenience } = this.state.report;

    return (public_schools.assigned || public_schools.unassigned) &&
      (catholic_schools.assigned || catholic_schools.unassigned) &&
      parks && transit && safety && convenience;
  }

  renderDetailedMap = () => {
    if (!this.shouldShowMap() || !this.state.gmapsLoaded) return null;
    const { 
      public_schools,
      catholic_schools,
      parks,
      transit,
      safety,
      convenience,
      latitude, 
      longitude 
    } = this.state.report;

    const places = {
      A:  public_schools.assigned,
      AA: public_schools.unassigned,
      B:  catholic_schools.assigned,
      BB: catholic_schools.unassigned,
      C:  parks,
      D:  transit,
      E:  safety,
      F:  convenience
    };

    const [markers, polygons] = mapMarkerGenerator(places, latitude, longitude);

    const dataSetMapStyle = Browser.Firefox === true 
      ? classNames(styles.dataSetMap)
      : classNames(styles.dataSetMapPrint);
    
    return (
      <div className={dataSetMapStyle}>
        <DetailedMap
          markers       = {markers}
          polygons      = {polygons}
          defaultCenter = {{ lat: latitude, lng: longitude }}
        />
      </div>
    );
  }

  render() {
    const { user, address, branding, campaign, package_contents } = this.state.report;
    const { params: { packageID }, hideSidebar } = this.props;

    const package_options = !this.state.loading ? this.state.report.package.options : null;

    return this.state.loading ? null : (
      <div className={styles.dataSetReport} style={{ marginTop: '20px' }} id="printJS-form">
        <div className={classNames('content-block', styles.dataSetReportParagraph)}>
          {this.renderSummary()}
          {this.renderSchools()}
          {this.state.availableDatasets.parks && this.renderParks()}
          {this.state.availableDatasets.transit && this.renderTransit()}
          {this.state.availableDatasets.safety && this.renderSafety()}
          {this.state.availableDatasets.convenience && this.renderConvenience()}
          {this.renderScoresExplained()}
          {this.renderDetailedMap()}
        </div>
      </div>
    );
  }
}

// const mapStateToProps = createStructuredSelector({
//   currentLocation: selectLocationState(),
//   displayScores:   selectSearchOptions()
// });

export default DetailedAddressReport;