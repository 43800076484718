/**
*
* SchoolLogo
*
*/

import React from 'react';

function SchoolLogo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 72 37.25">
      <rect y="9.9933" x="44.637" height="21.379" width="18.361" fill="#71CFF5" />
      <rect y="12.397" x="46.57" height="5.0305" width="3.6891" fill="#fff" />
      <rect y="12.397" x="52.103" height="5.0305" width="3.6891" fill="#fff" />
      <rect y="12.397" x="57.491" height="5.0305" width="3.6891" fill="#fff" />
      <rect y="22.589" x="46.57" height="5.0305" width="3.6891" fill="#fff" />
      <rect y="22.589" x="52.103" height="5.0305" width="3.6891" fill="#D6EFFB" />
      <rect y="22.589" x="57.491" height="5.0305" width="3.6891" fill="#D6EFFB" />
      <rect y="31.138" x="44.08" height="3.0577" width="20.093" fill="#39C5F3" />
      <rect y="8.6664" x="43.982" height="1.456" width="20.19" fill="#39C5F3" />
      <rect y="9.9933" x="3.1749" height="21.379" width="18.361" fill="#83D4F6" />
      <rect y="12.397" x="15.914" height="5.0305" width="3.6891" fill="#DEF2FC" />
      <rect y="12.397" x="10.381" height="5.0305" width="3.6891" fill="#DEF2FC" />
      <rect y="12.397" x="4.9933" height="5.0305" width="3.6891" fill="#DEF2FC" />
      <rect y="22.589" x="15.914" height="5.0305" width="3.6891" fill="#D6EFFB" />
      <rect y="22.589" x="10.381" height="5.0305" width="3.6891" fill="#fff" />
      <rect y="22.589" x="4.9933" height="5.0305" width="3.6891" fill="#fff" />
      <rect y="31.138" x="2" height="3.0577" width="20.093" fill="#39C5F3" />
      <rect y="8.6664" x="2" height="1.456" width="20.19" fill="#39C5F3" />
      <rect y="8.1325" x="21.511" height="22.86" width="23.237" fill="#5ECBF4" />
      <rect y="5.3661" x="21.511" height="3.0577" width="23.297" fill="#39C5F3" />
      <path d="m26.435 6.477h0.59861v0.11813h-0.47286v0.2766h0.44014v0.11782h-0.44014v0.29756h0.49667v0.11782h-0.62243v-0.9279z" fill="#fff" />
      <path d="m27.658 6.477h0.114l0.39696 0.92792h-0.14672l-0.093046-0.22928h-0.44173l-0.091776 0.22928h-0.14672l0.41-0.9279zm0.225 0.5885l-0.17276-0.42585h-0.002857l-0.17561 0.42585h0.352z" fill="#fff" />
      <path d="m28.448 7.2109c0.022865 0.034297 0.051764 0.059385 0.08733 0.075581 0.03525 0.015878 0.07177 0.024135 0.10924 0.024135 0.02096 0 0.042553-0.003176 0.064148-0.009844 0.021912-0.006669 0.041918-0.016513 0.059702-0.029533 0.017784-0.013021 0.032709-0.029216 0.043825-0.048588 0.011431-0.019054 0.017147-0.041283 0.017147-0.066689 0-0.035885-0.011433-0.063195-0.033979-0.081932-0.022863-0.018736-0.051128-0.034615-0.084473-0.046999-0.033661-0.012703-0.070499-0.025088-0.11019-0.036838-0.039696-0.01175-0.076534-0.028264-0.1102-0.049223-0.033663-0.020959-0.061607-0.049222-0.084473-0.084472-0.022547-0.03525-0.033979-0.083202-0.033979-0.14354 0-0.026993 0.005716-0.055891 0.017784-0.086378 0.011749-0.030486 0.030169-0.058432 0.055573-0.083837 0.025406-0.025405 0.057796-0.046682 0.097492-0.063513 0.039696-0.017148 0.087648-0.025723 0.14354-0.025723 0.050812 0 0.099081 0.006987 0.14544 0.020959 0.046366 0.013973 0.087013 0.042554 0.12194 0.085107l-0.10226 0.093047c-0.01556-0.024453-0.038107-0.044142-0.066689-0.05875-0.028898-0.014925-0.061607-0.022229-0.098446-0.022229-0.034931 0-0.063829 0.004446-0.087011 0.013655-0.023184 0.009209-0.04192 0.020959-0.055893 0.03525-0.013971 0.014608-0.023817 0.030169-0.029215 0.046682-0.005716 0.016513-0.008575 0.031756-0.008575 0.045729 0 0.039378 0.011116 0.069547 0.033979 0.090506 0.022865 0.020959 0.050812 0.038108 0.084473 0.051128 0.033663 0.013021 0.070499 0.02477 0.1102 0.03525 0.039696 0.01048 0.076534 0.025088 0.10988 0.044142 0.033661 0.018736 0.061924 0.043824 0.084789 0.075898 0.022547 0.031757 0.033979 0.075898 0.033979 0.13179 0 0.044459-0.008575 0.084472-0.025723 0.12036-0.016832 0.035885-0.039696 0.066053-0.068594 0.090506-0.028898 0.024452-0.062878 0.043189-0.10226 0.056209-0.039377 0.013338-0.081297 0.019689-0.12576 0.019689-0.059385 0-0.11591-0.01048-0.16926-0.031439-0.053034-0.020959-0.095587-0.053351-0.12703-0.096857l0.103-0.0894z" fill="#fff" />
      <path d="m29.474 6.5952h-0.29883v-0.1182h0.72341v0.11813h-0.29883v0.80979h-0.12576v-0.8097z" fill="#fff" />
      <path d="m30.191 7.2109c0.022865 0.034297 0.051764 0.059385 0.087332 0.075581 0.03525 0.015878 0.07177 0.024135 0.10924 0.024135 0.02096 0 0.042553-0.003176 0.064148-0.009844 0.021912-0.006669 0.041918-0.016513 0.059702-0.029533 0.017784-0.013021 0.032709-0.029216 0.043825-0.048588 0.011431-0.019054 0.017147-0.041283 0.017147-0.066689 0-0.035885-0.011431-0.063195-0.033979-0.081932-0.022863-0.018736-0.051126-0.034615-0.084471-0.046999-0.033663-0.012703-0.070499-0.025088-0.1102-0.036838-0.039696-0.01175-0.076534-0.028264-0.1102-0.049223-0.033663-0.020959-0.061607-0.049222-0.084473-0.084472-0.022547-0.03525-0.033979-0.083202-0.033979-0.14354 0-0.026993 0.005716-0.055891 0.017784-0.086378 0.011749-0.030486 0.030169-0.058432 0.055573-0.083837 0.025406-0.025405 0.057798-0.046682 0.097492-0.063513 0.039696-0.017148 0.087648-0.025723 0.14354-0.025723 0.050812 0 0.099081 0.006987 0.14544 0.020959 0.046366 0.013973 0.087013 0.042554 0.12195 0.085107l-0.10226 0.093047c-0.01556-0.024453-0.038107-0.044142-0.066689-0.05875-0.028898-0.014925-0.061607-0.022229-0.098446-0.022229-0.034931 0-0.063829 0.004446-0.087011 0.013655-0.023184 0.009209-0.04192 0.020959-0.055893 0.03525-0.013971 0.014608-0.023817 0.030169-0.029215 0.046682-0.005716 0.016513-0.008575 0.031756-0.008575 0.045729 0 0.039378 0.011116 0.069547 0.033979 0.090506 0.022865 0.020959 0.050812 0.038108 0.084473 0.051128 0.033663 0.013021 0.070499 0.02477 0.1102 0.03525 0.039696 0.01048 0.076534 0.025088 0.10988 0.044142 0.033661 0.018736 0.061924 0.043824 0.084789 0.075898 0.022547 0.031757 0.033979 0.075898 0.033979 0.13179 0 0.044459-0.008574 0.084472-0.025723 0.12036-0.01683 0.035885-0.039696 0.066053-0.068594 0.090506-0.028898 0.024452-0.062878 0.043189-0.10226 0.056209-0.039377 0.013338-0.081297 0.019689-0.12576 0.019689-0.059385 0-0.11591-0.01048-0.16926-0.031439-0.053034-0.020959-0.095587-0.053351-0.12703-0.096857l0.103-0.0894z" fill="#fff" />
      <path d="m31.023 6.477h0.12576v0.92792h-0.12576v-0.9279z" fill="#fff" />
      <path d="m31.497 6.477h0.32392c0.065418 0 0.1229 0.007622 0.17212 0.022547 0.04954 0.014608 0.092409 0.034615 0.12861 0.059384 0.036201 0.025088 0.066368 0.053668 0.090504 0.08606 0.023819 0.032074 0.042873 0.065419 0.056847 0.099398 0.013969 0.034297 0.024132 0.068276 0.030167 0.10226s0.009209 0.065418 0.009209 0.094316c0 0.059385-0.011112 0.11686-0.032707 0.17244-0.021912 0.055573-0.054306 0.1048-0.097176 0.14798-0.042873 0.043189-0.095905 0.078121-0.1591 0.10416-0.063513 0.026358-0.13655 0.039378-0.21944 0.039378h-0.30296v-0.928zm0.126 0.8101h0.15847c0.05335 0 0.10384-0.007304 0.15148-0.021595 0.047318-0.014608 0.089235-0.036202 0.12512-0.065101 0.035568-0.028899 0.064148-0.064784 0.085106-0.10797 0.020962-0.043189 0.031441-0.093682 0.031441-0.15148 0-0.029533-0.004765-0.064466-0.01429-0.10416-0.009846-0.039695-0.027946-0.077486-0.054623-0.11337s-0.061-0.0659-0.108-0.091c-0.047318-0.02477-0.10861-0.037155-0.18355-0.037155h-0.18864v0.692z" fill="#fff" />
      <path d="m32.62 6.477h0.59861v0.11813h-0.47285v0.2766h0.43983v0.11782h-0.43983v0.29756h0.49635v0.11782h-0.62211v-0.9279z" fill="#fff" />
      <path d="m34.075 7.2109c0.022865 0.034297 0.052078 0.059385 0.087646 0.075581 0.034931 0.015878 0.07177 0.024135 0.10924 0.024135 0.020962 0 0.042557-0.003176 0.064148-0.009844 0.021595-0.006669 0.04192-0.016513 0.059704-0.029533 0.017784-0.013021 0.032391-0.029216 0.043823-0.048588 0.011433-0.019054 0.017151-0.041283 0.017151-0.066689 0-0.035885-0.011433-0.063195-0.034298-0.081932s-0.050812-0.034615-0.084473-0.046999c-0.033661-0.012703-0.070499-0.025088-0.10988-0.036838-0.040012-0.01175-0.076851-0.028264-0.11051-0.049223-0.033661-0.020959-0.061607-0.049222-0.084473-0.084472-0.022228-0.03525-0.033661-0.083202-0.033661-0.14354 0-0.026993 0.005714-0.055891 0.017784-0.086378 0.011433-0.030486 0.02985-0.058432 0.055256-0.083837s0.057796-0.046682 0.097809-0.063513c0.039379-0.017148 0.08765-0.025723 0.14354-0.025723 0.050812 0 0.099079 0.006987 0.14545 0.020959 0.046364 0.013973 0.087009 0.042554 0.12194 0.085107l-0.10226 0.093047c-0.015877-0.024453-0.038109-0.044142-0.066689-0.05875-0.029217-0.014925-0.061607-0.022229-0.098446-0.022229-0.034931 0-0.064148 0.004446-0.087013 0.013655-0.023499 0.009209-0.041916 0.020959-0.055889 0.03525-0.013973 0.014608-0.024136 0.030169-0.029217 0.046682-0.005718 0.016513-0.008892 0.031756-0.008892 0.045729 0 0.039378 0.011433 0.069547 0.034298 0.090506s0.050808 0.038108 0.084473 0.051128c0.033661 0.013021 0.070499 0.02477 0.10988 0.03525 0.040016 0.01048 0.076851 0.025088 0.10988 0.044142 0.033661 0.018736 0.062244 0.043824 0.085106 0.075898 0.022232 0.031757 0.033665 0.075898 0.033665 0.13179 0 0.044459-0.008259 0.084472-0.025406 0.12036-0.019 0.0357-0.042 0.0659-0.07 0.0904-0.029213 0.024452-0.062878 0.043189-0.10225 0.056209-0.039379 0.013338-0.081299 0.019689-0.12576 0.019689-0.0597 0-0.11623-0.01048-0.16958-0.031439-0.053-0.021-0.095-0.0534-0.127-0.0969l0.104-0.0893z" fill="#fff" />
      <path d="m35.553 6.6777c-0.026676-0.034297-0.0597-0.060338-0.099712-0.078756-0.040016-0.018419-0.081932-0.02731-0.12449-0.02731-0.052715 0-0.099712 0.009527-0.14227 0.029216-0.042553 0.019689-0.078754 0.046682-0.1086 0.080661-0.030487 0.033979-0.053986 0.073993-0.070499 0.12004-0.016514 0.04573-0.024773 0.094952-0.024773 0.14735 0 0.048905 0.008259 0.09527 0.023502 0.13878 0.015877 0.043824 0.038742 0.082249 0.068592 0.11559 0.029217 0.033027 0.065418 0.059385 0.10861 0.078439 0.042553 0.019372 0.091457 0.028898 0.14544 0.028898 0.053352 0 0.099716-0.010797 0.14037-0.032709 0.040012-0.021912 0.07431-0.052398 0.10226-0.091776l0.10606 0.080027c-0.006985 0.009527-0.019688 0.023182-0.036835 0.041283-0.017784 0.017784-0.040649 0.035567-0.069229 0.053668-0.029217 0.017784-0.063515 0.033662-0.10416 0.047-0.040646 0.013655-0.087646 0.020324-0.141 0.020324-0.073673 0-0.13973-0.013973-0.1988-0.041919s-0.10924-0.064466-0.15052-0.10988c-0.041283-0.045412-0.07304-0.096857-0.095268-0.15402-0.021595-0.057162-0.032394-0.11528-0.032394-0.17371 0-0.07177 0.011433-0.13782 0.034935-0.19848 0.024132-0.060655 0.056526-0.11305 0.099079-0.1572 0.042553-0.044141 0.093365-0.078438 0.15306-0.10289 0.059067-0.024452 0.12448-0.036838 0.19626-0.036838 0.060974 0 0.12131 0.01175 0.18038 0.035567 0.059067 0.0235 0.10734 0.059702 0.14481 0.10861l-0.105 0.0801z" fill="#fff" />
      <path d="m35.96 6.477h0.12576v0.38552h0.48079v-0.3855h0.12576v0.92792h-0.12576v-0.4245h-0.48079v0.42458h-0.12576v-0.928z" fill="#fff" />
      <path d="m37.488 7.4285c-0.070499 0-0.13592-0.012385-0.19498-0.037155-0.059704-0.025088-0.11051-0.059385-0.15307-0.10289-0.041916-0.043824-0.075581-0.09527-0.099712-0.15466-0.023502-0.059384-0.035568-0.12353-0.035568-0.19276 0-0.068912 0.012066-0.13306 0.035568-0.19244 0.024132-0.059384 0.057796-0.11115 0.099712-0.15465 0.042557-0.043824 0.093365-0.078121 0.15307-0.10289 0.059067-0.025087 0.12448-0.037473 0.19498-0.037473s0.13592 0.012385 0.19498 0.037473c0.059704 0.02477 0.11051 0.059067 0.15306 0.10289 0.042557 0.043507 0.075581 0.09527 0.099716 0.15465 0.023502 0.059385 0.035568 0.12353 0.035568 0.19244 0 0.069229-0.012066 0.13338-0.035568 0.19276-0.024136 0.059385-0.057159 0.11083-0.099716 0.15466-0.042553 0.043506-0.093361 0.077803-0.15306 0.10289-0.059 0.0249-0.124 0.0373-0.195 0.0373zm0-0.1179c0.053352 0 0.10162-0.009844 0.14417-0.029533 0.042557-0.019689 0.079391-0.046047 0.10988-0.079391 0.030487-0.033027 0.054623-0.072087 0.071136-0.11718 0.016514-0.045094 0.024769-0.092729 0.024769-0.14354 0-0.050493-0.008255-0.098445-0.024769-0.14354-0.016514-0.044777-0.040649-0.083838-0.071136-0.11718-0.030487-0.033027-0.067322-0.059702-0.10988-0.079391-0.042553-0.019689-0.09082-0.029216-0.14417-0.029216s-0.10162 0.009527-0.14418 0.029216c-0.042553 0.019689-0.079388 0.046364-0.10988 0.079391-0.030487 0.033344-0.054623 0.072405-0.071136 0.11718-0.016514 0.045094-0.024769 0.093046-0.024769 0.14354 0 0.050811 0.008255 0.098445 0.024769 0.14354 0.016514 0.045094 0.040649 0.084155 0.071136 0.11718 0.030487 0.033344 0.067322 0.059702 0.10988 0.079391 0.043 0.0197 0.091 0.0295 0.144 0.0295z" fill="#fff" />
      <path d="m38.71 7.4285c-0.071136 0-0.13592-0.012385-0.19562-0.037155-0.059067-0.025088-0.11051-0.059385-0.15243-0.10289-0.042553-0.043824-0.075581-0.09527-0.099716-0.15466-0.024132-0.059384-0.036201-0.12353-0.036201-0.19276 0-0.068912 0.01207-0.13306 0.036201-0.19244 0.024136-0.059384 0.057163-0.11115 0.099716-0.15465 0.04192-0.043824 0.093365-0.078121 0.15243-0.10289 0.059704-0.025087 0.12448-0.037473 0.19562-0.037473 0.070499 0 0.13528 0.012385 0.19498 0.037473 0.0597 0.02477 0.11051 0.059067 0.15243 0.10289 0.042553 0.043507 0.075581 0.09527 0.099716 0.15465 0.024132 0.059385 0.036201 0.12353 0.036201 0.19244 0 0.069229-0.01207 0.13338-0.036201 0.19276-0.024136 0.059385-0.057163 0.11083-0.099716 0.15466-0.04192 0.043506-0.092731 0.077803-0.15243 0.10289-0.06 0.0249-0.125 0.0373-0.195 0.0373zm0-0.1179c0.052715 0 0.10098-0.009844 0.14417-0.029533 0.042553-0.019689 0.079391-0.046047 0.10988-0.079391 0.030487-0.033027 0.053986-0.072087 0.070499-0.11718 0.016514-0.045094 0.024769-0.092729 0.024769-0.14354 0-0.050493-0.008255-0.098445-0.024769-0.14354-0.016514-0.044777-0.040012-0.083838-0.070499-0.11718-0.030487-0.033027-0.067326-0.059702-0.10988-0.079391-0.04319-0.019689-0.091457-0.029216-0.14417-0.029216-0.053352 0-0.10162 0.009527-0.14418 0.029216-0.043186 0.019689-0.080025 0.046364-0.11051 0.079391-0.030487 0.033344-0.053986 0.072405-0.070499 0.11718-0.016514 0.045094-0.024769 0.093046-0.024769 0.14354 0 0.050811 0.008255 0.098445 0.024769 0.14354 0.016514 0.045094 0.040012 0.084155 0.070499 0.11718 0.030487 0.033344 0.067326 0.059702 0.11051 0.079391 0.042 0.0197 0.09 0.0295 0.144 0.0295z" fill="#fff" />
      <path d="m39.505 6.477h0.12576v0.81011h0.42617v0.11782h-0.55193v-0.9279z" fill="#fff" />
      <rect y="24.537" x="29.665" height="6.5036" width="3.3004" fill="#D6EFFB" />
      <rect y="24.537" x="33.548" height="6.5036" width="3.3004" fill="#fff" />
      <rect y="12.064" x="22.385" height="6.1154" width="4.8535" fill="#fff" />
      <rect y="12.064" x="38.66" height="6.1154" width="4.8535" fill="#D6EFFB" />
      <rect y="12.161" x="29.859" height="4.5813" width="2.2326" fill="#fff" />
      <rect y="17.384" x="29.859" height="4.5813" width="2.2326" fill="#fff" />
      <rect y="12.161" x="34.324" height="4.5813" width="2.2326" fill="#fff" />
      <rect y="17.384" x="34.324" height="4.5813" width="2.2326" fill="#fff" />
      <polygon points="16.56 35.652 21.511 30.992 44.662 30.992 50.195 35.797" fill="#00BBF1" />
      <rect y="22.839" x="22.239" height="1.8928" width="21.695" fill="#66CDF5" />
      <polygon points="66.11 22.915 61.43 21.438 56.981 23.002 56.9 14.482 66.029 14.395" fill="#00ADEE" />
      <path d="m56.757 23.321l-0.086315-9.0635 9.5829-0.09159 0.085358 9.0597-4.9056-1.5489-4.676 1.644zm0.372-8.615l0.074802 7.9769 4.2218-1.4841 4.4548 1.406-0.075768-7.9817-8.676 0.083z" fill="#fff" />
      <polygon points="61.32 4.3497 62.108 2.1235 62.741 4.3988 64.059 2.4387 64.107 4.7999 65.87 3.229 65.329 5.5279 67.428 4.4449 66.332 6.537 68.634 6.01 67.052 7.7639 69.413 7.8259 67.445 9.1313 69.716 9.7786 67.485 10.554 69.524 11.745 67.171 11.941 68.849 13.602 66.521 13.207 67.734 15.233 65.577 14.271 66.247 16.536 64.398 15.067 64.484 17.427 63.058 15.545 62.554 17.852 61.641 15.675 60.579 17.784 60.236 15.448 58.683 17.227 58.932 14.879 56.986 16.216 57.811 14.003 55.593 14.815 56.942 12.876 54.592 13.111 56.381 11.569 54.047 11.211 56.163 10.163 53.99 9.2361 56.301 8.7469 54.427 7.3089 56.787 7.4097 55.33 5.551 57.59 6.2354 56.641 4.0728 58.66 5.2978 58.279 2.9671 59.93 4.6558 60.14 2.3035" fill="#00ADEE" />
      <path d="m62.622 18.602l-1.0003-2.3856-1.1624 2.3108-0.37595-2.5592-1.7014 1.9498 0.27333-2.5731-2.1329 1.465 0.90343-2.424-2.4293 0.88856 1.4789-2.1233-2.576 0.25703 1.9613-1.6889-2.5568-0.39177 2.3171-1.1485-2.3794-1.0156 2.5309-0.53563-2.0514-1.5752 2.5837 0.11029-1.5959-2.0361 2.4763 0.7495-1.0396-2.3684 2.2125 1.3417-0.41815-2.5535 1.8078 1.85 0.23113-2.577 1.2918 2.2413 0.86315-2.4379 0.69435 2.4921 1.4434-2.1473 0.051788 2.5866 1.9315-1.7205-0.59174 2.518 2.2989-1.1863-1.2 2.2899 2.5223-0.57735-1.733 1.9215 2.5856 0.068092-2.156 1.4299 2.4887 0.70874-2.4446 0.84924 2.2336 1.3058-2.5779 0.21435 1.8385 1.8203-2.5501-0.43397 1.3283 2.2202-2.3631-1.054 0.73463 2.4806-2.0255-1.6083 0.094948 2.5851-1.5633-2.0624-0.552 2.528zm-0.962-3.469l0.82574 1.9694 0.45459-2.0869 1.2909 1.7028-0.077682-2.1353 1.6726 1.3283-0.60612-2.0481 1.9507 0.87082-1.0962-1.8337 2.1051 0.35821-1.5182-1.5028 2.1291-0.17694-1.8443-1.0785 2.0169-0.70059-2.0533-0.58502 1.78-1.1816-2.1348-0.055625 1.4299-1.5868-2.0831 0.47665 0.99167-1.8917-1.901 0.9799 0.49007-2.0797-1.5959 1.4213-0.042202-2.1358-1.1921 1.7723-0.57352-2.0576-0.713 2.014-1.0655-1.851-0.19085 2.1272-1.4932-1.5268 0.34526 2.1075-1.827-1.1077 0.85931 1.956-2.0447-0.61907 1.3177 1.6812-2.1349-0.09111 1.6946 1.301-2.0898 0.4426 1.9651 0.83821-1.9143 0.94802 2.1118 0.32368-1.6179 1.3945 2.1253-0.21195-1.2199 1.7532 2.0054-0.73416-0.74614 2.002 1.7608-1.2098-0.22538 2.1243 1.4041-1.6093 0.31073 2.1133 0.962-1.908z" fill="#fff" />
    </svg>
  );
}

export default SchoolLogo;
