/**
*
* PinShadow
*
*/

import React from 'react';

function PinShadow() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 30.5 15.333333" xmlSpace="preserve" viewBox="0 0 30.5 15.333333" version="1.1" y="0px" x="0px" xmlnsXlink="http://www.w3.org/1999/xlink">
      <radialGradient id="a" gradientUnits="userSpaceOnUse" cy="173.27" cx="-3088.1" gradientTransform="matrix(.67059 0 0 .30588 2086.1 -45.118)" r="21.351">
        <stop stopColor="#616161" offset=".000316" />
        <stop stopColor="#949495" stopOpacity=".67706" offset=".32316" />
        <stop stopColor="#D9D9DA" stopOpacity=".21586" offset=".78421" />
        <stop stopColor="#F4F4F5" stopOpacity="0" offset="1" />
      </radialGradient>
      <ellipse opacity=".7" rx="14.318" ry="6.531" cy="7.883" cx="15.217" fill="url(#a)" />
    </svg>
  );
}

export default PinShadow;
