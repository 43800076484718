import React from 'react';

import { Form, InputGroup } from 'react-bootstrap';
interface IProps {
  type:  string;
  name:  string;
  value: any;
  onChange?: (e: any) => void;
  onBlur?:   (e: any) => void;
  touched: any;
  errors: any;
  placeholder?: string;
  as?: string;
  options?: object;
  children?: any;
  disabled?: boolean;
}

function renderInputField(props) {
    return (
      <Form.Control
        type        = {props.type}
        name        = {props.name}
        value       = {props.value}
        onChange    = {props.onChange}
        onBlur      = {props.onBlur}
        isInvalid   = {props.touched && !!props.errors}
        placeholder = {props.placeholder}
        disabled    = {props.disabled}
      />
    );
}

function renderSelectField(props) {
  return (
    <Form.Control
      name        = {props.name}
      value       = {props.value}
      onChange    = {props.onChange}
      onBlur      = {props.onBlur}
      isInvalid   = {props.touched && !!props.errors}
      as          = "select"
      custom
    >
      <option value="">{props.placeholder}</option>
      {props.options.map(([name, value]) => <option value={value}>{name}</option>)}
    </Form.Control>
  );
}

export default function(props: IProps) {
  let fieldComponent = null;

  if (props.type === "select") {
    fieldComponent = renderSelectField(props);
  } else if (props.children !== undefined) {
    fieldComponent = props.children(props);
  } else {
    fieldComponent = renderInputField(props);
  }
  return (
    <Form.Group controlId={props.name}>
      {fieldComponent}
      <Form.Control.Feedback type="invalid">
      {props.errors}
      </Form.Control.Feedback>
    </Form.Group>
  )
}
