/* eslint indent: "off"*/
/* eslint no-multi-spaces: "off" */

/**
*
* Place
*
*/

import React from 'react';
import classNames from 'classnames';
import content from './content';

import styles from './styles.css';

// const FIFTEEN_MIN_WALK = 1.125;
const TWENTY_MIN_WALK  = 1.5;

function innerContent(variableContent, contentStyle, key) {
  return (
    <div className={classNames(styles.content, contentStyle)} key={key}>
      {variableContent}
    </div>
  );
}

class Place extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
      accordionExpanded: false,
    };
  }

  toggleAccordion = () => {
    this.setState({ accordionExpanded: !this.state.accordionExpanded });
  }

  render() {
    const {
      placeType,            // NOTE : used in DetailedReportLink for Kissmetrics
      iconType,
      logoType,             // NOTE : for sponsored convenience section
      genericPrimaryName,
      specificPrimaryName,
      secondaryName,
      gradeOfferings,
      gradeLevels,
      assignedGrades,       // NOTE: it is {true} if place_category_key === 'school-attendance-zones'
      catchmentDesignation, // NOTE : currently only {true} for schools highlights report
      address,
      distance,
      showWalkTime,
      facilities,           // NOTE : currently only used for parks
      features,             // TODO : check this
      featureKeys,          // TODO : check this
      url,
      urlMessage,
      handleClick,          // NOTE : used for tracking (Kissmetrics) place's url being clicked
      secondaryUrl,         // NOTE : primarily meant for Great Schools link
      secondaryUrlMessage,
      handleSecondaryClick,
      showLooKDeeper,       // NOTE : currently only being used in Schools component
      shiftLookDeeper,      // NOTE : affects styling of LookDeeper
      showCatchmentInfo,    // NOTE : currently only being used in Schools component
      showNoCatchmentInfo,  // NOTE : currently only being used in Schools component
      // hideDetailedRepLink,
      primaryIconStyle,     // NOTE : for colors, currently only used by Parks
      placeStyle,
      contentStyle,         // NOTE : for colors
      expandedInfoStyle,    // NOTE : for colors
      expanded,
      packageOptions,
    } = this.props;

    const { campaign, branding } = this.props;

    const viewingHKCampaign = campaign && campaign.get('campaign_id') === 'HarveyKallesCampaign';
    const schoolQBranding = branding === 'schoolq';

    const hideDetailedRepLink = packageOptions && !packageOptions.options.package_contents.includes('detailed');
    // const hideDetailedRepLink = viewingHKCampaign || schoolQBranding ? true : this.props.hideDetailedRepLink;

    // Shared in final display
    const lookDeeperDisplay        = content.lookDeeperDisplay(showLooKDeeper, shiftLookDeeper);
    const publicSchoolsInfoDisplay = content.publicSchoolsInfoDisplay(showCatchmentInfo, showNoCatchmentInfo);

    // Shared RegularCore and ExpandedCore Info Display Elements
    const primaryIconDisplay = content.primaryIconDisplay(iconType, logoType, primaryIconStyle);
    const walkTimeDisplay    = content.walkTimeDisplay(distance, showWalkTime, TWENTY_MIN_WALK);

    // Expanded Info Display Elements
    const [facilitiesExpandedDisplay, icons] = content.facilitiesAndIcons(facilities);

    const expandedInnerContent = (
      <div>
        {content.primaryNameExpandedDisplay(genericPrimaryName, specificPrimaryName, iconType)}
        {content.secondaryNameDisplay(secondaryName)}
        {content.schoolDesignation(catchmentDesignation, assignedGrades)}
        {content.schoolGradesDisplay(gradeOfferings, gradeLevels, assignedGrades, catchmentDesignation)}
        {content.addressExpandedDisplay(address, featureKeys)}
        {content.distanceExpandedDisplay(distance, featureKeys)}
        {facilitiesExpandedDisplay}
        {content.featuresDisplay(features, featureKeys, distance)}
        {content.primaryUrlDisplay(url, urlMessage, handleClick)}
        {content.secondaryUrlDisplay(secondaryUrl, secondaryUrlMessage, handleSecondaryClick)}
        {content.detailedReportLinkDisplay(placeType, hideDetailedRepLink)}
      </div>
    );

    const regularInnerContent = (
      <div>
        {content.primaryNameDisplay(genericPrimaryName, specificPrimaryName, iconType)}
        {content.secondaryNameDisplay(secondaryName)}
        {content.schoolDesignation(catchmentDesignation, assignedGrades)}
        {content.schoolGradesDisplay(gradeOfferings, gradeLevels, assignedGrades, catchmentDesignation)}
        {content.addressDisplay(address)}
        {/* content.distanceDisplay(distance) */}
        {content.facilitiesIconsDisplay(icons)}
      </div>
    );

    const expandedCore = [primaryIconDisplay, innerContent(expandedInnerContent, contentStyle, 'expanded'), walkTimeDisplay];
    const regularCore  = [primaryIconDisplay, innerContent(regularInnerContent, contentStyle, 'regular'), walkTimeDisplay];

    const expandedInfoContent = (
      <div className={classNames(styles.expandedInfo, expandedInfoStyle)}>
        {expandedCore}
      </div>
    );

    const regularInfoContent = (
      <div className={styles.regularInfo}>
        {regularCore}
      </div>
    );

    const accordionInfoContent = this.state.accordionExpanded ? (
      <div className={classNames(styles.regularInfo, expandedInfoStyle)}>
        <button className={styles.accordionButtonOne} onClick={this.toggleAccordion}>
          <i className={styles.chevronUp}></i>
        </button>
        {expandedCore}
      </div>
    ) : (
      <div className={styles.regularInfo}>
        <button className={styles.accordionButtonTwo} onClick={this.toggleAccordion}>
          <i className={styles.chevronDown}></i>
        </button>
        {regularCore}
      </div>
    );

    if (expanded) {
      return (
        <div>
          <div className={styles.nearbySchools}>
            {publicSchoolsInfoDisplay}
          </div>
          <div className={classNames(placeStyle, styles.place)}>
            <div className={classNames(styles.expandedInfoVisible, expandedInfoStyle)}>
              {expandedCore}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div>
          {publicSchoolsInfoDisplay}
        </div>
        <div className={classNames(placeStyle, styles.place, 'd-xs-none')}>
          {expandedInfoContent}
          {lookDeeperDisplay}
          {regularInfoContent}
        </div>
        <div className={classNames(placeStyle, styles.place, 'd-xs-block d-none')}>
          {accordionInfoContent}
        </div>
      </div>
    );
  }
}

// const mapStateToProps = createStructuredSelector({
//   campaign: selectHighlightsCampaignInfo(),
//   branding: selectHighlightsHoodqBranding(),
//   packageOptions: selectPackageOptions(),
// });

export default Place;
