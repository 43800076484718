// /**
// *
// * CancellationModal
// *
// */

// import React from 'react';
// import { connect } from 'react-redux';
// import { FormattedMessage } from 'react-intl';
// import classNames from 'classnames';

// import messages from './messages';
// import styles from './styles.css';

// import Modal from '../Modal';

// // import {
// //   displayHighlightsLoader,
// //   displayFlashMessage,
// //   hideModal,
// // } from 'containers/App/actions';

// // import {
// //   attemptCancelSubscription,
// // } from 'containers/AccountPage/actions';

// class CancellationModal extends React.Component { // eslint-disable-line react/prefer-stateless-function
//   render() {
//     return (
//       <Modal>
//         <h3>
//           <FormattedMessage {...messages.header} />
//         </h3>

//         <h5 className={styles.modalBody}>
//           <FormattedMessage {...messages.body} />
//         </h5>

//         <div className={classNames('row', styles.btnRow)}>
//           <div className={classNames('col-xs-3', styles.btnCol)}>
//             <button className={classNames('btn btn-danger', styles.btnDanger)} onClick={this.props.handleCancelSubscription}>
//               <FormattedMessage {...messages.cancelSubscriptionButton} />
//             </button>
//           </div>
//         </div>
//       </Modal>
//     );
//   }
// }

// // CancellationModal.propTypes = {
// //   handleCancelSubscription: React.PropTypes.func.isRequired,
// // };

// // function mapDispatchToProps(dispatch) {
// //   return {
// //     handleCancelSubscription: () => {
// //       dispatch(displayFlashMessage('info', 'cancelSubscriptionRequest', true));
// //       dispatch(hideModal());
// //       dispatch(displayHighlightsLoader());
// //       dispatch(attemptCancelSubscription());
// //     },

// //     dispatch,
// //   };
// // }

// export default CancellationModal;
