// import React from 'react';
// import { StripeProvider, Elements, CardElement } from 'react-stripe-elements';
// import fetchFromApi from 'hoodq-shared-js/lib/core/fetchFromApi';

// enum ProductOption { FREE, PACKAGE };

// declare global {
//   interface Window {
//     $: any
//   }
// }

// interface IProps {
//   address:   string;
//   stripeKey: string;
//   paidProductId: string;
//   freeProductId: string;
// }

// interface IState {
//   selectedProduct: ProductOption;
//   errorMessage:    string;
// }

// export default class MarketingCentrePurchaseModal extends React.Component<IProps, IState> {
//   state: IState = {
//     selectedProduct: ProductOption.PACKAGE,
//     errorMessage:    null
//   }
  
//   stripe: any;
//   stripeElement: any;

//   constructor(props) {
//     super(props);

//     this.stripe = window.Stripe(props.stripeKey);
//   }

//   purchaseOptionSelected = (ev) => {
//     this.setState({ selectedProduct: ev.target.value });
//   }

//   attemptPurchase = () => {
//     let csrfToken;
//     const { freeProductId, paidProductId } = this.props;
//     const { selectedProduct } = this.state;

//     const productId = selectedProduct == ProductOption.FREE ? freeProductId : paidProductId;

//     this.setState({ errorMessage: null });

//     try {
//       csrfToken = (document.querySelector("head meta[name='csrf-token']") as HTMLMetaElement).content;
//     } catch {
//       csrfToken = null;
//     }

//     fetchFromApi({
//       url: '',
//       path: `/products/${productId}/purchase_intent.json`,
//       method: 'POST',
//       params: { authenticity_token: csrfToken }
//     }).then((response: any) => {
//       if (response.require_payment) {
//         this.stripe.confirmCardPayment(response.client_secret, {
//           payment_method: {
//             card: this.stripeElement
//           }
//         }).then(result => {
//           if (result.error) {
//             this.setState({ errorMessage: result.error.message });
//           } else {
//             if (result.paymentIntent.status == 'succeeded') {
//               window.$('#marketing-centre-purchase-modal').modal('hide');
//             }
//           }
//         });
//       } else if (response.errors) {
//         console.error(response.errors);
//       } else {
//         if (response.free_product_url) {
//           document.location.href = response.free_product_url;
//         }
//         window.$('#marketing-centre-purchase-modal').modal('hide');
//       }
//     });
//   }

//   stripeReady = (el) => {
//     this.stripeElement = el;
//     this.stripeElement.on('change', ({ error }) => {
//       if (error) {
//         this.setState({ errorMessage: error.message });
//       } else {
//         this.setState({ errorMessage: null });
//       }
//     })
//   }

//   render() {
//     const { selectedProduct, errorMessage } = this.state;
//     const { address } = this.props;

//     return (
//       <StripeProvider stripe={this.stripe}>
//         <div className="modal fade" id="marketing-centre-purchase-modal">
//           <div className="modal-dialog modal-lg">
//             <div className="modal-content">
//               <div className="modal-body">
//                 <div className="container-fluid">
//                   <div className="row">
//                     <div className="col-12 text-center">
//                       <h4>Start Marketing Your Listing NOW</h4>
//                     </div>
//                   </div>

//                   <div className="row product-option">
//                     <div className="col-1 radio-container">
//                       <input type="radio" id="free" checked={selectedProduct == ProductOption.FREE} name="product-option" value={ProductOption.FREE} onClick={this.purchaseOptionSelected} />
//                     </div>
//                     <div className="col-11 label-container">
//                       <label htmlFor="free">Address Report - FREE! Our gift to you!</label>
//                     </div>
//                   </div>

//                   <div className="row product-option">
//                     <div className="col-12">
//                       <div className="row">
//                         <div className="col-3" id="item-count">
//                           <div id="item-count-container">
//                             <span>28</span>
//                             <span>items</span>
//                           </div>
//                         </div>
//                         <div className="col-9" id="package-offer-headline">
//                           <h2>Get the ENTIRE package of marketing material for {address}.</h2>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-12">
//                       <div className="row">
//                         <div className="col-6">
//                           Here is what has been created for you:
//                         </div>
//                         <div className="col-6">
//                           <ul>
//                             <li>1 Digital Postcard</li>
//                             <li>4 Print-Ready Postcards</li>
//                             <li>1 Digital Flyer</li>
//                             <li>1 Property Website</li>
//                             <li>1 Address Report</li>
//                             <li>1 School Report</li>
//                             <li>1 Open House Report</li>
//                             <li>1 Detailed Report</li>
//                             <li>1 Neighbourhood Report</li>
//                             <li>18 Social Media Templates</li>
//                           </ul>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-1">
//                       <input type="radio" id="package" checked={selectedProduct == ProductOption.PACKAGE} name="product-option" value={ProductOption.PACKAGE} onClick={this.purchaseOptionSelected} />
//                     </div>
//                     <div className="col-11">
//                       <label htmlFor="package">Buy My Marketing Package - $99.99 CDN - SAVE 115%</label>
//                     </div>
//                   </div>

//                   {
//                     selectedProduct == ProductOption.PACKAGE
//                       ? <div className="row">
//                           <div className="col-4 offset-4">
//                             <Elements>
//                               <CardElement onReady={this.stripeReady} />
//                             </Elements>
//                             {errorMessage && <span className="text-danger font-weight-bold">{errorMessage}</span>}
//                           </div>
//                         </div>
//                       : null
//                   }


//                   <div className="row">
//                     <div className="col-12 d-flex justify-content-center">
//                       <button className="btn btn-primary" onClick={this.attemptPurchase}>
//                         {
//                           selectedProduct == ProductOption.PACKAGE
//                             ? "BUY IT NOW"
//                             : "Get My Free Report"
//                         }
//                       </button>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </StripeProvider>
//     )
//   }
// }