/* eslint no-multi-spaces: "off" */
/* eslint react/jsx-equals-spacing: "off" */

/**
*
* HouseholdSize
*
*/

import React from 'react';
import { FormattedMessage } from 'react-intl';

import messages from './messages';
// import styles from './styles.css';

import BarGraph from '../BarGraph';

function HouseholdSize(props) {
  const {
    hoodName,
    householdSize,
  } = props;

  const graphColors = [
    (alpha) => `rgba(0,174,239,${alpha})`, // incomeDistroTradeAreaColors
    (alpha) => `rgba(196,214,0,${alpha})`, // incomeDistroBenchmarkColors
    (alpha) => `rgba(236,176,32,${alpha})`, // incomeDistroAltColors
  ];

  const data = {
    labels:   Object.keys(householdSize),
    datasets: [
      {
        label:                hoodName,
        backgroundColor:      graphColors[2](0.8),
        borderColor:          graphColors[2](1),
        borderWidth:          1,
        hoverBackgroundColor: graphColors[2](1),
        hoverBorderColor:     graphColors[2](1),
        data:                 Object.values(householdSize),
      },
    ],
  };

  const graphHeight = 240;

  const options = {
    maintainAspectRatio: true,
    scales: {
      xAxes: [{
        ticks: {
          callback(v) {
            return `${v.charAt(0)}${v.charAt(1)}`;
          },
        },
      }],
      yAxes: [{
        ticks: {
          callback(v) {
            return `${v*100}%`;
          }
        }
      }],
    },
  };

  const legend = {
    display: false,
  };

  return (
    <div>
      <h3><FormattedMessage {...messages.header} /></h3>
      <div style={{ marginTop: '15px' }} >
        <BarGraph
          data    = {data}
          height  = {graphHeight}
          options = {options}
          legend  = {legend}
        />
      </div>
      <p className="text-center"><FormattedMessage {...messages.graphName} /></p>
    </div>
  );
}

export default HouseholdSize;
