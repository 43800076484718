/* eslint indent: "off"*/

/**
*
* HarveyKallesLayout
*
*/

import React from 'react';
// import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

// import messages from './messages';
import styles from './styles.css';

function HarveyKallesLayout(props) {
  const { address, customHeaderImage } = props; // userEmail,

  const user = props.user || false;

  const firstName = user.firstName;
  const lastName = user.lastName;
  const lineOne = user.officePhoneNumber;
  const lineTwo = user.website;

  const addressContent = address ? (
    <div className={classNames('col-xs-12', styles.addressContent)} >
      <h2 className="d-xs-none">{address}</h2>
      <h4 className="d-xs-block d-none" style={{ margin: '0', width: '100%' }}>{address}</h4>
    </div>
  ) : null;

  const agentInfo = (
    <div className={styles.agentInfoNoPic}>
      <div className={classNames('d-xs-block d-none visible-sm', styles.agentInfoCenter)}>
        <div>{firstName} {lastName}</div>
        <div>{lineOne}</div>
        <div>{lineTwo}</div>
      </div>
      <div className="d-xs-none hidden-sm">
        <div>{firstName} {lastName}</div>
        <div>{lineOne}</div>
        <div>{lineTwo}</div>
      </div>
    </div>
  );

  const layout = (
    <div className={classNames('col-xs-12 text-left', styles.layout)}>
      <div className="row col-xs-12 col-sm-6">
        <img src={customHeaderImage} className={classNames('d-xs-block d-none', styles.ownMastheadOne)} alt="Own Masthead" />
        <img src={customHeaderImage} className={classNames('d-xs-none', styles.ownMastheadTwo)} alt="Own Masthead" />
      </div>
      <div className="row col-xs-12 col-sm-6 text-right">
        {agentInfo}
      </div>
    </div>
  );

  return (
    <div className={classNames('text-center row', styles.customHeaderLayout)}>
      {layout}
      {addressContent}
    </div>
  );

  // const { customStyle } = props;
  // const { firstName, lastName, sloganMessage, lineOne, lineTwo, lineThree, hideProfilePic, hideFirmLogo } = props.user; // retrieved, email,
  //
  // const profilePictureSrc = (
  //   props.user.profilePicture &&
  //   props.user.profilePicture.medium !== null &&
  //   props.user.profilePicture.medium
  // ) || '/assets/agent-photo.jpg';
  //
  // const firmLogoSrc = (
  //   props.user.firmLogo &&
  //   props.user.firmLogo.medium !== null &&
  //   props.user.firmLogo.medium
  // ) || '/assets/branding/remax.jpg';
  //
  // const firmLogo = hideFirmLogo ? null : (
  //   <div className={styles.firmLogo}>
  //     <img src={firmLogoSrc} alt="Agent Logo" />
  //   </div>
  // );
  //
  // const slogan = (
  //   <div className={styles.slogan}>
  //     <h3>{sloganMessage}</h3>
  //   </div>
  // );
  //
  // const profilePicture = hideProfilePic ? null : (
  //   <div className={classNames('col-xs-2', styles.profilePic)}>
  //     <img src={profilePictureSrc} alt="Agent Profile Pic" />
  //   </div>
  // );
  //
  // const agentInfo = (
  //   <div className={hideProfilePic ? styles.agentInfoNoPic : styles.agentInfo}>
  //     <h3>{firstName} {lastName}</h3>
  //     <div>{lineOne}</div>
  //     <div>{lineTwo}</div>
  //     <div>{lineThree}</div>
  //   </div>
  // );
  //
  // const layout = (
  //   <div className={classNames('col-xs-12 text-left', styles.layout)} style={customStyle}>
  //     <div className="row col-xs-12 col-sm-6">
  //       {profilePicture}
  //       {agentInfo}
  //     </div>
  //     <div className={classNames('col-xs-12 col-sm-6', styles.sloganLogo)}>
  //       {slogan}
  //       {firmLogo}
  //     </div>
  //   </div>
  // );
  //
  // return (
  //   <div className={classNames('text-center row', styles.customHeaderLayout)}>
  //     {layout}
  //     {addressContent}
  //   </div>
  // );
}

export default HarveyKallesLayout;
