/* eslint no-multi-spaces: "off" */
/* eslint react/jsx-equals-spacing: "off" */

/**
*
* PropertyTypesPriceDistribution
*
*/

import React from 'react';
import { FormattedMessage } from 'react-intl';

import messages from './messages';
// import styles from './styles.css';

import BarGraph from '../BarGraph';

function PropertyTypesPriceDistribution(props) {
  const {
    priceDistroCondo,
    priceDistroBungalow,
    priceDistroDuplex,
    priceDistroRow,
  } = props;

  const priceDistro = [
    priceDistroCondo,
    priceDistroBungalow,
    priceDistroDuplex,
    priceDistroRow,
  ];
  const saleLabels = ['Condo', 'Bungalow', 'Duplex', 'Row'];

  const lowSaleData    = priceDistro.map(p => p.low_sale_price);
  const medianSaleData = priceDistro.map(p => p.median_sale_price);
  const highSaleData   = priceDistro.map(p => p.high_sale_price);

  const graphColors = [
    (alpha) => `rgba(0,174,239,${alpha})`, // incomeDistroTradeAreaColors
    (alpha) => `rgba(196,214,0,${alpha})`, // incomeDistroBenchmarkColors
    (alpha) => `rgba(236,176,32,${alpha})`, // incomeDistroAltColors
  ];

  const propertyTypesPriceDistroData = {
    labels: saleLabels,
    datasets: [
      {
        label:                'Low Sale',
        backgroundColor:      graphColors[0](0.8),
        borderColor:          graphColors[0](1),
        borderWidth:          1,
        hoverBackgroundColor: graphColors[0](1),
        hoverBorderColor:     graphColors[0](1),
        data:                 lowSaleData,
      },
      {
        label:                'Median Sale',
        backgroundColor:      graphColors[1](0.8),
        borderColor:          graphColors[1](1),
        borderWidth:          1,
        hoverBackgroundColor: graphColors[1](1),
        hoverBorderColor:     graphColors[1](1),
        data:                 medianSaleData,
      },
      {
        label:                'High Sale',
        backgroundColor:      graphColors[2](0.8),
        borderColor:          graphColors[2](1),
        borderWidth:          1,
        hoverBackgroundColor: graphColors[2](1),
        hoverBorderColor:     graphColors[2](1),
        data:                 highSaleData,
      },
    ],
  };

  const graphWidth  = 95;
  const graphHeight = 35;

  const options = {
    maintainAspectRatio: true,
    scales: {
      yAxes: [{
        ticks: {
          callback(value) {
            return `$${Number(value).toLocaleString('en')}`;
          },
        },
      }],
    },
  };

  return (
    <div className="col-xs-12 col-sm-9">
      <h4><FormattedMessage {...messages.propertyTypePriceDistro} /></h4>
      <BarGraph
        data    = {propertyTypesPriceDistroData}
        width   = {graphWidth}
        height  = {graphHeight}
        options = {options}
      />
    </div>
  );
}

export default PropertyTypesPriceDistribution;
