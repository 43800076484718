/* eslint no-multi-spaces: "off" */
/* eslint react/jsx-equals-spacing: "off" */

/**
*
* PeriodHomeConstruction
*
*/

import React from 'react';
import { FormattedMessage } from 'react-intl';

import messages from './messages';
// import styles from './styles.css';

import HorizontalBarGraph from '../HorizontalBarGraph';

function PeriodHomeConstruction(props) {
  const {
    hoodName,
    // cityName,
    hoodConstruction,
    // cityConstruction,
  } = props;

  const graphColors = [
    (alpha) => `rgba(0,174,239,${alpha})`,
    (alpha) => `rgba(196,214,0,${alpha})`,
    (alpha) => `rgba(236,176,32,${alpha})`,
  ];

  const data = {
    // NOTE : should be same as Object.keys(cityConstruction)
    labels: Object.keys(hoodConstruction),
    datasets: [
      {
        label:                hoodName,
        backgroundColor:      graphColors[0](0.8),
        borderColor:          graphColors[0](1),
        borderWidth:          1,
        hoverBackgroundColor: graphColors[0](1),
        hoverBorderColor:     graphColors[0](1),
        data:                 Object.values(hoodConstruction),
      },
      // {
      //   label:                cityName,
      //   backgroundColor:      graphColors[1](0.8),
      //   borderColor:          graphColors[1](1),
      //   borderWidth:          1,
      //   hoverBackgroundColor: graphColors[1](1),
      //   hoverBorderColor:     graphColors[1](1),
      //   data:                 Object.values(cityConstruction),
      // },
    ],
  };

  const graphWidth  = 95;
  const graphHeight = 95;

  const options = {
    maintainAspectRatio: true,
    scales: {
      xAxes: [{
        ticks: {
          callback(v) {
            return `${Number(v).toLocaleString('en')}%`;
          },
        },
      }],
    },
  };

  return (
    <div className="col-xs-12 col-sm-3">
      <h4><span><FormattedMessage {...messages.header} /></span></h4>
      <HorizontalBarGraph
        data    = {data}
        width   = {graphWidth}
        height  = {graphHeight}
        options = {options}
      />
    </div>
  );
}

export default PeriodHomeConstruction;
