/*
 * ConvenienceReport Messages
 *
 * This contains all the text for the ConvenienceReport component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.ConvenienceReport.header',
    defaultMessage: 'Convenience',
  },
  description: {
    id: 'app.components.ConvenienceReport.description',
    defaultMessage: 'Being close to things you need every day makes life so much easier. See the conveniently located retailers near you below:',
  },
});
